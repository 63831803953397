import {Moment} from "moment";
import {RecordsProfile} from "./medicalRecord";
import {Document} from "./document";

import {Entity} from "./entity";

export class Education extends Entity {

    description: string
    supplier: { name: string, id: string, taxNumber: string }
    expectedDate: Moment | null = null
    date: Moment | null = null
    certificate: Document | null = null

    constructor() {
        super();
    }

    override getDocuments(): Document[] {
        return [this.certificate!].flat().filter(value => !!value)
    }
}

export class EducationProfile extends RecordsProfile<Education> {
}
