<form #form="ngForm" (ngSubmit)="save(project)">
    <article class="page">
        <header>
            <h1><span i18n="@@project">Progetto</span> {{ project.name }}</h1>

            <div class="flex-row">
                <button type="submit" mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above"
                        [disabled]="loading" [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above"
                        (click)="discardChanges(project)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>

        <div class="content">

            <mat-tab-group>
                <mat-tab label="Dettagli" i18n-label="@@details" labelClass="tab-label">
                        <div class="tab-content">
                            <mat-card class="infoCard registry-information">
                                <mat-card-header>
                                    <mat-card-title i18n="@@details">Dettagli</mat-card-title>

                                    <mat-icon mat-card-avatar>source</mat-icon>
                                </mat-card-header>

                                <mat-card-content>

                                    <mat-form-field class="flexible-size">
                                        <mat-label i18n="@@name">Nome</mat-label>
                                        <input name="name" #name="ngModel" required matInput [(ngModel)]="project.name">
                                        <mat-error *ngIf="name.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
                                    </mat-form-field>


                                    <mat-form-field>
                                        <mat-label i18n="@@address">Indirizzo</mat-label>
                                        <input name="address" #address="ngModel" required matInput [(ngModel)]="project.address">
                                        <mat-error *ngIf="address.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
                                    </mat-form-field>

                                </mat-card-content>

                            </mat-card>
                            <documents name="registryDocuments" class="registry-documents" [(ngModel)]="project.registryDocuments" [batch]="documentStorageBatch"
                                       i18n="@@documents">
                                Documenti
                            </documents>
                    </div>


                </mat-tab>
                <mat-tab label="Pianificazione" i18n-label="@@planning" labelClass="tab-label">
                    <ng-template matTabContent>
                        <div class="tab-content">
                            <activities name="activities" [(ngModel)]="project.activities" (activityChanged)="onActivityChanged(project)"></activities>
                            <journal [project]="project"></journal>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Contabilit&agrave;" i18n-label="@@accounting" labelClass="tab-label">
                    <ng-template matTabContent>
                        <div class="tab-content" *ngIf="balanceDetailsObservable | async as details">

                            <mat-card class="infoCard summary">
                                <mat-card-header>
                                    <mat-icon mat-card-avatar>summarize</mat-icon>
                                    <mat-card-title i18n="@@summary">Riepilogo</mat-card-title>
                                </mat-card-header>

                                <mat-card-content>

                                    <table>
                                        <tr>
                                            <td>
                                                <mat-icon>request_quote</mat-icon>
                                            </td>
                                            <td><span i18n="@@quoted_amount">Preventivato</span></td>
                                            <td class="currencyColumn">{{ details.amounts.quoted | formatCurrency}}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <mat-icon>arrow_backward</mat-icon>
                                            </td>
                                            <td><span i18n="@@outbound_amount">Fatture ricevute</span></td>
                                            <td class="currencyColumn">{{ details.amounts.paid | formatCurrency}}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <mat-icon>arrow_forward</mat-icon>
                                            </td>
                                            <td><span i18n="@@inbound_amount">Fatture emesse</span></td>
                                            <td class="currencyColumn">{{ details.amounts.received | formatCurrency}}</td>
                                        </tr>

                                    </table>


                                </mat-card-content>

                            </mat-card>

                            <payments [balance]="details.outbound" [mode]="'outbound'"></payments>
                            <payments [balance]="details.inbound" [mode]="'inbound'"></payments>
                            <payments [balance]="details.quotes" [mode]="'quotes'"></payments>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Forniture" i18n-label="@@supplies" labelClass="tab-label">
                    <ng-template matTabContent>
                        <div class="tab-content">
                            <supplies [batch]=documentStorageBatch *ngIf="project.supplies" [supplies]="project.supplies" [project]="project"></supplies>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>

            <!--<mat-card *ngIf="!project.isNew()" class="infoCard employees">
                <mat-card-header>
                    <mat-card-title>Dipendenti Assegnati</mat-card-title>

                    <mat-icon mat-card-avatar>
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor"
                                  d="M6 8C6 5.79 7.79 4 10 4S14 5.79 14 8 12.21 12 10 12 6 10.21 6 8M10 14C5.58 14 2 15.79 2 18V20H13.09C13.04 19.67 13 19.34 13 19C13 17.36 13.66 15.87 14.74 14.78C13.41 14.29 11.78 14 10 14M18 18V16L15 19L18 22V20H22V18H18Z"/>
                        </svg>
                    </mat-icon>
                </mat-card-header>

                <mat-card-content>

                    <section>
                        <div *ngIf="assignedEmployees" class="flex-row">
                            <div class="flex-column" *ngFor="let employee of assignedEmployees"
                                 [routerLink]="['/employees', employee.id]">
                                <mat-icon>account_circle</mat-icon>
                                <div class="employeeDescription">
                                    <p class="name">{{employee.fullName}}</p>
                                    <p>{{employee.function}}</p>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="assignedEmployees.length == 0">
                            <p>Nessun dipendente assegnato</p>
                        </div>

                    </section>

                </mat-card-content>
            </mat-card>-->


        </div>
    </article>
</form>


