<article class="page">

    <header><h1 i18n="@@privacy_policy">Privacy Policy</h1></header>
    <div>
        <p i18n="@@privacy_policy_p1">Ci impegniamo a proteggere la tua privacy e a farlo con la maggiore trasparenza possibile, senza abusare di
            riferimenti a normative che non fanno altro che confondere i più, ma facendoti capire realmente come vengono
            gestite le informazioni che ci condividi. Per qualsiasi tipo di informazione in merito, potrai sempre
            contattare
            il nostro responsabile della protezione dei dati.</p>

        <h2 i18n="@@privacy_policy_h2_1">Premessa</h2>
        <p i18n="@@privacy_policy_p2">La presente Privacy Policy descrive come Edilie.com raccoglie, utilizza, condivide e protegge le
            informazioni
            personali dei propri utenti. La presente Privacy Policy si applica solo alle attività di Edilie.com e non
            è
            valida per le attività di terze parti che Edilie.com potrebbe eventualmente pubblicizzare sulle proprie
            pagine
            web.</p>

        <h2 i18n="@@privacy_policy_h2_2">Raccolta e utilizzo delle informazioni personali</h2>
        Edilie.com raccoglie le informazioni personali dei propri utenti solo quando questi volontariamente forniscono
        tali informazioni, ad esempio tramite la registrazione all'utilizzo della nostra applicazione web o attraverso
        la
        compilazione dei vari moduli elettronici messi a disposizione per la gestione della propria attività.

        <br>

        <p i18n="@@privacy_policy_p3">Edilie.com utilizza le informazioni personali dei propri utenti per migliorare l'utilizzo della nostra
            applicazione web, per rispondere alle richieste dei propri utenti e per fornire supporto tecnico. Inoltre,
            Edilie.com può utilizzare le informazioni personali per inviare agli utenti informazioni sulle nostre
            attività
            e sui nostri prodotti e servizi.</p>

        <h2 i18n="@@privacy_policy_h2_3">Tipologia di dati raccolti e trattati</h2>
        <p i18n="@@privacy_policy_p4">Quando crei il tuo account ed usufruisci dei nostri servizi online, effettuiamo un trattamento dei tuoi dati
            personali. Il tipo di informazioni raccolte e gestite dipende dai moduli utilizzati e, a titolo
            esemplificativo
            ma non esaustivo, possono essere:</p>

        <ol i18n="@@privacy_policy_list">
            <li> nome e cognome</li>
            <li> indirizzo</li>
            <li> indirizzo email</li>
            <li> data di nascita</li>
            <li> luogo di nascita</li>
            <li> numero di telefono</li>
            <li> documento d'identità o patente</li>
            <li> indirizzo IP e log degli eventi</li>
            <li> dati bancari</li>
            <li> account nome o nickname</li>
        </ol>

        <h2 i18n="@@privacy_policy_h2_3">Diritti dell'interessato</h2>
        <p>Fedelmente a quanto previsto dagli artt. 15 e ss del GDPR, l'interessato potrà esercitare i seguenti
            diritti:</p>

        <br>

        <ol i18n="@@privacy_policy_list2">
            <li>accesso: conferma o meno che sia in corso un trattamento dei dati personali dell’interessato e diritto
                di
                accesso agli stessi; non è possibile rispondere a richieste manifestamente infondate, eccessive o
                ripetitive;
            </li>

            <li>rettifica: correggere/ottenere la correzione dei dati personali se errati o obsoleti e di completarli,
                se
                incompleti;
            </li>

            <li>cancellazione/oblio: ottenere, in alcuni casi, la cancellazione dei dati personali forniti; questo non è
                un
                diritto assoluto, in quanto Il Titolare al trattamento dati potrebbe avere motivi legittimi o legali per
                conservarli;
            </li>

            <li>limitazione: i dati saranno archiviati, ma non potranno essere né trattati, né elaborati ulteriormente,
                nei
                casi previsti dalla normativa;
            </li>

            <li>portabilità: spostare, copiare o trasferire i dati dai database di Edilie.com a terzi. Questo vale
                solo
                per i dati forniti dall’interessato per l’esecuzione di un contratto o per i quali è stato fornito
                consenso
                espresso e il trattamento viene eseguito con mezzi automatizzati;
            </li>

            <li>opposizione al marketing diretto;</li>

            <li>revoca del consenso in qualsiasi momento, qualora il trattamento si basi sul consenso;</li>
        </ol>


        <p i18n="@@privacy_policy_p5">Ai sensi dell’art. 2-undicies del D.Lgs. 196/2003 l’esercizio dei diritti dell’interessato può essere
            ritardato,
            limitato o escluso, con comunicazione motivata e resa senza ritardo, a meno che la comunicazione possa
            compromettere la finalità della limitazione, per il tempo e nei limiti in cui ciò costituisca una misura
            necessaria e proporzionata, tenuto conto dei diritti fondamentali e dei legittimi interessi
            dell’interessato, al
            fine di salvaguardare gli interessi di cui al comma 1, lettere a) (interessi tutelati in materia di
            riciclaggio), e) (allo svolgimento delle investigazioni difensive o all’esercizio di un diritto in sede
            giudiziaria) ed f) (alla riservatezza dell’identità del dipendente che segnala illeciti di cui sia venuto a
            conoscenza in ragione del proprio ufficio). In tali casi, i diritti dell’interessato possono essere
            esercitati
            anche tramite il Garante con le modalità di cui all’articolo 160 dello stesso Decreto. In tale ipotesi, il
            Garante informerà l’interessato di aver eseguito tutte le verifiche necessarie o di aver svolto un riesame
            nonché della facoltà dell’interessato di proporre ricorso giurisdizionale.</p>


        <p i18n="@@privacy_policy_p6">Si precisa, inoltre, che - prima dell’evasione delle richieste – il Titolare del trattamento dati potrà
            effettuare un accertamento dell’identità dell’interessato identità, per valutare la legittimità della
            richiesta
            ricevuta.</p>


        <p i18n="@@privacy_policy_p7">Per esercitare tali diritti l’interessato potrà rivolgersi al Titolare del trattamento dati in relazione agli
            ambiti come sopra definiti.</p>

        <br>

        <p i18n="@@privacy_policy_p8">Il Titolare del trattamento risponderà entro 30 giorni dalla ricezione della richiesta formale inviata
            dall’interessato.</p>

        <br>

        <p i18n="@@privacy_policy_p9">Ricordiamo che in caso di violazione dei dati personali dell’interessato, quest’ultimo potrà proporre un
            reclamo
            all’autorità competente: “Garante per la protezione dei dati personali”.</p>

        <h2 i18n="@@privacy_policy_h2_4">Obbligatorietà del conferimento dei dati</h2>
        <p i18n="@@privacy_policy_p10">L’interessato deve fornire a Edilie.com i dati necessari per lo svolgimento del rapporto contrattuale, come
            pure i dati necessari ad adempiere ad obblighi previsti da leggi, regolamenti, normative comunitarie, ovvero
            da
            disposizioni di Autorità a ciò legittimate dalla legge e da organi di vigilanza e controllo (di cui alle
            finalità a) e f) sopra indicate).</p>

        <p i18n="@@privacy_policy_p11">I dati non essenziali per lo svolgimento del rapporto contrattuale sono qualificati e considerati
            supplementari e
            il loro conferimento da parte dell’interessato, se richiesto, è facoltativo e vincolato al consenso. Il
            consenso
            fornito potrà essere revocato dall’interessato in qualsiasi momento scrivendo una email al Titolare del
            trattamento dati. Tale revoca non pregiudicherà in alcun modo la liceità del trattamento basata sui consensi
            conferiti prima della revoca.</p>

        <h2 i18n="@@privacy_policy_h2_5">Condivisione delle informazioni personali</h2>
        <p>Edilie.com non vende, affitta o cede le informazioni personali dei propri utenti a terze parti. Tuttavia,
            Edilie.com potrebbe condividere le informazioni personali dei propri utenti con società affiliate o altre
            terze parti solo nella misura in cui sia necessario per svolgere i propri obblighi nei confronti dei propri
            utenti o per far rispettare i termini della presente Privacy Policy.</p>

        <br>

        <p i18n="@@privacy_policy_p12">I dati forniti dall’interessato saranno trattati solo in Paesi siti all’interno dell’Unione Europea. Qualora
            i
            dati personali dell’interessato siano trattati in uno stato non appartenente all’UE, saranno garantiti i
            diritti
            attribuiti a quest’ultimo dalla normativa comunitaria e verrà data tempestiva comunicazione
            all’interessato.</p>

        <h2 i18n="@@privacy_policy_h2_6">Protezione delle informazioni personali</h2>
        <p i18n="@@privacy_policy_p13">Edilie.com adotta misure tecniche e organizzative appropriate per proteggere le informazioni personali dei
            propri utenti da accesso non autorizzato, utilizzo improprio, divulgazione, alterazione o distruzione.
            Tuttavia,
            poiché non esiste alcuna soluzione di sicurezza perfetta, Edilie.com non garantisce che le informazioni
            personali dei propri utenti siano al sicuro in ogni momento.</p>

        <h2 i18n="@@privacy_policy_h2_7">Modifiche alla presente Privacy Policy</h2>
        <p i18n="@@privacy_policy_p14">Edilie.com si riserva il diritto di modificare la presente Privacy Policy in qualsiasi momento. Qualora
            vengano
            apportate modifiche alla presente Privacy Policy, Edilie.com le pubblicherà su questa pagina web.</p>

        <h2 i18n="@@privacy_policy_h2_8">Titolare del trattamento</h2>
        <p i18n="@@privacy_policy_p15">Il Titolare del trattamento è il soggetto che determina le finalità e mezzi del trattamento dei dati
            personali.
            Pragmatic Developer Ltd, in persona del suo legale rappresentante p.t., con sede a Derby, opera come Titolare
            del
            Trattamento ed è contattabile, per comunicazioni inerenti alla presente informativa, compreso l’esercizio
            dei
            diritti indicati nella stessa, all’indirizzo info@edilie.com</p>

        <h2 i18n="@@privacy_policy_h2_9">Responsabile della protezione dei dati</h2>
        <p i18n="@@privacy_policy_p16">Il responsabile della protezione dei dati è una figura esperta che collabora alla protezione dei tuoi dati. È
            altrimenti nominato DPO, Data Protection Officer, e potrai contattarlo, per qualsiasi richiesta sul tema,
            all’indirizzo info@edilie.com</p>
    </div>
</article>
