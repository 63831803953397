import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {FiscalDocument} from "../model/fiscalDocument";
import {Line} from "../model/invoice";

@Directive({
    selector: '[validLines]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => LinesValidatorDirective),
        multi: true
    }]
})
export class LinesValidatorDirective implements Validator {

    @Input('fiscalDocument') fiscalDocument: FiscalDocument

    constructor() {
    }

    validate(control: AbstractControl):  ValidationErrors | null  {
            if(!this.fiscalDocument) return null

            if (this.fiscalDocument && this.fiscalDocument.lines.length == 0) {
                return {emptyInvoice: true}
            } else if( this.isAnyInvalidLine(this.fiscalDocument.lines)) {
                return {invalidLine: true}
            }

            return null;
    }

    private isAnyInvalidLine(lines: Line[]): boolean {
        return !!lines.find(line => this.isInvalid(line))
    }

    private isInvalid(line: Line) {
        return !line.description || line.cost === null || line.cost === undefined  || line.vat === null
            || line.vat === undefined || line.quantity === undefined
    }

}
