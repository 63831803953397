import {Injectable} from '@angular/core';
import {ProjectRepository, ProjectRepositoryConfiguration} from "./project-repository.service";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {Firestore} from "@angular/fire/firestore";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class ProjectRepositoryResolver  {

    constructor(private firestore: FirestoreWrapper) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ProjectRepository {
        let clientId = route.paramMap.get('clientId') || route.parent?.paramMap.get('clientId')
        return new ProjectRepository(this.firestore, new ProjectRepositoryConfiguration(clientId!))
    }

}
