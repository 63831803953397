import {Component, Inject} from '@angular/core';
import {DocumentStorageBatch} from "../../../document-storage.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Payslip} from "../../../model/payroll";

@Component({
  selector: 'payslip-record-dialog',
  templateUrl: './payslip-record-dialog.component.html',
  styleUrls: ['./payslip-record-dialog.component.scss']
})
export class PayslipRecordDialog {
    record: Payslip
    batch: DocumentStorageBatch
    editMode: boolean;

    months: any[] = [
        {name: "Gennaio", number: 1},
        {name: "Febbraio", number: 2},
        {name: "Marzo", number: 3},
        {name: "Aprile", number: 4},
        {name: "Maggio", number: 5},
        {name: "Giugno", number: 6},
        {name: "Luglio", number: 7},
        {name: "Agosto", number: 8},
        {name: "Settembre", number: 9},
        {name: "Ottobre", number: 10},
        {name: "Novembre", number: 11},
        {name: "Dicembre", number: 12},
    ]

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PayslipRecordDialog>) {
        this.record = data.record
        this.batch = data.batch
        this.editMode = data.editMode
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.dialogRef.close(this.record)
    }

    cancel(event: MouseEvent) {
        event.stopPropagation()
        event.preventDefault()
        this.dialogRef.close(null)
    }
}
