import {Business} from "./business";
import {Moment} from "moment";
import {Entity} from "./entity";
import {persist, PersistenceConfig} from "../persistence/decorators";

export enum DocumentStatus {
    UPLOADED = 'UPLOADED',
    DELETED = 'DELETED'
}

@persist(new PersistenceConfig({
    name: 'Document',
    timestamped: true,
    properties: ['name', 'fileName', 'fullPath', 'downloadUrl', 'status']
}))
export class Document extends Entity {

    public name: string
    public fileName: string
    public fullPath?: string
    public downloadUrl?: string
    public file?: File
    public businessId: string;
    public status: DocumentStatus
    public createdTime: Moment | null = null
    public updatedTime: Moment | null = null

    constructor() {
        super()
    }

    isUploaded(): boolean {
        return !!this.fullPath;
    }

    isDownloadable(): boolean {
        return this.isUploaded() && this.downloadUrl != null
    }

    hasFileData(): boolean {
        return !!this.file
    }

    onUploaded(fullPath: string, url: string) {
        if (!fullPath || !url) throw new Error("invalid state onuploaded")

        this.status = DocumentStatus.UPLOADED
        this.fullPath = fullPath
        this.downloadUrl = url
    }

    markDeleted() {
        this.status = DocumentStatus.DELETED
    }

    isDeleted() {
        return this.status == DocumentStatus.DELETED
    }


    override toString(): string {
        return this.fileName || this.name
    }

    static makeDocument(name: string, file: File, business: Business): Document {
        let document = new Document()
        document.name = name
        document.fileName = file.name
        document.file = file
        document.businessId = business.id

        return document
    }

}
