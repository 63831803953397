import {Component, Input, OnInit} from '@angular/core';
import {Supplier} from "../../model/supply";
import {SupplierRepository} from "../supplier.repository";
import {SupplyRepository} from "../../projects-management/supply/supply-repository.service";

@Component({
    selector: 'supplier-card',
    templateUrl: './supplier-card.component.html',
    styleUrls: ['./supplier-card.component.scss']
})
export class SupplierCardComponent implements OnInit {

    @Input() supplier: Supplier;

    constructor(private supplierRepository: SupplierRepository, private supplyRepository: SupplyRepository) {
    }

    ngOnInit(): void {
        this.supplyRepository.findSuppliesBySupplier(this.supplier.id).subscribe(value => this.supplier.supplies = value? value : [])
    }

}
