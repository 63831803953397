import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {Employee} from "../../model/employee";
import {ActivatedRoute} from "@angular/router";
import {EmployeeRepository} from "../employee.repository";
import {DocumentStorageService} from "../../document-storage.service";
import {NavigatorService} from "../../navigator.service";
import {NotificationService} from "../../notification.service";
import {FormComponentV2} from "../../components/formV2.component";
import {doc, Transaction} from "@angular/fire/firestore";
import {SupplierRepository} from "../../suppliers/supplier.repository";
import {Supplier} from "../../model/supply";
import {ReminderRepository} from "../../notifications/reminder.repository";
import {Reminder} from "../../model/reminder";
import {NotificationType} from "../../model/notification";
import * as moment from "moment/moment";
import {momentToDate} from "../../utils";

@Component({
    selector: 'app-employee',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent extends FormComponentV2<Employee> implements OnInit {

    employee: Employee
    suppliersObservable: Observable<Supplier[]>;

    constructor(navigatorService: NavigatorService, notificationService: NotificationService,
                repository: EmployeeRepository,
                documentStorage: DocumentStorageService,
                private reminderRepository: ReminderRepository,
                private suppliersRepository: SupplierRepository,
                private route: ActivatedRoute) {
        super(documentStorage, navigatorService, notificationService, repository);
    }

    ngOnInit(): void {
        this.employee = this.route.snapshot.data.employee as Employee;
        this.suppliersObservable = this.suppliersRepository.getAll()
    }

    async doCreate(transaction: Transaction, employee: Employee) {
        this.repository.transactionalSave(transaction, employee)
        this.saveReminders(employee, transaction)
    }

    async doUpdate(transaction: Transaction, employee: Employee): Promise<void> {
        await this.repository.transactionalUpdate(transaction, employee.id, {
            ...employee,
            version: employee.version + 1
        })
        this.saveReminders(employee, transaction)
    }

    delete(employee: Employee) {
        this.repository.delete(employee)
    }

    saveReminders(employee: Employee, transaction: Transaction) {
        let elements = [
            ...employee.medicalProfile.records.map(value => {
                return {record: value, type: NotificationType.MEDICAL_RECORD}
            }),
            ...employee.educationProfile.records.map(value => {
                return {record: value, type: NotificationType.EDUCATION}
            }),
        ]

        let reminders = elements.filter(element => !element.record.date && element.record.expectedDate && moment().isBefore(element.record.expectedDate))
            .map(element => {
                let record = element.record;

                let reminder = new Reminder();
                reminder.notificationType = element.type
                reminder.expiryDate = momentToDate(record.expectedDate!)
                reminder.id = `${NotificationType[reminder.notificationType]}:${record.id}`
                reminder.notificationPayload = {
                    employee: {id: employee.id, name: employee.name},
                    record: {
                        id: record.id,
                        expectedDate: momentToDate(record.expectedDate!),
                        description: record.description
                    }
                }

                return reminder
            })


        return reminders.map(async reminder => {
            let collectionReference = this.reminderRepository.getCollectionReference()
            let documentReference = doc(collectionReference, reminder.id);

            transaction.set(documentReference, reminder, {merge: true})
        })
    }
}
