import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import htmlToPdfmake from "html-to-pdfmake"
import {XmlExporterService} from "../xml-exporter.service";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {DocumentStorageService} from "../../document-storage.service";
import {FiscalDocument} from "../../model/fiscalDocument";
// @ts-ignore
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'export-invoice-to-pdf',
    templateUrl: './export-invoice-to-pdf.component.html',
    styleUrls: ['./export-invoice-to-pdf.component.scss']
})
export class ExportInvoiceToPdfComponent implements OnInit {

    @Input() fiscalDocument: FiscalDocument
    @Input() form: NgForm

    constructor(private xmlExporterService: XmlExporterService, private storageService: DocumentStorageService) {
    }

    ngOnInit(): void {
    }

    public downloadAsPDF(form: NgForm) {
        // if (!form.valid) {
        //     console.log("export to pdf, invalid form", form.errors, form.control.errors)
        //     this.getFormValidationErrors(form)
        //     form.control.markAllAsTouched();
        //     return
        // }

        this.storageService.retrieveBlob(this.fiscalDocument.filePath).then(invoiceXml => {

            this.xmlExporterService.getXsl(this.fiscalDocument.receiver.isPublicAdministration())
                .subscribe({
                    next: (value) => {
                        let xsl = value;
                        let xml = invoiceXml;
                        var parser = new DOMParser();
                        var xslDoc = parser.parseFromString(xsl, "application/xml");
                        // @ts-ignore
                        var xmlDoc = parser.parseFromString(xml, "application/xml");

                        let xsltProcessor = new XSLTProcessor();
                        xsltProcessor.importStylesheet(xslDoc);
                        let resultDocument = xsltProcessor.transformToDocument(xmlDoc);
                        let html = new XMLSerializer().serializeToString(resultDocument);

                        pdfMake.createPdf({
                            info: {
                                title: this.fiscalDocument.number,
                            },
                            content: htmlToPdfmake(html)
                        }).download(`${this.fiscalDocument.number}.pdf`);

                    },
                    error: error => {
                        alert("Impossibile convertire in PDF al momento, riprova più tardi")
                    }
                })
        }).catch(reason => alert("impossibile scaricare il PDF al momento"))

    }


}
