<h1 mat-dialog-title i18n="@@document">Documento</h1>
<div mat-dialog-content>
    <form #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)">

        <mat-form-field>
            <mat-label i18n="@@document_name">Nome Documento</mat-label>
            <input name="documentName" matInput required [(ngModel)]="record.name">
        </mat-form-field>

        <input name="fileUpload" type="file" class="file-input" (change)="onDocumentSelected($event)" #fileUpload>

        <mat-form-field>
            <mat-label>
                <span i18n="@@document">Documento</span>
            </mat-label>
            <input matInput required name="document" [(ngModel)]="record.fileName" readonly="true">

            <a mat-icon-button matSuffix [href]="getDownloadUrl()" *ngIf="isDownloadable()" download target="_blank">
                <mat-icon>download</mat-icon>
            </a>

            <button type="button" mat-icon-button matSuffix *ngIf="!record.fileName" (click)="selectDocument( $event, fileUpload)">
                <mat-icon>attach_file</mat-icon>
            </button>

        </mat-form-field>

        <mat-dialog-actions align="end">
            <button (click)="cancel($event)" mat-button i18n="@@cancel">Annulla</button>
            <button *ngIf="!editMode" name="addButton" type="submit" mat-button color="primary" i18n="@@add">Aggiungi</button>

            <button *ngIf="editMode" type="submit" color="primary" mat-button i18n="@@edit">Modifica</button>
        </mat-dialog-actions>
    </form>
</div>
