import {Document} from "./document";
import {Payment} from "./payment";
import {Payslip} from "./payroll";
import {Address} from "./address";
import {InvoiceSender} from "./invoiceSender";
import {Entity} from "./entity";
import {persist, PersistenceConfig} from "../persistence/decorators";
import {ShowcaseComponent} from "../business-administration/showcase/showcase.component";

export enum OrganizationType {
    PrivatePerson = 'PrivatePerson',
    LegalEntity = 'LegalEntity',
    PublicAdministration = 'PublicAdministration'
}

export class TaxRegime {
    code: string
    name: string

    constructor(code: string, name: string) {
        this.code = code;
        this.name = name;
    }
}

export let taxRegimes = [
    new TaxRegime('RF01', 'Ordinario'),
    new TaxRegime('RF02', 'Contribuenti minimi'),
    new TaxRegime('RF04', 'Agricoltura e attività connesse e pesca'),
    new TaxRegime('RF05', 'Vendita sali e tabacchi'),
    new TaxRegime('RF06', 'Commercio di fiammiferi'),
    new TaxRegime('RF07', 'Editoria'),
    new TaxRegime('RF08', 'Gestione di servizi di telefonia pubblica'),
    new TaxRegime('RF09', 'Rivendita di documenti di trasporto pubblico e di sosta'),
    new TaxRegime('RF10', 'Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al D.P.R. n. 640/72'),
    new TaxRegime('RF11', 'Agenzie di viaggi e turismo'),
    new TaxRegime('RF12', 'Agriturismo'),
    new TaxRegime('RF13', 'Vendite a domicilio'),
    new TaxRegime('RF14', 'Rivendita di beni usati, di oggetti d’arte, d’antiquariato o da collezione'),
    new TaxRegime('RF15', 'Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione'),
    new TaxRegime('RF16', 'IVA per cassa P.A.'),
    new TaxRegime('RF17', 'IVA per cassa'),
    new TaxRegime('RF18', 'Altro'),
    new TaxRegime('RF19', 'Forfettario'),
]

@persist(new PersistenceConfig({
    name: 'Business',
    properties: {
        name: {},
        phoneNumber: {},
        taxNumber: {},
        taxRegime: {},
        sdi: {},
        bank: {},
        iban: {},
        address: {converter: 'Address'},
        documents: {converter: 'Document'},
        showcase: {converter: 'Showcase'}
    }
}))
export class Business extends Entity implements InvoiceSender {
    name: string
    phoneNumber: string
    taxNumber: string
    address: Address = new Address()
    documents: Document[] = []
    taxRegime: string
    organizationType: OrganizationType = OrganizationType.PrivatePerson
    sdi: string | null = '2O2RU3T'
    bank: string | null = null;
    iban: string
    showcase: Showcase = new Showcase()


    override getDocuments(): Document[] {
        return [...this.documents]
    }

    isPublicAdministration() {
        return false
    }

    hasMissingData() {
        return !this.taxNumber || !this.address || !this.address.city || !this.address.street || !this.address.postcode || !this.address.nation
    }
}

export class PaymentsInsight {
    0 = {amount: 0, vat: 0}
    1 = {amount: 0, vat: 0}
    2 = {amount: 0, vat: 0}
    3 = {amount: 0, vat: 0}
    4 = {amount: 0, vat: 0}
    5 = {amount: 0, vat: 0}
    6 = {amount: 0, vat: 0}
    7 = {amount: 0, vat: 0}
    8 = {amount: 0, vat: 0}
    9 = {amount: 0, vat: 0}
    10 = {amount: 0, vat: 0}
    11 = {amount: 0, vat: 0}
    total = {amount: 0, vat: 0}
    expense: boolean = false
}

export class PaymentPayslipInsight extends PaymentsInsight {

    constructor(public payments: (Payment | Payslip)[], private year: number, expense = false) {
        super()
        this.expense = expense
        this.getPaymentsByYear(year).forEach((payment) => this.ingest(payment))
    }

    ingest(payment: Payment | Payslip) {
        let month = payment.getAccountingMonth()

        this[month.toString()].amount = this.aggregate(this[month.toString()].amount, payment.amount)
        this.total.amount = this.aggregate(this.total.amount, payment.amount)

        this[month.toString()].vat = this.aggregate(this[month.toString()].vat, 0 - payment.vatAmount)
        this.total.vat = this.aggregate(this.total.vat, 0 - payment.vatAmount)
    }

    private aggregate(a: number, b: number) {

        return this.expense ? a - b : a + b
    }

    private getPaymentsByYear(year: number) {
        return this.payments
            .filter(payment => payment.getAccountingYear() == year);
    }
}

export class PaymentsInsightAggregation extends PaymentsInsight {

    constructor(insights: PaymentsInsight[]) {
        super()

        insights.forEach(insight => {
            for (let i = 0; i <= 11; i++) {
                this[i].amount += insight[i].amount
                this[i].vat += insight[i].vat
            }

            this.total.amount += insight.total.amount
            this.total.vat += insight.total.vat

        })
    }
}

@persist(new PersistenceConfig({
    name: 'Showcase',
    properties: {
        description: {},
        businessId: {},
        businessName: {},
        logo: {converter: 'Document'}
    }
}))
export class Showcase extends Entity {
    description: string
    pictures: Document[] = []
    logo: Document;
    businessId: string
    businessName: string

    override getDocuments(): Document[] {
        return [this.logo]
    }

    get name() {
        return this.businessName
    }
}
