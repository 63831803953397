import {Component, forwardRef, Input} from '@angular/core';
import {Attachment} from "../../model/invoice";
import {DocumentStorageService} from "../../document-storage.service";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AttachmentsComponent),
            multi: true,
        },
    ],
})
export class AttachmentsComponent implements ControlValueAccessor {

    onChange = (value) => {};
    onTouched = () => {};
    touched = false;
    @Input() disabled = false;
    @Input() descriptionRequired = true;
    @Input() fiscalDocumentFilePath: string | null;
    attachments: Attachment[] = [];

    constructor(private storageService: DocumentStorageService,) {
    }

    addAttachment(fileUpload: HTMLInputElement) {
        fileUpload.click()
        fileUpload.onchange = ev => {
            if (fileUpload.files!.length > 0) {
                let attachment = new Attachment();
                let file = fileUpload.files![0];

                if (file.size > 1024 * 1000 /*1Mb*/) {
                    alert("La dimensione dell'allegato non può essere più di 1 megabyte")
                    return
                }

                attachment.name = file.name
                this.storageService.toBase64(file).then(value => {
                        if (typeof value !== 'string') {
                            throw new Error("cannot handle result type after converting to base4")
                        } else {
                            attachment.base64Data = value.split(',')[1]
                        }
                    }
                )

                this.attachments.push(attachment)
                this.onChange(this.attachments)
            }
        }

    }

    downloadAttachment(attachment: Attachment) {
        if (attachment.base64Data) {
            this.storageService.attachmentToFile(attachment)
        } else {
            this.storageService.downloadInvoiceAttachment(this.fiscalDocumentFilePath!, attachment)
        }
    }

    removeAttachment(event: MouseEvent, attachment: Attachment) {
        event.preventDefault()
        event.stopPropagation()

        let index = this.attachments.findIndex(value => attachment == value);
        this.attachments.splice(index, 1)
        this.onChange(this.attachments)
    }

    writeValue(attachments: Attachment[]) {
        this.attachments = attachments
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

}
