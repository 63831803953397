import {MedicalProfile} from "./medicalRecord";
import {EducationProfile} from "./education";
import {Leave, SickLeave} from "./leave";
import {Moment} from "moment";
import {Payroll} from "./payroll";
import {Document} from "./document";
import {Entity} from "./entity";

export class Employee extends Entity {
    fullName: string
    address: string | null = null
    phoneNumber: string | null = null
    taxNumber: string
    birthPlace: string | null = null
    function: string
    birthDate: Moment | null = null
    identificationDocument: Document | null
    employmentCenterDocument: Document | null
    c2Document: Document | null
    terminationLetter: Document | null
    jobContract: Document | null
    nextMedicalAppointment: Moment | null = null
    leaveProfile: Leave[] = []
    sickLeaveProfile: SickLeave[] = []
    educationProfile: EducationProfile = new EducationProfile()
    medicalProfile: MedicalProfile = new MedicalProfile()
    payroll: Payroll = new Payroll()
    hourlyRate: number;

    get name() {
        return this.fullName
    }

    override getDocuments(): Document[] {
        return [
            this.terminationLetter!,
            this.c2Document!,
            this.employmentCenterDocument!,
            this.identificationDocument!,
            this.jobContract!,
            this.medicalProfile.getDocuments(),
            this.educationProfile.getDocuments(),
            this.payroll.getDocuments(),
            this.sickLeaveProfile.map(value => value.getDocuments()).flat()
        ].flat().filter(value => !!value)

    }

    numberOfPayrollsByYear(currentYear: number) {
        return this.payroll.payslipsByYear(currentYear).length
    }

    isSickOnDay(date: Moment): boolean {
        return !!this.sickLeaveProfile.find(leave => leave.containsDate(date))
    }

    isOnLeaveOnDay(date: Moment) {
        return !!this.leaveProfile.find(leave => leave.containsDate(date))
    }

    type() {
        return 'employee'
    }

    findPayslip(year: number, month: number) {
        return this.payroll.records.find(value => value.year === year && value.month === month)
    }

    totalLeaveDays(year: number) {
        return this.totalDays(this.leaveProfile, year)

    }

    totalSickDays(year: number) {
        return this.totalDays(this.sickLeaveProfile, year)
    }

    totalDays(leave: Leave[], year: number) {
        return leave.filter(value => value.to.year() == year)
            .map(value => value.to.diff(value.from, 'days') + 1)
            .reduce((previousValue, currentValue) => currentValue + previousValue, 0)
    }

    getPayslipsByYear(year: number) {
        return this.payroll.payslipsByYear(year)
    }
}
