<article class="page">
    <header>
        <h1 i18n="@@invoicing_page_title">Fatturazione</h1>

        <div class="pageActions">

            <div style="display: inline" matTooltip="Scarica Pro Forma" matTooltipPosition="above">
                <button type="button" mat-fab color="accent" (click)="downloadProForma()"
                        [disabled]="selection.isEmpty()">
                    <mat-icon>download</mat-icon>
                </button>
            </div>

            <button mat-fab color="primary" name="addEntityButton" matTooltip="Nuova Fattura" matTooltipPosition="above" [routerLink]="['new']">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </header>

    <div class="content">
        <mat-card class="infoCard invoices">
            <div *ngIf="data | async as datasource">

                <mat-card-header>
                    <mat-icon mat-card-avatar>receipt</mat-icon>

                    <div class="headerWithCommand">
                        <mat-card-title>
                            <span i18n="@@outbound_invoices">Fatture in uscita</span>
                        </mat-card-title>
                        <search-table [dataSource]="$any(datasource)"></search-table>
                    </div>
                </mat-card-header>

                <mat-card-content>

                    <table mat-table matSort [dataSource]="datasource" matSortActive="date" matSortDirection="desc">

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                              [checked]="selection.hasValue() && isAllSelected()"
                                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? selection.toggle(row) : null"
                                              [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="number">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@number">Numero</th>
                            <td mat-cell *matCellDef="let invoice">{{ invoice.number }}</td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@state">Stato</th>
                            <td mat-cell *matCellDef="let invoice"><span
                                [style.color]="invoice.isError()? 'red' : 'inherit'"> {{ invoice | translateStatus }} </span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="client">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@client">Cliente</th>
                            <td mat-cell *matCellDef="let invoice"> {{ invoice.receiver.name }}</td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@issue_date">Data emissione
                            </th>
                            <td mat-cell *matCellDef="let invoice"> {{ invoice.date | date }}</td>
                        </ng-container>

                        <ng-container matColumnDef="dueDate">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@due_date">Data scadenza</th>
                            <td mat-cell *matCellDef="let invoice"> {{ invoice.dueDate | date }}</td>
                        </ng-container>

                        <ng-container matColumnDef="sentDate">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@sent_date">Data invio</th>
                            <td mat-cell *matCellDef="let invoice"> {{ invoice.sentDate | date }}</td>
                        </ng-container>

                        <ng-container matColumnDef="paid">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@payment_status">Stato
                                pagamento
                            </th>
                            <td mat-cell *matCellDef="let invoice"> {{ getPaymentState(invoice) }}</td>
                        </ng-container>

                        <ng-container matColumnDef="taxable">
                            <th mat-header-cell mat-sort-header class="currencyColumn" *matHeaderCellDef
                                i18n="@@taxable">Imponibile
                            </th>
                            <td mat-cell class="currencyColumn"
                                *matCellDef="let invoice"> {{ invoice.taxable | formatCurrency }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="tax">
                            <th mat-header-cell mat-sort-header class="currencyColumn" *matHeaderCellDef i18n="@@tax">
                                Imposta
                            </th>
                            <td mat-cell class="currencyColumn"
                                *matCellDef="let invoice"> {{ invoice.tax | formatCurrency }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <th mat-header-cell mat-sort-header class="currencyColumn" *matHeaderCellDef
                                i18n="@@total_amount">Importo
                            </th>
                            <td mat-cell class="currencyColumn"
                                *matCellDef="let invoice"> {{ invoice.total | formatCurrency }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let invoice" style="width: 6%">
                                <div class="iconsContainer">
                                    <button type="button" mat-icon-button
                                            *ngIf="!invoice.isSent() && !invoice.isSending() && !invoice.isPaid()"
                                            (click)="deleteInvoice($event, invoice)">
                                        <mat-icon class="mat-24">delete</mat-icon>
                                    </button>
                                    <mat-icon *ngIf="invoice.hasAttachments()" i18n-matTooltip="@@contains_attachments"
                                              matTooltip="La fattura contiene allegati" matTooltipPosition="above">
                                        attach_file
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let invoice; columns: displayedColumns;" [class.bad]="invoice.isError()"
                            (click)="invoiceSelected(invoice)"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" style="text-align: center" [colSpan]="displayedColumns.length"
                                i18n="@@no_invoice">Nessuna fattura
                            </td>
                        </tr>
                    </table>

                </mat-card-content>
            </div>

            <mat-card-footer>
                <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
            </mat-card-footer>

        </mat-card>

    </div>
</article>
