import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class I18nService {

    formatter: Intl.NumberFormat = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' })

    constructor() {
    }

    formatCurrency(value: number): string {
        return this.formatter.format(value)
    }


}
