<mat-card *ngIf="employee" class="infoCard summaryCard">
    <mat-card-header>
        <mat-icon mat-card-avatar>badge</mat-icon>

        <mat-card-title>{{ employee.fullName }}</mat-card-title>
        <mat-card-subtitle>{{ employee.taxNumber }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

        <table>
            <tr>
                <td>
                    <mat-icon>payments</mat-icon>
                </td>
                <td><span i18n="@@monthly_pay">Mensilità</span></td>
                <td>{{ employee.numberOfPayrollsByYear(currentYear) }}</td>
            </tr>

            <tr>
                <td>
                    <mat-icon>kitesurfing</mat-icon>
                </td>
                <td><span i18n="@@leave_days">Ferie</span></td>
                <td>{{ employee.totalLeaveDays(currentYear) }}</td>
            </tr>

            <tr>
                <td>
                    <mat-icon>sick</mat-icon>
                </td>
                <td><span i18n="@@sick_days">Giorni di malattia</span></td>
                <td>{{ employee.totalSickDays(currentYear) }}</td>
            </tr>

        </table>

    </mat-card-content>

    <mat-card-actions>
        <button mat-button [routerLink]="[employee.id]" i18n="@@see">VEDI</button>
    </mat-card-actions>
</mat-card>
