import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {bankTransfer, Payment, paymentTypes} from "../../model/payment";
import {compareEntities, getCurrentBusiness} from "../../utils";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm} from "@angular/forms";

@Component({
    selector: 'invoice-payments',
    templateUrl: './invoice-payments.component.html',
    styleUrls: ['./invoice-payments.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InvoicePaymentsComponent),
            multi: true,
        },
    ],
})
export class InvoicePaymentsComponent implements OnInit, ControlValueAccessor {

    onChange = (value) => {};
    onTouched = () => {};
    touched = false;
    @Input() disabled = false;

    @Input() form: NgForm;
    @Input() invoiceTotal: number = 0
    paymentTypes = paymentTypes;
    payments: Payment[] = []

    constructor() {
    }

    ngOnInit(): void {
    }

    addPayment() {
        let payment = new Payment();
        payment.type = bankTransfer
        let business = getCurrentBusiness();
        payment.iban = business.iban
        payment.bank = business.bank

        if (this.payments.length == 0) {
            payment.amount = this.invoiceTotal
        }

        this.payments.push(payment)
        this.onChange(this.payments)
    }

    removePayment(event: MouseEvent, payment: Payment) {
        event.preventDefault()

        let index = this.payments.findIndex(value => payment == value);
        this.payments.splice(index, 1)
        this.onChange(this.payments)
    }

    setPaymentValues(event: any, payment: Payment) {
        if (!payment.isBankTransfer()) {
            payment.bank = null
            payment.iban = null
        }
        this.onChange(this.payments)
    }

    compareEntities(a, b) {
        return compareEntities(a, b)
    }

    writeValue(payments: Payment[]) {
        this.payments = payments
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

}
