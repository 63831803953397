<form>

    <article class="page">
        <header>
            <h1 i18n="@@accounting">Contabilit&agrave;</h1>
        </header>

        <div class="content">

            <mat-card class="infoCard accounting">
                <mat-card-header>
                    <mat-icon mat-card-avatar>ssid_chart</mat-icon>
                    <mat-card-title>
                        <span>Anno {{year}}</span>
                        <mat-icon [matMenuTriggerFor]="menu">arrow_drop_down</mat-icon>
                    </mat-card-title>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onYearSelected(2017)"><span>2017</span></button>
                        <button mat-menu-item (click)="onYearSelected(2018)"><span>2018</span></button>
                        <button mat-menu-item (click)="onYearSelected(2019)"><span>2019</span></button>
                        <button mat-menu-item (click)="onYearSelected(2020)"><span>2020</span></button>
                        <button mat-menu-item (click)="onYearSelected(2021)"><span>2021</span></button>
                        <button mat-menu-item (click)="onYearSelected(2022)"><span>2022</span></button>
                        <button mat-menu-item (click)="onYearSelected(2023)"><span>2023</span></button>
                        <button mat-menu-item (click)="onYearSelected(2024)"><span>2024</span></button>
                        <button mat-menu-item (click)="onYearSelected(2025)"><span>2025</span></button>
                    </mat-menu>
                </mat-card-header>

                <mat-card-content>
                    <table mat-table [dataSource]="data">

                        <ng-container matColumnDef="{{column.title}}" *ngFor="let column of displayedColumns">
                            <th mat-header-cell *matHeaderCellDef [class]="getClass(column)">{{column.title}}</th>
                            <td [class.bad]="isBadCell(row, column)" [class]="getClass(column)" mat-cell
                                *matCellDef="let row">
                                {{getCellValue(row, column) | formatCurrency }}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="columnIds"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnIds;"
                            [class.nested]="row.nested" [class.highlighted]="row.highlighted"
                            [class.unpaid]="row.unpaid" [class.bad]="isBadRow(row)"></tr>
                    </table>
                </mat-card-content>

            </mat-card>

        </div>
    </article>
</form>
