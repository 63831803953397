import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    title: string
    question: string

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data.title
        this.question = data.question
    }

}
