import {Entity} from "./entity";
import {persist, PersistenceConfig} from "../persistence/decorators";

export enum NotificationType {
    EDUCATION = 'EDUCATION',
    MEDICAL_RECORD = 'MEDICAL_RECORD'
}

@persist(new PersistenceConfig({name: 'Notification', properties: ['type', 'payload', 'acknowledged']}))
export class Notification extends Entity {

    type: NotificationType
    payload: any
    acknowledged: boolean = false;

    isMedicalAppointmentReminder() {
        return this.type == NotificationType.MEDICAL_RECORD
    }

    isEducationAppointmentReminder() {
        return this.type == NotificationType.EDUCATION
    }

    getExpectedDateFromPayload() {
        return new Date(this.payload.record.expectedDate.seconds * 1000)
    }
}
