import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Supplier, Supply} from "../../model/supply";
import {SupplierRepository} from "../supplier.repository";
import {SupplyRepository} from "../../projects-management/supply/supply-repository.service";
import {Balance} from "../../model/balance";
import {countries, districts} from "../../utils";
import {NavigatorService} from "../../navigator.service";
import {NotificationService} from "../../notification.service";
import {InboundInvoicesRepository} from "../../invoicing/inbound/inbound-invoices.repository";
import {first, forkJoin, map, mergeMap, Observable, of, tap} from "rxjs";
import {Invoice} from "../../model/invoice";
import {FormComponentV2} from "../../components/formV2.component";
import {DocumentStorageService} from "../../document-storage.service";
import {Transaction} from "@angular/fire/firestore";

@Component({
    selector: 'app-supplier',
    templateUrl: './supplier.component.html',
    styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent extends FormComponentV2<Supplier> implements OnInit {
    supplier: Supplier
    supplies: Supply[];
    displayedColumns: string[] = ['description', 'estimatedDate', 'deliveryDate', 'actions'];
    balance: Balance;
    districts: any = districts;
    balanceObservable: Observable<Balance>;

    constructor(navigatorService: NavigatorService,
                documentStorageService: DocumentStorageService,
                notificationService: NotificationService,
                private inboundInvoicesRepository: InboundInvoicesRepository,
                private route: ActivatedRoute, private supplierRepository: SupplierRepository,
                private supplyRepository: SupplyRepository) {
        super(documentStorageService, navigatorService, notificationService, supplierRepository);
    }

    ngOnInit(): void {
        this.supplier = this.route.snapshot.data.supplier as Supplier;

        this.balanceObservable = this.supplyRepository.findSuppliesBySupplier(this.supplier.id).pipe(
            tap(supplies => this.supplies = supplies ? supplies : []),
            map(() => this.supplies),
            mergeMap(supplies => {
                let invoicesObservables = supplies.map(supply => this.inboundInvoicesRepository.findBySupply(supply).pipe(first()));

                if(invoicesObservables.length == 0) invoicesObservables = [of([])]

                return forkJoin(invoicesObservables).pipe(map(invoicesBySupply => invoicesBySupply.flat())) as Observable<Invoice[]>
            }),
            map((invoices: Invoice[]) => {
                let balance = new Balance();
                balance.addPaymentsFromInvoices(invoices)
                return balance
            }));
    }


    delete(supplier: Supplier) {
        this.supplierRepository.delete(supplier)
    }

    getSupplyType(supplier: Supplier) {
        if (supplier._type == 'supplier') return 'deliverable'
        if (supplier._type == 'medical_center') return 'medical_record'
        if (supplier._type == 'educational_center') return 'education'
        if (supplier._type == 'accountant') return 'education'

        throw new Error(`cannot happen: invalid supplier type - ${supplier._type}`)
    }

    async doCreate(transaction: Transaction, supplier: Supplier) {
        this.repository.transactionalSave(transaction, supplier)
    }

    async doUpdate(transaction: Transaction, supplier: Supplier): Promise<void> {

        await this.supplierRepository.transactionalUpdate(transaction, supplier.id, {
            id: supplier.id,
            _type: supplier._type,
            email: supplier.email,
            sdi: supplier.sdi,
            address: supplier.address,
            iban: supplier.iban,
            phoneNumber: supplier.phoneNumber,
            name: supplier.name,
            taxNumber: supplier.taxNumber,
            version: supplier.version + 1
        } as Supplier)
    }

    protected readonly countries = countries;
}
