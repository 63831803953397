<mat-dialog-content>
    <section class="priceLists">
        <header><h1>Listini</h1></header>
        <mat-selection-list [multiple]="false" *ngIf="lists | async as priceLists" [(ngModel)]="selectedList"
                            (selectionChange)="onPriceListSelected()">
            <mat-list-option *ngFor="let priceList of priceLists" [value]="priceList">
                {{ priceList.name }}
            </mat-list-option>
        </mat-selection-list>
    </section>

    <section class="priceList">

        <header>
            <h1>Voci listino</h1>
            <div class="search">
                <mat-form-field>
                    <input matInput #filterInput (change)="filter(filterInput.value)" placeholder="Cerca...">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
        </header>

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null"
                                  [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> Codice</th>
                <td mat-cell *matCellDef="let element"> {{ element.code }}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Descrizione</th>
                <td class="description" mat-cell *matCellDef="let element"> {{ element.description }}</td>
            </ng-container>

            <ng-container matColumnDef="unitOfMeasure">
                <th mat-header-cell *matHeaderCellDef> Unit&agrave; di misura</th>
                <td mat-cell *matCellDef="let element"> {{ element.unitOfMeasure }}</td>
            </ng-container>

            <ng-container matColumnDef="cost">
                <th mat-header-cell *matHeaderCellDef> Prezzo</th>
                <td mat-cell *matCellDef="let element"> {{ element.cost | formatCurrency }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div style="display: flex; flex-flow: row-reverse">
                        <button mat-icon-button type="button" (click)="onExpandLine(element, $event)">
                            <mat-icon *ngIf="!element.expanded">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="element.expanded">keyboard_arrow_up</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="details">
                <td mat-cell [attr.colspan]="displayedColumns.length" *matCellDef="let element">
                    <div class="detailsCell" [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
                        <mat-list>
                            <div mat-subheader>Appartiene a</div>
                            <mat-list-item *ngFor="let parent of sort( element.parents)">
                                <span matListItemTitle>{{ parent.code }}</span>
                                <span matListItemLine>{{ parent.description }}</span>
                            </mat-list-item>
                        </mat-list>


                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="lineRow" *matRowDef="let row; columns: displayedColumns;"
                (click)="selection.toggle(row)">
            </tr>
            <tr mat-row class="detailsRow" *matRowDef="let row; columns: ['details'];"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
    </section>

</mat-dialog-content>

<div class="actions" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Annulla</button>
    <button mat-button [mat-dialog-close]="selection" cdkFocusInitial>Copia</button>
</div>
