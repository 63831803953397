<form (ngSubmit)="save(supplier)">

    <article class="page">
        <header>
            <h1 i18n="@@supplier">Fornitore</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above" [disabled]="loading"
                        [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>


                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above"
                        (click)="discardChanges(supplier)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>

        <div class="content">
            <mat-card class="infoCard details">
                <mat-card-header>
                    <mat-icon mat-card-avatar>local_shipping</mat-icon>
                    <mat-card-title>{{ supplier.name }}</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <mat-radio-group color="primary" [(ngModel)]="supplier._type" name="supplierType"
                                     [disabled]="supplier.persisted()">
                        <mat-radio-button value="medical_center" i18n="@@medical_center">
                            Centro Medico
                        </mat-radio-button>
                        <mat-radio-button value="educational_center" i18n="@@educational_center">
                            Centro Formativo
                        </mat-radio-button>
                        <mat-radio-button value="supplier" i18n="@@supplier_or_services">
                            Fornitore Materiali o Servizi
                        </mat-radio-button>
                        <mat-radio-button value="accountant" i18n="@@accountant">
                            Commercialista
                        </mat-radio-button>
                    </mat-radio-group>

                    <mat-form-field>
                        <mat-label i18n="@@name">Nome</mat-label>
                        <input name="name" #name="ngModel" matInput placeholder="esempio: Luca Rossi" required
                               [(ngModel)]="supplier.name">
                        <mat-error *ngIf="name.errors?.['required']" i18n="@@required">Campo obbligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="flexible-size">
                        <mat-label i18n="@@tax_code_or_vat_number">Codice Fiscale o Partita IVA</mat-label>
                        <input name="taxNumber" #taxNumber="ngModel" matInput required
                               [(ngModel)]="supplier.taxNumber">
                        <mat-error *ngIf="taxNumber.errors?.['required']" i18n="@@required">Campo obbligatorio
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="flexible-size">
                        <mat-label i18n="@@sdi">SDI</mat-label>
                        <input name="sdi" #sdi="ngModel" matInput required [(ngModel)]="supplier.sdi">
                        <mat-error *ngIf="sdi.errors?.['required']" i18n="@@required">Campo obbligatorio</mat-error>
                    </mat-form-field>

                    <div class="addressFormPart">
                        <mat-form-field class="fixed-size nation">
                            <mat-label i18n="@@country">Nazione</mat-label>
                            <mat-select name="nation" #nation="ngModel" [required]="true" [(ngModel)]="supplier.address.nation">
                                <mat-option *ngFor="let nation of countries" [value]="nation.code">
                                    {{ nation.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="nation.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field class="fixed-size" *ngIf="supplier.address.nation == 'IT'">
                            <mat-label i18n="@@district">Provincia</mat-label>
                            <mat-select name="district" #district="ngModel" [required]="true" [(ngModel)]="supplier.address.district">
                                <mat-option *ngFor="let district of districts" [value]="district.abbr">
                                    {{ district.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="district.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field class="fixed-size" *ngIf="supplier.address.nation != 'IT'">
                            <mat-label i18n="@@district">Provincia</mat-label>
                            <input name="district" #district="ngModel" matInput [required]="true" [(ngModel)]="supplier.address.district"/>
                            <mat-error *ngIf="district.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>


                        <div class="flex-row" style="width: 100%">
                            <mat-form-field class="flexible-size">
                                <mat-label i18n="@@city">Citt&agrave;</mat-label>
                                <input name="city" #city="ngModel" matInput required
                                       [(ngModel)]="supplier.address.city">
                                <mat-error *ngIf="city.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                            <mat-form-field class="fixed-size postcode">
                                <mat-label i18n="@@postcode">CAP</mat-label>
                                <input name="postcode" #postcode="ngModel" matInput [required]="true" [(ngModel)]="supplier.address.postcode">
                                <mat-error *ngIf="postcode.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                        </div>

                        <mat-form-field class="flexible-size">
                            <mat-label i18n="@@address">Indirizzo</mat-label>
                            <input name="street" #addressLine="ngModel" matInput required [(ngModel)]="supplier.address.street">
                            <mat-error *ngIf="addressLine.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>
                    </div>


                    <mat-form-field class="flexible-size">
                        <mat-label i18n="@@phone_number">Telefono</mat-label>
                        <input name="phoneNumber" #phoneNumber="ngModel" matInput pattern="\+{0,1}[0-9]+"
                               minlength="4" [(ngModel)]="supplier.phoneNumber">
                        <mat-error *ngIf="phoneNumber.hasError('pattern')" i18n="@@invalid_phone_number">Numero
                            telefono non valido
                        </mat-error>
                        <mat-error *ngIf="phoneNumber.hasError('minlength')" i18n="@@invalid_phone_number">Numero
                            telefono non valido
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="flexible-size">
                        <mat-label i18n="@@email">Email</mat-label>
                        <input name="email" pattern="^[\w+\.\-]+@[\w\.\-]+$" #email="ngModel" matInput
                               [(ngModel)]="supplier.email">
                        <mat-error *ngIf="email.hasError('pattern')" i18n="@@invalid_email">Email non valida
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="flexible-size">
                        <mat-label i18n="@@iban">IBAN</mat-label>
                        <input name="iban" #iban="ngModel" matInput [(ngModel)]="supplier.iban">
                    </mat-form-field>
                </mat-card-content>

            </mat-card>
            <mat-card class="infoCard supplies" *ngIf="supplier.persisted()">
                <mat-card-header>
                    <mat-card-title i18n="@@supplies">Forniture</mat-card-title>

                    <mat-icon mat-card-avatar>pallet</mat-icon>
                </mat-card-header>

                <mat-card-content>

                    <table mat-table [dataSource]="supplies" *ngIf="supplies">

                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef i18n="@@description">Descrizione</th>
                            <td mat-cell *matCellDef="let supply">
                                {{ supply.description }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="estimatedDate">
                            <th mat-header-cell *matHeaderCellDef i18n="@@estimated_delivery_date">Data prevista
                                consegna
                            </th>
                            <td mat-cell
                                *matCellDef="let supply"> {{ supply.estimatedDeliveryDate | date }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="deliveryDate">
                            <th mat-header-cell *matHeaderCellDef i18n="@@delivery_date">Data effettiva consegna
                            </th>
                            <td mat-cell *matCellDef="let supply"> {{ supply.deliveryDate | date }}</td>
                        </ng-container>


                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let supply">
                                <a mat-icon-button name="editButton" *ngIf="!supply.transient"
                                   [routerLink]="['/supplies', supply.id]">
                                    <mat-icon class="mat-24">edit</mat-icon>
                                </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" [attr.colspan]="displayedColumns.length"
                                style="text-align: center" i18n="@@no_supply">Nessuna fornitura presente
                            </td>
                        </tr>
                    </table>

                </mat-card-content>

                <mat-card-actions>
                    <a mat-button color="primary" [routerLink]="['/supplies', 'new']"
                            [queryParams]="{supplierId: supplier.id, type: getSupplyType(supplier)}" i18n="@@add">Aggiungi</a>
                </mat-card-actions>

            </mat-card>

            <payments [balance]="balance" *ngIf="balanceObservable  | async as balance" [owner]="supplier"></payments>
        </div>

    </article>

</form>
