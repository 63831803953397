import {Component} from '@angular/core';
import {ClientRepository} from "../client.repository";
import {ListLayoutComponent} from "../../components/list-layout/list-layout.component";

@Component({
    selector: 'clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.scss']
})
export class ClientsComponent extends ListLayoutComponent {
    label

    constructor(public clientRepository: ClientRepository) {
        super()
        this.repository = clientRepository


        let singular = $localize`:@@client:cliente`
        let plural = $localize`:@@clients:clienti`
        this.label = {
            singular, plural
        }

    }

}
