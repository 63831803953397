import {AfterViewInit, Component, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {map, Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {InboundInvoicesRepository} from "../inbound-invoices.repository";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {getCurrentBusiness} from "../../../utils";
import {environment} from "../../../../environments/environment";
import {ConfirmDialogComponent} from "../../outbound/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {Bouncer} from "../../../bouncer.service";
import {MatSort} from "@angular/material/sort";
import {FiscalDocument} from "../../../model/fiscalDocument";
import {SelectionModel} from "@angular/cdk/collections";
import {Invoice} from "../../../model/invoice";
import {FiscalDocumentService} from "../../fiscal-document.service";

@Component({
    templateUrl: './inbound-invoices.component.html',
    styleUrls: ['./inbound-invoices.component.scss']
})
export class InboundInvoicesComponent implements AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChildren(MatSort) sortQuery: QueryList<MatSort>;

    selection = new SelectionModel<Invoice>(true, []);
    dataSource: MatTableDataSource<FiscalDocument>
    data: Observable<MatTableDataSource<FiscalDocument>>

    displayedColumns: string[] = ['select', 'number', 'sender', 'date', 'dueDate', 'paid', 'taxable', 'tax', 'total', 'actions'];

    constructor(private router: Router, private route: ActivatedRoute,
                private invoiceRepository: InboundInvoicesRepository,
                private httpClient: HttpClient,
                private bouncer: Bouncer,
                private fiscalDocumentService: FiscalDocumentService,
                private dialog: MatDialog) {

    }


    ngAfterViewInit() {
        this.data = this.invoiceRepository.getAll().pipe(map(data => {
            this.dataSource = new MatTableDataSource<FiscalDocument>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = (invoice, property) => {
                switch (property) {
                    case 'sender': {
                        return invoice.sender.name
                    }
                    case 'paid' : {
                        return invoice.isPaid() ? 'Pagata' : 'Non pagata'
                    }
                    default: {
                        return invoice[property];
                    }
                }
            }
            this.sortQuery.changes.subscribe(value => {
                if (value) this.dataSource.sort = value.get(0)
            })
            return this.dataSource
        }))
    }

    invoiceSelected(invoice) {
        this.router.navigate([invoice.id], {relativeTo: this.route});
    }

    selectXml(event, xmlUpload: HTMLInputElement) {
        event.preventDefault()
        xmlUpload.click()
    }

    async importXml(event) {
        let files = event.target.files;
        if (!files) {
            return
        }
        const file: File = files[0];

        let invoice = await file.text()
        let token = await this.bouncer.getAccessToken()

        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json').append('Authorization', `Bearer ${token}`);

        this.httpClient.post(`${environment.hermes.url}/invoices/inbound`, {
            businessId: getCurrentBusiness().id,
            invoice: invoice,
            fileName: file.name
        }, {headers})
            .subscribe({
                next: value => {
                    console.log("invoice imported");
                    alert("Fattura importata")
                },
                error: error => alert("Impossibile importare le fattura al momento, riprovare più tardi")
            })
    }

    deleteInvoice(event: MouseEvent, invoice) {
        event.preventDefault()
        event.stopPropagation()

        this.confirmDelete().subscribe(confirmed => {
            if (confirmed) {
                this.invoiceRepository.delete(invoice)
            }
        })
    }

    confirmDelete(): Observable<any> {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Conferma cancellazione',
                question: 'Sei sicuro che vuoi cancellare la fattura?'
            }
        }).afterClosed();
    }

    downloadPdf() {
        this.selection.selected.forEach(invoice => this.fiscalDocumentService.downloadOutboundInvoiceProForma(invoice))
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected == numRows;
    }

    toggleAllRows() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row as Invoice));
    }
}
