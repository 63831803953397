import {
    ApplicationRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef, EmbeddedViewRef,
    Injector,
    Input
} from '@angular/core';

@Component({
  selector: 'infoTooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss']
})
export class InfoTooltipComponent {

    @Input() tooltip = 'Hai richieste da possibili clienti';
    @Input() disabled: boolean = true

    private componentRef: ComponentRef<any> ;
    private showTimeout?: number;


    constructor(
        private elementRef: ElementRef,
        private appRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector) {
    }

    ngOnInit(): void {
    }

    initializeTooltip() {
        if (this.componentRef === null) {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InfoTooltipComponent);
            this.componentRef = componentFactory.create(this.injector);
            this.componentRef.instance.tooltip = this.tooltip


            this.appRef.attachView(this.componentRef.hostView);
            const [tooltipDOMElement] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;


            document.body.appendChild(tooltipDOMElement);
        }
    }


    disable() {
        this.disabled = true
    }

    enable() {
        this.disabled = false
    }
}
