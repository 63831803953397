import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {formatCurrency, getCurrencySymbol, getLocaleCurrencyCode} from '@angular/common';

@Pipe({
    name: 'formatCurrency',
})
export class FormatCurrencyPipe implements PipeTransform {


    constructor(@Inject(LOCALE_ID) public locale: string) {
    }

    transform(value: number | null): any {
        if(value == null || isNaN(Number(value))) return value

        let currencyCode = getLocaleCurrencyCode(this.locale);

        return formatCurrency(
            value,
            this.locale,
            getCurrencySymbol(currencyCode!, 'narrow'),
            currencyCode!,
        );
    }
}
