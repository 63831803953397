import {Component, OnInit} from '@angular/core';
import {Bouncer} from "../bouncer.service";
import {NavigatorService} from "../navigator.service";

@Component({
    selector: 'app-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
    signinModel = {username: null, password: null}

    constructor(private bouncer: Bouncer, private navigator: NavigatorService) {}

    ngOnInit(): void {
    }

    signIn(event: SubmitEvent, signinModel) {
        event.preventDefault()
        this.bouncer.signIn(signinModel.username, signinModel.password).then(value => this.navigator.goToHomePage())
    }

    togglePassword(password: HTMLInputElement) {
        password.type = password.type == 'password' ? 'text' : 'password'
    }
}
