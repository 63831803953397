import {Component} from '@angular/core';
import {map, Observable} from "rxjs";
import {ProjectRequest} from "../../model/project.request";
import {ActivatedRoute} from "@angular/router";
import {ProjectRequestRepository} from "../project-requests.repository";
import {getCurrentBusiness} from "../../utils";
import {ChatRepository} from "../../chats/chat.repository";
import {Chat, Message} from "../../chats/message";
import {NotificationService} from "../../notification.service";
import {NavigatorService} from "../../navigator.service";

@Component({
    selector: 'app-project-request',
    templateUrl: './project-request.component.html',
    styleUrls: ['./project-request.component.scss']
})
export class ProjectRequestComponent {
    loading: boolean = false;
    projectRequestObservable: Observable<ProjectRequest | undefined>;

    chatObservable: Observable<Chat>;

    constructor(private chatRepository: ChatRepository, private route: ActivatedRoute, private projectRequestRepository: ProjectRequestRepository,
                private notificationService: NotificationService, private navigatorService: NavigatorService) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            let id = params.get('id');
            this.projectRequestObservable = this.projectRequestRepository.findById(id!)

            let business = getCurrentBusiness();

            this.chatObservable = this.chatRepository.findByProjectRequestId(id!).pipe(map(value => {
                if (value !== null && value !== undefined && value.length != 0) return value[0]

                let chat = new Chat();
                chat.context.projectRequestId = id
                chat.context.businessId = business.id

                return chat
            }));
        });

    }

    sendMessage(chat: Chat, messageInput: HTMLTextAreaElement) {

        let onSaved = () => {
            messageInput.value = ''
            this.notificationService.notify($localize`:@@message_sent:messaggio inviato`)
            this.chatObservable = this.chatRepository.findById(chat.id).pipe(map((value) => value!));
        };

        let business = getCurrentBusiness();
        let message = chat.sendMessageFromBusiness(business, messageInput.value)

        let saving = chat.persisted() ? this.chatRepository.saveMessage(chat, message) : this.chatRepository.save(chat);
        saving.then(onSaved)
    }

    prepareQuote(chat: Chat, projectRequest: ProjectRequest) {
        if (!chat.persisted()) this.chatRepository.save(chat);


        this.navigatorService.goToQuotePage(projectRequest)
    }

    isOwnMessage(message: Message) {
        let business = getCurrentBusiness();
        return message.isOwn(business.id)
    }
}


