import {Injectable} from "@angular/core";
import {Repository} from "../persistence/repository";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";
import {ConverterRegistry} from "../persistence/converter-registry.service";
import {ProjectRequest} from "../model/project.request";

@Injectable({
    providedIn: 'root'
})
export class ProjectRequestRepository extends Repository<ProjectRequest> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, ConverterRegistry.find('ProjectRequest'))
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection(`projectRequests`).withConverter(this.converter);
    }

}
