import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, OnInit, Output, ViewChild} from '@angular/core';
import {Activity} from "../../model/activity";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {Employee} from "../../model/employee";
import {EmployeeRepository} from "../../employees/employee.repository";
import {MatTable} from "@angular/material/table";
import {CrudCardComponent} from "../../components/crud-card/crud-card.component";
import {Leave} from "../../model/leave";
import {clone, generateId} from "../../utils";
import {MatDialog} from "@angular/material/dialog";
import {ActivityDialogComponent} from "./activity-dialog/activity-dialog.component";

@Component({
    selector: 'activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ActivitiesComponent),
            multi: true,
        },
    ],
})
export class ActivitiesComponent extends CrudCardComponent<Activity> implements OnInit, AfterViewInit {

    @ViewChild(MatTable) activitiesTable: MatTable<Activity>
    @ViewChild('assignees') assigneesInput: ElementRef<HTMLInputElement>;
    @Output('activityChanged') activityChangeEmitter = new EventEmitter<Activity>()

    displayedColumns: string[] = ['name', 'from', 'to', 'actions'];

    private fullEmployeesObjects: Employee[];

    constructor(private employeeRepository: EmployeeRepository, dialog: MatDialog) {
        super(dialog)

        this.setDialogComponent(ActivityDialogComponent)
    }

    ngOnInit(): void {
        this.employeeRepository.getAll().subscribe(fullEmployeesObjects => {
            this.loadOriginalFullEmployeeObjectIntoActivities(fullEmployeesObjects)
            this.fullEmployeesObjects = fullEmployeesObjects
        });
    }

    private loadOriginalFullEmployeeObjectIntoActivities(employees: Employee[]) {
        this.activities.forEach(activity => {
            let ids = activity.assignees.map(value => value.id)
            activity.assignees = employees.filter(fullEmployeeObject => ids.includes(fullEmployeeObject.id))
        })
    }

    ngAfterViewInit() {
        super.table = this.activitiesTable
    }

    public newEntity() {
        let activity = new Activity();
        activity.id = generateId()
        return activity
    }

    get activities(): Activity[] {
        return this.list;
    }

    override onEntityEdited() {
        this.activityChangeEmitter.emit(this.subject)
    }

    override onEntitySaved() {
        this.activityChangeEmitter.emit(this.subject)
    }

    openNewDialog() {
        super.openNewRecordDialog({
            record: this.newEntity(),
            fullEmployeesObjects: this.fullEmployeesObjects,
            assigneesInput: this.assigneesInput
        })
    }

    openEditDialog(event: MouseEvent, record: Leave) {
        super.openEditRecordDialog(event, {
            record: clone(record),
            fullEmployeesObjects: this.fullEmployeesObjects,
            assigneesInput: this.assigneesInput
        })

    }
}
