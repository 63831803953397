import {Injectable} from '@angular/core';
import {getCurrentBusiness} from "../utils";
import {map, mergeMap, Observable, of, tap} from "rxjs";
import {FiscalDocumentType, Invoice, Quote, SenderType} from "../model/invoice";
import * as moment from "moment/moment";
import {BusinessRepository} from "../authentication/business.repository";
import {QuotesRepository} from "./quotes/quotes.repository";
import {FiscalDocumentConverter} from "./outbound/outbound-invoices.repository";
import {NotificationService} from "../notification.service";
import {I18nService} from "../i18n.service";
import {Content, ContentTable, ContentText, TDocumentDefinitions} from "pdfmake/interfaces";
import * as pdfMake from "pdfmake/build/pdfmake";
import {InboundInvoicesRepository} from "./inbound/inbound-invoices.repository";
import htmlToPdfmake from "html-to-pdfmake"
import {XmlExporterService} from "./xml-exporter.service";
import {DocumentStorageService} from "../document-storage.service";

@Injectable({
    providedIn: 'root'
})
export class FiscalDocumentService {

    constructor(private businessRepository: BusinessRepository, private quotesRepository: QuotesRepository,
                private inboundInvoicesRepository: InboundInvoicesRepository,
                private i18n: I18nService,
                private xmlExporterService: XmlExporterService, private storageService: DocumentStorageService,
                private notificationService: NotificationService) {
    }

    makeNewQuote() {
        return this.businessRepository.findById(getCurrentBusiness().id).pipe(map(business => {
                let quote = new Quote()
                quote.senderType = SenderType.Business
                quote.date = moment()
                quote.currency = "Euro"
                quote.sender = business!
                return quote
            }
        ))
    }

    copyQuote(quoteId) {
        return this.quotesRepository.findById(quoteId).pipe(mergeMap(quote => {
            if (!quote) throw new Error("Quote not found during copy to invoice")
            return this.copyQuoteDataIntoNewQuote(quote).pipe(tap(value => this.notificationService.notify($localize`:@@quote_copied:Preventivo copiato`)))
        }))
    }

    copyQuoteIntoNewInvoice(quoteId: string): Observable<Invoice> {
        return this.quotesRepository.findById(quoteId).pipe(map(quote => {
            if (!quote) throw new Error("Quote to copy not found: " + quoteId)
            let toCopy = quote.clone(new Quote()) as Quote;
            toCopy.id = ""
            toCopy['_issueDate'] = moment()
            toCopy.number = ""
            toCopy.type = FiscalDocumentType.Invoice

            let converter = this.inboundInvoicesRepository.converter as FiscalDocumentConverter<Invoice>;
            let invoice = converter.makeFiscalDocument(toCopy)

            invoice.markAsDraft()
            return invoice
        }), tap(value => this.notificationService.notify($localize`:@@quote_copied_into_invoice:Preventivo copiato in fattura`)))

    }

    makeNewOutboundInvoice() {
        return this.businessRepository.findById(getCurrentBusiness().id)
            .pipe(map(business => Invoice.makeNewOutboundInvoice(business!)));
    }

    makeNewInboundInvoice(type: string | null, id: string | null, projectId: string | null, supplierId: string | null) {
        return this.businessRepository.findById(getCurrentBusiness().id)
            .pipe(map(business => Invoice.makeNewInboundInvoice(business!, type, id, projectId, supplierId)));
    }

    downloadInvoiceSdiPdf(invoice: Invoice) {
        this.storageService.retrieveBlob(invoice.filePath).then(invoiceXml => {

            this.xmlExporterService.getXsl(invoice.receiver.isPublicAdministration())
                .subscribe({
                    next: (value) => {
                        let xsl = value;
                        let xml = invoiceXml;
                        var parser = new DOMParser();
                        var xslDoc = parser.parseFromString(xsl, "application/xml");
                        // @ts-ignore
                        var xmlDoc = parser.parseFromString(xml, "application/xml");

                        let xsltProcessor = new XSLTProcessor();
                        xsltProcessor.importStylesheet(xslDoc);
                        let resultDocument = xsltProcessor.transformToDocument(xmlDoc);
                        let html = new XMLSerializer().serializeToString(resultDocument);

                        pdfMake.createPdf({
                            info: {
                                title: invoice.number,
                            },
                            content: htmlToPdfmake(html)
                        }).download(`${invoice.number}.pdf`);

                    },
                    error: error => {
                        alert("Impossibile convertire in PDF al momento, riprova più tardi")
                    }
                })
        }).catch(reason => alert("impossibile scaricare il PDF al momento"))
    }

    downloadQuoteProForma(quote) {
        let purchaseOrder: ContentText[] = quote.purchaseOrder ? [{
            text: "Ordine D'Acquisto",
            style: 'fieldName', alignment: 'right'
        }, {text: quote.purchaseOrder, alignment: 'right'}] : []

        let priceModifiers = quote.getPriceModifiersWithNoContributions().map(value => {
            let amount = value.amount ? value.amount : value.getValue(quote.taxable);
            let type = value.isDiscount() ? 'Sconto' : 'Maggiorazione';
            type = value.percentage ? type + ` ${value.percentage}%` : type

            return ['', '', '', '', {text: type, bold: true}, {
                text: this.i18n.formatCurrency(amount),
                bold: true,
                alignment: 'right'
            }];
        })

        let footerHeader = {text: 'Conforme Standard', style: "sectionHeader"};
        let footerContent = {
            text: 'Stampa di cortesia senza valenza fiscale ai sensi dell\'art. 21 DPR 633/72 e s.m., salvo per i soggetti non ' +
                'titolari di partita IVA, in regime dei minimi o forfettario o non residenti. ' +
                'L\'originale del documento è consultabile presso l\'indirizzo PEC e/o Codice destinatario fornitoci, oppure, ' +
                'in caso di mancata consegna, nella sua area riservata dell\'Agenzia delle Entrate.'
        };

        let docContent: Content = [
            {text: `Preventivo numero ${quote.number}`, style: "header"},
            {
                columns: [
                    {
                        width: '35%', stack: [
                            {text: "Cedente/Prestatore", style: 'sectionHeader'},
                            {text: "Denominazione: " + quote.sender.name},
                            {text: "Indirizzo: " + quote.sender.address.street},
                            {text: "Comune: " + quote.sender.address.city},
                            {text: "Codice Postale: " + quote.sender.address.postcode},
                            {text: "Provincia: " + quote.sender.address.district},
                            {text: `Codice Fiscale/P.IVA: ${quote.sender.taxNumber}`},
                        ]
                    },
                    {
                        width: '35%', stack: [
                            {text: "Cessionario/Committente", style: 'sectionHeader'},
                            {text: "Denominazione: " + quote.receiver.name},
                            {text: "Indirizzo: " + quote.receiver.address.street},
                            {text: "Comune: " + quote.receiver.address.city},
                            {text: "Codice Postale: " + quote.receiver.address.postcode},
                            {text: "Provincia: " + quote.receiver.address.district},
                            {text: "Codice Fiscale/P.IVA: " + quote.receiver.taxNumber || ""},

                        ]
                    },
                    {
                        width: '30%', stack: [
                            {text: "Data Emissione", style: 'fieldName', alignment: "right"},
                            {text: quote.date.format("DD/MM/YYYY"), alignment: "right"},
                            {text: "Data Scadenza Pagamento", style: 'fieldName', alignment: "right"},
                            ...purchaseOrder
                        ]
                    }
                ],
                margin: [0, 0, 0, 20]
            },
            {text: quote.description || '', margin: [0, 0, 0, 30]},

            {
                layout: 'lightHorizontalLines', // optional
                margin: [0, 0, 0, 30],
                table: {
                    headerRows: 1,
                    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],

                    body: [
                        ['Descrizione', 'Quantità', 'Unità di misura', 'Prezzo Unitario', 'IVA', 'Prezzo'],
                        ...quote.lines.map(line => {
                            return [
                                line.description,
                                line.quantity || '',
                                line.unitOfMeasure,
                                {text: this.i18n.formatCurrency(line.cost), alignment: "right"},
                                line.vat + '%',
                                {text: this.i18n.formatCurrency(line.taxable), alignment: "right"}
                            ];
                        }),
                        ...priceModifiers,
                        ['', '', '', '', {text: 'Totale Imponibile', bold: true}, {
                            text: this.i18n.formatCurrency(quote.taxable), bold: true, alignment: "right"
                        }],
                        ['', '', '', '', {text: 'Totale Imposta', bold: true},
                            {text: this.i18n.formatCurrency(quote.tax), bold: true, alignment: "right"}],
                        ['', '', '', '',
                            {text: 'Totale Documento', bold: true},
                            {text: this.i18n.formatCurrency(quote.total), bold: true, alignment: "right"}],

                    ]
                }
            },

        ]

        let governmentContributions = quote.governmentContributions;
        if (governmentContributions.length > 0) {
            let contributionsHeader: ContentText = {
                text: 'Contributo governativo',
                style: "sectionHeader",
                alignment: "right"
            };
            let contributionsList: ContentText[] = governmentContributions.map(item => {
                return ({
                    text: [item.description, '    ', {
                        text: this.i18n.formatCurrency(item.getValue(quote.taxable)),
                        bold: true
                    }], alignment: "right"
                }) as ContentText;
            });
            docContent.push(contributionsHeader, ...contributionsList, {text: '', margin: [0, 0, 0, 20]})
        }

        if (quote.payments.length > 0) {
            let paymentHeader = {text: 'Pagamento', style: "sectionHeader"};
            let paymentTable: ContentTable = {
                layout: 'lightHorizontalLines', // optional
                margin: [0, 0, 0, 30],
                table: {
                    headerRows: 1,
                    widths: ['auto', 'auto', '*', 'auto', 'auto'],

                    body: [
                        ['Tipo', 'Istituto finanziario', 'IBAN', 'Data scadenza', 'Importo',],
                        ...quote.payments.map(payment => {
                            return [payment.type?.name, payment.bank || '', payment.iban || '', payment.dueDate?.format("DD/MM/YYYY") || '', `${this.i18n.formatCurrency(payment.amount)}`];
                        }),

                    ]
                }
            };

            docContent.push(paymentHeader, paymentTable,)
        }

        docContent.push(footerHeader, footerContent,)


        let docDefinition: TDocumentDefinitions = {
            pageSize: 'A4',
            pageMargins: [30, 50, 30, 50],
            content: docContent,
            styles: {
                header: {
                    fontSize: 15,
                    bold: true,
                    margin: [0, 0, 0, 30]
                },
                sectionHeader: {
                    fontSize: 10,
                    bold: true,
                },
                fieldName: {
                    fontSize: 7,
                    lineHeight: 1.5,
                    bold: true

                }
            },
            defaultStyle: {
                fontSize: 8,
                lineHeight: 1.75
            }

        };

        pdfMake.createPdf(docDefinition).open();
    }

    public downloadOutboundInvoiceProForma(invoice: Invoice) {
        let dueDate = invoice.dueDate?.format("DD/MM/YYYY") || '';
        let purchaseOrder: ContentText[] = invoice.purchaseOrder ? [{
            text: "Ordine D'Acquisto",
            style: 'fieldName', alignment: 'right'
        }, {text: invoice.purchaseOrder, alignment: 'right'}] : []

        let priceModifiers = invoice.getPriceModifiersWithNoContributions().map(value => {
            let amount = value.amount ? value.amount : value.getValue(invoice.taxable);
            let type = value.isDiscount() ? 'Sconto' : 'Maggiorazione';
            type = value.percentage ? type + ` ${value.percentage}%` : type

            return ['', '', '', '', {text: type, bold: true}, {
                text: this.i18n.formatCurrency(amount),
                bold: true,
                alignment: 'right'
            }];
        })

        let footerHeader = {text: 'Conforme Standard', style: "sectionHeader"};
        let footerContent = {
            text: 'Stampa di cortesia senza valenza fiscale ai sensi dell\'art. 21 DPR 633/72 e s.m., salvo per i soggetti non ' +
                'titolari di partita IVA, in regime dei minimi o forfettario o non residenti. ' +
                'L\'originale del documento è consultabile presso l\'indirizzo PEC e/o Codice destinatario fornitoci, oppure, ' +
                'in caso di mancata consegna, nella sua area riservata dell\'Agenzia delle Entrate.'
        };

        let documentType = invoice.type == FiscalDocumentType.Invoice? 'Fattura' : 'Nota di credito';

        let docContent: Content = [
            {text: `${documentType} numero ${invoice.number}`, style: "header"},
            {
                columns: [
                    {
                        width: '35%', stack: [
                            {text: "Cedente/Prestatore", style: 'sectionHeader'},
                            {text: "Denominazione: " + invoice.sender.name},
                            {text: "Indirizzo: " + invoice.sender.address.street},
                            {text: "Comune: " + invoice.sender.address.city},
                            {text: "Codice Postale: " + invoice.sender.address.postcode},
                            {text: "Provincia: " + invoice.sender.address.district},
                            {text: `Codice Fiscale/P.IVA: ${invoice.sender.taxNumber}`},
                        ]
                    },
                    {
                        width: '35%', stack: [
                            {text: "Cessionario/Committente", style: 'sectionHeader'},
                            {text: "Denominazione: " + invoice.receiver.name},
                            {text: "Indirizzo: " + invoice.receiver.address.street},
                            {text: "Comune: " + invoice.receiver.address.city},
                            {text: "Codice Postale: " + invoice.receiver.address.postcode},
                            {text: "Provincia: " + invoice.receiver.address.district},
                            {text: "Codice Fiscale/P.IVA: " + invoice.receiver.taxNumber || ""},

                        ]
                    },
                    {
                        width: '30%', stack: [
                            {text: "Data Emissione", style: 'fieldName', alignment: "right"},
                            {text: invoice.date.format("DD/MM/YYYY"), alignment: "right"},
                            {text: "Data Scadenza Pagamento", style: 'fieldName', alignment: "right"},
                            {text: dueDate, alignment: "right"},
                            ...purchaseOrder
                        ]
                    }
                ],
                margin: [0, 0, 0, 20]
            },
            {text: invoice.description || '', margin: [0, 0, 0, 30]},

            {
                layout: 'lightHorizontalLines', // optional
                margin: [0, 0, 0, 30],
                table: {
                    headerRows: 1,
                    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],

                    body: [
                        ['Descrizione', 'Quantità', 'Unità di misura', 'Prezzo Unitario', 'IVA', 'Prezzo'],
                        ...invoice.lines.map(line => {
                            return [
                                line.description,
                                line.quantity || '',
                                line.quantity ? line.unitOfMeasure : '',
                                line.quantity ? {text: this.i18n.formatCurrency(line.cost), alignment: "right"} : '',
                                line.quantity ? line.vat + '%' : '',
                                line.quantity ? {text: this.i18n.formatCurrency(line.taxable), alignment: "right"} : ''
                            ];
                        }),
                        ...priceModifiers,
                        ['', '', '', '', {text: 'Totale Imponibile', bold: true}, {
                            text: this.i18n.formatCurrency(invoice.taxable), bold: true, alignment: "right"
                        }],
                        ['', '', '', '', {text: 'Totale Imposta', bold: true},
                            {text: this.i18n.formatCurrency(invoice.tax), bold: true, alignment: "right"}],
                        ['', '', '', '',
                            {text: 'Totale Documento', bold: true},
                            {text: this.i18n.formatCurrency(invoice.total), bold: true, alignment: "right"}],

                    ]
                }
            },

        ]

        let governmentContributions = invoice.governmentContributions;
        if (governmentContributions.length > 0) {
            let contributionsHeader: ContentText = {
                text: 'Contributo governativo',
                style: "sectionHeader",
                alignment: "right"
            };
            let contributionsList: ContentText[] = governmentContributions.map(item => {
                return ({
                    text: [item.description, '    ', {
                        text: this.i18n.formatCurrency(item.getValue(invoice.taxable)),
                        bold: true
                    }], alignment: "right"
                }) as ContentText;
            });
            docContent.push(contributionsHeader, ...contributionsList, {text: '', margin: [0, 0, 0, 20]})
        }

        if (invoice.payments.length > 0) {
            let paymentHeader = {text: 'Pagamento', style: "sectionHeader"};
            let paymentTable: ContentTable = {
                layout: 'lightHorizontalLines', // optional
                margin: [0, 0, 0, 30],
                table: {
                    headerRows: 1,
                    widths: ['auto', 'auto', '*', 'auto', 'auto'],

                    body: [
                        ['Tipo', 'Istituto finanziario', 'IBAN', 'Data scadenza', 'Importo',],
                        ...invoice.payments.map(payment => {
                            return [payment.type?.name, payment.bank || '', payment.iban || '', payment.dueDate?.format("DD/MM/YYYY") || '', `${this.i18n.formatCurrency(payment.amount)}`];
                        }),

                    ]
                }
            };

            docContent.push(paymentHeader, paymentTable,)
        }

        docContent.push(footerHeader, footerContent,)

        let docDefinition: TDocumentDefinitions = {
            pageSize: 'A4',
            pageMargins: [30, 50, 30, 50],
            content: docContent,
            styles: {
                header: {
                    fontSize: 15,
                    bold: true,
                    margin: [0, 0, 0, 30]
                },
                sectionHeader: {
                    fontSize: 10,
                    bold: true,
                },
                fieldName: {
                    fontSize: 7,
                    lineHeight: 1.5,
                    bold: true

                }
            },
            defaultStyle: {
                fontSize: 8,
                lineHeight: 1.75
            }

        };

        pdfMake.createPdf(docDefinition).download(invoice.number);
    }

    private copyQuoteDataIntoNewQuote(quoteToCopy: Quote) {
        let converter = this.quotesRepository.converter as FiscalDocumentConverter<Quote>;

        let quoteData = quoteToCopy.clone(new Quote()) as Quote;
        quoteData.id = ""
        quoteData.date = moment()
        quoteData.number = ""

        let quote = converter.makeFiscalDocument(quoteData)
        return of(quote);
    }
}
