import {Document} from "./document";

export interface Embedded {}

export class Entity {
    id: string;
    version: number = 0;

    // TODO this property is to signal the entity has not been saved yet (for embedded entities like Activities), find a different solution
    transient: boolean = false

    clone(instance: Entity): Entity {
        return Object.assign(instance, {...this})
    }

    persisted(): boolean {
        return !!this.id
    }

    getDocuments(): Document[] {
        return []
    }

}
