import {v4 as uuidV4} from 'uuid';
import {Business} from "./model/business";
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {Deliverable} from "./model/supply";
import * as moment from "moment";
import {Moment} from "moment";
import cloneDeep from 'lodash-es/cloneDeep'

export function clone(object) {
    return cloneDeep(object)
}

export function momentToDate(momentDate: Moment) {
    return momentDate.toDate()
}

export function momentDate(year: number, month: number, dayNumber: number) {
    return moment(`${year}-${month + 1}-${dayNumber}`, 'YYYY-MM-DD');
}

export function generateId() {
    return uuidV4()
}

export function getCurrentBusiness(): Business {
    let item = localStorage.getItem("business");
    if (!item) throw new Error("business not present")

    let inspectedBusiness = getInspectedBusiness()
    if (inspectedBusiness) return inspectedBusiness

    return JSON.parse(item) as Business
}

export function getInspectedBusiness(): Business | null {
    let inspectedBusiness = localStorage.getItem("inspectedBusiness");

    return inspectedBusiness ? JSON.parse(inspectedBusiness) as Business : null
}

export function compareEntities(a, b) {
    if (a == null || b == null) return null

    return a.id == b.id ? a.name : null
}


export function round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100
}

export function getMonthName(month: number) {
    return months.find((m) => m.number === month)?.name || ''
}


export function verifyAutocomplete(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let value = control.value;
        return typeof value === "string" ? {nonExistent: {value: control.value}} : null;
    };
}

export function makeSupply(type: string) {
    if (type == 'deliverable') {
        return new Deliverable()
    } else if (type == 'medical_record') {
        return new Deliverable()
    } else if (type == 'education') {
        return new Deliverable()
    } else {
        throw Error("cannot happen")
    }
}

export const months = [
    {number: 0, name: $localize`:@@january:Gennaio`},
    {number: 1, name: $localize`:@@february:Febbraio`},
    {number: 2, name: $localize`:@@march:Marzo`},
    {number: 3, name: $localize`:@@april:Aprile`},
    {number: 4, name: $localize`:@@may:Maggio`},
    {number: 5, name: $localize`:@@june:Giugno`},
    {number: 6, name: $localize`:@@july:Luglio`},
    {number: 7, name: $localize`:@@august:Agosto`},
    {number: 8, name: $localize`:@@september:Settembre`},
    {number: 9, name: $localize`:@@october:Ottobre`},
    {number: 10, name: $localize`:@@november:Novembre`},
    {number: 11, name: $localize`:@@dicember:Dicembre`},
]

export const districts = [
    {
        name: "Agrigento",
        abbr: "AG",
        region: "Sicilia"
    },
    {
        name: "Alessandria",
        abbr: "AL",
        region: "Piemonte"
    },
    {
        name: "Ancona",
        abbr: "AN",
        region: "Marche"
    },
    {
        name: "Arezzo",
        abbr: "AR",
        region: "Toscana"
    },
    {
        name: "Ascoli Piceno",
        abbr: "AP",
        region: "Marche"
    },
    {
        name: "Asti",
        abbr: "AT",
        region: "Piemonte"
    },
    {
        name: "Avellino",
        abbr: "AV",
        region: "Campania"
    },
    {
        name: "Bari",
        abbr: "BA",
        region: "Puglia"
    },
    {
        name: "Barletta-Andria-Trani",
        abbr: "BT",
        region: "Puglia"
    },
    {
        name: "Belluno",
        abbr: "BL",
        region: "Veneto"
    },
    {
        name: "Benevento",
        abbr: "BN",
        region: "Campania"
    },
    {
        name: "Bergamo",
        abbr: "BG",
        region: "Lombardia"
    },
    {
        name: "Biella",
        abbr: "BI",
        region: "Piemonte"
    },
    {
        name: "Bologna",
        abbr: "BO",
        region: "Emilia-Romagna"
    },
    {
        name: "Bolzano/Bozen",
        abbr: "BZ",
        region: "Trentino-Alto Adige/Südtirol"
    },
    {
        name: "Brescia",
        abbr: "BS",
        region: "Lombardia"
    },
    {
        name: "Brindisi",
        abbr: "BR",
        region: "Puglia"
    },
    {
        name: "Cagliari",
        abbr: "CA",
        region: "Sardegna"
    },
    {
        name: "Caltanissetta",
        abbr: "CL",
        region: "Sicilia"
    },
    {
        name: "Campobasso",
        abbr: "CB",
        region: "Molise"
    },
    {
        name: "Carbonia-Iglesias",
        abbr: "CI",
        region: "Sardegna"
    },
    {
        name: "Caserta",
        abbr: "CE",
        region: "Campania"
    },
    {
        name: "Catania",
        abbr: "CT",
        region: "Sicilia"
    },
    {
        name: "Catanzaro",
        abbr: "CZ",
        region: "Calabria"
    },
    {
        name: "Chieti",
        abbr: "CH",
        region: "Abruzzo"
    },
    {
        name: "Como",
        abbr: "CO",
        region: "Lombardia"
    },
    {
        name: "Cosenza",
        abbr: "CS",
        region: "Calabria"
    },
    {
        name: "Cremona",
        abbr: "CR",
        region: "Lombardia"
    },
    {
        name: "Crotone",
        abbr: "KR",
        region: "Calabria"
    },
    {
        name: "Cuneo",
        abbr: "CN",
        region: "Piemonte"
    },
    {
        name: "Enna",
        abbr: "EN",
        region: "Sicilia"
    },
    {
        name: "Fermo",
        abbr: "FM",
        region: "Marche"
    },
    {
        name: "Ferrara",
        abbr: "FE",
        region: "Emilia-Romagna"
    },
    {
        name: "Firenze",
        abbr: "FI",
        region: "Toscana"
    },
    {
        name: "Foggia",
        abbr: "FG",
        region: "Puglia"
    },
    {
        name: "Forlì-Cesena",
        abbr: "FC",
        region: "Emilia-Romagna"
    },
    {
        name: "Frosinone",
        abbr: "FR",
        region: "Lazio"
    },
    {
        name: "Genova",
        abbr: "GE",
        region: "Liguria"
    },
    {
        name: "Gorizia",
        abbr: "GO",
        region: "Friuli-Venezia Giulia"
    },
    {
        name: "Grosseto",
        abbr: "GR",
        region: "Toscana"
    },
    {
        name: "Imperia",
        abbr: "IM",
        region: "Liguria"
    },
    {
        name: "Isernia",
        abbr: "IS",
        region: "Molise"
    },
    {
        name: "L'Aquila",
        abbr: "AQ",
        region: "Abruzzo"
    },
    {
        name: "La Spezia",
        abbr: "SP",
        region: "Liguria"
    },
    {
        name: "Latina",
        abbr: "LT",
        region: "Lazio"
    },
    {
        name: "Lecce",
        abbr: "LE",
        region: "Puglia"
    },
    {
        name: "Lecco",
        abbr: "LC",
        region: "Lombardia"
    },
    {
        name: "Livorno",
        abbr: "LI",
        region: "Toscana"
    },
    {
        name: "Lodi",
        abbr: "LO",
        region: "Lombardia"
    },
    {
        name: "Lucca",
        abbr: "LU",
        region: "Toscana"
    },
    {
        name: "Macerata",
        abbr: "MC",
        region: "Marche"
    },
    {
        name: "Mantova",
        abbr: "MN",
        region: "Lombardia"
    },
    {
        name: "Massa-Carrara",
        abbr: "MS",
        region: "Toscana"
    },
    {
        name: "Matera",
        abbr: "MT",
        region: "Basilicata"
    },
    {
        name: "Medio Campidano",
        abbr: "VS",
        region: "Sardegna"
    },
    {
        name: "Messina",
        abbr: "ME",
        region: "Sicilia"
    },
    {
        name: "Milano",
        abbr: "MI",
        region: "Lombardia"
    },
    {
        name: "Modena",
        abbr: "MO",
        region: "Emilia-Romagna"
    },
    {
        name: "Monza e della Brianza",
        abbr: "MB",
        region: "Lombardia"
    },
    {
        name: "Napoli",
        abbr: "NA",
        region: "Campania"
    },
    {
        name: "Novara",
        abbr: "NO",
        region: "Piemonte"
    },
    {
        name: "Nuoro",
        abbr: "NU",
        region: "Sardegna"
    },
    {
        name: "Ogliastra",
        abbr: "OG",
        region: "Sardegna"
    },
    {
        name: "Olbia-Tempio",
        abbr: "OT",
        region: "Sardegna"
    },
    {
        name: "Oristano",
        abbr: "OR",
        region: "Sardegna"
    },
    {
        name: "Padova",
        abbr: "PD",
        region: "Veneto"
    },
    {
        name: "Palermo",
        abbr: "PA",
        region: "Sicilia"
    },
    {
        name: "Parma",
        abbr: "PR",
        region: "Emilia-Romagna"
    },
    {
        name: "Pavia",
        abbr: "PV",
        region: "Lombardia"
    },
    {
        name: "Perugia",
        abbr: "PG",
        region: "Umbria"
    },
    {
        name: "Pesaro e Urbino",
        abbr: "PU",
        region: "Marche"
    },
    {
        name: "Pescara",
        abbr: "PE",
        region: "Abruzzo"
    },
    {
        name: "Piacenza",
        abbr: "PC",
        region: "Emilia-Romagna"
    },
    {
        name: "Pisa",
        abbr: "PI",
        region: "Toscana"
    },
    {
        name: "Pistoia",
        abbr: "PT",
        region: "Toscana"
    },
    {
        name: "Pordenone",
        abbr: "PN",
        region: "Friuli-Venezia Giulia"
    },
    {
        name: "Potenza",
        abbr: "PZ",
        region: "Basilicata"
    },
    {
        name: "Prato",
        abbr: "PO",
        region: "Toscana"
    },
    {
        name: "Ragusa",
        abbr: "RG",
        region: "Sicilia"
    },
    {
        name: "Ravenna",
        abbr: "RA",
        region: "Emilia-Romagna"
    },
    {
        name: "Reggio di Calabria",
        abbr: "RC",
        region: "Calabria"
    },
    {
        name: "Reggio nell'Emilia",
        abbr: "RE",
        region: "Emilia-Romagna"
    },
    {
        name: "Rieti",
        abbr: "RI",
        region: "Lazio"
    },
    {
        name: "Rimini",
        abbr: "RN",
        region: "Emilia-Romagna"
    },
    {
        name: "Roma",
        abbr: "RM",
        region: "Lazio"
    },
    {
        name: "Rovigo",
        abbr: "RO",
        region: "Veneto"
    },
    {
        name: "Salerno",
        abbr: "SA",
        region: "Campania"
    },
    {
        name: "Sassari",
        abbr: "SS",
        region: "Sardegna"
    },
    {
        name: "Savona",
        abbr: "SV",
        region: "Liguria"
    },
    {
        name: "Siena",
        abbr: "SI",
        region: "Toscana"
    },
    {
        name: "Siracusa",
        abbr: "SR",
        region: "Sicilia"
    },
    {
        name: "Sondrio",
        abbr: "SO",
        region: "Lombardia"
    },
    {
        name: "Taranto",
        abbr: "TA",
        region: "Puglia"
    },
    {
        name: "Teramo",
        abbr: "TE",
        region: "Abruzzo"
    },
    {
        name: "Terni",
        abbr: "TR",
        region: "Umbria"
    },
    {
        name: "Torino",
        abbr: "TO",
        region: "Piemonte"
    },
    {
        name: "Trapani",
        abbr: "TP",
        region: "Sicilia"
    },
    {
        name: "Trento",
        abbr: "TN",
        region: "Trentino-Alto Adige/Südtirol"
    },
    {
        name: "Treviso",
        abbr: "TV",
        region: "Veneto"
    },
    {
        name: "Trieste",
        abbr: "TS",
        region: "Friuli-Venezia Giulia"
    },
    {
        name: "Udine",
        abbr: "UD",
        region: "Friuli-Venezia Giulia"
    },
    {
        name: "Aosta",
        abbr: "AO",
        region: "Valle d'Aosta/Vallée d'Aoste"
    },
    {
        name: "Varese",
        abbr: "VA",
        region: "Lombardia"
    },
    {
        name: "Venezia",
        abbr: "VE",
        region: "Veneto"
    },
    {
        name: "Verbano-Cusio-Ossola",
        abbr: "VB",
        region: "Piemonte"
    },
    {
        name: "Vercelli",
        abbr: "VC",
        region: "Piemonte"
    },
    {
        name: "Verona",
        abbr: "VR",
        region: "Veneto"
    },
    {
        name: "Vibo Valentia",
        abbr: "VV",
        region: "Calabria"
    },
    {
        name: "Vicenza",
        abbr: "VI",
        region: "Veneto"
    },
    {
        name: "Viterbo",
        abbr: "VT",
        region: "Lazio"
    }
]

export const countries = [
    {
        code: "AF",
        name: "AFGHANISTAN"
    },
    {
        code: "AL",
        name: "ALBANIA"
    },
    {
        code: "DZ",
        name: "ALGERIA"
    },
    {
        code: "AS",
        name: "SAMOA SWAIN (AMERICANE)"
    },
    {
        code: "AD",
        name: "ANDORRA"
    },
    {
        code: "AO",
        name: "ANGOLA"
    },
    {
        code: "AI",
        name: "ANGUILLA (ISOLA)"
    },
    {
        code: "AQ",
        name: "ANTARCTICA"
    },
    {
        code: "AG",
        name: "ANTIGUA E BARBUDA"
    },
    {
        code: "AR",
        name: "ARGENTINA"
    },
    {
        code: "AM",
        name: "ARMENIA"
    },
    {
        code: "AW",
        name: "ARUBA (ISOLA)"
    },
    {
        code: "AU",
        name: "AUSTRALIA"
    },
    {
        code: "AT",
        name: "AUSTRIA"
    },
    {
        code: "AZ",
        name: "AZERBAIGIAN"
    },
    {
        code: "BS",
        name: "BAHAMA"
    },
    {
        code: "BH",
        name: "BAHREIN"
    },
    {
        code: "BD",
        name: "BANGLADESH"
    },
    {
        code: "BB",
        name: "BARBADOS"
    },
    {
        code: "BY",
        name: "BIELORUSSIA"
    },
    {
        code: "BE",
        name: "BELGIO"
    },
    {
        code: "BZ",
        name: "BELIZE"
    },
    {
        code: "BJ",
        name: "BENIN"
    },
    {
        code: "BM",
        name: "BERMUDA"
    },
    {
        code: "BT",
        name: "BHUTAN"
    },
    {
        code: "BO",
        name: "BOLIVIA"
    },
    {
        code: "BA",
        name: "BOSNIA ED ERZEGOVINA"
    },
    {
        code: "BW",
        name: "BOTSWANA"
    },
    {
        code: "BV",
        name: "BOUVET"
    },
    {
        code: "BR",
        name: "BRASILE"
    },
    {
        code: "IO",
        name: "TERRITORIO BRIT. DELL'OCEANO INDIANO"
    },
    {
        code: "BN",
        name: "BRUNEI"
    },
    {
        code: "BG",
        name: "BULGARIA"
    },
    {
        code: "BF",
        name: "BURKINA"
    },
    {
        code: "BI",
        name: "BURUNDI"
    },
    {
        code: "KH",
        name: "CAMBOGIA"
    },
    {
        code: "CM",
        name: "CAMERUN"
    },
    {
        code: "CA",
        name: "CANADA"
    },
    {
        code: "CV",
        name: "CAPO VERDE"
    },
    {
        code: "KY",
        name: "CAYMAN (ISOLE)"
    },
    {
        code: "CF",
        name: "CENTRAFRICANA REPUBBLICA"
    },
    {
        code: "TD",
        name: "CIAD"
    },
    {
        code: "CL",
        name: "CILE"
    },
    {
        code: "CN",
        name: "CINA REPUBBLICA POPOLARE"
    },
    {
        code: "CX",
        name: "CHRISTMAS (ISOLA)"
    },
    {
        code: "CC",
        name: "COCOS (ISOLE)"
    },
    {
        code: "CO",
        name: "COLOMBIA"
    },
    {
        code: "KM",
        name: "COMORE"
    },
    {
        code: "CG",
        name: "CONGO REPUBBLICA POPOLARE"
    },
    {
        code: "CD",
        name: "CONGO REPUBBLICA DEMOCRATICA"
    },
    {
        code: "CK",
        name: "COOK (ISOLE)"
    },
    {
        code: "CR",
        name: "COSTA RICA"
    },
    {
        code: "CI",
        name: "COSTA D'AVORIO"
    },
    {
        code: "HR",
        name: "CROAZIA"
    },
    {
        code: "CU",
        name: "CUBA"
    },
    {
        code: "CY",
        name: "CIPRO"
    },
    {
        code: "CZ",
        name: "CECA REPUBBLICA"
    },
    {
        code: "DK",
        name: "DANIMARCA"
    },
    {
        code: "DJ",
        name: "GIBUTI"
    },
    {
        code: "DM",
        name: "DOMINICA (ISOLA)"
    },
    {
        code: "DO",
        name: "DOMINICANA REPUBBLICA"
    },
    {
        code: "EC",
        name: "ECUADOR"
    },
    {
        code: "EG",
        name: "EGITTO"
    },
    {
        code: "SV",
        name: "EL SALVADOR"
    },
    {
        code: "GQ",
        name: "GUINEA EQUATORIALE"
    },
    {
        code: "ER",
        name: "ERITREA"
    },
    {
        code: "EE",
        name: "ESTONIA"
    },
    {
        code: "ET",
        name: "ETIOPIA"
    },
    {
        code: "FK",
        name: "FALKLAND (ISOLE)"
    },
    {
        code: "FO",
        name: "FAER OER (ISOLE)"
    },
    {
        code: "FJ",
        name: "FIGI"
    },
    {
        code: "FI",
        name: "FINLANDIA"
    },
    {
        code: "FR",
        name: "FRANCIA"
    },
    {
        code: "GF",
        name: "GUAYANA"
    },
    {
        code: "PF",
        name: "POLINESIA FRANCESE (ISOLE)"
    },
    {
        code: "TF",
        name: "TERRITORI AUSTRALI E ANTARTICI FRANCESI"
    },
    {
        code: "GA",
        name: "GABON"
    },
    {
        code: "GM",
        name: "GAMBIA"
    },
    {
        code: "GE",
        name: "GEORGIA"
    },
    {
        code: "DE",
        name: "GERMANIA"
    },
    {
        code: "GH",
        name: "GHANA"
    },
    {
        code: "GI",
        name: "GIBILTERRA"
    },
    {
        code: "GR",
        name: "GRECIA"
    },
    {
        code: "GL",
        name: "GROENLANDIA"
    },
    {
        code: "GD",
        name: "GRENADA"
    },
    {
        code: "GP",
        name: "GUADALUPA"
    },
    {
        code: "GU",
        name: "GUAM (ISOLA)"
    },
    {
        code: "GT",
        name: "GUATEMALA"
    },
    {
        code: "GN",
        name: "GUINEA REPUBBLICA POPOLARE"
    },
    {
        code: "GW",
        name: "GUINEA BISSAU"
    },
    {
        code: "GY",
        name: "GUYANA"
    },
    {
        code: "HT",
        name: "HAITI"
    },
    {
        code: "VA",
        name: "CITTA' DEL VATICANO"
    },
    {
        code: "HN",
        name: "HONDURAS"
    },
    {
        code: "HK",
        name: "HONG KONG"
    },
    {
        code: "HU",
        name: "UNGHERIA"
    },
    {
        code: "IS",
        name: "ISLANDA"
    },
    {
        code: "IN",
        name: "INDIA"
    },
    {
        code: "ID",
        name: "INDONESIA"
    },
    {
        code: "IR",
        name: "IRAN"
    },
    {
        code: "IQ",
        name: "IRAQ"
    },
    {
        code: "IE",
        name: "IRLANDA"
    },
    {
        code: "IL",
        name: "ISRAELE"
    },
    {
        name: "ITALIA",
        code: "IT"
    },
    {
        code: "JM",
        name: "GIAMAICA"
    },
    {
        code: "JP",
        name: "GIAPPONE"
    },
    {
        code: "JO",
        name: "GIORDANIA"
    },
    {
        code: "KZ",
        name: "KAZAKISTAN"
    },
    {
        code: "KE",
        name: "KENYA"
    },
    {
        code: "KI",
        name: "KIRIBATI"
    },
    {
        code: "KP",
        name: "COREA DEL NORD"
    },
    {
        code: "KR",
        name: "COREA DEL SUD"
    },
    {
        code: "KW",
        name: "KUWAIT"
    },
    {
        code: "KG",
        name: "KIRGHIZISTAN"
    },
    {
        code: "LA",
        name: "LAOS"
    },
    {
        code: "LV",
        name: "LETTONIA"
    },
    {
        code: "LB",
        name: "LIBANO"
    },
    {
        code: "LS",
        name: "LESOTHO"
    },
    {
        code: "LR",
        name: "LIBERIA"
    },
    {
        code: "LY",
        name: "LIBIA"
    },
    {
        code: "LI",
        name: "LIECHTENSTEIN"
    },
    {
        code: "LT",
        name: "LITUANIA"
    },
    {
        code: "LU",
        name: "LUSSEMBURGO"
    },
    {
        code: "MO",
        name: "MACAO"
    },
    {
        code: "MK",
        name: "MACEDONIA"
    },
    {
        code: "MG",
        name: "MADAGASCAR"
    },
    {
        code: "MW",
        name: "MALAWI"
    },
    {
        code: "MY",
        name: "MALAYSIA"
    },
    {
        code: "MV",
        name: "MALDIVE"
    },
    {
        code: "ML",
        name: "MALI"
    },
    {
        code: "MT",
        name: "MALTA"
    },
    {
        code: "MH",
        name: "MARSHALL (ISOLE)"
    },
    {
        code: "MQ",
        name: "MARTINICA"
    },
    {
        code: "MR",
        name: "MAURITANIA"
    },
    {
        code: "MU",
        name: "MAURIZIO (ISOLE)"
    },
    {
        code: "YT",
        name: "MAYOTTE"
    },
    {
        code: "MX",
        name: "MESSICO"
    },
    {
        code: "FM",
        name: "MICRONESIA STATI FEDERATI"
    },
    {
        code: "MD",
        name: "MOLDAVIA"
    },
    {
        code: "MC",
        name: "MONACO"
    },
    {
        code: "MN",
        name: "MONGOLIA"
    },
    {
        code: "MS",
        name: "MONTSERRAT (ISOLA)"
    },
    {
        code: "MA",
        name: "MAROCCO"
    },
    {
        code: "MZ",
        name: "MOZAMBICO"
    },
    {
        code: "MM",
        name: "MYANMAR"
    },
    {
        code: "NA",
        name: "NAMIBIA"
    },
    {
        code: "NR",
        name: "NAURU"
    },
    {
        code: "NP",
        name: "NEPAL"
    },
    {
        code: "NL",
        name: "PAESI BASSI"
    },
    {
        code: "NC",
        name: "NUOVA CALEDONIA (ISOLE E DIPENDENZE)"
    },
    {
        code: "NZ",
        name: "NUOVA ZELANDA"
    },
    {
        code: "NI",
        name: "NICARAGUA"
    },
    {
        code: "NE",
        name: "NIGER"
    },
    {
        code: "NG",
        name: "NIGERIA"
    },
    {
        code: "NU",
        name: "NIUE (ATOLLO)"
    },
    {
        code: "NF",
        name: "NORFOLK (ISOLE)"
    },
    {
        code: "MP",
        name: "MARIANNE (ISOLE)"
    },
    {
        code: "NO",
        name: "NORVEGIA"
    },
    {
        code: "OM",
        name: "OMAN"
    },
    {
        code: "PK",
        name: "PAKISTAN"
    },
    {
        code: "PW",
        name: "PALAU REPUBBLICA"
    },
    {
        code: "PA",
        name: "PANAMA'"
    },
    {
        code: "PG",
        name: "PAPUA NUOVA GUINEA"
    },
    {
        code: "PY",
        name: "PARAGUAY"
    },
    {
        code: "PE",
        name: "PERU'"
    },
    {
        code: "PH",
        name: "FILIPPINE"
    },
    {
        code: "PN",
        name: "PITCAIRN (E DIPENDENZE)"
    },
    {
        code: "PL",
        name: "POLONIA"
    },
    {
        code: "PT",
        name: "PORTOGALLO"
    },
    {
        code: "PR",
        name: "PUERTO RICO (STATO LIBERO ASSOCIATO)"
    },
    {
        code: "QA",
        name: "QATAR"
    },
    {
        code: "RE",
        name: "RIUNIONE (ISOLA)"
    },
    {
        code: "RU",
        name: "RUSSIA"
    },
    {
        code: "RW",
        name: "RUANDA"
    },
    {
        code: "SH",
        name: "SANT’ELENA"
    },
    {
        code: "KN",
        name: "SAINT KITTS E NEVIS=S. CHRISTOPHER E NEVIS"
    },
    {
        code: "LC",
        name: "SAINT LUCIA"
    },
    {
        code: "PM",
        name: "SAINT PIERRE ET MIQUELON (ISOLE)"
    },
    {
        code: "VC",
        name: "SAINT VINCENT E GRENADINE"
    },
    {
        code: "SM",
        name: "SAN MARINO"
    },
    {
        code: "ST",
        name: "SAO TOME' E PRINCIPE (ISOLE)"
    },
    {
        code: "SA",
        name: "ARABIA SAUDITA"
    },
    {
        code: "SN",
        name: "SENEGAL"
    },
    {
        code: "SL",
        name: "SIERRA LEONE"
    },
    {
        code: "SG",
        name: "SINGAPORE"
    },
    {
        code: "SK",
        name: "SLOVACCA REPUBBLICA"
    },
    {
        code: "SI",
        name: "SLOVENIA"
    },
    {
        code: "SB",
        name: "SALOMONE"
    },
    {
        code: "SO",
        name: "SOMALIA"
    },
    {
        code: "ZA",
        name: "SUDAFRICANA REPUBBLICA"
    },
    {
        code: "GS",
        name: "GEORGIA DEL SUD E SANDWICH AUSTRALI"
    },
    {
        code: "ES",
        name: "SPAGNA"
    },
    {
        code: "LK",
        name: "SRI LANKA"
    },
    {
        code: "SD",
        name: "SUDAN"
    },
    {
        code: "SR",
        name: "SURINAME"
    },
    {
        code: "SJ",
        name: "SVALBARD E JAN MAYEN (ISOLE)"
    },
    {
        code: "SZ",
        name: "SWAZILAND"
    },
    {
        code: "SE",
        name: "SVEZIA"
    },
    {
        code: "CH",
        name: "SVIZZERA"
    },
    {
        code: "SY",
        name: "SIRIA"
    },
    {
        code: "TW",
        name: "TAIWAN"
    },
    {
        code: "TJ",
        name: "TAGIKISTAN"
    },
    {
        code: "TZ",
        name: "TANZANIA"
    },
    {
        code: "TH",
        name: "THAILANDIA"
    },
    {
        code: "TG",
        name: "TOGO"
    },
    {
        code: "TK",
        name: "TOKELAU (ISOLE)"
    },
    {
        code: "TO",
        name: "TONGA"
    },
    {
        code: "TT",
        name: "TRINIDAD E TOBAGO"
    },
    {
        code: "TN",
        name: "TUNISIA"
    },
    {
        code: "TR",
        name: "TURCHIA"
    },
    {
        code: "TM",
        name: "TURKEMENISTAN"
    },
    {
        code: "TC",
        name: "TURKS E CAICOS (ISOLE)"
    },
    {
        code: "TV",
        name: "TUVALU"
    },
    {
        code: "UG",
        name: "UGANDA"
    },
    {
        code: "UA",
        name: "UCRAINA"
    },
    {
        code: "AE",
        name: "EMIRATI ARABI UNITI"
    },
    {
        code: "GB",
        name: "REGNO UNITO"
    },
    {
        code: "US",
        name: "STATI UNITI D'AMERICA"
    },
    {
        code: "UM",
        name: "STATI UNITI - ISOLE MINORI LONTANE"
    },
    {
        code: "UY",
        name: "URUGUAY"
    },
    {
        code: "UZ",
        name: "UZBEKISTAN"
    },
    {
        code: "VU",
        name: "VANUATU"
    },
    {
        code: "VE",
        name: "VENEZUELA"
    },
    {
        code: "VN",
        name: "VIETNAM"
    },
    {
        code: "VG",
        name: "ISOLE VERGINI BRITANNICHE"
    },
    {
        code: "VI",
        name: "ISOLE VERGINI AMERICANE"
    },
    {
        code: "WF",
        name: "WALLIS E FUTUNA (ISOLE)"
    },
    {
        code: "EH",
        name: "WESTERN SAHARA"
    },
    {
        code: "YE",
        name: "YEMEN"
    },
    {
        code: "ZM",
        name: "ZAMBIA"
    },
    {
        code: "ZW",
        name: "ZIMBABWE"
    }
]
