<table mat-table [dataSource]="messageErrors">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef style="width: 4%">ID</th>
        <td mat-cell *matCellDef="let message"> {{message.id}} </td>
    </ng-container>

    <ng-container matColumnDef="flowId">
        <th mat-header-cell *matHeaderCellDef style="width: 4%">Flow id</th>
        <td mat-cell *matCellDef="let message"> {{message.flowId}} </td>
    </ng-container>

    <ng-container matColumnDef="messageId">
        <th mat-header-cell *matHeaderCellDef  style="width: 4%">Message Id</th>
        <td mat-cell *matCellDef="let message"> {{message.messageId}} </td>
    </ng-container>

    <ng-container matColumnDef="receivedTime">
        <th mat-header-cell *matHeaderCellDef>Received Time</th>
        <td mat-cell *matCellDef="let message"> {{message.receivedTime | date:'dd/MM/YYYY HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="errorCode">
        <th mat-header-cell *matHeaderCellDef>Error Code</th>
        <td mat-cell *matCellDef="let message"> {{message.errorCode}} </td>
    </ng-container>

    <ng-container matColumnDef="errorMessage">
        <th mat-header-cell *matHeaderCellDef>Error message</th>
        <td mat-cell *matCellDef="let message"> {{message.errorMessage}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let message" style="width: 10%; text-align: right">
            <button [disabled]="!message.filePath" mat-icon-button matTooltip="Download XML"  (click)="downloadXml(message)"
                    matTooltipPosition="right">
                <mat-icon>download</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length"
            style="text-align: center">
            Nessun messaggio da SDI presente
        </td>
    </tr>
</table>
