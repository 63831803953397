<h1 mat-dialog-title i18n="@@payslip">Busta paga</h1>
<div mat-dialog-content>
    <form #form="ngForm" name="addForm" (ngSubmit)="form.valid && onSubmit()">

        <mat-form-field class="fixed-size">
            <mat-label>Anno</mat-label>
            <mat-select name="year" #year="ngModel" required [(ngModel)]="record.year">
                <mat-option [value]="2023">2023</mat-option>
                <mat-option [value]="2024">2024</mat-option>
                <mat-option [value]="2025">2025</mat-option>
                <mat-option [value]="2026">2026</mat-option>
            </mat-select>
            <mat-error *ngIf="year.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
        </mat-form-field>

        <mat-form-field class="fixed-size">
            <mat-label i18n="@@month">Mese</mat-label>
            <mat-select name="month" #month="ngModel" required [(ngModel)]="record.month">
                <mat-option *ngFor="let month of months" [value]="month.number">
                    {{ month.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="month.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
        </mat-form-field>


        <mat-form-field class="fixed-size">
            <mat-label i18n="@@number">Numero</mat-label>
            <input name="number" matInput #number="ngModel" required [(ngModel)]="record.number">
            <mat-error *ngIf="number.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
        </mat-form-field>


        <div class="flex-row flexible-size">
            <mat-form-field>
                <mat-label i18n="@@gross">Lordo</mat-label>
                <input type="number" name="amount" matInput #amount="ngModel" required [(ngModel)]="record.amount">
                <mat-error *ngIf="amount.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field class="fixed-size date">
                <mat-label i18n="@@paid_date">Data pagamento</mat-label>
                <input name="paidDate" matInput [matDatepicker]="pickerPaidDate"
                       #paidDate="ngModel" [(ngModel)]="record.paidDate">
                <mat-datepicker-toggle matSuffix [for]="pickerPaidDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerPaidDate></mat-datepicker>
            </mat-form-field>
        </div>
        <document-input [batch]="batch" name="payslip" type="payslip" label="Busta paga"
                        [(ngModel)]="record.payslipDocument"></document-input>

        <document-input [batch]="batch" name="confirmedPayslip" type="confirmed_payslip"
                        label="Conferma busta paga"
                        [(ngModel)]="record.confirmedPayslipDocument"></document-input>

        <document-input [batch]="batch" name="receipt" type="receipt" label="Prova di pagamento"
                        [(ngModel)]="record.receipt"></document-input>

        <mat-form-field>
            <mat-label i18n="@@notes">Note</mat-label>
            <textarea cdkTextareaAutosize name="notes" type="text"  matInput [(ngModel)]="record.notes"></textarea>
        </mat-form-field>

        <mat-dialog-actions align="end">
            <button (click)="cancel($event)" mat-button i18n="@@cancel">Annulla</button>
            <button *ngIf="!editMode" name="addButton" type="submit" mat-button color="primary" i18n="@@add">Aggiungi
            </button>

            <button *ngIf="editMode" type="submit" color="primary" mat-button i18n="@@edit">Modifica</button>
        </mat-dialog-actions>
    </form>
</div>
