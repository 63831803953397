import {Injectable} from '@angular/core';
import {collectionData, query, where} from "@angular/fire/firestore";
import {Repository} from "../../persistence/repository";
import {getCurrentBusiness} from "../../utils";
import {AddressConverter} from "../../authentication/addressConverter";
import {DocumentConverter} from "../../document.repository";
import {Quote} from "../../model/invoice";
import {FiscalDocumentConverter} from "../outbound/outbound-invoices.repository";
import {Observable} from "rxjs";
import {Project} from "../../model/project";
import {FirestoreWrapper} from "../../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class QuotesRepository extends Repository<Quote> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, new FiscalDocumentConverter(new AddressConverter(), new DocumentConverter()))
    }

    ngOnInit(): void {
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection( `businesses/${business.id}/quotes`).withConverter(this.converter);
    }

    findByInvoiceNumber(number: string): Observable<Quote[]> {
        return collectionData(
            query(this.getCollectionReference(),
                where('number', '==', number))
        )
    }

    findByProject(project: Project) {
        return collectionData(
            query(this.getCollectionReference().withConverter(this.converter),
                where('project.id', '==', project.id))
        )
    }
}


