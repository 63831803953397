import {
    DocumentData,
    FirestoreDataConverter,
    query,
    QueryDocumentSnapshot,
    SnapshotOptions,
    where
} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";
import * as moment from "moment";
import {Deliverable, Supply} from "../../model/supply";
import {Repository} from "../../persistence/repository";
import {getCurrentBusiness, makeSupply} from "../../utils";
import {MedicalRecord} from "../../model/medicalRecord";
import {Education} from "../../model/education";
import {Project} from "../../model/project";
import {collectionData} from "rxfire/firestore";
import {Observable} from "rxjs";
import {DocumentConverter} from "../../document.repository";
import {FirestoreWrapper} from "../../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class SupplyRepository extends Repository<Supply> {

    constructor(firestore: FirestoreWrapper) {
        let documentConverter = new DocumentConverter();
        let supplyConverter = new SupplyConverter(documentConverter);
        super(firestore, supplyConverter)
    }

    ngOnInit(): void {
    }


    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection( 'businesses', business.id, 'supplies').withConverter(this.converter);
    }

    findByProject(project: Project): Observable<Deliverable[]> {
        // @ts-ignore Project supplies are always deliverables
        return collectionData(query(
            this.getCollectionReference(),
            where('projectId', '==', project.id)
        ).withConverter(this.converter))
    }

    findEducationByEmployeeId(id: string): Observable<Education[]> {
        // @ts-ignore
        return collectionData(query(
            this.getCollectionReference(),
            where('_type', '==', 'education'),
            where('employeeId', '==', id)
        ).withConverter(this.converter))
    }

    findMedicalRecordsByEmployeeId(id: string): Observable<MedicalRecord[]> {
        // @ts-ignore
        return collectionData(query(
            this.getCollectionReference(),
            where('_type', '==', 'medical_record'),
            where('employeeId', '==', id)
        ).withConverter(this.converter))
    }

    findSuppliesBySupplier(supplierId: string): Observable<Supply[] | undefined> {
        return collectionData(query(
            this.getCollectionReference(),
            where('supplier.id', '==', supplierId)
        ).withConverter(this.converter))
    }

    findSuppliesByProject(projectId: string): Observable<Supply[] | undefined> {
        return collectionData(query(
            this.getCollectionReference(),
            where('projectId', '==', projectId)
        ).withConverter(this.converter))
    }

    findDeliverables(): Observable<Deliverable[] | undefined> {
        // @ts-ignore
        return collectionData(query(
            this.getCollectionReference(),
            where('_type', '==', 'deliverable')
        ).withConverter(this.converter))
    }
}

export class SupplyConverter implements FirestoreDataConverter<Deliverable | MedicalRecord | Education> {

    constructor(private documentConverter: DocumentConverter) {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Deliverable {
        const data = snapshot.data(options)!
        let supply: any = makeSupply(data._type)
        supply._type = data._type

        if (data._type == 'deliverable') {
            supply.estimatedDeliveryDate = data.estimatedDeliveryDate ? moment(data.estimatedDeliveryDate, 'DD/MM/YYYY') : null
            supply.deliveryDate = data.deliveryDate ? moment(data.deliveryDate, 'DD/MM/YYYY') : null
        } else if (data._type == 'medical_record' || data._type == 'education') {
            supply.certificate = this.documentConverter.fromData(data.certificate)!
            supply.date = moment(data.date, 'DD/MM/YYYY')
            supply.employeeId = data.employeeId
        } else {
            throw Error("cannot happen: invalid supply type")
        }

        supply.id = data.id
        supply.description = data.description
        supply.order = this.documentConverter.fromData(data.order)
        supply.quote = this.documentConverter.fromData(data.quote)
        supply.supplier = data['supplier']? {id: data.supplier.id, name: data.supplier.name, taxNumber: data.supplier.taxNumber} : null
        supply.projectId = data.projectId
        supply.clientId = data.clientId
        supply.quotedAmount = data['quotedAmount'] || supply.balance.quotedAmount
        supply.version = data['version'] || 0

        return supply
    }

    toFirestore(supply: Deliverable): DocumentData {
        let data: any = {
            id: supply.id,
            description: supply.description,
            order: this.documentConverter.toData(supply.order),
            quote: this.documentConverter.toData(supply.quote),
            supplier: supply.supplier? {id: supply.supplier.id, name: supply.supplier.name} : null,
            projectId: supply.projectId,
            clientId: supply.clientId,
            quotedAmount: supply.quotedAmount,
            version: supply.version
        };

        if (supply._type == 'deliverable') {
            supply = supply as Deliverable
            data._type = 'deliverable'
            data.estimatedDeliveryDate = supply.estimatedDeliveryDate ? supply.estimatedDeliveryDate.format('DD/MM/YYYY') : null
            data.deliveryDate = supply.deliveryDate ? supply.deliveryDate.format('DD/MM/YYYY') : null
        }

        console.log("save supply", data)

        return data
    }

}
