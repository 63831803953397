<form #form="ngForm" (ngSubmit)="supplierAutocompleteControl.valid && save(supply)">
    <article class="page">
        <header>
            <h1 i18n="@@supply_or_service">Fornitura / Servizio</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above" [disabled]="loading"
                        [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above"
                        (click)="discardChanges(supply)">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>


        <div class="content">

            <div class="flex-column left">
                <mat-card class="infoCard supply">
                    <mat-card-header>
                        <mat-card-title i18n="@@information">Informazioni</mat-card-title>

                        <mat-icon mat-card-avatar>source</mat-icon>
                    </mat-card-header>

                    <mat-card-content>
                        <mat-form-field>
                            <mat-label i18n="@@description">Descrizione</mat-label>
                            <textarea name="supplyDescription" matInput
                                      #description="ngModel" required
                                      [(ngModel)]="supply.description"></textarea>
                            <mat-error *ngIf="description.hasError('required')">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label i18n="@@supplier">Fornitore</mat-label>
                            <input name="supplier" type="text" matInput [formControl]="supplierAutocompleteControl" required [matAutocomplete]="supplierAutocomplete">
                            <mat-autocomplete #supplierAutocomplete="matAutocomplete" [displayWith]="getSupplierName">
                                <mat-option *ngFor="let supplier of filteredSupplierOptions | async" [value]="supplier">
                                    {{ supplier.name }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="supplierAutocompleteControl.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
                            <mat-error *ngIf="supplierAutocompleteControl.hasError('nonExistent')" i18n="@@supplier_not_found">Il fornitore non esiste. Seleziona un fornitore esistente</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label i18n="@@project">Progetto</mat-label>
                            <input name="project" type="text" matInput [formControl]="projectAutocompleteControl"
                                   [matAutocomplete]="projectAutocomplete">
                            <mat-autocomplete #projectAutocomplete="matAutocomplete" [displayWith]="getProjectName">
                                <mat-option *ngFor="let project of filteredProjectOptions | async" [value]="project">
                                    {{ project.name }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="projectAutocompleteControl.hasError('nonExistent')" i18n="@@project_not_found">Il progetto non esiste. Seleziona un progetto esistente
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="date fixed-size">
                            <mat-label i18n="@@estimated_delivery_date_or_service">Data prevista consegna/servizio</mat-label>
                            <input name="estimatedDeliveryDate" matInput [matDatepicker]="pickerEstimated"
                                   [(ngModel)]="supply['estimatedDeliveryDate']">
                            <mat-datepicker-toggle matSuffix [for]="pickerEstimated"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEstimated></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="date fixed-size">
                            <mat-label i18n="@@delivery_date_or_service">Data consegna/servizio</mat-label>
                            <input name="deliveryDate" matInput [matDatepicker]="pickerDelivery"
                                   [(ngModel)]="supply['deliveryDate']">
                            <mat-datepicker-toggle matSuffix [for]="pickerDelivery"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDelivery></mat-datepicker>
                        </mat-form-field>

                        <document-input class="fixed-size" name="supply_quote" type="supply quote" label="Preventivo" i18n-label="@@quote"
                                        [batch]="documentStorageBatch"
                                        [(ngModel)]="supply.quote"></document-input>

                        <document-input class="fixed-size" name="supply_order" type="supply order" [batch]="documentStorageBatch" i18n-label="@@order"
                                        label="Ordine" [(ngModel)]="supply.order"></document-input>

                        <mat-form-field>
                            <mat-label i18n="@@quoted_price">Prezzo preventivato</mat-label>
                            <input type="number" name="quotedAmount" matInput [(ngModel)]="supply.quotedAmount">
                        </mat-form-field>

                    </mat-card-content>
                </mat-card>
            </div>

            <div class="flex-column right">
                <mat-card class="infoCard summary">
                    <mat-card-header>
                        <mat-icon mat-card-avatar>summarize</mat-icon>
                        <mat-card-title i18n="@@summary">Bilancio</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>

                        <table>
                            <tr>
                                <td>
                                    <mat-icon>request_quote</mat-icon>
                                </td>
                                <td><span i18n="@@quoted_amount">Preventivato</span></td>
                                <td class="currencyColumn">{{ supply.balance.quotedAmount | formatCurrency }}</td>
                            </tr>

                            <tr>
                                <td>
                                    <mat-icon>arrow_backward</mat-icon>
                                </td>
                                <td><span i18n="@@outbound_amount">Fatture ricevute</span></td>
                                <td class="currencyColumn">{{ supply.balance.getTotal() | formatCurrency }}</td>
                            </tr>

                        </table>


                    </mat-card-content>

                </mat-card>
                <payments [balance]="supply.balance" [owner]="supply"></payments>
            </div>
        </div>

    </article>
</form>
