<article class="page">

    <div class="content">
        <mat-card class="infoCard verification">
            <mat-card-header>
                <mat-icon mat-card-avatar>login</mat-icon>
                <mat-card-title i18n="@@complete_registration">Completa la registrazione</mat-card-title>
            </mat-card-header>

            <mat-card-content i18n="@@verify_email">
                Verifica la tua email per accedere ai servizi
            </mat-card-content>

            <mat-card-actions>
                <button mat-button type="button" i18n="@@send_again">INVIA DI NUOVO</button>
            </mat-card-actions>
        </mat-card>
    </div>
</article>
