import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {Payslip} from "../../model/payroll";
import * as moment from "moment";
import {MatTable} from "@angular/material/table";
import {CrudCardComponent} from "../../components/crud-card/crud-card.component";
import {DocumentStorageBatch} from "../../document-storage.service";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {clone} from "../../utils";
import {Leave} from "../../model/leave";
import {MatDialog} from "@angular/material/dialog";
import {PayslipRecordDialog} from "./payslip-record-dialog/payslip-record-dialog.component";

@Component({
    selector: 'payroll',
    templateUrl: './payroll.component.html',
    styleUrls: ['./payroll.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PayrollComponent
        }
    ]
})
export class PayrollComponent extends CrudCardComponent<Payslip> implements AfterViewInit {

    @ViewChild(MatTable) payrollTable: MatTable<Payslip>;
    @Input() batch: DocumentStorageBatch;

    displayedColumns: string[] = ['year', 'month', 'amount', 'actions'];
    // TODO use variables
    months: any[] = [
        {name: "Gennaio", number: 1},
        {name: "Febbraio", number: 2},
        {name: "Marzo", number: 3},
        {name: "Aprile", number: 4},
        {name: "Maggio", number: 5},
        {name: "Giugno", number: 6},
        {name: "Luglio", number: 7},
        {name: "Agosto", number: 8},
        {name: "Settembre", number: 9},
        {name: "Ottobre", number: 10},
        {name: "Novembre", number: 11},
        {name: "Dicembre", number: 12},
    ]

    selectedYear: BehaviorSubject<number> = new BehaviorSubject(moment().year());
    filteredList: BehaviorSubject<Payslip[]> = new BehaviorSubject<Payslip[]>([]);

    constructor(dialog: MatDialog) {
        super(dialog)

        this.setDialogComponent(PayslipRecordDialog)
    }

    ngAfterViewInit() {
        super.table = this.payrollTable
    }

    public newEntity() {
        let payslip = new Payslip();
        return payslip
    }

    numberToMonth(month: number) {
        return this.months.find(m => m.number === month).name;
    }

    override remove(event: Event, entity: Payslip) {
        super.remove(event, entity);
        entity.getDocuments().forEach(value =>  this.batch.addToDeleted(value) )
    }

    override onListWritten() {
        this.selectedYear.subscribe(year => this.filteredList.next( this.list?.filter(payslip => payslip.year == year) || [] ))
    }

    moveTimeLeft() {
        this.selectedYear.next(this.selectedYear.value - 1)
    }

    moveTimeRight() {
        this.selectedYear.next(this.selectedYear.value + 1)
    }

    override hideTable(): boolean {
        return this.filteredList.value.length == 0
    }

    override refreshTable() {
        this.filteredList.next( this.list?.filter(playslip => playslip.year == this.selectedYear.value) || [] )
    }

    openNewDialog() {
        super.openNewRecordDialog({
            record: this.newEntity(),
            batch: this.batch
        })
    }

    openEditDialog(event: MouseEvent, record: Leave) {
        super.openEditRecordDialog(event, {
            record: clone(record),
            batch: this.batch
        })

    }

}

