import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Client} from "../../model/client";
import {ClientService} from "../client.service";

@Component({
    selector: 'client-card',
    templateUrl: './client-card.component.html',
    styleUrls: ['./client-card.component.scss']
})
export class ClientCardComponent implements OnInit {

    @Input() client: Client;
    numberOfIssuedInvoices: number;
    numberOfPaidInvoices: number;
    numberOfOverdueInvoices: number;

    constructor(private clientService: ClientService, private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.clientService.getProjects(this.client).subscribe(projects => {
            this.client.projects = projects
            this.changeDetectorRef.detectChanges()
        })

        this.clientService.findInboundInvoicesByClient(this.client).subscribe(value => {
            this.numberOfIssuedInvoices = value.length;
            this.numberOfPaidInvoices = value.filter(invoice => invoice.isPaid()).length;
            this.numberOfOverdueInvoices = value.filter(invoice => invoice.isOverdue()).length;
        })

    }

}
