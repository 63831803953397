import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import {Project} from "../model/project";
import {Injectable} from "@angular/core";
import * as moment from "moment";
import {Activity} from "../model/activity";
import {getCurrentBusiness} from "../utils";
import {Employee} from "../model/employee";
import {DocumentConverter} from "../document.repository";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";


export class ProjectRepositoryConfiguration {
    clientId: string
    private businessId: string;


    constructor(clientId: string) {
        this.clientId = clientId
        this.businessId = getCurrentBusiness().id
    }
}

@Injectable()
export class ProjectRepository extends Repository<Project> {

    constructor(firestore: FirestoreWrapper, configuration: ProjectRepositoryConfiguration) {
        let documentConverter = new DocumentConverter();
        let projectConverter = new ProjectConverter(documentConverter);
        super(firestore, projectConverter, configuration)
    }

    ngOnInit(): void {
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection( 'businesses', business.id, 'clients', this.configuration.clientId, 'projects').withConverter(this.converter);
    }

    override save(project: Project) {
        if (!project.clientId) project.clientId = this.configuration.clientId
        if (!project.businessId) project.businessId = this.configuration.businessId

        return super.save(project);
    }

}

@Injectable({
    providedIn: "root"
})
export class ProjectConverter implements FirestoreDataConverter<Project> {

    constructor(private documentConverter: DocumentConverter) {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Project {
        const data = snapshot.data(options)!
        let project = new Project();
        project.id = data['id']
        project.name = data['name']
        project.address = data['address']
        project.clientId = data['clientId']
        project.businessId = data['businessId']
        project.paid = data['paid']
        project.completed = data['completed']
        project.registryDocuments = data['registryDocuments'].map(value => this.documentConverter.fromData(value)) || []
        project.administrativeDocuments = data['administrativeDocuments'].map(value => this.documentConverter.fromData(value)) || []
        project.activities = this.convertToActivities(data['activities'] || [])
        project.order = this.documentConverter.fromData(data['order'])
        project.quote = this.documentConverter.fromData(data['quote'])
        project.version = data['version'] || 0

        return project
    }

    private convertToActivities(data: any) {
        return data.map(value => {
            let activity = new Activity();
            activity.id = value.id
            activity.name = value.name
            activity.from = value.from ? moment(value.from, 'DD/MM/YYYY') : null
            activity.to = value.to ? moment(value.to, 'DD/MM/YYYY') : null
            activity.description = value.description
            activity.progress = value.progress
            activity.notes = value.notes
            activity.assignees = value.assignees?.map(value => {
                let employee = new Employee();
                employee.id = value.id
                employee.fullName = value.fullName
                employee.function = value.function
                return employee
            }) || []

            return activity
        })
    }


    toFirestore(project: Project): DocumentData {
        return {
            id: project.id,
            name: project.name,
            address: project.address,
            clientId: project.clientId,
            businessId: project.businessId,
            paid: !!project.paid,
            completed: !!project.completed,
            registryDocuments: project.registryDocuments.map(value => this.documentConverter.toData(value)),
            administrativeDocuments: project.administrativeDocuments.map(value => this.documentConverter.toData(value)),
            activities: project.activities.map(value => {
                return {
                    id: value.id,
                    name: value.name,
                    from: value.from?.format('DD/MM/YYYY') || null,
                    to: value.to?.format('DD/MM/YYYY') || null,
                    description: value.description,
                    progress: value.progress || null,
                    notes: value.notes || null,
                    assignees: this.assigneesToData(value.assignees)
                }
            }),
            order: this.documentConverter.toData(project.order),
            quote: this.documentConverter.toData(project.quote),
            version: project.version
        }
    }

    private assigneesToData(assignees: Employee[]) {
        return assignees.map(value => {
            return {
                id: value.id,
                fullName: value.fullName,
                function: value.function
            }
        })
    }
}

