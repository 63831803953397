import {DocumentData, FirestoreDataConverter} from "@angular/fire/firestore";
import {Address} from "../model/address";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class AddressConverter implements FirestoreDataConverter<Address> {

    constructor() {
    }

    fromFirestore(data): Address {
        let address = new Address();
        address.street = data['street']
        address.city = data['city']
        address.district = data['district']
        address.postcode = data['postcode']
        address.nation = data['nation']

        return address
    }

    toFirestore(address: Address): DocumentData {
        return {
            street: address.street || null,
            city: address.city || null,
            district: address.district || null,
            postcode: address.postcode || null,
            nation: address.nation || 'IT'
        }
    }
}
