import {Injectable} from '@angular/core';
import {Client} from "../../model/client";
import {
    collectionData,
    DocumentData,
    FirestoreDataConverter,
    query,
    QueryDocumentSnapshot,
    SnapshotOptions,
    updateDoc,
    where
} from "@angular/fire/firestore";
import {Repository} from "../../persistence/repository";
import {getCurrentBusiness} from "../../utils";
import {
    Attachment, FiscalDocumentType,
    Invoice,
    InvoiceStatus,
    Line,
    PriceModifier,
    Quote,
    QuoteStatus,
    Reference,
    SdiStatus,
    SenderType
} from "../../model/invoice";
import {Business, OrganizationType} from "../../model/business";
import * as moment from "moment";
import {AddressConverter} from "../../authentication/addressConverter";
import {Deliverable, Supplier} from "../../model/supply";
import {Project} from "../../model/project";
import {Accountant} from "../../model/accountant";
import {GenericSender, InvoiceSender} from "../../model/invoiceSender";
import {Employee} from "../../model/employee";
import {MedicalRecord} from "../../model/medicalRecord";
import {Education} from "../../model/education";
import {Payment} from "../../model/payment";
import {Observable} from "rxjs";
import {DocumentConverter} from "../../document.repository";
import {FiscalDocument} from "../../model/fiscalDocument";
import {FirestoreWrapper} from "../../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class OutboundInvoicesRepository extends Repository<Invoice> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, new FiscalDocumentConverter<Invoice>(new AddressConverter(), new DocumentConverter()))
    }

    ngOnInit(): void {
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection( `businesses/${business.id}/outboundInvoices`).withConverter(this.converter);
    }

    findByInvoiceNumber(invoiceNumber: string): Observable<Invoice[]> {
        return collectionData(
            query(this.getCollectionReference(), where('number', '==', invoiceNumber))
        )
    }

    findByProject(project: Project) {
        return collectionData(
            query(this.getCollectionReference(),
                where('project.id', '==', project.id))
        )
    }

    updateToSent(invoice: Invoice) {
        let converter = this.converter as FiscalDocumentConverter<Invoice>;
        updateDoc(this.getReference(invoice), {
            sdiStatus: converter.convertSdiStatus(invoice),
            _sentDate: converter.convertSentDate(invoice),
            version: invoice.version+1
        })
    }

    findByClient(client: Client) {
        return collectionData(
            query(this.getCollectionReference(), where('project.clientId', '==', client.id))
        )
    }

    findAllByYear(year: number) {
        return collectionData(
//            query(this.getCollectionReference(), where('date', '>=', moment().startOf("year").toDate()))
            query(this.getCollectionReference())
        )
    }
}

export class FiscalDocumentConverter<T extends FiscalDocument> implements FirestoreDataConverter<T> {

    constructor(private addressConverter: AddressConverter, private documentConverter: DocumentConverter) {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): T {
        const data = snapshot.data(options)!
        return this.makeFiscalDocument(data);
    }

    createInstance<T>(c: new () => T): T {
        return new c();
    }

    makeFiscalDocument(data: DocumentData): T {

        let fiscalDocument: Invoice | Quote

        if (data['type'] == FiscalDocumentType.Invoice || data['type'] == FiscalDocumentType.CreditNote ) {
            fiscalDocument = this.createInstance(Invoice)
            FiscalDocumentConverter.makeInvoice(fiscalDocument as Invoice, data);
        } else {
            fiscalDocument = this.createInstance(Quote)
        }

        fiscalDocument.filePath = data['filePath'] || null
        fiscalDocument.version = data['version'] || 0
        fiscalDocument.id = data['id']
        fiscalDocument.type = data['type']
        fiscalDocument.number = data['number']
        fiscalDocument.date = moment(data['_issueDate'].toDate())
        fiscalDocument.sentDate = data['_sentDate'] ? moment(data['_sentDate'].toDate(), 'DD/MM/YYYY') : null
        fiscalDocument._total = data['total'] != undefined ? data['total'] : null
        fiscalDocument.currency = data['currency']
        fiscalDocument.description = data['description']
        fiscalDocument.receipt = data['receipt']

        let sender = this.makeSender(fiscalDocument);
        let senderData = data['sender']
        sender.id = senderData['id']
        sender.name = senderData['name']
        sender.taxNumber = senderData['taxNumber']
        sender['taxRegime'] = senderData['taxRegime'] ? senderData['taxRegime'] : null
        sender.address = this.addressConverter.fromFirestore(senderData['address'])

        fiscalDocument.sender = sender

        let clientData = data['receiver']

        let client = new Client();
        client.id = clientData['id']
        client.sdi = clientData['sdi']
        client.name = clientData['name']
        client.taxNumber = clientData['taxNumber']
        client.organizationType = OrganizationType[clientData['organizationType'] as keyof typeof OrganizationType]
        client.address = this.addressConverter.fromFirestore(clientData['address'])

        fiscalDocument.senderType = SenderType[clientData['senderType'] as keyof typeof SenderType]

        fiscalDocument.receiver = client

        let project = new Project()
        let projectData = data['project'];
        if (projectData) {
            project.id = projectData['id']
            project.name = projectData['name']
            project.clientId = projectData['clientId']
            fiscalDocument.project = project
        }


        fiscalDocument.lines = data['lines'].map(value => {
            let line = new Line();
            line.position = value['position']
            line.description = value['description']
            line.quantity = value['quantity'] || null
            line.unitOfMeasure = value['unitOfMeasure'] ? value['unitOfMeasure'] : null
            line.cost = value['cost']
            line.vat = value['vat']
            line._total = value['total'] != undefined ? value['total'] : null

            line.references = value['references']?.map(refData => {
                let reference = new Reference();
                reference.type = refData['type']
                reference.textReference = refData['textReference'] || null
                reference.numberReference = refData['numberReference'] || null
                reference.dateReference = refData['dateReference'] ? moment(data['dateReference'], 'DD/MM/YYYY') : null

                return reference
            }) || []
            return line
        })

        fiscalDocument.payments = data['payments']?.map(value => {
            let payment = new Payment()
            payment.type = value['type']
            payment.amount = value['amount']
            payment.bank = value['bank']
            payment.iban = value['iban']
            payment.dueDate = value['_dueDate'] ? moment(value['_dueDate'].toDate()) : null
            return payment
        }) || []

        fiscalDocument.priceModifiers = data['priceModifiers']?.map(value => {
            let modifier = new PriceModifier()
            modifier.type = value.type
            modifier.percentage = value.percentage
            modifier.amount = value.amount
            modifier.governmentContribution = value.governmentContribution || false
            modifier.description = value.description || null
            return modifier
        }) || []

        fiscalDocument.attachments = data['attachments']?.map(value => {
            let attachment = new Attachment()
            attachment.name = value['name']
            attachment.type = value['type'] || null
            attachment.description = value['description'] || null
            attachment.compressionAlgorithm = value['compressionAlgorithm'] || null
            attachment.base64Data = value['base64Data'] || null
            return attachment
        }) || []

        return fiscalDocument as T
    }

    private static makeInvoice(invoice: Invoice, data: DocumentData) {
        invoice.flowId = data['flowId'] || null
        invoice.imported = data['imported'] || false

        invoice.dueDate = data['_dueDate'] ? moment(data['_dueDate'].toDate()) : null
        invoice.paidDate = data['_paidDate'] ? moment(data['_paidDate'].toDate()) : null

        invoice.status = InvoiceStatus[data['status'] as keyof typeof InvoiceStatus]
        invoice.sdiStatus = data['sdiStatus'] && SdiStatus[data['sdiStatus'] as keyof typeof SdiStatus] || SdiStatus.NotSent
        invoice.purchaseOrder = data['purchaseOrder']

        let accountant = new Accountant()
        let accountantData = data['accountant'];
        if (accountantData) {
            accountant.id = accountantData['id']
            accountant.name = accountantData['name']
            invoice.accountant = accountant
        }

        let employee = new Employee()
        let employeeData = data['employee'];
        if (employeeData) {
            employee.id = employeeData['id']
            employee.fullName = employeeData['fullName']
            invoice.employee = employee
        }

        let supplyData = data['supply'];
        if (supplyData) {
            let supply = new Deliverable();
            supply.id = supplyData['id']
            supply.description = supplyData['description']
            invoice.supply = supply
        }

        let supplierData = data['supplier'];
        if (supplierData) {
            let supplier = new Supplier();
            supplier.id = supplierData['id']
            supplier.name = supplierData['name']
            invoice.supplier = supplier
        }

        let medicalRecordData = data['medicalRecord'];
        if (medicalRecordData) {
            let medicalRecord = new MedicalRecord();
            medicalRecord.id = medicalRecordData['id']
            medicalRecord.description = medicalRecordData['description']
            invoice.medicalRecord = medicalRecord
        }

        let educationData = data['education'];
        if (educationData) {
            let education = new Education();
            education.id = educationData['id']
            education.description = educationData['description']
            invoice.education = education
        }

    }

    private makeSender(fiscalDocument: FiscalDocument) {
        if (fiscalDocument.isSenderASupplier()) {
            return new Supplier()
        }
        if (fiscalDocument.isSenderABusiness()) {
            return new Business()
        }
        if (fiscalDocument.isSenderAnAccountant()) {
            return new Accountant()
        }

        return new GenericSender()
    }

    toFirestore(fiscalDocument: T): DocumentData {
        let receiver: Business | Client = fiscalDocument.receiver
        let sender: InvoiceSender = fiscalDocument.sender

        let project: Project | null = null
        if (fiscalDocument.project) {
            project = {
                id: fiscalDocument.project.id,
                name: fiscalDocument.project.name,
                clientId: fiscalDocument.project.clientId
            } as Project
        }

        let data: any = {
            filePath: fiscalDocument.filePath,
            id: fiscalDocument.id,
            number: fiscalDocument.number,
            tax: fiscalDocument.tax,
            taxable: fiscalDocument.taxable,
            total: fiscalDocument.total,
            _issueDate: fiscalDocument.date.toDate(),
            _sentDate: fiscalDocument.sentDate?.toDate() || null,
            currency: fiscalDocument.currency,
            description: fiscalDocument.description || null,
            receipt: this.documentConverter.toData(fiscalDocument.receipt),
            version: fiscalDocument.version,
            'project': project,

            receiver: {
                id: receiver.id || null,
                sdi: receiver.sdi || null,
                name: receiver.name,
                taxNumber: receiver.taxNumber,
                organizationType: OrganizationType[receiver.organizationType] || null,
                address: this.addressConverter.toFirestore(receiver.address)
            },

            senderType: SenderType[fiscalDocument.senderType] || null,
            sender: {
                id: sender.id || null,
                name: sender.name,
                taxNumber: sender.taxNumber,
                taxRegime: sender['taxRegime'] ? sender['taxRegime'] : null,
                address: this.addressConverter.toFirestore(sender.address)
            },

            lines: fiscalDocument.lines.map(line => ({
                position: line.position,
                description: line.description,
                quantity: line.quantity || null,
                unitOfMeasure: line.unitOfMeasure ? line.unitOfMeasure : null,
                cost: line.cost,
                vat: line.vat,
                total: line.total,
                taxable: line.taxable,

                references: line.references?.map(reference => ({
                    type: reference.type,
                    textReference: reference.textReference || null,
                    numberReference: reference.numberReference || null,
                    dateReference: reference.dateReference?.format('DD/MM/YYYY') || null,
                })) || []
            })),
            payments: fiscalDocument.payments.map(payment => {
                return {
                    type: payment.type,
                    amount: payment.amount,
                    bank: payment.bank,
                    iban: payment.iban,
                    _dueDate: payment.dueDate?.toDate() || null,
                }
            }),
            priceModifiers: fiscalDocument.priceModifiers.map(modifier => {
                return {
                    type: modifier.type,
                    amount: modifier.amount,
                    percentage: modifier.percentage,
                    governmentContribution: modifier.governmentContribution,
                    description: modifier.description,
                }
            }),
            attachments: fiscalDocument.attachments.map(attachment => {
                return {
                    type: attachment.type,
                    description: attachment.description,
                    name: attachment.name,
                    compressionAlgorithm: attachment.compressionAlgorithm,
                    base64Data: attachment.base64Data
                }
            }),
        };

        if (fiscalDocument instanceof Invoice) {
            this.invoiceToFirestore(fiscalDocument, data);
        } else {
            this.quoteToFirestore(fiscalDocument, data)
        }

        return data
    }

    private quoteToFirestore(quote: Quote, data: any) {
        data.status = QuoteStatus[quote.status] || null
    }

    private invoiceToFirestore(invoice: Invoice, data: any) {
        let accountant: Accountant | null = null
        if (invoice.accountant) {
            accountant = {
                id: invoice.accountant.id,
                name: invoice.accountant.name,
            } as Accountant
        }

        let employee: Employee | null = null
        if (invoice.employee) {
            employee = {
                id: invoice.employee.id,
                fullName: invoice.employee.fullName,
            } as Employee
        }

        let supplier: Supplier | null = null
        if (invoice.supplier) {
            supplier = {
                id: invoice.supplier!.id,
                name: invoice.supplier!.name
            } as Supplier
        }

        let supply: Deliverable | null = null
        if (invoice.supply) {
            supply = {
                id: invoice.supply!.id,
                description: invoice.supply!.description
            } as Deliverable
        }

        let medicalRecord: MedicalRecord | null = null
        if (invoice.medicalRecord) {
            medicalRecord = {
                id: invoice.medicalRecord!.id,
                description: invoice.medicalRecord!.description
            } as MedicalRecord
        }

        let education: Education | null = null
        if (invoice.education) {
            education = {
                id: invoice.education!.id,
                description: invoice.education!.description
            } as Education
        }

        data.flowId = invoice.flowId
        data.imported = invoice.imported
        data.type = invoice.type
        data._dueDate = invoice.dueDate?.toDate() || null
        data.status = InvoiceStatus[invoice.status] || null
        data.sdiStatus = this.convertSdiStatus(invoice)
        data._paidDate = invoice.paidDate?.toDate() || null
        data.accountant = accountant
        data.employee = employee
        data.supply = supply
        data.supplier = supplier
        data.medicalRecord = medicalRecord
        data.education = education
        data.purchaseOrder = invoice.purchaseOrder || null



    }

    convertSdiStatus(invoice: Invoice) {
        return SdiStatus[invoice.sdiStatus] || null;
    }

    convertSentDate(fiscalDocument: T) {
        return fiscalDocument.sentDate?.toDate() || null;
    }
}






