import {Injectable} from '@angular/core';
import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import {User} from "../model/user";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class UserRepository extends Repository<User> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, new UserConverter())
    }

    ngOnInit(): void {
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection("users").withConverter(this.converter);
    }

}

class UserConverter implements FirestoreDataConverter<User> {
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): User {
        const data = snapshot.data(options)!
        let user = new User();
        user.id = data['id']
        user.name = data['name']
        user.businessId = data['businessId']
        return user
    }

    toFirestore(user: User): DocumentData {
        return {
            id: user.id,
            name: user.name,
            businessId: user.businessId
        }
    }
}
