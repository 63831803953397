<div class="flex-row">
    <button mat-fab color="primary" name="addEntityButton" matTooltip="Nuova listino prezzi" matTooltipPosition="above"
            [routerLink]="['new']">
        <mat-icon>add</mat-icon>
    </button>
</div>

<table mat-table [dataSource]="lists">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef style="width: 4%">id</th>
        <td mat-cell *matCellDef="let list"> {{list.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let list"> {{list.name}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let list"> {{list.date  | date:'dd/MM/YYYY HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let priceList" style="width: 10%; text-align: right">
            <button type="button" mat-icon-button (click)="delete($event, priceList)">
                <mat-icon class="mat-24">delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let priceList; columns: displayedColumns;" (click)="onSelected(priceList)"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length" style="text-align: center">
            Nessun listino prezzi
        </td>
    </tr>
</table>
