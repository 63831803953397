import {ConverterRegistry} from "./converter-registry.service";
import {Converter} from "./converter";

export class PersistenceConfig {
    name: string
    timestamped: boolean = false
    properties: string[] | any

    constructor(config: { name: string, properties: string[] | any, timestamped?: boolean }) {
        this.name = config.name
        this.properties = config.properties
        this.timestamped = config.timestamped ?? false
    }

    getPropertyNames() {
        if (this.properties instanceof Array) return this.properties
        else return Object.keys(this.properties)
    }

    getConverterKey(key: string) {
        if (this.properties instanceof Array) return null
        return this.properties[key].converter
    }

}

export function persist(config: PersistenceConfig) {
    return function (constructor: any) {
        ConverterRegistry.register(config.name, new Converter(constructor))
        constructor['_persistence'] = config
    }
}
