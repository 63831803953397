import {Injectable} from "@angular/core";
import {Repository} from "../../persistence/repository";
import {
    collectionData,
    doc,
    DocumentData,
    FirestoreDataConverter,
    orderBy,
    query,
    QueryDocumentSnapshot,
    SnapshotOptions,
    updateDoc,
    where
} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import * as moment from "moment";
import {Moment} from "moment";
import {Entity} from "../../model/entity";
import {FirestoreWrapper} from "../../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class OutboundSdiMessagesRepository extends Repository<OutboundSdiMessage> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, new OutboundSdiMessageConverter())
    }

    ngOnInit(): void {
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection( "outboundSdiMessages").withConverter(this.converter);
    }

    override getAll(): Observable<OutboundSdiMessage[]> {
        return collectionData(query(
            this.getCollectionReference(),
            where('archived', '==', false),
            orderBy("sentRequestTime", "desc"),
        ));
    }

    setArchived(message: OutboundSdiMessage) {
        let reference = doc(this.getCollectionReference(), message.id);
        updateDoc(reference, {archived: true})
    }
}

class OutboundSdiMessageConverter implements FirestoreDataConverter<OutboundSdiMessage> {

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): OutboundSdiMessage {
        const data = snapshot.data(options)!
        let sdiInbound = new OutboundSdiMessage();
        sdiInbound.id = snapshot.id
        sdiInbound.flowId = data['id']
        sdiInbound.businessId = data['businessId']
        sdiInbound.businessName = data['businessName']
        sdiInbound.invoiceId = data['invoiceId']
        sdiInbound.fileName = data['fileName']
        sdiInbound.receptionError = data['receptionError']
        sdiInbound.errors = data['errors']
        sdiInbound.response = data['response']
        sdiInbound.invoiceAvailabilityDate =  data['invoiceAvailabilityDate']? moment(data['invoiceAvailabilityDate'].toDate()) : null
        sdiInbound.sentXml = data['sentXml']
        sdiInbound.sentRequestTime = moment(data['sentRequestTime'].toDate())
        sdiInbound.archived = !!data['archived']

        return sdiInbound
    }

    toFirestore(business: OutboundSdiMessage): DocumentData {
        throw new Error("not possible")
    }
}


export class OutboundSdiMessage extends Entity {
    flowId: number
    businessId: string
    businessName: string
    invoiceId: string
    fileName: string
    receptionError: string
    errors: string[]
    response: string
    invoiceAvailabilityDate: Moment | null
    sentXml: string
    sentRequestTime: Moment
    archived: boolean = false

    hasError() {
       return this.receptionError || (this.errors && this.errors.length > 0)
    }
}
