<form #form="ngForm" (ngSubmit)="save(client)" (reset)="discardChanges(client)">

    <article class="page">
        <header>
            <h1 i18n="@@client">Cliente</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above" [disabled]="loading"
                        [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>

        <div class="content">

            <mat-card *ngIf="client" class="infoCard client">
                <mat-card-header>
                    <mat-icon mat-card-avatar>person</mat-icon>

                    <mat-card-title>{{ client.name }}</mat-card-title>
                    <mat-card-subtitle>{{ client.taxNumber }}</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <mat-radio-group color="primary" [(ngModel)]="client.organizationType" name="organizationType">
                        <mat-radio-button [value]="privatePersonType" i18n="@@private_entity">
                            Persona Privata
                        </mat-radio-button>
                        <mat-radio-button [value]="legalEntityType" i18n="@@legal_entity">
                            Entit&agrave; Legale
                        </mat-radio-button>
                        <!--    <mat-radio-button [value]="publicAdministrationType">
                                Ente Pubblico
                            </mat-radio-button>-->
                    </mat-radio-group>

                    <mat-form-field>
                        <mat-label i18n="@@name">Nome</mat-label>
                        <input name="name" #fullName="ngModel" matInput required [(ngModel)]="client.name">
                        <mat-error *ngIf="fullName.errors?.['required']" i18n="@@required_field">Campo obbligatorio</mat-error>
                    </mat-form-field>

                    <mat-form-field class="flexible-size">
                        <mat-label i18n="@@fiscal_code_or_vat">Codice Fiscale o Partita IVA</mat-label>
                        <input name="taxNumber" #taxNumber="ngModel" matInput required [(ngModel)]="client.taxNumber">
                        <mat-error *ngIf="taxNumber.errors?.['required']" i18n="@@required_field">Campo obbligatorio</mat-error>
                    </mat-form-field>

                    <document-input type="documento di riconoscimento" name="identificationDocument" i18n-label="@@id_document"
                                    label="Documento di riconoscimento" [batch]="documentStorageBatch"
                                    [(ngModel)]="client.identificationDocument">
                    </document-input>

                    <div class="addressFormPart">
                        <mat-form-field class="fixed-size nation">
                            <mat-label i18n="@@country">Nazione</mat-label>
                            <mat-select name="nation" #nation="ngModel" [required]="true" [(ngModel)]="client.address.nation">
                                <mat-option *ngFor="let nation of countries" [value]="nation.code">
                                    {{ nation.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="nation.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field class="fixed-size" *ngIf="client.address.nation == 'IT'">
                            <mat-label i18n="@@district">Provincia</mat-label>
                            <mat-select name="district" #district="ngModel" [required]="true" [(ngModel)]="client.address.district">
                                <mat-option *ngFor="let district of districts"
                                            [value]="district.abbr">
                                    {{ district.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="district.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field class="fixed-size" *ngIf="client.address.nation != 'IT'">
                            <mat-label i18n="@@district">Provincia</mat-label>
                            <input name="district" #district="ngModel" matInput [required]="true" [(ngModel)]="client.address.district"/>
                            <mat-error *ngIf="district.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>


                        <div class="flex-row" style="width: 100%">
                            <mat-form-field class="flexible-size">
                                <mat-label i18n="@@city">Citt&agrave;</mat-label>
                                <input name="city" #city="ngModel" matInput required
                                       [(ngModel)]="client.address.city">
                                <mat-error *ngIf="city.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                            <mat-form-field class="fixed-size postcode">
                                <mat-label i18n="@@postcode">CAP</mat-label>
                                <input name="postcode" #postcode="ngModel" matInput [required]="true" [(ngModel)]="client.address.postcode">
                                <mat-error *ngIf="postcode.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                        </div>

                        <mat-form-field class="flexible-size">
                            <mat-label i18n="@@address">Indirizzo</mat-label>
                            <input name="street" #addressLine="ngModel" matInput required [(ngModel)]="client.address.street">
                            <mat-error *ngIf="addressLine.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>
                    </div>


                    <mat-form-field class="flexible-size">
                        <mat-label i18n="@@phone_number">Telefono</mat-label>
                        <input name="phoneNumber" #phoneNumber="ngModel" matInput pattern="\+{0,1}[0-9]+" minlength="4"
                               [(ngModel)]="client.phoneNumber">
                        <mat-error *ngIf="phoneNumber.hasError('pattern')" i18n="@@invalid_phone_number">Numero telefono non valido</mat-error>
                        <mat-error *ngIf="phoneNumber.hasError('minlength')" i18n="@@phone_number_short">Deve essere almeno 4 cifre</mat-error>
                    </mat-form-field>

                    <mat-form-field class="flexible-size">
                        <mat-label>Codice SDI</mat-label>
                        <input name="sdi" [required]="client.isPublicAdministration()" #sdi="ngModel" matInput [(ngModel)]="client.sdi">
                        <mat-error *ngIf="sdi.errors?.['required']" i18n="@@required_field">Campo obbligatorio</mat-error>
                    </mat-form-field>


                    <mat-form-field class="flexible-size">
                        <mat-label i18n="@@email">Email</mat-label>
                        <input name="email" pattern="^[\w+\.\-]+@[\w\.\-]+$" #email="ngModel" matInput [(ngModel)]="client.email">
                        <mat-error *ngIf="email.hasError('pattern')" i18n="@@invalid_email">Email non valida</mat-error>
                    </mat-form-field>

                </mat-card-content>

            </mat-card>

            <mat-card class="infoCard projects" *ngIf="client.projects">
                <mat-card-header>
                    <mat-icon mat-card-avatar>location_city</mat-icon>
                    <mat-card-title i18n="@@projects">Progetti</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <table mat-table [dataSource]="client.projects" [hidden]="hideTable(client.projects)">

                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef i18n="@@name">Nome</th>
                            <td mat-cell *matCellDef="let project"> {{ project.name }}</td>
                        </ng-container>

                        <ng-container matColumnDef="address">
                            <th mat-header-cell *matHeaderCellDef i18n="@@address">Indirizzo</th>
                            <td mat-cell *matCellDef="let project"> {{ project.address }}</td>
                        </ng-container>

                        <ng-container matColumnDef="completed">
                            <th mat-header-cell *matHeaderCellDef i18n="@@status">Stato</th>
                            <td mat-cell *matCellDef="let project">
                                {{ getStatus(project) }}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row (click)="projectSelected(project)"
                            *matRowDef="let project; columns: displayedColumns;"></tr>
                    </table>

                    <div *ngIf="hideTable(client.projects)" i18n="@@no_projects">Nessun progetto presente</div>
                </mat-card-content>

                <mat-card-actions>
                    <a mat-button color="primary" [routerLink]="['projects/new']" i18n="@@add">Aggiungi</a>
                </mat-card-actions>
            </mat-card>
        </div>

    </article>
</form>
