import {Injectable} from '@angular/core';
import {TimesheetRepository} from "./timesheet.repository";
import {EmployeeRepository} from "../employees/employee.repository";
import {SupplierRepository} from "../suppliers/supplier.repository";
import {combineLatestWith, map, take} from "rxjs";
import {Employee} from "../model/employee";
import {Timesheet, TimesheetRow, WorkingDay} from "../model/timesheet";
import {momentDate} from "../utils";
import {Supplier} from "../model/supply";
import {Content, TDocumentDefinitions} from "pdfmake/interfaces";
import * as pdfMake from "pdfmake/build/pdfmake";
import {Project} from "../model/project";
import {MatDialog} from "@angular/material/dialog";
import {InformDialogComponent} from "../inform-dialog/inform-dialog.component";

@Injectable({
    providedIn: 'root'
})
export class TimesheetService {

    constructor(private timesheetRepository: TimesheetRepository,
                private employeeRepository: EmployeeRepository,
                private supplierRepository: SupplierRepository,
                private dialog: MatDialog) {
    }

    findTimesheet(year: number, month: number) {
        return this.timesheetRepository.find(year, month).pipe(
            combineLatestWith(this.employeeRepository.getAll(), this.supplierRepository.getAll()),
            map(args => {
                let timesheet: Timesheet = args[0][0]!
                let employees: Employee[] = args[1]
                let suppliers: Supplier[] = args[2]

                if (!timesheet) return

                return this.makeTimesheet(employees, suppliers, timesheet);
            }))
    }

    newTimesheet(year: number, month: number) {
        return this.employeeRepository.getAll().pipe(combineLatestWith(this.supplierRepository.getAll()), take(1), map(args => {
            let employees: Employee[] = args[0]
            let suppliers: Supplier[] = args[1]

            let timesheet: Timesheet = new Timesheet(year, month);
            return this.makeTimesheet(employees, suppliers, timesheet);
        }))
    }

    private makeTimesheet(employees: Employee[], suppliers: Supplier[], timesheet: Timesheet) {

        [...employees, ...suppliers].forEach(subject => {
            let isEmployee = subject.type() == 'employee';
            let row = timesheet.findRow(subject, subject.type());

            if (!row && !isEmployee) return

            if (!row) {
                row = new TimesheetRow()
                timesheet.rows.push(row)
            }

            row.subject = subject
            // @ts-ignore
            let employee: Employee | null = isEmployee ? subject : null;
            this.addMissingDaysToRow(timesheet.daysInMonth(), row, timesheet.year, timesheet.month, employee);
        })

        timesheet.sortByType()
        return timesheet
    }

    public makeNewTimesheetRowForTimesheet(timesheet: Timesheet): TimesheetRow {
        let row = new TimesheetRow()
        this.addMissingDaysToRow(timesheet.daysInMonth(), row, timesheet.year, timesheet.month)
        return row
    }


    private addMissingDaysToRow(daysInMonth: number, row: TimesheetRow, year: number, month: number, employee: Employee | null = null) {
        for (let dayNumber = 1; dayNumber <= daysInMonth; dayNumber++) {
            if (row.hasDay(dayNumber)) continue

            let workingDay = new WorkingDay();
            workingDay.day = dayNumber
            workingDay.date = momentDate(year, month, dayNumber)
            workingDay.sickLeave = !!employee?.isSickOnDay(workingDay.date)
            workingDay.leave = !!employee?.isOnLeaveOnDay(workingDay.date)

            row.days.push(workingDay)
        }

        this.sortRowData(row)
    }

    downloadAsPDF(timesheet: Timesheet, project: Project) {

        let rows = timesheet.findRowsByProject(project).filter(row => row.getTotalByProject(project) > 0);
        if(!rows.length) {
            this.dialog.open(InformDialogComponent, {
                data: {
                    title: 'Impossibile generare il report',
                    message: 'non ci sono ore rendicontate per questo progetto'
                }
            }).afterClosed()
            return
        }

        let monthName = timesheet.getMonthName().charAt(0).toUpperCase() +  timesheet.getMonthName().slice(1);
        let tableHeader = [
            {text: monthName, style: [ 'leftPadding', 'tableHeader',]},
            ...Array.from({length: timesheet.daysInMonth()}, (_, i) => ({
                text: (i + 1).toString(),
                style: 'tableHeader'
            })),
            {text: 'Totale', alignment: 'right', style: ['rightPadding', 'tableHeader' ]}
        ];

        let weatherRow = [
            {text: 'Meteo avverso', style: ['leftPadding', 'weatherRow']},
            ...timesheet.weather.days.map(day => ({ text: day.hoursOfRain, style: 'weatherRow'})),
            {text: timesheet.weather.getTotalHoursOfRain(), alignment: "right", style: ['rightPadding', 'weatherRow']}
        ]

        let tableRows: any[] = rows.map((row, index) => {
            let isHighlighted = index % 2 === 1;

            let dayCells = row.days.map(day => {
                let hours = day.getHoursByProject(project)
                let styles = ['tableRow']
                if (hours > 0) styles.push('bolder')
                if (isHighlighted) styles.push('highlight')

                return {
                    text: hours,
                    style: styles
                };
            });

            let leftMostColumnStyles = ['bolder', 'leftPadding'];
            let rightMostColumnStyles = ['bolder', 'rightPadding'];

            if (isHighlighted) {
                leftMostColumnStyles.push('highlight')
                rightMostColumnStyles.push('highlight')
            }

            return [
                {text: row.subject.name, style: leftMostColumnStyles},
                ...dayCells,
                {text: row.getTotalByProject(project), alignment: "right", style: rightMostColumnStyles}
            ]
        });

        let docContent: Content = [
            {text: `Progetto ${project.name}`, style: "header"},
            {text: `Registro ore di ${monthName} - ${timesheet.year}`, style: "subheader"},


            {
                layout: 'lightHorizontalLines', // optional
                margin: [0, 0, 0, 30],
                table: {
                    headerRows: 1,
                    widths: ['*', ...Array(timesheet.daysInMonth()).fill(13), 'auto'],

                    body: [
                        tableHeader,
                        weatherRow,
                        ...tableRows,
                    ]
                }
            },

            {text: `Attività`, style: 'sectionHeader'},
            {
                layout: 'lightHorizontalLines',
                table: {
                    headerRows: 1,
                    widths: ['auto', 60, 20, '*'],
                    body: [
                        ['Collaboratore', 'Data', 'Ore', 'Attività'],
                        ...timesheet.findAllWorkedDaysByProject(project).map( workedDay => [
                            workedDay.subject.name,
                            workedDay.day.date.format('DD/MM/YYYY'),
                            workedDay.times.hours,
                            workedDay.times.description || 'Nessuna attività registrata'
                        ])
                    ]
                }
            },

        ]

        let docDefinition: TDocumentDefinitions = {
            pageSize: 'A3',
            pageOrientation: 'landscape',
            pageMargins: [30, 50, 30, 80],
            content: docContent,
            footer: function(currentPage, pageCount) {
                return [
                    {
                        text: 'Report generato da Edilie.com.',
                        alignment: 'right',
                        margin: [0, 0, 30, 0],
                        bold: true,
                        link: 'http://www.edilie.com'
                    },
                    {
                        text: 'Il gestionale online per il settore edile con il miglior rapporto qualità-prezzo.',
                        alignment: 'right',
                        margin: [0, 0, 30, 0],
                        link: 'http://www.edilie.com'
                    }

                ];
            },
            styles: {
                header: {
                    fontSize: 20,
                    bold: true,
                    color: '#005cbf', // Change the color of the header text
                    margin: [0, 0, 0, 30],
                },
                subheader: {
                    fontSize: 12,
                    bold: true,
                    margin: [0, 10, 0, 20] // Adjust the margin as needed
                },
                sectionHeader: {
                    fontSize: 12,
                    bold: true,
                },
                fieldName: {
                    fontSize: 10,
                    lineHeight: 1.5,
                    bold: true,
                    color: '#008000', // Change the color of the field name text
                },
                tableHeader: {
                    bold: true,
                    fillColor: '#e1e1e1', // Change the background color of the header
                    color: '#232323', // Change the color of the header text
                    margin: [0, 10, 0, 10] // Adjust the margin as needed to increase row height
                },
                tableRow: {
                    margin: [0, 10, 0, 10] // Adjust the margin as needed to increase row height
                },
                weatherRow: {
                    margin: [0, 10, 0, 10],
                    fillColor: '#fff2f2', // Change the background color of the even rows
                },
                bolder: {
                    bold: true,
                },
                highlight: {
                    fillColor: '#f5f5f5', // Change the background color of the even rows
                },
                leftPadding: {
                    margin: [20, 10, 0, 10] // Add left padding
                },
                rightPadding: {
                    margin: [0, 10, 20, 10] // Add right padding
                },
            },
            defaultStyle: {
                fontSize: 10,
                lineHeight: 1.75,
                color: '#000000', // Change the color of the default text
            }

        };

        pdfMake.createPdf(docDefinition).open();
    }

    private sortRowData(row: TimesheetRow) {
        row.days.sort((a, b) => a.day - b.day)

        // TODO this sorting doesn't seem to work
        row.days.forEach(day => day.sortWorkedTimes())
    }
}
