import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Deliverable, Supplier} from "../../model/supply";
import {MatTable} from "@angular/material/table";
import {UntypedFormControl} from "@angular/forms";
import {map, Observable, startWith} from "rxjs";
import {SupplierRepository} from "../../suppliers/supplier.repository";
import {generateId, verifyAutocomplete} from "../../utils";
import {CrudCardComponent} from "../../components/crud-card/crud-card.component";
import {Project} from "../../model/project";
import {DocumentStorageBatch} from "../../document-storage.service";

@Component({
    selector: 'supplies',
    templateUrl: './supplies.component.html',
    styleUrls: ['./supplies.component.scss']
})
export class SuppliesComponent extends CrudCardComponent<Deliverable> implements OnInit, AfterViewInit {

    private _supplies: Deliverable[];
    @ViewChild(MatTable) suppliersTable: MatTable<Deliverable>;
    @Input() project: Project
    @Input() batch: DocumentStorageBatch

    displayedColumns: string[] = ['description', 'supplier', 'estimatedDate', 'deliveryDate', 'actions'];

    autocompleteControl: UntypedFormControl = new UntypedFormControl(null, [verifyAutocomplete()])
    private autocompleteSupplierOptions: Supplier[]
    filteredOptions: Observable<Supplier[]>

    constructor(private supplierRepository: SupplierRepository) {
        super()
    }

    ngOnInit(): void {
        this.supplierRepository.getAll().subscribe(value => {
            this.autocompleteSupplierOptions = value;

            this.filteredOptions = this.autocompleteControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value?.name),
                map(name => (name ? this._filter(name) : this.autocompleteSupplierOptions.slice())),
            );
        });
    }

    ngAfterViewInit() {
        super.table = this.suppliersTable
    }

    public newEntity() {
        return new Deliverable()
    }

    override beforeAdd() {
        if (!this.subject.id) this.subject.id = generateId()
        this.subject.supplier = this.autocompleteControl.value
        this.autocompleteControl.setValue(null)
    }

    override beforeEdit(event: Event, entity: Deliverable) {
        super.beforeEdit(event, entity)
        this.autocompleteControl.setValue(entity.supplier)
    }

    override beforeCloseEdit() {
        this.autocompleteControl.setValue(null)
    }

    getSupplierName(supplier: Supplier): string {
        return supplier && supplier.name ? supplier.name : '';
    }

    private _filter(name: string): Supplier[] {
        const filterValue = name.toLowerCase();

        return this.autocompleteSupplierOptions.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    get supplies(): Deliverable[] {
        return this._supplies
    }

    @Input()
    set supplies(value: Deliverable[]) {
        super.list = value
        this._supplies = value
    }

    override remove(event: Event, entity: Deliverable) {
        super.remove(event, entity);
        entity.getDocuments().forEach(value =>  this.batch.addToDeleted(value) )
    }
}
