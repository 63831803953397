import {Entity} from "../model/entity";
import {persist, PersistenceConfig} from "../persistence/decorators";
import {Business} from "../model/business";
import {generateId} from "../utils";


@persist(new PersistenceConfig({
    name: 'Chat',
    timestamped: true,
    properties: {
        messages: {converter: 'Message'},
        quoteId: {},
        context: {}
    }
}))
export class Chat extends Entity {
    messages: Message[] = []
    context: any = {};
    quoteId: string | null = null;

    getNumberOfNotReadByReceiverMessages(id: string) {
        return this.messages.filter(value => value.isNotRead(id))?.length || 0
    }

    sendMessageFromBusiness(business: Business, content: string) {
        let message = new Message()
        message.id = generateId()
        message.content = content
        message.senderId = business.id
        message.senderName = business.name

        this.messages.push(message)

        return message
    }
}

@persist(new PersistenceConfig({
    name: 'Message',
    timestamped: true,
    properties: ['senderName', 'senderId', 'content', 'readBySender', 'readByReceiver']
}))
export class Message extends Entity {

    senderName: string
    senderId: string
    content: string
    readBySender: boolean = false
    readByReceiver: boolean = false

    isOwn(id: string) {
        return this.senderId == id
    }

    isNotRead(id: string) {
        return !this.readByReceiver && !this.isOwn(id)
    }

}

