import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SupplierRepository} from "../supplier.repository";
import {ListLayoutComponent} from "../../components/list-layout/list-layout.component";

@Component({
    selector: 'app-suppliers',
    templateUrl: './suppliers.component.html',
    styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent extends ListLayoutComponent {
    label: { singular: string; plural: string };
    constructor(public supplierRepository: SupplierRepository, router: Router, snackBar: MatSnackBar) {
        // @ts-ignore
        super(supplierRepository, router, snackBar);
        let singular = $localize`:@@supplier:fornitore`
        let plural = $localize`:@@suppliers:fornitori`
        this.label = {singular, plural}
    }
}
