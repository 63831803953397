import {Component} from '@angular/core';
import {Client} from "../model/client";
import {Address} from "../model/address";
import {ClientRepository} from "../clients-management/client.repository";
import {InformDialogComponent} from "../inform-dialog/inform-dialog.component";
import {ClientsImportDialog} from "./clients-import-dialog/clients-import-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Supplier} from "../model/supply";
import {Entity} from "../model/entity";
import {SupplierRepository} from "../suppliers/supplier.repository";
import {SuppliersImportDialog} from "./suppliers-import-dialog/suppliers-import-dialog.component";
import {districts} from "../utils";
import {Employee} from "../model/employee";
import {EmployeeRepository} from "../employees/employee.repository";
import {EmployeesImportDialog} from "./employees-import-dialog/employees-import-dialog.component";

@Component({
    selector: 'app-data-import',
    templateUrl: './data-import.component.html',
    styleUrls: ['./data-import.component.scss']
})
export class DataImportComponent {

    constructor(private clientRepository: ClientRepository, private supplierRepository: SupplierRepository,
                private employeeRepository: EmployeeRepository, private dialog: MatDialog) {
    }

    async validateClientsImport(event) {
        let files = event.target.files;
        if (!files) {
            this.informError("Nessun file selezionato")
            return
        }
        const file: File = files[0];

        let csv = await file.text()

        console.log("csv", csv)
        let parsed: any[] = []

        csv.split('\n').forEach((line, index, array) => {
            if (index == 0) {
                return
            }

            if (line.trim() == '') {
                console.log("ignoring empty line", line)
                return
            }

            console.log("processing ", line)
            let values = line.split(",").map(value => replaceAll(value, '"', ''));

            let client = new Client();
            client.name = `${values[0]} ${values[1]}`.trim()
            client.taxNumber = values[2].trim()
            client.address = new Address()
            client.address.nation = 'IT'
            client.address.district = this.findDistrict(values[3].trim())
            client.address.city = values[4].trim()
            client.address.postcode = values[5].trim()
            client.address.street = values[6].trim()

            parsed.push({index, entity: client})
        })

        this.validateParsedClients(parsed).then(errors => {
            console.log("opening dialog")
            this.dialog.open(ClientsImportDialog, {
                data: {
                    errors,
                    entities: parsed
                }
            }).afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    console.log("importing clients")

                    this.clientRepository.transaction(async (transaction) => {
                        parsed.forEach(value => {
                            this.clientRepository.transactionalSave(transaction, value.entity)
                        })
                    }).then(() => {
                        this.informSuccess('Clienti importati', 'I clienti sono stati importati con successo');
                    }).catch(reason => {
                        console.error("error import clients:", reason)
                        this.informError('Impossibilie importare i clienti');
                    })
                }
            })
        })

    }

    async validateSuppliersImport(event) {
        let files = event.target.files;
        if (!files) {
            this.informError("Nessun file selezionato")
            return
        }
        const file: File = files[0];

        let csv = await file.text()

        console.log("csv", csv)
        let parsed: any[] = []

        csv.split('\n').forEach((line, index, array) => {
            if (index == 0) {
                return
            }

            if (line.trim() == '') {
                console.log("ignoring empty line", line)
                return
            }

            console.log("processing ", line)
            let values = line.split(",").map(value => replaceAll(value, '"', ''));

            let supplier = new Supplier();
            supplier.name = values[0].trim()
            supplier.sdi = values[1].trim()
            supplier.taxNumber = values[2].trim()
            supplier.address = new Address()
            supplier.address.nation = 'IT'
            supplier.address.district = this.findDistrict(values[3].trim())
            supplier.address.city = values[4].trim()
            supplier.address.postcode = values[5].trim()
            supplier.address.street = values[6].trim()

            parsed.push({index, entity: supplier})
        })

        this.validateParsedSuppliers(parsed).then(errors => {
            console.log("opening dialog")
            this.dialog.open(SuppliersImportDialog, {
                data: {
                    errors,
                    entities: parsed
                }
            }).afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    console.log("importing suppliers")

                    this.supplierRepository.transaction(async (transaction) => {
                        parsed.forEach(value => {
                            this.supplierRepository.transactionalSave(transaction, value.entity)
                        })
                    }).then(() => {
                        this.informSuccess('Fornitori importati', 'I fornitori sono stati importati con successo');
                    }).catch(reason => {
                        console.error("error import suppliers:", reason)
                        this.informError('Impossibilie importare i fornitori');
                    })
                }
            })
        })

    }

    async validateEmployeesImport(event) {
        let files = event.target.files;
        if (!files) {
            this.informError("Nessun file selezionato")
            return
        }
        const file: File = files[0];

        let csv = await file.text()

        console.log("csv", csv)
        let parsed: any[] = []

        csv.split('\n').forEach((line, index, array) => {
            if (index == 0) {
                return
            }

            if (line.trim() == '') {
                console.log("ignoring empty line", line)
                return
            }

            console.log("processing ", line)
            let values = line.split(",").map(value => replaceAll(value, '"', ''));

            let employee = new Employee();
            employee.fullName = values[0].trim()
            employee.taxNumber = values[1].trim()
            employee.function = values[2].trim()
            employee.hourlyRate = values[3].trim()

            parsed.push({index, entity: employee})
        })

        this.validateParsedEmployees(parsed).then(errors => {
            console.log("opening dialog")
            this.dialog.open(EmployeesImportDialog, {
                data: {
                    errors,
                    entities: parsed
                }
            }).afterClosed().subscribe(confirmed => {
                if (confirmed) {
                    console.log("importing employees")

                    this.employeeRepository.transaction(async (transaction) => {
                        parsed.forEach(value => {
                            this.employeeRepository.transactionalSave(transaction, value.entity)
                        })
                    }).then(() => {
                        this.informSuccess('Dipendenti importati', 'I dipendenti sono stati importati con successo');
                    }).catch(reason => {
                        console.error("error import employees:", reason)
                        this.informError('Impossibilie importare i dipendenti');
                    })
                }
            })
        })

    }

    private async validateParsedClients(parsed: any[]) {
        let errors: ProcessingError[] = []

        let promises: Promise<void>[] = parsed.map(async line => {
            let index: number = line.index
            let client: Client = line.entity

            let existingClient = await this.clientRepository.findByTaxNumber(client.taxNumber);
            if (existingClient.length > 0) {
                this.makeError(errors, index, client, `Il cliente con codice fiscale / partita iva ${client.taxNumber} è già presente nel database`);
            }

            if (client.name.trim() == '') this.makeError(errors, index, client, `Il campo nome è obbligatorio`)
            if (client.taxNumber.trim() == '') this.makeError(errors, index, client, `Il campo codice fiscale/partita iva è obbligatorio`)
            if (client.address.nation.trim() == '') this.makeError(errors, index, client, `Il campo nazione è obbligatorio`)
            if (client.address.district.trim() == '') this.makeError(errors, index, client, `Il campo provincia è obbligatorio`)
            if (client.address.city.trim() == '') this.makeError(errors, index, client, `Il campo città è obbligatorio`)
            if (client.address.postcode.trim() == '') this.makeError(errors, index, client, `Il campo CAP è obbligatorio`)
            if (client.address.street.trim() == '') this.makeError(errors, index, client, `Il campo indirizzo è obbligatorio`)

            return
        })
        await Promise.all(promises);
        return errors;

    }

    private async validateParsedSuppliers(parsed: any[]) {
        let errors: ProcessingError[] = []

        let promises: Promise<void>[] = parsed.map(async line => {
            let index: number = line.index
            let supplier: Supplier = line.entity

            let existingSupplier = await this.supplierRepository.findByTaxNumber(supplier.taxNumber);
            if (existingSupplier.length > 0) {
                this.makeError(errors, index, supplier, `Il fornitore con codice fiscale / partita iva ${supplier.taxNumber} è già presente nel database`);
            }

            if (supplier.name.trim() == '') this.makeError(errors, index, supplier, `Il campo nome è obbligatorio`)
            if (supplier.taxNumber.trim() == '') this.makeError(errors, index, supplier, `Il campo codice fiscale/partita iva è obbligatorio`)
            if (supplier.sdi.trim() == '') this.makeError(errors, index, supplier, `Il campo SDI è obbligatorio`)
            if (supplier.address.nation.trim() == '') this.makeError(errors, index, supplier, `Il campo nazione è obbligatorio`)
            if (supplier.address.district.trim() == '') this.makeError(errors, index, supplier, `Il campo provincia è obbligatorio`)
            if (supplier.address.city.trim() == '') this.makeError(errors, index, supplier, `Il campo città è obbligatorio`)
            if (supplier.address.postcode.trim() == '') this.makeError(errors, index, supplier, `Il campo CAP è obbligatorio`)
            if (supplier.address.street.trim() == '') this.makeError(errors, index, supplier, `Il campo indirizzo è obbligatorio`)

            return
        })
        await Promise.all(promises);
        return errors;

    }

    private async validateParsedEmployees(parsed: any[]) {
        let errors: ProcessingError[] = []

        let promises: Promise<void>[] = parsed.map(async line => {
            let index: number = line.index
            let employee: Employee = line.entity

            let existingEmployee = await this.employeeRepository.findByTaxNumber(employee.taxNumber);
            if (existingEmployee.length > 0) {
                this.makeError(errors, index, employee, `Il dipendente con codice fiscale / partita iva ${employee.taxNumber} è già presente nel database`);
            }

            if (employee.name.trim() == '') this.makeError(errors, index, employee, `Il campo nome è obbligatorio`)
            if (employee.taxNumber.trim() == '') this.makeError(errors, index, employee, `Il campo codice fiscale/partita iva è obbligatorio`)
            if (employee.function.trim() == '') this.makeError(errors, index, employee, `Il campo funzionalità è obbligatorio`)
            // @ts-ignore
            if (isNaN(Number.parseFloat(employee.hourlyRate))) this.makeError(errors, index, employee, `Il campo Paga oraria deve essere un numero`)
            else {
                // @ts-ignore
                employee.hourlyRate = Number.parseFloat(employee.hourlyRate)
            }

            return
        })
        await Promise.all(promises);
        return errors;

    }

    private findDistrict(districtName: string) {
        return districts.find(value => value.name == districtName.trim())?.abbr || '';
    }

    private informError(message: string) {
        this.dialog.open(InformDialogComponent, {
            data: {
                title: 'Si è verificato un errore',
                message: message
            }
        }).afterClosed()
    }

    private informSuccess(title: string, message: string) {
        this.dialog.open(InformDialogComponent, {
            data: {
                title: title,
                message: message
            }
        }).afterClosed()
    }


    private makeError(errors: ProcessingError[], index: number, entity: Entity, message: string) {
        let processingError = new ProcessingError();
        processingError.index = index
        processingError.entity = entity
        processingError.message = message

        errors.push(processingError)
    }
}

function replaceAll(str, find, replace) {
    return str.trim().replace(new RegExp(find, 'g'), replace);
}

export class ProcessingError {
    index: number
    entity: Entity
    message: string
}
