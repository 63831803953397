import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DocumentStorageService} from "../document-storage.service";
import {FiscalDocument} from "../model/fiscalDocument";

@Injectable({
    providedIn: 'root'
})
export class XmlExporterService {

    constructor(private httpClient: HttpClient, private storageService: DocumentStorageService) {
    }

    downloadAsXml(fiscalDocument: FiscalDocument) {
        if(!fiscalDocument.filePath) alert('Nessun XML disponibile')

        this.storageService.retrieveBlob(fiscalDocument.filePath).then(invoiceXml => {
            this.storageService.xmlToFile(invoiceXml, 'application/xml', `${fiscalDocument.number}.xml`)
        }).catch(reason => alert("Impossibile convertire in XML al momento, riprova più tardi"))

    }

    getXsl(publicAdministration: boolean = false) {
        let url = publicAdministration ? 'assets/fattura_PA.xsl' : 'assets/fattura_ordinaria.xsl';
        return this.httpClient.get(url, {responseType: 'text'})
    }

}
