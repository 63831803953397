<form name="businessForm" #form="ngForm" (ngSubmit)="save(showcase, form)" *ngIf="showcaseObservable | async as showcase">
    <article class="page">
        <header>
            <h1>Vetrina</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above"
                        [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>
        <div class="content">

            <mat-card class="infoCard business">
                <mat-card-header>
                    <mat-icon mat-card-avatar>business</mat-icon>
                    <mat-card-title>{{ showcase.name }}</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <mat-form-field>
                        <input name="businessName" #businessName="ngModel" required matInput [(ngModel)]="showcase.businessName"
                                  placeholder="Nome società" />
                        <mat-error *ngIf="businessName.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <textarea name="description" #description="ngModel" required matInput [(ngModel)]="showcase.description"
                                  style="min-height: 200px"
                                  placeholder="Qui la descrizione della tua societ&agrave;"></textarea>
                        <mat-error *ngIf="description.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                    </mat-form-field>


                </mat-card-content>

            </mat-card>

            <!--    <documents name="documents" [batch]="documentStorageBatch" [(ngModel)]="showcase.pictures" i18n="@@documents">Foto progetti</documents> -->

            <div *ngIf="!showcase.logo" class="yourLogoHere showcaseLogo" (click)="selectDocument(logo)">
                Il tuo logo qui
            </div>

            <div *ngIf="showcase.logo" class="logoContainer">
                <img class="showcaseLogo" [src]="showcase.logo.downloadUrl || logoUrl">
                <button type="button" mat-button (click)="selectDocument(logo)" >Cambia logo</button>
            </div>

            <document-input #logo type="logo" name="logo" [batch]="documentStorageBatch" [(ngModel)]="showcase.logo" style="visibility: hidden">
            </document-input>


        </div>
    </article>
</form>
