<mat-card class="infoCard">
    <mat-card-header>
        <mat-card-title *ngIf="isInboundMode()" i18n="@@inbound_invoices">Fatture in entrata</mat-card-title>
        <mat-card-title *ngIf="isOutboundMode()" i18n="@@outbound_invoices">Fatture in uscita</mat-card-title>
        <mat-card-title *ngIf="isQuoteMode()" i18n="@@quotes">Preventivi</mat-card-title>

        <mat-icon mat-card-avatar *ngIf="isInboundMode()">arrow_forward</mat-icon>
        <mat-icon mat-card-avatar *ngIf="isOutboundMode()">arrow_back</mat-icon>
        <mat-icon mat-card-avatar *ngIf="isQuoteMode()">request_quote</mat-icon>
    </mat-card-header>

    <mat-card-content>
        <section *ngIf="balance.amount != null">
            <p><span  i18n="@@quoted_price">Prezzo preventivato</span>: {{balance.quotedAmount | formatCurrency }}</p>
            <p><span  i18n="@@current_price">Prezzo effettivo</span>: {{balance.amount | formatCurrency }}</p>
            <p><span  i18n="@@paid">Pagato</span>: {{balance.getTotal() | formatCurrency }}</p>
            <p><span  i18n="@@rest">Residuo</span>: {{balance.getResidualToPay() | formatCurrency }}</p>
        </section>

        <table mat-table matSort [dataSource]="this.balance.payments" >

            <ng-container matColumnDef="invoiceNumber">
                <th mat-header-cell *matHeaderCellDef  i18n="@@invoice_number">Numero fattura</th>
                <td mat-cell *matCellDef="let payment"> {{payment.invoiceNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th class="currencyColumn" mat-header-cell mat-sort-header *matHeaderCellDef  i18n="@@amount">Importo</th>
                <td class="currencyColumn" mat-cell *matCellDef="let payment"> {{payment.amount | formatCurrency }} </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef  i18n="@@description">Descrizione</th>
                <td mat-cell *matCellDef="let payment"> {{payment.description}} </td>
            </ng-container>

            <ng-container matColumnDef="dueDate">
                <th mat-header-cell *matHeaderCellDef>Data scadenza</th>
                <td mat-cell [class.bad]="payment.isOverdue()" *matCellDef="let payment"> {{payment.dueDate | date}} </td>
            </ng-container>

            <ng-container matColumnDef="paidDate">
                <th mat-header-cell *matHeaderCellDef  i18n="@paid_date">Data pagamento</th>
                <td mat-cell *matCellDef="let payment"> {{payment.paidDate | date}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let payment">
                    <a mat-icon-button [routerLink]="[getBaseUrl(), payment.invoiceId]">
                        <mat-icon class="mat-24">visibility</mat-icon>
                    </a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.bad]="row.isOverdue()"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length"
                    style="text-align: center"  i18n="@@no_payment">Nessun pagamento presente</td>
            </tr>
        </table>

    </mat-card-content>

    <mat-card-actions>
        <a mat-button color="primary"
                [queryParams]="owner? {type: owner.constructor.name, id: owner.id, projectId: owner['projectId'] || null, supplierId: owner['supplier']?.id} : {}"
                [routerLink]="[ getBaseUrl() +'/new']"  i18n="@@add">Aggiungi</a>
    </mat-card-actions>

</mat-card>
