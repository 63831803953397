import {Injectable} from '@angular/core';
import {
    collectionData,
    DocumentData,
    FirestoreDataConverter,
    query,
    QueryDocumentSnapshot,
    SnapshotOptions,
    where
} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import {getCurrentBusiness} from "../utils";
import {
    TimesByProject,
    Timesheet,
    TimesheetRow,
    WeatherDay,
    WeatherRow,
    WorkedTimes,
    WorkingDay
} from "../model/timesheet";
import * as moment from "moment";
import {Employee} from "../model/employee";
import {Supplier} from "../model/supply";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class TimesheetRepository extends Repository<Timesheet> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, new TimesheetConverter())
    }


    ngOnInit(): void {
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection( `businesses/${business.id}/timesheet`).withConverter(this.converter);
    }

    find(year: number, month: number) {
        return collectionData(query(
            this.getCollectionReference(),
            where("year", '==', year),
            where("month", '==', month)
        ));
    }
}

class TimesheetConverter implements FirestoreDataConverter<Timesheet> {

    constructor() {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Timesheet {
        const data = snapshot.data(options)!
        let timesheet = new Timesheet(data['year'], data['month']);
        timesheet.id = data['id']
        let rows: any[] = data['rows'];

        let weatherRow = new WeatherRow(0, data['year'], data['month']);
        weatherRow.days = data['weatherDays'].map(dayData => {
            let weatherDay = new WeatherDay();
            weatherDay.day = dayData['day']
            weatherDay.hoursOfRain = dayData['hoursOfRain']
            weatherDay.date = moment(dayData['date'], 'DD/MM/YYYY')
            return weatherDay
        })
        timesheet.weather = weatherRow

        timesheet.rows = rows.map(value => {
            let row = new TimesheetRow();
            if (value.type == 'employee') {
                let employee = new Employee();
                employee.id = value.subject
                row.subject = employee
            }

            if (value.type == 'supplier' || value.type == 'medical_center' || value.type == 'educational_center' ) {
                let supplier = new Supplier();
                supplier._type = value.type;
                supplier.id = value.subject
                row.subject = supplier
            }

            let days: any[] = value.days;
            row.days = days.map(dayData => {
                let workingDay = new WorkingDay();
                workingDay.day = dayData['day']
                workingDay.date = moment(dayData['date'], 'DD/MM/YYYY')
                workingDay.workedTimes = new WorkedTimes();
                workingDay.workedTimes.timesByProject.push(
                    ...dayData['workedTimes'].map(times => {
                        let timesByProject = new TimesByProject();
                        timesByProject.hours = times['hours']
                        timesByProject.description = times['description'] || null
                        timesByProject.project = {id: times['projectId'], name: times['projectName']}
                        return timesByProject
                    })
                )
                return workingDay
            })
            return row
        })

        return timesheet
    }

    toFirestore(timesheet: Timesheet): DocumentData {

        return {
            id: `${timesheet.year}-${timesheet.month}`,
            year: timesheet.year,
            month: timesheet.month,
            weatherDays: timesheet.weather.days.map(day => {
                return {
                    day: day.day,
                    hoursOfRain: day.hoursOfRain,
                    date: day.date.format('DD/MM/YYYY')
                }
            }),
            rows: timesheet.rows.map(row => {
                return {
                    subject: row.subject.id,
                    type: row.subject.type(),
                    days: row.days.filter(workingDay => workingDay.total > 0).map(workingDay => {
                        return {
                            day: workingDay.day,
                            date: workingDay.date.format('DD/MM/YYYY'),
                            workedTimes: workingDay.workedTimes.timesByProject.filter(times => times.hours > 0).map(times => {
                                return {
                                    projectId: times.project.id,
                                    hours: times.hours,
                                    description: times.description || null
                                }
                            })
                        }
                    })
                }
            })
        }
    }
}
