import {Component, OnInit} from '@angular/core';
import {Bouncer} from "../../bouncer.service";
import {NavigatorService} from "../../navigator.service";
import {BusinessRepository} from "../business.repository";
import {UserRepository} from "../user.repository";
import {Business, OrganizationType} from "../../model/business";
import {User} from "../../model/user";
import {generateId} from "../../utils";
import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot} from "@angular/fire/firestore";

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

    emailSent: boolean = false;

    signupModel = {username: null, password: null, name: null, businessName: null}

    constructor(private bouncer: Bouncer, private navigator: NavigatorService,
                private businessRepository: BusinessRepository, private userRepository: UserRepository) {
    }

    ngOnInit(): void {
    }

    async signUp(event: SubmitEvent, signupModel) {
        event.preventDefault()

        let business = new Business()
        business.name = signupModel.businessName
        business.version = 0

        let user = new User()

        let userId = await this.bouncer.signUp(signupModel.username, signupModel.password, signupModel.name);
        user.id = userId
        user.name = signupModel.name
        console.log("storing business")
        business.id = generateId()
        let collectionReference = this.businessRepository.getCollectionReference().withConverter(new BasicBusinessConverter())
        await this.businessRepository.firestoreWrapper.setDoc(this.businessRepository.getReference(business, collectionReference), business)
        user.businessId = business.id

        console.log("storing user")
        await this.userRepository.save(user)
        this.emailSent = true

    }

    togglePassword(password: HTMLInputElement) {
        password.type = password.type == 'password' ? 'text' : 'password'
    }
}

class BasicBusinessConverter implements FirestoreDataConverter<Business> {

    fromFirestore(snapshot: QueryDocumentSnapshot) {
        let data = snapshot.data() as any;
        let business = new Business();
        business.name = data['name']
        business.id = data['id']

        return business
    }

    toFirestore(business: Business): DocumentData {
        return {
            name: business.name,
            id: business.id,
            organizationType: OrganizationType.LegalEntity,
            version: 0,
            address: {
                street: null,
                city: null,
                district: null,
                postcode: null,
                nation: 'IT'
            },
            phoneNumber: null,
            taxNumber: null,
            documents: [],
            taxRegime: null,
            sdi: null,
            bank: null,
            iban: null,
        }
    }

}
