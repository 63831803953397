<article class="page">
    <header>
        <h1>{{ entityLabelling.plural }}</h1>

        <form class="search">
            <mat-form-field>
                <input matInput (keyup)="applyFilter($event)" placeholder="Cerca..." i18n-placeholder="@@search">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </form>
        <div *ngIf="!addButtonDisabled">
            <button mat-fab color="primary" name="addEntityButton" matTooltip="Aggiungi" i18n-matTooltip="@@add"
                    matTooltipPosition="above" [routerLink]="['new']">
                <mat-icon>{{ addIcon }}</mat-icon>
            </button>
        </div>
    </header>

    <div class="cards-container">
        <ng-template [ngTemplateOutlet]="templateRef"
                     [ngTemplateOutletContext]="{$implicit: (listObservable | async)}"></ng-template>
    </div>

    <div *ngIf="(listObservable | async)?.length == 0">
        <p><span i18n="@@no_">Nessun</span> {{ entityLabelling.singular }}</p>
    </div>


</article>
