import {Component, OnInit} from '@angular/core';
import {Observable, of} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {NgForm} from "@angular/forms";
import {FakeShowcaseRepository} from "../../fake-showcases.component";
import {Showcase} from "../../../../model/business";
import {DocumentInputComponent} from "../../../../components/document-input/document-input.component";
import {DocumentStorageBatch, DocumentStorageService} from "../../../../document-storage.service";

@Component({
    selector: 'app-showcase',
    templateUrl: './fake-showcase.component.html',
    styleUrls: ['./fake-showcase.component.scss']
})
export class FakeShowcaseComponent implements OnInit {
    loading: boolean = false;
    showcaseObservable: Observable<Showcase | undefined>;
    logoUrl: any;
    documentStorageBatch: DocumentStorageBatch = new DocumentStorageBatch();


    constructor(private fakeShowcaseRepository: FakeShowcaseRepository, private route: ActivatedRoute, private documentStorageService: DocumentStorageService) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            let id = params.get('id');
            if (!id || id === 'new') {
                let showcase = new Showcase();
                showcase.businessId = 'X'
                this.showcaseObservable = of(showcase)
            } else {
                this.showcaseObservable = this.fakeShowcaseRepository.findById(id)
            }
        });
    }

    save(showcase: Showcase, form: NgForm) {
        if (!form.valid) return

        this.loading = true;
        this.documentStorageService.upload(showcase.logo)
            .then(value => this.fakeShowcaseRepository.save(showcase))
            .then(value => this.loading = false);
    }

    selectDocument(logo: DocumentInputComponent) {
        logo.selectDocument()
        logo.documentSelectedEmitter.subscribe((result: any) => {
            var reader = new FileReader();

            reader.readAsDataURL(result.document.file!); // read file as data url

            reader.onload = (event) => { // called once readAsDataURL is completed
                this.logoUrl = event.target!.result;
            }
        })
    }
}
