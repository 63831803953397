import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Leave} from "../../model/leave";
import {MatTable} from "@angular/material/table";
import {CrudCardComponent} from "../../components/crud-card/crud-card.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {BehaviorSubject, Observable} from "rxjs";
import * as moment from "moment/moment";
import {MatDialog} from "@angular/material/dialog";
import {clone, generateId} from "../../utils";
import {LeaveRecordDialog} from "./leave-record-dialog/leave-record-dialog.component";

@Component({
    selector: 'leave-profile',
    templateUrl: './leave-profile.component.html',
    styleUrls: ['./leave-profile.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: LeaveProfileComponent
        }
    ]
})
export class LeaveProfileComponent extends CrudCardComponent<Leave> implements AfterViewInit {

    @ViewChild(MatTable) leaveTable: MatTable<Leave>;

    displayedColumns: string[] = ['from', 'to', 'actions'];
    selectedYear: BehaviorSubject<number> = new BehaviorSubject(moment().year());
    filteredList: BehaviorSubject<Leave[]> = new BehaviorSubject([] as Leave[])

    constructor(dialog: MatDialog) {
        super(dialog)

        this.setDialogComponent(LeaveRecordDialog)
    }


    ngAfterViewInit() {
        super.table = this.leaveTable
    }

    public newEntity() {
        let leave = new Leave();
        leave.id = generateId()
        return leave
    }

    get leaves(): Observable<Leave[]> {
        return this.filteredList;
    }

    override onListWritten() {
        this.selectedYear.subscribe(year => this.filteredList.next( this.list?.filter(leave => leave.from.year() == year) || [] ))
    }

    moveTimeLeft() {
        this.selectedYear.next(this.selectedYear.value - 1)
    }

    moveTimeRight() {
        this.selectedYear.next(this.selectedYear.value + 1)
    }

    override hideTable(): boolean {
        return this.filteredList.value.length == 0
    }

    override refreshTable() {
        this.filteredList.next( this.list?.filter(leave => leave.from.year() == this.selectedYear.value) || [] )
    }

    openNewDialog() {
        super.openNewRecordDialog({
            record: this.newEntity()
        })
    }

    openEditDialog(event: MouseEvent, record: Leave) {
        super.openEditRecordDialog(event, {
            record: clone(record),
        })

    }

}
