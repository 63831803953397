<section class="attachments">
    <header><h1 i18n="@@attachments">Allegati</h1></header>

    <div class="actions">
        <button mat-flat-button color="primary" (click)="addAttachment( fileUpload)"
                type="button" *ngIf="!disabled" i18n="@@add_attachment">
            Aggiungi Allegato
        </button>
        <input name="fileUpload" type="file" class="file-input" #fileUpload>
    </div>


    <div *ngFor="let attachment of attachments; let i = index" class="attachment">

        <p>{{ attachment.name }}</p>

        <mat-form-field class="attachmentDescription">
            <mat-label i18n="@@description">Descrizione</mat-label>

            <input name="attachmentDescription-{{i}}" #attachmentDescription="ngModel" (ngModelChange)="onChange(attachments)"
                   matInput [required]="descriptionRequired" maxlength="99"
                   [disabled]="disabled"
                   [(ngModel)]="attachment.description"/>
            <mat-error *ngIf="attachmentDescription.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
        </mat-form-field>

        <div>
            <button type="button" mat-icon-button matSuffix
                    (click)="removeAttachment($event, attachment)"
                    *ngIf="!disabled">
                <mat-icon>delete</mat-icon>
            </button>

            <button type="button" mat-icon-button matSuffix
                    (click)="downloadAttachment(attachment)">
                <mat-icon>download</mat-icon>
            </button>
        </div>
    </div>


    <div *ngIf="attachments.length == 0" style="text-align: center"  i18n="@@no_attachments">
        Nessun Allegato
    </div>

</section>
