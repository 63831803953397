import {Component, Inject} from '@angular/core';
import {getCurrentBusiness} from "../../../utils";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Document} from "../../../model/document";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss']
})
export class DocumentDialog {

    record: Document
    editMode: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DocumentDialog>) {
        this.record = data.record
        this.editMode = data.editMode
    }

    ngOnInit(): void {
    }

    onSubmit(form: NgForm) {
        this.dialogRef.close(this.record)
    }

    cancel(event: MouseEvent) {
        event.stopPropagation()
        event.preventDefault()
        this.dialogRef.close(null)
    }
    onDocumentInputClear() {

        let document = new Document();
        this.record = document

        // @ts-ignore
        this.onChange(document)
    }

    isDownloadable(): boolean {
        return !!this.record && this.record.isDownloadable()
    }

    getDownloadUrl() {
        return this.record?.downloadUrl
    }

    selectDocument(event: MouseEvent, fileUpload: HTMLInputElement) {
        event.preventDefault()
        event.stopPropagation()
        fileUpload.click()
    }

    onDocumentSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.record = Document.makeDocument(this.record.name, file, getCurrentBusiness());
        }
    }
}
