import {Injectable} from '@angular/core';
import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import {DocumentConverter} from "../document.repository";
import {Moment} from "moment";
import * as moment from "moment/moment";
import {Entity} from "../model/entity";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class ContactRequestRepository extends Repository<ContactRequest> {

    documentConverter: DocumentConverter

    constructor(firestore: FirestoreWrapper) {
        super(firestore, new ContactRequestConverter())

        this.documentConverter = new DocumentConverter();
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection(`contactRequests`).withConverter(this.converter);
    }

}

class ContactRequestConverter implements FirestoreDataConverter<ContactRequest> {
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): ContactRequest {
        const data = snapshot.data(options)!
        let contactRequest = new ContactRequest();
        contactRequest.id = data['id']
        contactRequest.name = data['name']
        contactRequest.email = data['email']
        contactRequest.package = data['package'] || null
        contactRequest.description = data['description'] || null

        contactRequest.date = moment(data['date'].toDate())

        return contactRequest
    }

    toFirestore(contactRequest: ContactRequest): DocumentData {
        return {
            id: contactRequest.id,
            name: contactRequest.name,
            email: contactRequest.email,
            package: contactRequest.package,
            description: contactRequest.description,
            date: contactRequest.date.toDate()
        }
    }
}

export class ContactRequest extends Entity {
    name: string
    email: string
    date: Moment = moment()
    package: string | null = null
    description: string = ''
}
