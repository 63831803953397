import {Injectable} from '@angular/core';
import {collectionData, query, where} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import {getCurrentBusiness} from "../utils";
import {Notification} from "../model/notification";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";
import {ConverterRegistry} from "../persistence/converter-registry.service";

@Injectable({
    providedIn: 'root'
})
export class NotificationRepository extends Repository<Notification> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, ConverterRegistry.find('Notification'))
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection(`businesses/${business.id}/notifications`).withConverter(this.converter);
    }

    getAllNotAcknowledged() {
        return collectionData(query(
            this.getCollectionReference(),
            where("acknowledged", '==', false)
        ));
    }
}

