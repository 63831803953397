import {EventEmitter, Injectable, Output} from '@angular/core';
import {getBlob, getDownloadURL, ref, Storage, uploadBytesResumable} from "@angular/fire/storage";
import {DocumentEvent} from "./components/document-input/document-input.component";
import {Document} from "./model/document";
import {generateId} from "./utils";
import {Attachment} from "./model/invoice";
import {DocumentRepository} from "./document.repository";
import * as moment from "moment";
import {Transaction} from "@angular/fire/firestore";

@Injectable({
    providedIn: 'root'
})
export class DocumentStorageService {

    @Output("fileUploaded") fileUploadedEmitter = new EventEmitter<DocumentEvent>();
    @Output("fileDeleted") fileDeletedEmitter = new EventEmitter<DocumentEvent>();

    constructor(private storage: Storage, public repository: DocumentRepository) {
    }

    upload(document: Document | null | undefined): Promise<Document | void> {

        return new Promise((resolve, reject) => {
            if (!document || document.isUploaded()) {
                console.log("document skipped", document, document?.isUploaded())

                return resolve()
            }

            if (!document.hasFileData()) {
                console.log("rejecting file upload")
                reject("FILE_DATA_MISSING")
            }

            let file = document!.file!
            document.id = generateId();
            document.createdTime = moment()
            let storageRef = ref(this.storage, `business/${document.businessId}/${document.id}/${file.name}`);

            console.log("uploading", storageRef, file)

            let metadata = {
                customMetadata: {
                    businessId: document.businessId,
                    fileName: file.name,
                    documentName: document.name
                }
            }
            const task = uploadBytesResumable(storageRef, file, metadata);
            return task.then(() => this.retrieveDownloadUrl(storageRef), error => { throw new Error("Cannot upload file " + error)})
                .then((url) => {
                    // @ts-ignore
                    document.onUploaded(storageRef.fullPath, url)
                    console.log("saving document into docs", document)
                    return this.repository.save(document).then(value => {
                        console.log("val", value)
                        return document
                    })
                }).then(value =>
                    resolve(document!)
                )
                .catch(reason => {
                    console.log("cannot upload", reason)
                    throw reason
                })

        });

    }

    retrieveDownloadUrl(storageRef): Promise<string> {
        if (typeof storageRef === 'string' || storageRef instanceof String) {
            // @ts-ignore
            storageRef = ref(this.storage, storageRef)
        }

        return getDownloadURL(storageRef).then(
            url => url,
            reason => {
                throw new Error("cannot get download url: " + reason)
                return ''
            })
    }

    retrieveBlob(storageRef): Promise<string> {
        if (typeof storageRef === 'string' || storageRef instanceof String) {
            // @ts-ignore
            storageRef = ref(this.storage, storageRef)
        }

        return getBlob(storageRef).then(value => value.text())
    }

    markToDelete(documents: Document[]) {
        return documents.map(document => {
            document.markDeleted()
            return this.repository.updateDeleted(document)
        })
    }

    uploadSequentially(documents: Document[]) {
        let promise = this.upload(documents[0]);
        for (let i = 1; i < documents.length; ++i) {
            promise = promise.then(value => this.upload(documents[i]))
        }
        return promise;
    }

    xmlToFile(data: any, type: string, fileName: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        const blob = new Blob([data], {type: type});
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    blobToFile(blob: Blob, type: string, fileName: string) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    base64toBlob(base64Data, type = 'application/octet-stream') {
        let url = base64Data.startsWith('data:') ? base64Data : `data:${type};base64,${base64Data}`
        return fetch(url).then(res => res.blob());
    }

    toBase64(file: File): Promise<string | ArrayBuffer | null> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    downloadInvoiceAttachment(fiscalDocumentFilePath: string, attachment: Attachment) {
        this.retrieveBlob(fiscalDocumentFilePath).then(xml => {
            let parser = new DOMParser()
            let xmlDoc = parser.parseFromString(xml, "text/xml")
            let attachmentXml = Array.from(xmlDoc.getElementsByTagName("Allegati"))
                .find(value => value.getElementsByTagName('NomeAttachment')[0].innerHTML == attachment.name)!

            let base64Attachment = attachmentXml.getElementsByTagName('Attachment')[0].innerHTML
            this.base64ToFile(base64Attachment, attachment);
        })
    }

    base64ToFile(base64Attachment: string, attachment: Attachment) {
        this.base64toBlob(base64Attachment).then(blob => {
            this.blobToFile(blob, 'application/pdf', attachment.name)
        })
    }

    attachmentToFile(attachment: Attachment) {
        if (!attachment.base64Data) throw Error("No attachment base64 data")

        this.base64ToFile(attachment.base64Data, attachment)
    }

    openBatch() {
        let batch = new DocumentStorageBatch();
        batch.id = generateId()
        return batch
    }

    markDocumentsDelete(transaction: Transaction, docs: Document[]) {
        docs.forEach(document => {
            let reference = this.repository.getReference(document);

            document.updatedTime = moment()

            let documentData = this.repository.converter.toFirestore(document);
            transaction.update(reference, {status: documentData.status, updatedTime: documentData.updatedTime});
        })
    }
}

export class DocumentStorageBatch {
    id: string
    toDelete: Document[] = []

    addToDeleted(doc: Document) {
        if(doc.id) this.toDelete.push(doc)
    }
}
