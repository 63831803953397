import {Component, Inject} from '@angular/core';
import {WeatherDay} from "../../model/timesheet";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-weather-dialog',
  templateUrl: './weather-dialog.component.html',
  styleUrls: ['./weather-dialog.component.scss']
})
export class WeatherDialogComponent {

    weatherDay: WeatherDay

    constructor(public dialogRef: MatDialogRef<WeatherDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.weatherDay = data.weatherDay

    }

}
