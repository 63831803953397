import {Moment} from "moment";
import {Document} from "./document";
import {Address} from "./address";
import {Invoice} from "./invoice";
import {InvoiceSender} from "./invoiceSender";
import {Payment} from "./payment";
import {Balance} from "./balance";
import {Entity} from "./entity";

export class Supplier extends Entity implements InvoiceSender {
    _type = 'supplier'
    name: string
    address: Address = new Address()
    taxNumber: string
    sdi: string
    iban: string
    phoneNumber: string
    email: string | null = null

    supplies: Supply[] = []

    numberOfSupplies() {
        return this.supplies.length
    }

    private getBalances() {
        return this.supplies.map(value => value.balance).flat();
    }

    numberOfIssuedInvoices() {
        return this.getBalances()
            .map(value => value.numberOfInvoices())
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    }

    numberOfPaidInvoices() {
        return this.getBalances()
            .map(value => value.numberOfPaidInvoices())
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    }

    numberOfUnpaidInvoices() {
        return this.getBalances()
            .map(value => value.numberOfUnpaidInvoices())
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    }

    type() {
        return this._type
    }
}

export abstract class Supply extends Entity {
    _type: string;

    description: string
    supplier: { name: string, id: string, taxNumber: string }
    quote: Document | null
    order: Document | null
    balance: Balance = new Balance()
    projectId: string | null = null
    clientId: string | null = null
    quotedAmount: number = 0;

    get name() {
        return this.description
    }

    addPaymentFromInvoice(invoice: Invoice) {
        this.balance.addPaymentFromInvoice(invoice)
    }

    updateSupplyPaymentFromInvoice(invoice: Invoice) {
        this.balance.updatePaymentFromInvoice(invoice)
    }

    override getDocuments(): Document[] {
        return [
            this.quote!,
            this.order!,
            this.balance.getDocuments()
        ].flat().filter(value => !!value)
    }

    get firstPayment() {
        return this.balance.payments[0]
    }

    set firstPayment(payment: Payment) {
        this.balance.payments[0] = payment
    }

    get firstInvoice() {
        return this.firstPayment?.invoice
    }

    set firstInvoice(invoice: Invoice) {
        if(this.firstPayment) this.firstPayment.invoice = invoice
    }
}

export class Deliverable extends Supply {
    estimatedDeliveryDate: Moment | null = null
    deliveryDate: Moment | null = null
    override _type = 'deliverable'

}
