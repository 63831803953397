import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {DocumentEvent, DocumentInputComponent} from "../document-input/document-input.component";
import {MatTable} from "@angular/material/table";
import {CrudCardComponent} from "../crud-card/crud-card.component";
import {Document} from "../../model/document";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DocumentStorageBatch} from "../../document-storage.service";
import {clone} from "../../utils";
import {Leave} from "../../model/leave";
import {MatDialog} from "@angular/material/dialog";
import {DocumentDialog} from "./document-dialog/document-dialog.component";

@Component({
    selector: 'documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DocumentsComponent),
            multi: true,
        },
    ],
})
// TODO implement material form
export class DocumentsComponent extends CrudCardComponent<Document> implements AfterViewInit, ControlValueAccessor {

    @Output("documentAdded") documentAddedEmitter = new EventEmitter<DocumentEvent>();
    @Output("documentRemoved") documentRemovedEmitter = new EventEmitter<DocumentEvent>();
    @ViewChild(MatTable) documentsTable: MatTable<Document>;
    @Input() batch: DocumentStorageBatch;
    @ViewChild(DocumentInputComponent) docInput: DocumentInputComponent;

    private _documents: Document[] = []
    displayedColumns: string[] = ['name', 'file', 'actions'];

    constructor(dialog: MatDialog) {
        super(dialog)

        this.setDialogComponent(DocumentDialog)
    }

    ngAfterViewInit() {
        super.table = this.documentsTable
    }

    public newEntity() {
        let document = new Document();
        return document
    }

    get documents(): Document[] {
        return this._documents;
    }

    @Input()
    set documents(value: Document[]) {
        this.list = value
        this._documents = value
    }


    override writeValue(list: Document[]) {
        this.list = list
        this._documents = list
        super.writeValue(list);
    }

    override remove(event: Event, entity: Document) {
        super.remove(event, entity);
        entity.markDeleted()
        this.batch?.addToDeleted(entity)
    }

    openNewDialog() {
        super.openNewRecordDialog({
            record: this.newEntity(),
            editMode: false
        })
    }

    openEditDialog(event: MouseEvent, record: Leave) {
        super.openEditRecordDialog(event, {
            record: clone(record),
            editMode: true
        })

    }
}
