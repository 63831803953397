import {AfterViewInit, Component, forwardRef, Input, ViewChild} from '@angular/core';
import {MedicalRecord} from "../../model/medicalRecord";
import {MatDialog} from "@angular/material/dialog";
import {MatTable} from "@angular/material/table";
import {CrudCardComponent} from "../../components/crud-card/crud-card.component";
import {DocumentStorageBatch} from "../../document-storage.service";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {Supplier} from "../../model/supply";
import {MedicalRecordDialog} from "./medical-record-dialog/medical-record-dialog.component";
import {clone, generateId} from "../../utils";

@Component({
    selector: 'medical-profile',
    templateUrl: './medical-profile.component.html',
    styleUrls: ['./medical-profile.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MedicalProfileComponent),
            multi: true,
        },
    ],
})
export class MedicalProfileComponent extends CrudCardComponent<MedicalRecord> implements AfterViewInit {

    @ViewChild(MatTable) recordsTable: MatTable<MedicalRecord>;

    displayedColumns: string[] = ['description', 'expectedDate', 'date', 'actions'];
    @Input() batch: DocumentStorageBatch;
    @Input() suppliers: Supplier[];


    constructor(dialog: MatDialog) {
        super(dialog)

        this.setDialogComponent(MedicalRecordDialog)
    }

    ngAfterViewInit() {
        super.table = this.recordsTable
    }

    public newEntity() {
        let medicalRecord = new MedicalRecord();
        medicalRecord.id = generateId()
        return medicalRecord
    }

    openNewDialog() {
        super.openNewRecordDialog({
            record: this.newEntity(),
            suppliers: this.suppliers,
            batch: this.batch
        })
    }

    openEditDialog(event: MouseEvent, record: MedicalRecord) {
        super.openEditRecordDialog(event,{
            record: clone(record),
            suppliers: this.suppliers,
            batch: this.batch
        })
    }
}
