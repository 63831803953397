import {Injectable} from '@angular/core';
import {PriceListRepository} from "./priceList.repository";

@Injectable({
    providedIn: 'root'
})
export class PriceListService {

    constructor(public priceListRepository: PriceListRepository) {
    }

    getAllLists() {
        return this.priceListRepository.getAll()
    }

}
