import {MatPaginatorIntl} from '@angular/material/paginator';

const rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
        return `0 di ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} di ${length}`;
}


export function getPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = $localize`:@@items_per_page:Elementi per pagina`;
    paginatorIntl.nextPageLabel = $localize`:@@next_page:Prossima pagina`;
    paginatorIntl.previousPageLabel = $localize`:@@previous_page:Pagina precedente`;
    paginatorIntl.getRangeLabel = rangeLabel;

    return paginatorIntl;
}
