import {Embedded} from "./entity";
import {persist, PersistenceConfig} from "../persistence/decorators";

@persist(new PersistenceConfig({
    name: 'Address',
    properties: ['street', 'postcode', 'city', 'district', 'nation']
}))
export class Address implements Embedded {
    street: string
    postcode: string
    city: string
    district: string
    nation: string = 'IT'
}
