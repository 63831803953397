import {Pipe, PipeTransform} from '@angular/core';
import {Invoice, Quote} from "../model/invoice";

@Pipe({
    name: 'translateStatus'
})
export class TranslateStatusPipe implements PipeTransform {

    transform(invoice: Invoice | Quote, ...args: unknown[]): string {
        if(invoice.isError()) return $localize `:@@error:Errore`
        if (invoice.isPaid()) return $localize `:@@paid:Pagata`

        if (invoice instanceof Invoice && invoice.isSending())   return $localize `:@@sending:Invio in corso`
        if (invoice instanceof Invoice && invoice.isSent())      return $localize `:@@sent:Inviata`
        if (invoice instanceof Invoice && invoice.isDelivered()) return $localize `:@@delivered:Consegnata`

        if (invoice.isDraft()) return $localize `:@@draft:Bozza`
        return '';
    }

}
