<article class="page">
    <header>
        <h1 i18n="@@quotes">Preventivi</h1>

        <div>
            <button mat-fab color="primary" name="addEntityButton" matTooltip="Nuovo Preventivo" i18n="@@new_quote"
                    matTooltipPosition="left" [routerLink]="['new']">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </header>

    <div class="content">

        <mat-card class="infoCard quotes" >
            <div *ngIf="data | async as datasource">
                <mat-card-header>
                    <mat-icon mat-card-avatar>receipt</mat-icon>

                    <div class="headerWithCommand">
                        <mat-card-title>
                            <span i18n="@@quotes">Preventivi</span>
                        </mat-card-title>

                        <search-table [dataSource]="$any(datasource)"></search-table>
                    </div>
                </mat-card-header>

                <mat-card-content>

                    <table mat-table matSort [dataSource]="datasource" matSortActive="date" matSortDirection="desc">

                        <ng-container matColumnDef="number">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@number">Numero</th>
                            <td mat-cell *matCellDef="let quote"> {{ quote.number }}</td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@status">Stato</th>
                            <td mat-cell *matCellDef="let quote"> {{ quote | translateStatus }}</td>
                        </ng-container>

                        <ng-container matColumnDef="client">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@client">Cliente</th>
                            <td mat-cell *matCellDef="let quote"> {{ quote.receiver.name }}</td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@issue_date">Data emissione
                            </th>
                            <td mat-cell *matCellDef="let quote"> {{ quote.date | date }}</td>
                        </ng-container>

                        <ng-container matColumnDef="taxable">
                            <th class="currencyColumn" mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@taxable">Imponibile</th>
                            <td class="currencyColumn" mat-cell *matCellDef="let quote"> {{ quote.taxable | formatCurrency }}</td>
                        </ng-container>

                        <ng-container matColumnDef="tax">
                            <th class="currencyColumn" mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@tax">Imposta</th>
                            <td class="currencyColumn" mat-cell *matCellDef="let quote"> {{ quote.tax | formatCurrency }}</td>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <th class="currencyColumn" mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@amount">Importo</th>
                            <td class="currencyColumn" mat-cell *matCellDef="let quote"> {{ quote.total | formatCurrency }}</td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let quote" style="width: 6%">
                                <div class="iconsContainer">
                                    <button type="button" mat-icon-button (click)="deleteQuote($event, quote)">
                                        <mat-icon class="mat-24">delete</mat-icon>
                                    </button>
                                    <mat-icon *ngIf="quote.hasAttachments()" i18n-matTooltip="contains_attachments"
                                              matTooltip="Il preventivo contiene allegati" matTooltipPosition="above">
                                        attach_file
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let quote; columns: displayedColumns;"
                            (click)="quoteSelected(quote)"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" style="text-align: center" [colSpan]="displayedColumns.length"
                                i18n="@@no_quotes">Nessun preventivo
                            </td>
                        </tr>
                    </table>

                </mat-card-content>
            </div>

            <mat-card-footer>
                <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
            </mat-card-footer>

        </mat-card>

    </div>
</article>
