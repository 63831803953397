import {Component, OnInit} from '@angular/core';
import {Business, Showcase} from "../../model/business";
import {FormComponentV2} from "../../components/formV2.component";
import {Bouncer} from "../../bouncer.service";
import {ActivatedRoute} from "@angular/router";
import {NavigatorService} from "../../navigator.service";
import {NotificationService} from "../../notification.service";
import {DocumentStorageService} from "../../document-storage.service";
import {Transaction} from "@angular/fire/firestore";
import {DocumentInputComponent} from "../../components/document-input/document-input.component";
import {ShowcaseRepository} from "../../showcases/business-showcases.component";
import {getCurrentBusiness} from "../../utils";

@Component({
    selector: 'app-showcase',
    templateUrl: './showcase.component.html',
    styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent extends FormComponentV2<Showcase> implements OnInit {

    business: Business
    logoUrl: any;

    constructor(private showcaseRepository: ShowcaseRepository, private bouncer: Bouncer, private route: ActivatedRoute,
                navigatorService: NavigatorService,
                notificationService: NotificationService,
                documentStorage: DocumentStorageService,) {
        super(documentStorage, navigatorService, notificationService, showcaseRepository)
    }

    ngOnInit(): void {
        this.business = getCurrentBusiness()
        this.business.showcase = this.route.snapshot.data.showcase
    }

    override async doUpdate(transaction: Transaction, showcase: Showcase) {
        showcase.businessName = this.business.name
        showcase.businessId = this.business.id

        await this.showcaseRepository.transactionalUpdate(transaction, showcase.id, {
            id: showcase.id,
            description: showcase.description,
            logo: showcase.logo,
            businessId: showcase.businessId,
            businessName: showcase.businessName,
            version: showcase.version + 1
        } as Showcase)
    }

    async doCreate(transaction: Transaction, entity: Showcase) {
        this.repository.transactionalSave(transaction, entity)

    }

    selectDocument(logo: DocumentInputComponent) {
        logo.selectDocument()
        logo.documentSelectedEmitter.subscribe((result: any) => {
            var reader = new FileReader();

            reader.readAsDataURL(result.document.file!); // read file as data url

            reader.onload = (event) => { // called once readAsDataURL is completed
                this.logoUrl = event.target!.result;
            }
        })
    }
}
