<article class="page">
    <header>
        <h1>Administration</h1>
    </header>
    <div class="content">

        <mat-card class="infoCard admin">
            <mat-card-header>
                <mat-card-title>Console</mat-card-title>
                <mat-icon mat-card-avatar>admin_panel_settings</mat-icon>
            </mat-card-header>

            <mat-card-content>
                <div class="flex-row" style="flex-direction: row-reverse">
                    <button mat-raised-button (click)="sendInvoices()" color="primary">
                        Send invoices
                    </button>

                    <button mat-raised-button (click)="checkGcp()" color="primary">
                        Check GCP
                    </button>

                    <button mat-raised-button (click)="checkGae()" color="primary">
                        Check GAE
                    </button>

                    <button mat-raised-button (click)="migrate()" color="primary">
                        Migrate
                    </button>
                </div>

                <nav mat-tab-nav-bar>
                    <a mat-tab-link
                       *ngFor="let link of navLinks"
                       [routerLink]="link.path"
                       routerLinkActive #rla="routerLinkActive"
                       [active]="rla.isActive">{{link.label}}</a>
                </nav>

                <router-outlet></router-outlet>

            </mat-card-content>

        </mat-card>

    </div>
</article>
