<mat-toolbar color="primary">
    <!--<button mat-icon-button (click)="opened = !opened" [routerLink]="goToMainPage()">
        <mat-icon>construction</mat-icon>
    </button>-->
    <span class="logo" [routerLink]="getMainPage()">Edilie</span>
    <span class="spacer"></span>
    <span class="businessName" *ngIf="business">{{ business.name }}</span>

    <span class="spacer"></span>

    <span><a class="partnersLink" routerLink="/business/showcase">Aziende partner</a></span>
    <span><a class="linkSeparator">|</a></span>
    <span class="notifications" *ngIf="notificationObservable | async as notifications">
        <mat-icon mat-button [matMenuTriggerFor]="notificationsMenu"
                  [matBadgeHidden]="unreadNotificationsNumber(notifications) == 0"
                  [matBadge]="unreadNotificationsNumber(notifications)" matBadgeColor="warn">notifications</mat-icon>

        <mat-menu xPosition="before" class="notificationMenu" #notificationsMenu="matMenu" yPosition="below">
             <div class="notification"  *ngFor="let notification of notifications" mat-menu-item [disableRipple]="true">
                 <div *ngIf="notification.isMedicalAppointmentReminder()">
                       <div class="notificationContent">
                         <mat-icon>medication</mat-icon>

                         <p i18n="@@medical_appointment_reminder">La visita medica "{{ notification.payload.record.description }}" per {{ notification.payload.employee.name }} &egrave; prevista per il giorno {{ notification.getExpectedDateFromPayload() | date }}
                         </p>
                       </div>
                       <div class="notificationActions">
                          <button mat-icon-button (click)="markNotificationAcknowledged(notification)"><mat-icon>check</mat-icon></button>
                       </div>
                 </div>

                 <div *ngIf="notification.isEducationAppointmentReminder()">
                       <div class="notificationContent">
                         <mat-icon>book</mat-icon>

                         <p i18n="@@medical_appointment_reminder">La formazione "{{ notification.payload.record.description }}" per {{ notification.payload.employee.name }} &egrave; prevista per il giorno {{ notification.getExpectedDateFromPayload() | date }}
                         </p>
                       </div>
                       <div class="notificationActions">
                          <button mat-icon-button (click)="markNotificationAcknowledged(notification)"><mat-icon>check</mat-icon></button>
                       </div>
                 </div>
             </div>

             <div class="notification noNotifications"  *ngIf="isMenuShowable && notifications.length == 0" mat-menu-item [disableRipple]="true">
                 <div class="notificationContent">
                     <p i18n="@@no_notifications">Nessuna notifica</p>
                 </div>
             </div>

        </mat-menu>
    </span>
    <button mat-button *ngIf="isMenuShowable" (click)="logout()" class="authButton">
        <mat-icon>logout</mat-icon>
        <span i18n="@@logout">Esci</span>
    </button>
    <button mat-button routerLink="/auth" *ngIf="!business" class="authButton">
        <mat-icon>login</mat-icon>
        <span i18n="@@login">Entra</span>
    </button>

    <button mat-button routerLink="/registration/requests" *ngIf="!business" class="authButton">
        <mat-icon>app_registration</mat-icon>
        <span i18n="@@signup">Registrati</span>
    </button>
</mat-toolbar>

<div class="appContainer">
    <div class="sidenav" *ngIf="isMenuShowable">

        <div class="logo"></div>

        <div *ngIf="isSuperAdmin" class="businessSelection">
            <mat-select (selectionChange)="businessSelected($event)" [value]="bouncer.getInspectedBusiness()"
                        [compareWith]="compareEntities" placeholder="business da ispezionare">
                <mat-option *ngFor="let businessToInspect of businesses" [value]="businessToInspect">
                    {{ businessToInspect.name }}
                </mat-option>
            </mat-select>
        </div>

        <mat-divider></mat-divider>

        <a routerLink="/mybusiness" routerLinkActive="active">
            <mat-icon>business</mat-icon>
            <span i18n="@@business">Azienda</span></a>
        <div *ngIf="isInvoicingEnabled || isErpEnabled">
            <a routerLink="/clients" routerLinkActive="active">
                <mat-icon>people</mat-icon>
                <span i18n="@@clients">Clienti</span></a>
            <a routerLink="/suppliers" routerLinkActive="active">
                <mat-icon>local_shipping</mat-icon>
                <span i18n="@@suppliers">Fornitori</span></a>
            <a routerLink="/invoicing/quotes" routerLinkActive="active">
                <mat-icon>request_quote</mat-icon>
                <span i18n="@@quotes">Preventivi</span></a>
            <a>
                <mat-icon>receipt</mat-icon>
                <span i18n="@@invoicing">Fatturazione</span></a>
            <ul class="sublink">
                <li><a routerLink="/invoicing/outbound" routerLinkActive="active">
                    <mat-icon>arrow_forward</mat-icon>
                    <span i18n="@@outbound_invoicing">In uscita</span></a>
                </li>
                <li><a routerLink="/invoicing/inbound" routerLinkActive="active" style="white-space: nowrap">
                    <mat-icon>arrow_back</mat-icon>
                    <span i18n="@@inbound_invoicing">In entrata</span></a>
                </li>
            </ul>
        </div>
        <div *ngIf="isErpEnabled">
            <a routerLink="/accounting" routerLinkActive="active">
                <mat-icon>ssid_chart</mat-icon>
                <span i18n="@@accounting">Contabilità</span></a>
            <a routerLink="/employees" routerLinkActive="active">
                <mat-icon>badge</mat-icon>
                <span i18n="@@employees">Dipendenti</span></a>
            <a routerLink="/timesheet" routerLinkActive="active">
                <mat-icon>punch_clock</mat-icon>
                <span i18n="@@timesheet">Registro ore</span></a>
            <a routerLink="/requests" routerLinkActive="active" (mouseenter)="requestsTooltip.disable()">
                <mat-icon>chat_bubble</mat-icon>
                <span i18n="@@requests">Richieste</span>

                <infoTooltip #requestsTooltip></infoTooltip>
            </a>
        </div>
        <a routerLink="/data-import" routerLinkActive="active">
            <mat-icon>publish</mat-icon>
            <span>Importa dati</span></a>
        <a class="flex-column" style="align-items: center">
                <span>
                    <button mat-raised-button color="primary" routerLink="/mybusiness/showcase">{{showcaseText}}</button>
                </span>
        </a>


        <div *ngIf="isSuperAdmin">
            <a routerLink="/admin" routerLinkActive="active">
                <mat-icon>admin_panel_settings</mat-icon>
                <span>Admin</span></a>
        </div>
    </div>

    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>

<footer>
    <article class="businessDetails">
        <header><h1>Edilie.com</h1></header>
        <p i18n="@@edilie_slogan">La vera gestione edile semplificata</p>
    </article>
    <article class="rights" i18n="@@edilie_right">
        &copy;Edilie.com - Pragmatic Developers Ltd - VAT 399682027 - Tutti i diritti riservati
    </article>
    <article class="documentation">
        <header><h1 i18n="@@documentation">Documentazione</h1></header>
        <p><a routerLink="privacy-policy" i18n="@@privac_policy">Privacy Policy</a></p>
        <p><a routerLink="legal-notes" i18n="@@legal_notes">Note Legali</a></p>
    </article>

</footer>
