<article class="page">
    <div class="content">

        <mat-card class="infoCard" *ngIf="!sent">
            <mat-card-header>
                <mat-card-title i18n="@@order_summary">Riepilogo ordine</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form #f="ngForm" (ngSubmit)="onSubmit(f)">

                    <div>
                        <table>
                            <tbody>
                            <tr>
                                <td i18n="@@subscription">Abbonamento</td>
                                <td style="text-transform: capitalize">{{ promotion.period }}  <span i18n="@@free_trial">mese gratis</span> + {{contactRequest.package}}</td>
                            </tr>
                            <tr>
                                <td i18n="@@duration">Durata</td>
                                <td i18n="@@until_cancelled">Fino alla cancellazione</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <mat-divider></mat-divider>

                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@business">Azienda</mat-label>
                        <input name="business" #b="ngModel" matInput required
                               [(ngModel)]="contactRequest.name">
                        <mat-error *ngIf="b.hasError('required')">Campo obbligatorio</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@email">Email</mat-label>
                        <input name="email" matInput #e='ngModel' pattern="^[\w+\.\-]+@[\w\.\-]+$"
                               [(ngModel)]="contactRequest.email" required/>
                        <mat-error *ngIf="e.hasError('pattern')" i18n="@@invalid_email">Email non valida</mat-error>
                        <mat-error *ngIf="e.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                    </mat-form-field>

                    <p style="font-style: italic; text-align: center; margin-bottom: 2rem; margin-top: 1rem"  i18n="@@we_will_contact_you">Una volta confermanto l'ordine ti contatteremo per attivare il tuo account.</p>

                    <button mat-flat-button color="primary" i18n="@@confirm">Conferma</button>

                </form>
            </mat-card-content>
        </mat-card>

        <mat-card class="infoCard" *ngIf="sent">
            <mat-card-header>
                <mat-card-title  i18n="@@order_sent">Ordine inviato</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p  i18n="@@order_confirmed">L'ordine &egrave; stato inviato. Ti contatteremo al pi&ugrave; presto.</p>
            </mat-card-content>
        </mat-card>
    </div>
</article>
