import {Component, OnInit, ViewChild} from '@angular/core';
import {getCurrentBusiness} from "../utils";
import {BusinessRepository} from "../authentication/business.repository";
import {Business, PaymentPayslipInsight, PaymentsInsight, PaymentsInsightAggregation} from "../model/business";
import {EmployeeRepository} from "../employees/employee.repository";
import {first, forkJoin, map} from "rxjs";
import {MatTable} from "@angular/material/table";
import {Payment} from "../model/payment";
import * as moment from "moment";
import {SupplyRepository} from "../projects-management/supply/supply-repository.service";
import {BusinessAccountingRepository} from "./business-accounting-repository.service";
import {BalanceService} from "../business-administration/balance.service";
import {Employee} from "../model/employee";

@Component({
    selector: 'app-accounting',
    templateUrl: './accounting.component.html',
    styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {

    @ViewChild(MatTable) table: MatTable<Payment>;

    data: Row[] = [];
    displayedColumns: Column[]

    business: Business
    year: number = moment().year()

    private descriptionText: string;

    constructor(private repository: BusinessAccountingRepository, private supplyRepository: SupplyRepository, private businessRepository: BusinessRepository,
                private employeeRepository: EmployeeRepository, private balanceService: BalanceService) {

        this.descriptionText = $localize`:@@description:Descrizione`;
        this.displayedColumns = [
            {property: 'description', title: this.descriptionText},
            {property: '0', title: $localize`:@@january:Gennaio`},
            {property: '1', title: $localize`:@@february:Febbraio`},
            {property: '2', title: $localize`:@@march:Marzo`},
            {property: '3', title: $localize`:@@april:Aprile`},
            {property: '4', title: $localize`:@@may:Maggio`},
            {property: '5', title: $localize`:@@june:Giugno`},
            {property: '6', title: $localize`:@@july:Luglio`},
            {property: '7', title: $localize`:@@august:Agosto`},
            {property: '8', title: $localize`:@@september:Settembre`},
            {property: '9', title: $localize`:@@october:Ottobre`},
            {property: '10', title: $localize`:@@november:Novembre`},
            {property: '11', title: $localize`:@@dicember:Dicembre`},
            {property: 'total', title: $localize`:@@total:Totale`},
        ];

    }

    ngOnInit(): void {
        let year = this.year

        let revenueObservable = this.balanceService.getRevenueBalanceByYear(year).pipe(map(balance => {
            let revenues = new PaymentPayslipInsight(balance.payments, year);
            let unpaidRevenues = new PaymentPayslipInsight(balance.payments.filter(value => !value.isPaid()), year);
            return {revenues, unpaidRevenues};
        }));

        let suppliesExpenses = this.balanceService.getExpensesBalanceByYear(year).pipe(map(balance => {
            let suppliesExpenses = new PaymentPayslipInsight(balance.payments, year);
            let unpaidSuppliesExpenses = new PaymentPayslipInsight(balance.payments.filter(value => !value.isPaid()), year);
            return {suppliesExpenses, unpaidSuppliesExpenses};
        }));

        let businessObservable = this.businessRepository.findById(getCurrentBusiness().id).pipe(first());
        let suppliesObservable = this.supplyRepository.getAll().pipe(first());  // TODO filter the supplies by year
        let employeesObservable = this.employeeRepository.getAll().pipe(first());

        forkJoin([businessObservable, suppliesObservable, employeesObservable, revenueObservable, suppliesExpenses]).pipe(first())
            .subscribe(([business, supplies, employees, revenueInsights, supplyExpensesInsights]) => {
                this.business = business!

                this.data = []
                let revenues = revenueInsights.revenues
                let unpaidRevenues = revenueInsights.unpaidRevenues

                let supplierExpenses = supplyExpensesInsights.unpaidSuppliesExpenses

                let {payslipInsight, unpaidPayslipInsight} = this.getPayslipsInsights(employees, year)
                let unpaidExpenses = new PaymentPayslipInsight([supplyExpensesInsights.suppliesExpenses.payments, unpaidPayslipInsight.payments].flat(), year, false )


                let totalExpenses = new PaymentsInsightAggregation([supplierExpenses, payslipInsight]);
                let net = new PaymentsInsightAggregation([revenues, totalExpenses]);
                let outboundVatAggregation = new PaymentsInsightAggregation([supplierExpenses]);
                let vatTotalAggregation = new PaymentsInsightAggregation([revenues, supplierExpenses]);

                this.data.push(new Row(revenues, 'amount', $localize`:@@revenues:Totale ricavi`))
                this.data.push(new Row(totalExpenses, 'amount', $localize`:@@total_expenses:Totale costi`))
                // this.data.push(new Row(accountantsExpenses, 'amount', $localize `:@@accountant_expenses:↳ Costo commercialista`, true))
                this.data.push(new Row(supplierExpenses, 'amount', $localize`:@@supplier_expenses:↳ Costo forniture e servizi`, true))
                this.data.push(new Row(payslipInsight, 'amount', $localize`:@@salaries:↳ Stipendi`, true))
                this.data.push(new Row(net, 'amount', $localize`:@@net:Utile`, false, true))
                this.data.push(new Row(revenues, 'vat', $localize`:@@vat:IVA a debito`))
                this.data.push(new Row(outboundVatAggregation, 'vat', $localize`:@@credit_vat:IVA a credito`))
                this.data.push(new Row(vatTotalAggregation, 'vat', $localize`:@@total_vat:IVA totale`, false, true))
                this.data.push(new Row(unpaidExpenses, 'amount', $localize`:@@unpaid_expenses:Pagamenti non effettuati`, false, false, true))
                this.data.push(new Row(unpaidRevenues, 'amount', $localize`:@@unpaid_revenues:Pagamenti non riscossi`, false, false, true))

                this.table.renderRows()

            })
    }

    get columnIds() {
        return this.displayedColumns.map(value => value.title)
    }

    getCellValue(row: Row, column: Column) {
        if (column.title.toLowerCase().includes(this.descriptionText.toLowerCase())) return row.description
        if (row.property == 'amount') {
            return row.value[column.property].amount.toFixed(2)
        }
        if (row.property == 'vat') return row.value[column.property].vat.toFixed(2)
    }

    isBadCell(row: Row, column: Column) {
        if (!row.highlighted && !row.unpaid) return false

        let cellValue = this.getCellValue(row, column)
        // @ts-ignore
        if (!isNaN(cellValue)) {
            return row.unpaid ? cellValue > 0 : cellValue < 0
        }

        return false
    }

    isBadRow(row: Row) {
        return row.unpaid && row.value.total.amount > 0
    }

    onYearSelected(year: number) {
        this.year = year
        this.ngOnInit()
    }

    getClass(column: Column) {
        return column.title.toLowerCase().includes(this.descriptionText.toLowerCase()) ? 'descriptionColumn' : 'monthColumn';
    }

    getPayslipsInsights(employees: Employee[], year: number) {
        let payslips = employees.map(value => value.getPayslipsByYear(year)).flat()

        let payslipInsight = new PaymentPayslipInsight(payslips, year, true);
        let unpaidPayslipInsight = new PaymentPayslipInsight(payslips.filter(value => !value.isPaid()), year, false);

        return {payslipInsight, unpaidPayslipInsight}
    }
}

export interface Column {
    property: string
    title: string
}

export class Row {
    value: PaymentsInsight
    property: string
    description: string

    nested: boolean
    highlighted: boolean
    unpaid: boolean

    constructor(value: PaymentsInsight, property: string, description: string,
                nested: boolean = false, highlighted: boolean = false, unpaid: boolean = false) {
        this.value = value;
        this.property = property;
        this.description = description;
        this.nested = nested;
        this.highlighted = highlighted;
        this.unpaid = unpaid
    }
}
