import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'inform-dialog',
    templateUrl: './inform-dialog.component.html',
    styleUrls: ['./inform-dialog.component.scss']
})
export class InformDialogComponent {

    title: string
    message: string

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data.title
        this.message = data.message
    }

}
