import {Injectable} from "@angular/core";
import {Repository} from "../../persistence/repository";
import {
    collection,
    collectionData,
    DocumentData,
    Firestore,
    FirestoreDataConverter,
    orderBy,
    query,
    QueryDocumentSnapshot,
    SnapshotOptions
} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import * as moment from "moment";
import {Moment} from "moment";
import {Entity} from "../../model/entity";
import {FirestoreWrapper} from "../../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class InboundSdiMessagesRepository extends Repository<InboundSdiMessage> {


    constructor(firestore: FirestoreWrapper) {
        super(firestore, new InboundSdiMessageConverter())
    }

    ngOnInit(): void {
    }

    override getCollectionReference() {
        return  this.firestoreWrapper.collection( "inboundSdiMessages").withConverter(this.converter);
    }

    override getAll(): Observable<InboundSdiMessage[]> {
        return collectionData(query(
            this.getCollectionReference(),
            orderBy("receivedTime", "desc"),
        ));
    }

}

class InboundSdiMessageConverter implements FirestoreDataConverter<InboundSdiMessage> {

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): InboundSdiMessage {
        const data = snapshot.data(options)!
        let sdiInbound = new InboundSdiMessage();
        sdiInbound.id = data['id']
        sdiInbound.cades = data['cades']
        sdiInbound.xades = data['xades']
        sdiInbound.fileName = data['fileName'] || null
        sdiInbound.filePath = data['filePath'] || null
        sdiInbound.businessId = data['businessId'] || null
        sdiInbound.businessName = data['businessName'] || null
        sdiInbound.invoiceId = data['invoiceId'] || null
        sdiInbound.receivedTime =  moment(data['receivedTime'].toDate())
        return sdiInbound
    }

    toFirestore(business: InboundSdiMessage): DocumentData {
        throw new Error("not possible")
    }
}


export class InboundSdiMessage extends Entity {
    cades: boolean
    xades: boolean
    fileName: string | null
    filePath: string | null
    businessId: string | null
    businessName: string | null
    invoiceId: string | null
    receivedTime: Moment

    hasInvoiceDetails() {
        return this.businessId && this.invoiceId
    }
}
