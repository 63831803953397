import {Component, OnInit} from '@angular/core';
import {ContactRequest, ContactRequestRepository} from "../../welcome/contact-request.repository";
import {map, Observable} from "rxjs";

@Component({
    selector: 'contact-requests',
    templateUrl: './contact-requests.component.html',
    styleUrls: ['./contact-requests.component.scss']
})
export class ContactRequestsComponent implements OnInit {

    displayedColumns: string[] = [
        "id",
        "name",
        "email",
        "package",
        'description',
        "date",
    ];

    constructor(private repository: ContactRequestRepository) {
    }

    requests: Observable<ContactRequest[]>;

    ngOnInit(): void {
        this.requests = this.repository.getAll().pipe(map(value => value.sort((a, b) => a.date.isBefore(b.date) ? 1 : -1)));
    }

}
