import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {inject} from "@angular/core";
import {BusinessRepository} from "../authentication/business.repository";
import {getCurrentBusiness, makeSupply} from "../utils";
import {Business, Showcase} from "../model/business";
import {first, forkJoin, map, mergeMap, Observable, of, take} from "rxjs";
import {Client} from "../model/client";
import {ClientRepository} from "../clients-management/client.repository";
import {Repository} from "../persistence/repository";
import {Project} from "../model/project";
import {SupplyRepository} from "../projects-management/supply/supply-repository.service";
import {Supplier, Supply} from "../model/supply";
import {SupplierRepository} from "../suppliers/supplier.repository";
import {Employee} from "../model/employee";
import {EmployeeRepository} from "../employees/employee.repository";
import {Invoice, Quote} from "../model/invoice";
import {QuotesRepository} from "../invoicing/quotes/quotes.repository";
import {FiscalDocumentService} from "../invoicing/fiscal-document.service";
import {OutboundInvoicesRepository} from "../invoicing/outbound/outbound-invoices.repository";
import {InboundInvoicesRepository} from "../invoicing/inbound/inbound-invoices.repository";
import {Entity} from "../model/entity";
import {CompositeShowcaseRepository, ShowcaseRepository} from "../showcases/business-showcases.component";
import {ProjectRequest} from "../model/project.request";
import {ProjectRequestRepository} from "../project-requests/project-requests.repository";

export const businessResolver: ResolveFn<Business> = (route, state) => {
    let businessRepository = inject(BusinessRepository);
    return businessRepository.findById(getCurrentBusiness().id).pipe(map(business => {
        if (!business) throw Error("not_found")

        return business
    }))
};

export const currentBusinessShowcaseResolver: ResolveFn<Showcase> = (route, state) => {
    let showcaseRepository = inject(ShowcaseRepository);
    return showcaseRepository.getAll().pipe(map(showcases => {
        if (!(!showcases || showcases.length == 0)) return showcases[0];


        let currentBusiness = getCurrentBusiness();
        let showcase = new Showcase();
        showcase.businessId = currentBusiness.id
        showcase.businessName = currentBusiness.name
        return showcase;
    }))
};

export const clientResolver: ResolveFn<Client> = (route, state) => {
    let repository = inject(ClientRepository);
    let clientId = route.paramMap.get('id');

    let observable: Observable<Client> = forkJoin([
        resolverByEntityIdParam<Client>(route, state, repository, () => new Client()),
        repository.getProjects(clientId!).pipe(take(1))
    ]).pipe(map(([client, projects]) => {
        client.projects = projects
        return client
    }));

    return observable
};


export const supplyPageDataResolver: ResolveFn<SupplyPageData> = (route, state) => {
    let queryParams = route.queryParams
    let supplyObservable = resolverByEntityIdParam<Supply>(route, state, inject(SupplyRepository), () => makeSupply(queryParams['type']))
        .pipe(map(supply => {
            supply.projectId = supply.projectId || queryParams['projectId'] || null
            supply.clientId = supply.clientId || queryParams['clientId'] || null
            return supply
        }))

    return forkJoin([
        supplyObservable,
        inject(SupplierRepository).getAll().pipe(first()),
        inject(BusinessRepository).getAllProjects().pipe(first())
    ]).pipe(map(([supply, suppliers, projects]) => {
        if (queryParams['supplierId']) supply.supplier = suppliers.find(value => value.id == queryParams['supplierId'])!

        return {supply, projects, suppliers}
    }))

};

export const projectResolver: ResolveFn<Project> = (route, state) => {
    let clientId = route.paramMap.get('clientId');
    let id = route.paramMap.get('id');

    if (!id || !clientId) {
        throw new Error("params missing")
    } else if (id === 'new') {
        let newProject = new Project()
        newProject.businessId = getCurrentBusiness().id
        newProject.clientId = clientId!
        return of(newProject)
    }

    let supplyRepository = inject(SupplyRepository);

    return inject(ClientRepository).findProjectById(clientId, id).pipe(map(entity => {
        if (!entity) throw Error("not_found")

        return entity
    }), mergeMap((project: Project) => {
        return supplyRepository.findByProject(project).pipe(take(1), map(value => {
            project.supplies.push(...value)
            return project
        }));
    }))

};

export const showcaseResolver: ResolveFn<Showcase> = (route, state) => {
    return resolverByEntityIdParam<Showcase>(route, state, inject(CompositeShowcaseRepository), () => new Showcase())
};

export const employeeResolver: ResolveFn<Employee> = (route, state) => {
    return resolverByEntityIdParam<Employee>(route, state, inject(EmployeeRepository), () => new Employee())
};

export const supplierResolver: ResolveFn<Supplier> = (route, state) => {
    return resolverByEntityIdParam<Supplier>(route, state, inject(SupplierRepository), () => new Supplier())
};

export const outboundInvoiceResolver: ResolveFn<Invoice> = (route, state) => {
    let fiscalDocumentService = inject(FiscalDocumentService);

    return resolverByEntityIdParam(route, state, inject(OutboundInvoicesRepository), () => fiscalDocumentService.makeNewOutboundInvoice(), (quoteId: string) => fiscalDocumentService.copyQuoteIntoNewInvoice(quoteId))
};

export const inboundInvoiceResolver: ResolveFn<Invoice> = (route, state) => {
    let fiscalDocumentService = inject(FiscalDocumentService);
    let query = route.queryParams

    return resolverByEntityIdParam<Invoice>(route, state, inject(InboundInvoicesRepository), () => fiscalDocumentService.makeNewInboundInvoice(query.type, query.id, query.projectId, query.supplierId))
};

export const quoteResolver: ResolveFn<Quote> = (route, state) => {
    let fiscalDocumentService = inject(FiscalDocumentService);

    return resolverByEntityIdParam(route, state, inject(QuotesRepository), () => fiscalDocumentService.makeNewQuote(), (quoteId: string) => fiscalDocumentService.copyQuote(quoteId))
};

export const projectRequestResolver: ResolveFn<ProjectRequest | null> = (route, state) => {
    // @ts-ignore
    return resolverByEntityIdParam<ProjectRequest | null>(route, state, inject(ProjectRequestRepository), () => null, () => {
        throw new Error("onCopy not implemented")
    }, 'projectRequest')
};


const resolverByEntityIdParam = <E extends Entity>(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, repository: Repository<E>,
                                                   onNotFound: () => E | Observable<E>, onCopy: (id: string) => E | Observable<E> = () => {
        throw new Error("onCopy not implemented")
    }, paramName: string = 'id') => {
    let id = route.paramMap.get(paramName) || route.queryParamMap.get(paramName)
    if (!id || id === 'new') {
        let value = route.queryParams.toCopy ? onCopy(route.queryParams.toCopy) : onNotFound()
        return wrapIntoObservableIfNeeded(value)
    }

    return repository.findById(id!).pipe(map(entity => {
        if (!entity) throw Error("not_found")

        return entity
    }))
}

function wrapIntoObservableIfNeeded<E>(value: Observable<E> | E) {
    return value instanceof Observable ? value : of(value);
}

export class SupplyPageData {
    supply: Supply
    projects: Project[]
    suppliers: Supplier[]
}


