import {Employee} from "./employee";
import {Supplier} from "./supply";
import * as moment from "moment";
import {Moment} from "moment";
import {getMonthName, momentDate} from "../utils";
import {Project} from "./project";
import {Entity} from "./entity";

export class Timesheet extends Entity {
    year: number
    month: number
    rows: TimesheetRow[] = []
    weather: WeatherRow

    constructor(year: number, month: number) {
        super();
        this.year = year;
        this.month = month;

        this.weather = new WeatherRow(this.daysInMonth(), this.year, this.month)
    }

    daysInMonth() {
        return moment(`${this.year}-${this.month + 1}`, 'YYYY-MM').daysInMonth();
    }

    getMonthName() {
        return getMonthName(this.month)
    }

    findRow(subject: Employee | Supplier, type: string) {
        return this.rows.find(row => {
            return row.subject.type() == type && row.subject.id == subject.id;
        })
    }

    findAllWorkedDaysByProject(project: Project): {day: WorkingDay, times: TimesByProject, subject: Employee | Supplier}[] {
        return this.rows.flatMap(row => row.findWorkedDaysByProject(project)
            .map(value => {
            return {...value, subject: row.subject}
        })).sort((a, b) => a.day.day - b.day.day);
    }

    // TODO it might be that there is always a row for each project, so this method might need an additional filter over total
    findRowsByProject(project: Project): TimesheetRow[] {
        return this.rows.filter(row => {
            return row.containsProject(project)
        })
    }

    weatherForDay(day: number) {
        return this.weather.days.find(value => value.day == day)!
    }

    weatherSunnyOnDay(day: number) {
        return this.weatherForDay(day).isSunny()
    }

    weatherRainyOnDay(day: number) {
        return this.weatherForDay(day).isRainy()
    }

    sortByType() {
        this.rows.sort((a, b) => {
            if (a.type() === b.type() && a.subject.name) {
                return a.subject.name.localeCompare(b.subject.name)
            }

            return a.isSupplier() ? 1 : -1;

        })
    }

}

export class WeatherRow {
    days: WeatherDay[] = []

    constructor(days: number, year: number, month: number) {
        for (let day = 1; day <= days; day++) {
            let weatherDay = new WeatherDay();
            weatherDay.day = day
            weatherDay.date = momentDate(year, month, day)
            this.days.push(weatherDay)
        }
    }

    getTotalHoursOfRain() {
        return this.days.map(value => value.hoursOfRain).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    }
}

export class WeatherDay {
    date: Moment
    day: number
    hoursOfRain: number = 0

    isSunny() {
        return this.hoursOfRain === 0
    }

    isRainy() {
        return this.hoursOfRain > 0
    }
}

export class TimesheetRow {
    subject: Employee | Supplier
    days: WorkingDay[] = []

    get total() {
        return this.days.map(day => day.total).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    }

    getTotalByProject(project: Project) {
        return this.days.map(value => value.getHoursByProject(project)).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    }

    getTotalForDay(day: number) {
        return this.getDay(day)!.total
    }

    getDay(day: number): WorkingDay {
        let workingDay = this.days.find(value => value.day == day);
        if (!workingDay) {
            throw new Error("day not found")
        }

        return workingDay;
    }

    hasDay(dayNumber: number) {
        return this.days.find(value => value.day == dayNumber)
    }

    isAbsentOnDay(day: number): boolean {
        return this.getDay(day).isAbsent()
    }

    isSickOnDay(day: number): boolean {
        return this.getDay(day).sickLeave
    }

    isOnLeaveOnDay(day: number): boolean {
        return this.getDay(day).leave
    }

    isEmployee() {
        return this.subject.type() == 'employee'
    }

    isSupplier() {
        return this.subject.type() == 'supplier' || this.subject.type() == 'medical_center' || this.subject.type() == 'educational_center'
    }

    type() {
        return this.subject.type;
    }

    containsProject(project: Project): boolean {
        return !!this.days.find(day => day.workedTimes.timesByProject.find(times => times.project.id == project.id));
    }

    findWorkedDaysByProject(project: Project): {day: WorkingDay, times: TimesByProject}[] {
        // @ts-ignore
        return this.days.filter(day => day.workedTimes.timesByProject.find(times => times.project.id == project.id))
            .map(day => {
                let timesByProject = day.workedTimes.timesByProject.find(times => times.project.id == project.id);
                if (!timesByProject) return null;

                return {
                    day: day,
                    times: timesByProject
                }
            }).filter(value => value != null)


    }
}

export class WorkingDay {
    date: Moment
    day: number
    workedTimes: WorkedTimes = new WorkedTimes()
    sickLeave: boolean
    leave: boolean

    get total() {
        return this.workedTimes.total
    }

    getHoursByProject(project: Project) {
        return this.workedTimes.getHoursByProject(project)
    }

    isAbsent() {
        return this.sickLeave || this.leave
    }

    sortWorkedTimes() {
        this.workedTimes.sortTimesByProject()
    }
}

export class WorkedTimes {
    timesByProject: TimesByProject[] = []

    get total() {
        return this.timesByProject.reduce((previousValue, currentValue) => previousValue + currentValue.hours, 0)
    }

    getHoursByProject(project: Project) {
        return this.timesByProject.find(value => value.project.id == project.id)?.hours || 0
    }

    sortTimesByProject() {
        this.timesByProject.sort((a, b) => a.project.id.localeCompare(b.project.id))
    }
}

export class TimesByProject {
    project: { name: string, id: string }
    hours: number = 0
    description: string | null = null
}
