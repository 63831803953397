<h1 mat-dialog-title>{{workingDay.date.day()}} {{getMonthName(workingDay.date.month())}}</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <mat-error *ngIf="this.workingDay.total > 8" style="text-align: right"  i18n="@@more_than_8_hours">ATTENZIONE: Pi&ugrave; di 8 ore rendicontate</mat-error>
        <table mat-table [dataSource]="timesByProject">
            <ng-container matColumnDef="project">
                <th mat-header-cell *matHeaderCellDef  i18n="@@project">Progetto</th>
                <td mat-cell *matCellDef="let row">
                    {{row.project.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="hours">
                <th mat-header-cell *matHeaderCellDef i18n="@@hours">Ore</th>
                <td mat-cell *matCellDef="let row; let i = index">
                    <mat-form-field class="hours">
                        <input [required]="!!row.description" type="number" name="hours-{{row.project.id}}" #hours="ngModel" matInput
                               [(ngModel)]="row.hours" [min]="!!row.description ? 1 : 0" max="24"/>
                        <mat-error *ngIf="hours.hasError('max')">Non pu&ograve; essere pi&ugrave; di 24 ore</mat-error>
                        <mat-error style="position: relative" *ngIf="hours.hasError('min') && !!row.description"  i18n="@@less_than_1h">Non pu&ograve; essere meno di 1 ore</mat-error>
                        <mat-error style="position: relative" *ngIf="hours.hasError('min') && !row.description"  i18n="@@negative_hours">Non pu&ograve; essere meno di 0 ore</mat-error>
                        <mat-error *ngIf="hours.hasError('required')"  i18n="@@enter_hours">Specificare ore attivit&agrave;</mat-error>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef  i18n="@@activity">Attivit&agrave;</th>
                <td mat-cell *matCellDef="let row; let i = index">
                    <mat-form-field class="description">
                        <input name="description-{{row.project.id}}" #description="ngModel"
                               matInput [(ngModel)]="row.description" />
                    </mat-form-field>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button  [disabled]="form.invalid" [mat-dialog-close]="true" i18n="@@close">Chiudi</button>
</div>

