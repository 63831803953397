<article class="page">

    <div class="content">

        <mat-card class="infoCard login">
            <mat-card-header>
                <mat-icon mat-card-avatar>login</mat-icon>
                <mat-card-title i18n="@@logging_in">Autenticazione</mat-card-title>
            </mat-card-header>
            <form (submit)="signIn($event, signinModel)" class="flex-column">

                <mat-card-content>
                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@email">Email</mat-label>
                        <input name="username" pattern="^[\w+\.\-]+@[\w\.\-]+$" matInput #username='ngModel' [(ngModel)]="signinModel.username" required/>
                        <mat-error *ngIf="username.hasError('pattern')" i18n="@@invalid_email">Email non valida</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@password">Password</mat-label>
                        <input matInput #password name="password" type="password"  [(ngModel)]="signinModel.password"/>
                        <button type="button" mat-icon-button matSuffix (click)="togglePassword(password)">
                            <mat-icon *ngIf="password.type=='password'">visibility</mat-icon>
                            <mat-icon *ngIf="password.type=='text'">visibility_off</mat-icon>
                        </button>
                    </mat-form-field>
                </mat-card-content>

                <mat-card-actions >
                    <button mat-button name="loginSubmitButton" type="submit" i18n="@@login">Entra</button>
                </mat-card-actions>
            </form>
        </mat-card>
    </div>

</article>
