import * as moment from "moment";
import {Moment} from "moment";
import {RecordsProfile} from "./medicalRecord";
import {Document} from "./document";
import {Entity} from "./entity";


export class Payslip extends Entity {
    number: string
    month: number
    year: number = moment().year()
    payslipDocument: Document | null
    confirmedPayslipDocument: Document | null
    amount: number;
    paidDate: Moment | null;
    receipt: Document | null;
    notes: string | null

    override getDocuments(): Document[] {
        return [this.payslipDocument!, this.confirmedPayslipDocument!, this.receipt!].filter(value => !!value)
    }

    isPaid(): boolean {
        return this.paidDate != null
    }

    get dueDate() {
        return this.paidDate
    }

    get vatAmount() {
        return 0
    }

    // @ts-ignore
    override get id() {
        return this.number
    }

    override set id(s) {
    }

    getAccountingMonth() {
        return this.month - 1
    }

    getAccountingYear() {
        return this.year
    }

}

export class Payroll extends RecordsProfile<Payslip> {
    isEmpty() {
        return this.records.length == 0
    }

    size() {
        return this.records.length
    }

    payslipsByYear(year: number) {
        return this.records
            .filter(value => value.year === year)
    }
}
