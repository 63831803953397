<mat-card class="infoCard">
    <mat-card-header>
        <mat-icon mat-card-avatar>payments</mat-icon>

        <div class="headerWithCommand">
            <mat-card-title i18n="@@payslips">Buste paga</mat-card-title>
            <span class="yearSelector" *ngIf="selectedYear | async as selected">
                <mat-icon (click)="moveTimeLeft()">arrow_left</mat-icon>{{ selected }}<mat-icon (click)="moveTimeRight()">arrow_right</mat-icon>
            </span>
        </div>
    </mat-card-header>

    <mat-card-content>

        <table mat-table [dataSource]="filteredList" [hidden]="hideTable()">

            <ng-container matColumnDef="year">
                <th mat-header-cell *matHeaderCellDef i18n="@@year">Anno</th>
                <td mat-cell *matCellDef="let payslip">{{ payslip.year }}</td>
            </ng-container>

            <ng-container matColumnDef="month">
                <th mat-header-cell *matHeaderCellDef i18n="@@month">Mese</th>
                <td mat-cell *matCellDef="let payslip">{{ numberToMonth(payslip.month) }}</td>
            </ng-container>


            <ng-container matColumnDef="amount">
                <th style="text-align: right" mat-header-cell *matHeaderCellDef i18n="@@gross">Lordo</th>
                <td style="text-align: right" mat-cell
                    *matCellDef="let payslip"> {{ payslip.amount | formatCurrency }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let payslip">
                    <button mat-icon-button type="button" (click)="openEditDialog($event, payslip)">
                        <mat-icon class="mat-24">edit</mat-icon>
                    </button>
                    <button mat-icon-button type="button" (click)="remove($event, payslip)">
                        <mat-icon class="mat-24">delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="hideTable()">
            <p i18n="@@no_payslip">Nessuna busta paga per l&apos;anno selezionato </p>
        </div>
    </mat-card-content>

    <mat-card-actions>
        <button mat-button color="primary" name="openFormButton" type="button" (click)="openNewDialog()" i18n="@@add">Aggiungi</button>
    </mat-card-actions>
</mat-card>
