import {CollectionReference, FirestoreDataConverter, Transaction,} from "@angular/fire/firestore";
import {defer, Observable, Subject} from "rxjs";
import {generateId} from "../utils";
import {Entity} from "../model/entity";
import {FirestoreWrapper} from "./firestoreWrapper";

export abstract class Repository<Type extends Entity> {

    private deletedObservable: Subject<Type>
    private savedObservable: Subject<Type>

    constructor(public firestoreWrapper: FirestoreWrapper, public converter: FirestoreDataConverter<Type>, public configuration?: any) {
        this.initObservables();
    }

    protected initObservables() {
        this.deletedObservable = new Subject();
        this.savedObservable = new Subject();
    }

    public abstract getCollectionReference(): CollectionReference<Type>

    save(entity: Type): Promise<Type> {
        if (!entity.id) entity.id = this.getId()

        return this.firestoreWrapper.setDoc(this.getReference(entity), entity).then(() => entity)
    }

    getReference(entity: Type, collectionReference: CollectionReference<Type> = this.getCollectionReference()) {
        return this.firestoreWrapper.doc(collectionReference, entity.id);
    }

    getId(collectionReference: CollectionReference<Type> = this.getCollectionReference()): string {
        return this.firestoreWrapper.docId(collectionReference)
    }

    findById(id: string, collectionReference: CollectionReference<Type> = this.getCollectionReference()): Observable<Type | undefined> {
        let documentReference = this.firestoreWrapper.doc<Type>(collectionReference, id);
        return defer(() => this.firestoreWrapper.getDoc<Type>(documentReference).then(value => value.data()))
    }

    getAll(collectionReference: CollectionReference<Type> = this.getCollectionReference()): Observable<Type[]> {
        return this.firestoreWrapper.collectionData(collectionReference)
    }

    delete(entity: Type) {
        this.firestoreWrapper.deleteDoc(this.getReference(entity)).then(() => this.notifyDeletedListeners(entity))
    }

    private notifyDeletedListeners(entity: Type) {
        this.deletedObservable.next(entity)
    }

    transactionalSave(transaction: Transaction, entity: any, collectionReference: CollectionReference<Type> = this.getCollectionReference()) {
        if (entity.id) throw new Error("Entity cannot be saved, id value already present")

        entity.id = generateId()
        let docRef = this.firestoreWrapper.doc(collectionReference, entity.id).withConverter(this.converter)

        transaction.set(docRef, entity);
    }

    async transactionalUpdate(transaction: Transaction, id: string, part: any, collectionReference: CollectionReference<Type> = this.getCollectionReference()) {
        let docRef = this.firestoreWrapper.doc(collectionReference, id).withConverter(this.converter)
        let docData = await transaction.get(docRef);
        let currentVersion = docData.get('version') || 0;

        if (currentVersion + 1 != part.version) {
            alert("Impossibile salvare, qualcun'altro ha modificato gli stessi dati. Ricaricare la pagina")
            console.log("impossibile salvare, versione, versione in db", part.version, currentVersion)

            throw Error("Version mismatch")
        }

        part.id = id
        transaction.update(docRef, this.converter.toFirestore(part));
    }

    async transaction(callback: (transaction: Transaction) => Promise<unknown>) {
        await this.firestoreWrapper.runTransaction(callback)
    }

}
