import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountingComponent} from "./accounting/accounting.component";
import {ClientsComponent} from "./clients-management/clients/clients.component";
import {ClientComponent} from "./clients-management/client/client.component";
import {ProjectRepositoryResolver} from "./projects-management/project-repository-resolver.service";
import {ProjectComponent} from "./projects-management/project/project.component";
import {EmployeesComponent} from "./employees/employees/employees.component";
import {EmployeeComponent} from "./employees/employee/employee.component";
import {SuppliersComponent} from "./suppliers/suppliers/suppliers.component";
import {SupplierComponent} from "./suppliers/supplier/supplier.component";
import {SupplyComponent} from "./projects-management/supply/supply.component";
import {EducationComponent} from "./employees/education/education.component";
import {MedicalProfileComponent} from "./employees/medical-profile/medical-profile.component";
import {PayrollComponent} from "./employees/payroll/payroll.component";
import {AuthenticationComponent} from "./authentication/authentication.component";
import {AuthGuard} from "./auth.guard";
import {VerificationComponent} from "./verification/verification.component";
import {BusinessAdministrationComponent} from "./business-administration/business-administration.component";
import {InboundInvoicesComponent} from "./invoicing/inbound/invoices/inbound-invoices.component";
import {OutboundInvoicesComponent} from "./invoicing/outbound/invoices/outbound-invoices.component";
import {OutboundInvoiceComponent} from "./invoicing/outbound/invoice/outbound-invoice.component";
import {InboundInvoiceComponent} from "./invoicing/inbound/invoice/inbound-invoice.component";
import {TimesheetComponent} from "./timesheet/timesheet.component";
import {AdminComponent} from "./admin/admin.component";
import {WelcomeComponent} from "./welcome/welcome.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {LegalNotesComponent} from "./legal-notes/legal-notes.component";
import {QuotesComponent} from "./invoicing/quotes/quotes.component";
import {QuoteComponent} from "./invoicing/quotes/quote/quote.component";
import {CheckoutComponent} from "./checkout/checkout.component";
import {DemoComponent} from "./demo/demo.component";
import {PriceListComponent} from "./price-lists/price-list/price-list.component";
import {PriceListsComponent} from "./price-lists/price-lists/price-lists.component";
import {InboundMessageErrorsComponent} from "./admin/inbound-message-errors/inbound-message-errors.component";
import {InboundMessagesComponent} from "./admin/inbound-messages/inbound-messages.component";
import {ContactRequestsComponent} from "./admin/contact-requests/contact-requests.component";
import {OutboundMessagesComponent} from "./admin/outbound-messages/outbound-messages.component";
import {CanLeaveFormGuard} from "./can-leave-form.guard";
import {
    businessResolver,
    clientResolver,
    currentBusinessShowcaseResolver,
    employeeResolver,
    inboundInvoiceResolver,
    outboundInvoiceResolver, projectRequestResolver,
    projectResolver,
    quoteResolver,
    showcaseResolver,
    supplierResolver,
    supplyPageDataResolver
} from "./components/resolvers";
import {DataImportComponent} from "./data-import/data-import.component";
import {ProjectRequestsComponent} from "./project-requests/project-requests.component";
import {ProjectRequestsComponent as AdminProjectRequests} from "./admin/project-requests/project-requests.component";
import {ProjectRequestComponent as AdminProjectRequest} from "./admin/project-requests/project-request/project-request.component";
import {ProjectRequestComponent} from "./project-requests/project-request/project-request.component";
import {ShowcaseComponent} from "./business-administration/showcase/showcase.component";
import {BusinessShowcasesComponent} from "./showcases/business-showcases.component";
import {BusinessShowcaseComponent} from "./showcases/business-showcase/business-showcase.component";
import {BusinessContactRequestComponent} from "./admin/business-contact-requests/business-contact-request/business-contact-request.component";
import {FakeShowcasesComponent} from "./admin/showcases/fake-showcases.component";
import {FakeShowcaseComponent} from "./admin/showcases/fakeShowcase/fake-showcase/fake-showcase.component";
import {RegistrationComponent} from "./authentication/registration/registration.component";
import {
    OnRequestRegistrationComponent
} from "./authentication/on-request-registration/on-request-registration.component";

const routes: Routes = [
    {
        path: 'welcome', component: WelcomeComponent, title: 'Welcome',
    },
    {
        path: 'business/showcase', component: BusinessShowcasesComponent, title: 'Showcase',
    },
    {
        path: 'business/showcase/:id', component: BusinessShowcaseComponent, title: 'Showcase', resolve: {showcase: showcaseResolver}
    },
    {
        path: 'registration/edilie', component: RegistrationComponent, title: 'Registration'
    },
    {
        path: 'registration/requests', component: OnRequestRegistrationComponent, title: 'Registration'
    },
    {
        path: 'checkout/:package', component: CheckoutComponent, title: 'Checkout', pathMatch: 'full'
    },
    {
        path: 'demo', component: DemoComponent, title: 'Demo'
    },
    {
        path: 'privacy-policy', component: PrivacyPolicyComponent, title: 'Privacy'
    },
    {
        path: 'legal-notes', component: LegalNotesComponent, title: 'Legal Notes'
    },
    {
        path: 'auth', component: AuthenticationComponent, title: 'Authentication'
    },
    {
        path: 'verification', component: VerificationComponent,
    },
    {
        path: '', redirectTo: '/clients', pathMatch: 'full'
    },
    {
        path: '', canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
            {path: 'mybusiness', component: BusinessAdministrationComponent, canDeactivate: [CanLeaveFormGuard], resolve: {business: businessResolver}},
            {path: 'mybusiness/showcase', component: ShowcaseComponent, canDeactivate: [CanLeaveFormGuard], resolve: {showcase: currentBusinessShowcaseResolver}},
            {path: 'accounting', component: AccountingComponent,},
            {
                path: 'supplies/:id',
                component: SupplyComponent, canDeactivate: [CanLeaveFormGuard], resolve: {supplyPageData: supplyPageDataResolver}
            },
            {path: 'clients/:id', component: ClientComponent, canDeactivate: [CanLeaveFormGuard], resolve: {client: clientResolver}},
            {path: 'clients', component: ClientsComponent},
            {
                path: 'clients/:clientId/projects/:id',
                component: ProjectComponent,
                resolve: {repository: ProjectRepositoryResolver, project: projectResolver},
                canDeactivate: [CanLeaveFormGuard]
            },
            {path: 'employees/:id/education-profile', component: EducationComponent, pathMatch: 'full'},
            {path: 'employees/:id/medical-profile', component: MedicalProfileComponent, pathMatch: 'full'},
            {path: 'employees/:id/payroll', component: PayrollComponent, pathMatch: 'full'},
            {
                path: 'employees/:id',
                component: EmployeeComponent,
                pathMatch: 'full',
                canDeactivate: [CanLeaveFormGuard],
                resolve: {employee: employeeResolver}
            },
            {path: 'employees', component: EmployeesComponent},
            {path: 'suppliers/:id', component: SupplierComponent, canDeactivate: [CanLeaveFormGuard], resolve: {supplier: supplierResolver}},
            {path: 'suppliers', component: SuppliersComponent},
            {path: 'invoicing/outbound', component: OutboundInvoicesComponent},
            {
                path: 'invoicing/outbound/:id',
                component: OutboundInvoiceComponent,
                pathMatch: 'full',
                canDeactivate: [CanLeaveFormGuard],
                resolve: {invoice: outboundInvoiceResolver}
            },
            {path: 'invoicing/inbound', component: InboundInvoicesComponent,},
            {path: 'invoicing/inbound/:id', component: InboundInvoiceComponent, pathMatch: 'full', canDeactivate: [CanLeaveFormGuard], resolve: {invoice: inboundInvoiceResolver}},
            {path: 'invoicing/quotes', component: QuotesComponent,},
            {path: 'invoicing/quotes/:id', component: QuoteComponent, pathMatch: 'full', canDeactivate: [CanLeaveFormGuard], resolve: {quote: quoteResolver, projectRequest: projectRequestResolver}},
            {path: 'timesheet', component: TimesheetComponent, canDeactivate: [CanLeaveFormGuard]},
            {path: 'requests', component: ProjectRequestsComponent},
            {path: 'requests/:id', component: ProjectRequestComponent},
            {
                path: 'admin', component: AdminComponent, children: [
                    {path: 'inboundMessageErrors', component: InboundMessageErrorsComponent, pathMatch: 'full'},
                    {path: 'inboundMessages', component: InboundMessagesComponent, pathMatch: 'full'},
                    {path: 'outboundMessages', component: OutboundMessagesComponent, pathMatch: 'full'},
                    {path: 'contactRequests', component: ContactRequestsComponent, pathMatch: 'full'},
                    {path: 'businessContactRequests', component: BusinessContactRequestComponent, pathMatch: 'full'},
                    {path: 'priceLists/new', component: PriceListComponent, pathMatch: 'full'},
                    {path: 'priceLists/:id', component: PriceListComponent, pathMatch: 'full'},
                    {path: 'priceLists', component: PriceListsComponent, pathMatch: 'full'},
                    {path: 'projectRequests', component: AdminProjectRequests, pathMatch: 'full'},
                    {path: 'projectRequests/new', component: AdminProjectRequest, pathMatch: 'full'},
                    {path: 'projectRequests/:id', component: AdminProjectRequest, pathMatch: 'full'},
                    {path: 'fakeShowcases', component: FakeShowcasesComponent, pathMatch: 'full'},
                    {path: 'fakeShowcases/new', component: FakeShowcaseComponent, pathMatch: 'full'},
                    {path: 'fakeShowcases/:id', component: FakeShowcaseComponent, pathMatch: 'full'},

                ]
            },
            {path: 'data-import', component: DataImportComponent, pathMatch: 'full'},

        ]
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        enableTracing: false,
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
