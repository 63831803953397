import {Component, ViewChild} from "@angular/core";
import {NgForm} from "@angular/forms";

@Component({template: ''})
export class FormComponent {
    @ViewChild(NgForm) form: NgForm;


    canDeactivate() {
        if(!this.form) return true

        return this.form?.pristine
    }


}
