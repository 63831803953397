<mat-card class="infoCard information">
    <mat-card-header>
        <mat-card-title i18n="@@education">Formazione</mat-card-title>

        <mat-icon mat-card-avatar>book</mat-icon>
    </mat-card-header>

    <mat-card-content>

        <table mat-table [dataSource]="list" [hidden]="hideTable()">

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Descrizione</th>
                <td mat-cell *matCellDef="let record">{{ record.description }}</td>
            </ng-container>


            <ng-container matColumnDef="expectedDate">
                <th mat-header-cell *matHeaderCellDef i18n="@@expected_date">Data prevista</th>
                <td mat-cell *matCellDef="let record"> {{ record.expectedDate | date }}</td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Data formazione</th>
                <td mat-cell *matCellDef="let record"> {{ record.date | date }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let record">
                    <button mat-icon-button type="button" (click)="onEditDialog($event, record)">
                        <mat-icon class="mat-24">edit</mat-icon>
                    </button>
                    <button mat-icon-button  type="button" (click)="remove($event, record)">
                        <mat-icon class="mat-24">delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="hideTable()">
            <p>Nessuna formazione</p>
        </div>
    </mat-card-content>

    <mat-card-actions>
        <button mat-button type="button" color="primary" name="openFormButton" (click)="openDialog()">Aggiungi</button>
    </mat-card-actions>

</mat-card>
