import {Component, Injectable, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Showcase} from "../../model/business";
import {NgForm} from "@angular/forms";
import {NotificationService} from "../../notification.service";
import {Entity} from "../../model/entity";
import {Repository} from "../../persistence/repository";
import {DocumentConverter} from "../../document.repository";
import {FirestoreWrapper} from "../../persistence/firestoreWrapper";
import {ConverterRegistry} from "../../persistence/converter-registry.service";
import {persist, PersistenceConfig} from "../../persistence/decorators";
import {Moment} from "moment";

@Component({
  selector: 'app-business-showcase',
  templateUrl: './business-showcase.component.html',
  styleUrls: ['./business-showcase.component.scss']
})
export class BusinessShowcaseComponent implements OnInit {

    showcase: Showcase;
    contactRequest: BusinessContactRequest = new BusinessContactRequest();

    constructor(private route: ActivatedRoute, private notificationService: NotificationService, private repository: BusinessContactRequestRepository) {

    }

    ngOnInit(): void {
        this.showcase = this.route.snapshot.data.showcase
    }

    onSubmit(form: NgForm) {
        if (form.invalid) return

        this.repository.save(this.contactRequest)
        this.notificationService.notify("Richiesta inviata")
        this.contactRequest = new BusinessContactRequest()
        form.resetForm()
    }
}

@persist(new PersistenceConfig({
    name: 'BusinessContactRequest',
    timestamped: true,
    properties: {
        name: {},
        phoneNumber: {},
        email: {},
        description: {},
    }
}))
export class BusinessContactRequest extends Entity {
    name: string
    email: string
    phoneNumber: string
    description: string = ''
    createdTime: Moment
}

@Injectable({
    providedIn: 'root'
})
export class BusinessContactRequestRepository extends Repository<BusinessContactRequest> {

    documentConverter: DocumentConverter

    constructor(firestore: FirestoreWrapper) {
        super(firestore, ConverterRegistry.find('BusinessContactRequest'))

        this.documentConverter = new DocumentConverter();
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection(`businessContactRequests`).withConverter(this.converter);
    }

}
