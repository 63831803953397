import {doc, Firestore, getDoc, Transaction} from "@angular/fire/firestore";
import {getCurrentBusiness} from "../utils";
import {collectionData} from "rxfire/firestore";
import {ProjectConverter} from "./project-repository.service";
import {from, map} from "rxjs";
import {Project} from "../model/project";
import {Repository} from "../persistence/repository";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";

export class ProjectRepositoryV2 extends Repository<Project> {

    constructor(firestore: FirestoreWrapper, converter: ProjectConverter) {
        super(firestore, converter, {});
    }

    getAllByClient(clientId: string) {
        let collectionRef = this.getCollectionRef(clientId);
        return collectionData(collectionRef.withConverter(this.converter))
    }


    findProjectById(clientId: string, id: string) {
        let collectionRef = this.getCollectionRef(clientId);
        return from(getDoc(doc(collectionRef, id).withConverter(this.converter))).pipe(map(snapshot => {
            return snapshot.data()
        }))
    }


    override transactionalSave(transaction: Transaction, entity: Project) {
        super.transactionalSave(transaction, entity, this.getCollectionRef(entity.clientId));
    }

    override transactionalUpdate(transaction: Transaction, id: string, part: any) {
        if (!part.clientId) throw new Error("ClientId required to updated project")
        return super.transactionalUpdate(transaction, id, part, this.getCollectionRef(part.clientId));
    }

    private getCollectionRef(clientId: string) {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection('businesses', business.id, 'clients', clientId, 'projects').withConverter(this.converter);
    }

//@ts-ignore
    getCollectionReference(firestore: Firestore) {
        throw new Error("ProjectRepositoryV2#getCollectionReference should not be called")
    }

}
