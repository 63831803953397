import {Injectable} from '@angular/core';
import {ClientRepository} from "./client.repository";
import {OutboundInvoicesRepository} from "../invoicing/outbound/outbound-invoices.repository";
import {Client} from "../model/client";
import {Invoice} from "../model/invoice";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    constructor(private clientRepository: ClientRepository, private invoicesRepository: OutboundInvoicesRepository) {

    }

    findInboundInvoicesByClient(client: Client): Observable<Invoice[]> {
        return this.invoicesRepository.findByClient(client)
    }


    getProjects(client: Client) {
        return this.clientRepository.getProjects(client.id)
    }
}
