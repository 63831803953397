<mat-card class="infoCard archive">
    <mat-card-header>
        <mat-card-title>
            <ng-content></ng-content>
        </mat-card-title>

        <mat-icon mat-card-avatar>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor"
                      d="M16,15H9V13H16V15M19,11H9V9H19V11M19,7H9V5H19V7M3,5V21H19V23H3A2,2 0 0,1 1,21V5H3M21,1A2,2 0 0,1 23,3V17C23,18.11 22.11,19 21,19H7A2,2 0 0,1 5,17V3C5,1.89 5.89,1 7,1H21M7,3V17H21V3H7Z"/>
            </svg>
        </mat-icon>
    </mat-card-header>

    <mat-card-content>

        <table mat-table [dataSource]="documents" [hidden]="hideTable()">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef i18n="@@document">Documento</th>
                <td mat-cell *matCellDef="let document"> {{ document.name }}</td>
            </ng-container>

            <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef i18n="@@file">File</th>
                <td mat-cell *matCellDef="let document"> {{ document.fileName }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let document">

                    <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                        <a mat-menu-item matSuffix target="_blank" [href]="document.downloadUrl" *ngIf="document.isDownloadable()">
                            <mat-icon>download</mat-icon>
                            <span i18n="@@download">Scarica</span>
                        </a>

                        <button mat-menu-item type="button" (click)="openEditDialog($event, document)">
                            <mat-icon class="mat-24">edit</mat-icon>
                            <span i18n="@@edit">Modifica</span>
                        </button>

                        <button mat-menu-item  type="button" (click)="remove($event, document)">
                            <mat-icon class="mat-24">delete</mat-icon>
                            <span i18n="@@delete">Rimuovi</span>
                        </button>
                    </mat-menu>


                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="hideTable()">
            <p i18n="@@no_document">Nessun documento</p>
        </div>
    </mat-card-content>

    <mat-card-actions>
        <button mat-button name="openDocumentFormButton" color="primary" type="button" (click)="openNewDialog()" i18n="@@add">Aggiungi</button>
    </mat-card-actions>

</mat-card>



