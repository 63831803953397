<section class="payments">
    <header><h1 i18n="@@payment">Pagamento</h1></header>

    <div class="actions">
        <button mat-flat-button color="primary" (click)="addPayment()" type="button" *ngIf="!disabled" i18n="@@add_payment">Aggiungi Pagamento</button>
    </div>

    <mat-error style="font-size: larger" *ngIf="form.hasError('paymentsAmountNotMatchingTotal')" i18n="@@non_matching_total">La somma dei pagamenti non corrisponde al totale del preventivo</mat-error>

    <div *ngFor="let payment of payments; let i = index" class="payment">
        <mat-form-field class="paymentType">
            <mat-label i18n="@@type">Tipo</mat-label>

            <mat-select name="paymentType-{{i}}" [(ngModel)]="payment.type" #paymentType="ngModel" required [disabled]="disabled"
                        (ngModelChange)="setPaymentValues($event, payment)" [compareWith]="compareEntities">
                <mat-option *ngFor="let paymentType of paymentTypes" [value]="paymentType">{{ paymentType.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="paymentType.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label i18n="@@amount">Importo</mat-label>

            <input type="number" name="paymentAmount-{{i}}" #paymentAmount="ngModel" (ngModelChange)="onChange(payments)"
                   matInput required [disabled]="disabled" [(ngModel)]="payment.amount"/>
            <label matSuffix>€</label>
            <mat-error *ngIf="paymentAmount.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label i18n="@@due_date">Data scadenza</mat-label>
            <input name="paymentDueDate-{{i}}" matInput [matDatepicker]="pickerPaymentDueDate" (ngModelChange)="onChange(payments)"
                   #paymentDueDate="ngModel" [disabled]="disabled" [(ngModel)]="payment.dueDate">
            <mat-datepicker-toggle matSuffix [for]="pickerPaymentDueDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerPaymentDueDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="payment.isBankTransfer()">
            <mat-label i18n="@@bank">Istituto finanziario</mat-label>
            <input name="bank-{{i}}" #bank="ngModel" (ngModelChange)="onChange(payments)" [disabled]="disabled" matInput [(ngModel)]="payment.bank">
        </mat-form-field>

        <mat-form-field *ngIf="payment.isBankTransfer()" class="iban">
            <mat-label i18n="@@iban">IBAN</mat-label>
            <input name="iban-{{i}}" #iban="ngModel" (ngModelChange)="onChange(payments)" [disabled]="disabled" matInput required [(ngModel)]="payment.iban">
            <mat-error *ngIf="iban.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
        </mat-form-field>

        <button type="button" mat-icon-button matSuffix (click)="removePayment($event, payment)" *ngIf="!disabled">
            <mat-icon>delete</mat-icon>
        </button>
    </div>

    <div *ngIf="payments.length == 0" style="text-align: center" i18n="@@no_payments">Nessun Pagamento</div>
</section>
