import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Bouncer} from "./bouncer.service";
import {NotificationService} from "./notification.service";
import {NavigatorService} from "./navigator.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(private bouncer: Bouncer, private router: Router, private notificationService: NotificationService, private navigatorService: NavigatorService) {
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authCheck()
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authCheck()
    }

    private authCheck() {
        if (!this.bouncer.isLoggedIn()) {
            console.log("not logged in, redirecting to welcome page");
            return this.navigatorService.goToWelcomePage()
        }

        if (!this.bouncer.isVerified()) {
            this.notificationService.notify("Per accedere ai servizi, verifica la tua email")
            return this.navigatorService.goToVerificationPage()
        }

        return true
    }

}
