import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NgForm} from "@angular/forms";
import {ContactRequest, ContactRequestRepository} from "../welcome/contact-request.repository";
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
    package: string | null;
    contactRequest: ContactRequest = new ContactRequest();
    sent: boolean = false;

    promotion = environment.promotion

    constructor(private route: ActivatedRoute,
                private repository: ContactRequestRepository) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {

            this.contactRequest.package = params.get('package');
            console.log("here", this.contactRequest.package)

        })
    }

    onSubmit(form: NgForm) {
        if (form.invalid) return

        this.repository.save(this.contactRequest).then(value => {
            this.sent = true
            this.contactRequest = new ContactRequest()
            form.resetForm()
        })

    }
}
