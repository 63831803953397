import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {OutboundInvoicesRepository} from "./outbound/outbound-invoices.repository";
import {map, Observable, of, take} from "rxjs";
import {Invoice} from "../model/invoice";
import {FiscalDocument} from "../model/fiscalDocument";

@Directive({
    selector: '[uniqueInvoiceNumber]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: forwardRef(() => UniqueInvoiceNumberDirective),
        multi: true
    }]
})
export class UniqueInvoiceNumberDirective implements AsyncValidator {

    @Input('fiscalDocument') fiscalDocument: FiscalDocument

    constructor(private invoicesRepository: OutboundInvoicesRepository) {
    }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        const invoiceNumber = control.value;

        if (!invoiceNumber) return of(null);

        return this.invoicesRepository.findByInvoiceNumber(invoiceNumber)
            .pipe(take(1), map((foundInvoices: Invoice[]) => {
                    let sameNumber = foundInvoices.find(value => value.id != this.fiscalDocument.id);
                    return sameNumber ? {nonUnique: true} : null;
                }
            ))

    }

}
