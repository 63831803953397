export const environment = {
    production: true,
    useEmulators: false,
    firebase: {
        apiKey: "AIzaSyDQ7VYKuD9_ajkBLD8Rharar-TJHNw2-xk",
        locationId: 'europe-west',
        authDomain: "hermes-project-339216.firebaseapp.com",
        projectId: "hermes-project-339216",
        storageBucket: "hermes-project-339216.appspot.com",
        messagingSenderId: "818976474713",
        appId: "1:818976474713:web:77783ded0f61956da9d348",
        measurementId: "G-RHY5MT92HF"
    },
    hermes: {
        url: 'https://hermes-project-339216.ew.r.appspot.com',
        gcp: 'https://35.212.235.222'
    },
    pan: {
        version: '1.0.3'
    },
    algolia: {
        priceListIndex: 'PriceLists',
        appId: 'VRHR7ICUAM',
        apiToken: '878826624b3221ef20290a5436054ac4'
    },
    googleAnalytics: {
        enabled: true,
        id: "G-95MGRT8L6X"
    },
    promotion: {
        period: '1',
        base: {
            id: 'base',
            price: 16,
            discount: 30,
            discountedPrice: 11,
            name: 'Base',
        },
        standard: {
            id: 'standard',
            price: 20,
            discount: 30,
            discountedPrice: 14,
            name: 'Standard',
        },
        premium: {
            id: 'premium',
            price: 30,
            discount: 30,
            discountedPrice: 21,
            name: 'Premium',
        },
    }
};
