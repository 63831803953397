import {Component, Input} from '@angular/core';
import {Supplier} from "../../model/supply";
import {Showcase} from "../../model/business";

@Component({
  selector: 'business-showcase-card',
  templateUrl: './business-showcase-card.component.html',
  styleUrls: ['./business-showcase-card.component.scss']
})
export class BusinessShowcaseCardComponent {

    @Input() showcase: Showcase;


}
