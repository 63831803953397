<mat-card class="infoCard">
    <mat-card-header>
        <mat-card-title i18n="@@supplies">Forniture</mat-card-title>

        <mat-icon mat-card-avatar>pallet</mat-icon>
    </mat-card-header>

    <mat-card-content>

        <table mat-table [dataSource]="supplies" [hidden]="hideTable()">

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef i18n="@@description">Descrizione</th>
                <td mat-cell *matCellDef="let supply">
                    {{ supply.description }}
                </td>
            </ng-container>


            <ng-container matColumnDef="supplier">
                <th mat-header-cell *matHeaderCellDef i18n="@@supplier">Fornitore</th>
                <td mat-cell *matCellDef="let supply"> {{ supply.supplier?.name }}</td>
            </ng-container>

            <ng-container matColumnDef="estimatedDate">
                <th mat-header-cell *matHeaderCellDef i18n="@@estimated_delivery_date">Data prevista consegna</th>
                <td mat-cell *matCellDef="let supply"> {{ supply.estimatedDeliveryDate | date }}</td>
            </ng-container>

            <ng-container matColumnDef="deliveryDate" i18n="@@delivery_date">
                <th mat-header-cell *matHeaderCellDef>Data effettiva consegna</th>
                <td mat-cell *matCellDef="let supply"> {{ supply.deliveryDate | date }}</td>
            </ng-container>


            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let supply">
                    <a mat-icon-button name="editButton" [routerLink]="['/supplies', supply.id]" *ngIf="!supply.transient">
                        <mat-icon class="mat-24">edit</mat-icon>
                    </a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="hideTable()">
            <p  i18n="@@no_supply">Nessuna fornitura</p>
        </div>
    </mat-card-content>

    <mat-card-actions>
        <a mat-button color="primary"
           [routerLink]="['/supplies', 'new']" [queryParams]="{projectId: project.id, clientId: project.clientId, type: 'deliverable'}"
           (click)="$event.preventDefault(); $event.stopPropagation()"  i18n="@@add">Aggiungi</a>
    </mat-card-actions>

</mat-card>
