import {Address} from "./address";

export interface InvoiceSender {
    id: string
    address: Address
    name: string
    phoneNumber: string
    taxNumber: string
}

export class GenericSender implements InvoiceSender {
    id: string
    address: Address = new Address()
    name: string
    phoneNumber: string
    taxNumber: string
}
