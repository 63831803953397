import {Injectable} from '@angular/core';
import {collectionData, query, where} from "@angular/fire/firestore";
import {Repository} from "../../persistence/repository";
import {getCurrentBusiness} from "../../utils";
import {Invoice} from "../../model/invoice";
import {AddressConverter} from "../../authentication/addressConverter";
import {FiscalDocumentConverter} from "../outbound/outbound-invoices.repository";
import {DocumentConverter} from "../../document.repository";
import {Project} from "../../model/project";
import {Supply} from "../../model/supply";
import {Client} from "../../model/client";
import {FirestoreWrapper} from "../../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class InboundInvoicesRepository extends Repository<Invoice> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, new FiscalDocumentConverter<Invoice>(new AddressConverter(), new DocumentConverter()))
    }

    ngOnInit(): void {
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection(`businesses/${business.id}/inboundInvoices`).withConverter(this.converter);
    }

    findByProject(project: Project) {
        let invoiceConverter = new FiscalDocumentConverter<Invoice>(new AddressConverter(), new DocumentConverter());
        return collectionData(
            query(this.getCollectionReference().withConverter(invoiceConverter),
                where('project.id', '==', project.id))
        )
    }

    findBySupply(supply: Supply) {
        let invoiceConverter = new FiscalDocumentConverter<Invoice>(new AddressConverter(), new DocumentConverter());
        return collectionData(
            query(this.getCollectionReference().withConverter(invoiceConverter),
                where('supply.id', '==', supply.id))
        )
    }

    findByClient(client: Client) {
        let invoiceConverter = new FiscalDocumentConverter<Invoice>(new AddressConverter(), new DocumentConverter());
        return collectionData(
            query(this.getCollectionReference(), where('project.clientId', '==', client.id))
        )
    }

    findAllByYear(year: number) {
        return collectionData(
//            query(this.getCollectionReference(), where('date', '>=', moment().startOf("year").toDate()))
            query(this.getCollectionReference())
        )
    }
}

