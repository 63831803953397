import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BusinessRepository} from "../../authentication/business.repository";
import {Observable} from "rxjs";
import {Project} from "../../model/project";
import {MatTable} from "@angular/material/table";
import {Line} from "../../model/invoice";
import {TimesByProject, WorkingDay} from "../../model/timesheet";
import {getMonthName} from "../../utils";


@Component({
    selector: 'app-working-times-dialog',
    templateUrl: './working-times-dialog.component.html',
    styleUrls: ['./working-times-dialog.component.scss']
})
export class WorkingDayDialog {
    @ViewChild(MatTable) table: MatTable<Line>

    workingDay: WorkingDay;
    projects: Observable<Project[]>;
    displayedColumns: string[] = ['project', 'hours', 'description'];
    timesByProject: TimesByProject[] = [];

    constructor(public dialogRef: MatDialogRef<WorkingDayDialog>, private businessRepository: BusinessRepository, @Inject(MAT_DIALOG_DATA) public data: any) {

        this.workingDay = data.workingDay

        businessRepository.getAllProjects().subscribe(projects => {

            this.workingDay.workedTimes.timesByProject.forEach(workingDay => {
                workingDay.project = projects.find(project => project.id == workingDay.project.id )!
            })


            let projectsNotPresentInWorkedTimes = projects.filter(project => {
                return !this.workingDay.workedTimes.timesByProject.find(time => {
                    return time.project.id == project.id;
                });
            }).map(project => {
                let timesByProject = new TimesByProject();
                timesByProject.project = project
                return timesByProject
            });

            this.workingDay.workedTimes.timesByProject.push(...projectsNotPresentInWorkedTimes)

            this.timesByProject.push(...this.workingDay.workedTimes.timesByProject)
            this.table.renderRows()
        })
    }

    protected readonly getMonthName = getMonthName;
}
