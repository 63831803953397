<div class="flex-row">
    <button mat-fab color="primary" name="addEntityButton" matTooltip="Nuova richiesta progetto" matTooltipPosition="above"
            [routerLink]="['new']">
        <mat-icon>add</mat-icon>
    </button>
</div>

<table mat-table [dataSource]="list">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef style="width: 4%">id</th>
        <td mat-cell *matCellDef="let projectRequest"> {{projectRequest.id}} </td>
    </ng-container>

    <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let projectRequest"> {{projectRequest.title}} </td>
    </ng-container>

    <ng-container matColumnDef="requester">
        <th mat-header-cell *matHeaderCellDef>Requestor</th>
        <td mat-cell *matCellDef="let projectRequest"> {{projectRequest.requester}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let projectRequest"> {{projectRequest.description.substring(0,50)}}... </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let projectRequest"> {{projectRequest.date  | date:'dd/MM/YYYY HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let projectRequest" style="width: 10%; text-align: right">
            <button type="button" mat-icon-button (click)="delete($event, projectRequest)">
                <mat-icon class="mat-24">delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let projectRequest; columns: displayedColumns;" (click)="onSelected(projectRequest)"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length" style="text-align: center">
            Nessuna richiesta
        </td>
    </tr>
</table>
