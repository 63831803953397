import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {TranslateStatusPipe} from "../translate-status.pipe";
import {FormatCurrencyPipe} from "../../format-currency.pipe";
import {Searchable} from "../../model/fiscalDocument";

@Component({
    selector: 'search-table',
    templateUrl: './search-table.component.html',
    styleUrls: ['./search-table.component.scss']
})
export class SearchTableComponent implements OnInit {
    @Input() dataSource: MatTableDataSource<Searchable>;

    constructor(private translateStatusPipe: TranslateStatusPipe, private formatCurrencyPipe: FormatCurrencyPipe) {
    }

    ngOnInit(): void {
    }

    applyFilter(event: Event) {
        this.dataSource.filter = (event.target as HTMLInputElement).value.trim().toLowerCase();
        this.dataSource.filterPredicate = (entity, filterValue) => {
            let entityAsText = entity.asText({
                translateStatusPipe: this.translateStatusPipe,
                formatCurrencyPipe: this.formatCurrencyPipe
            }).toLowerCase()

            return entityAsText.includes(filterValue)
        }

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
}
