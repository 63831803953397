import {DEFAULT_CURRENCY_CODE, ErrorHandler, Injectable, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../environments/environment';
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {getAuth, provideAuth} from "@angular/fire/auth";
import {getStorage, provideStorage} from '@angular/fire/storage';
import {MatSidenavModule} from '@angular/material/sidenav';

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {AccountingComponent} from './accounting/accounting.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {DocumentInputComponent} from './components/document-input/document-input.component';
import {ClientsComponent} from './clients-management/clients/clients.component';
import {ClientComponent} from './clients-management/client/client.component';
import {ProjectsComponent} from './projects-management/projects/projects.component';
import {ProjectComponent} from './projects-management/project/project.component';
import {MatCardModule} from "@angular/material/card";
import {DocumentsComponent} from './components/documents/documents.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {EmployeesComponent} from './employees/employees/employees.component';
import {EmployeeComponent} from './employees/employee/employee.component';
import {MedicalProfileComponent} from './employees/medical-profile/medical-profile.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {EducationComponent} from './employees/education/education.component';
import {LeaveProfileComponent} from './employees/leave-profile/leave-profile.component';
import {ActivitiesComponent} from './projects-management/activities/activities.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatTableModule} from "@angular/material/table";
import {
    NextMedicalAppointmentDialogComponent
} from './employees/next-medical-appointment-dialog/next-medical-appointment-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {SuppliersComponent} from './suppliers/suppliers/suppliers.component';
import {SupplierComponent} from './suppliers/supplier/supplier.component';
import {SuppliesComponent} from './projects-management/supplies/supplies.component';
import {MatSelectModule} from "@angular/material/select";
import {SupplyComponent} from './projects-management/supply/supply.component';
import {PaymentComponent} from './components/balance/payment/payment.component';
import {PayrollComponent} from './employees/payroll/payroll.component';

import {CommonModule, DATE_PIPE_DEFAULT_OPTIONS, Location, NgOptimizedImage} from '@angular/common';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ClientCardComponent} from './clients-management/client-card/client-card.component';
import {BalanceComponent} from "./components/balance/balance/balance.component";
import {SupplierCardComponent} from "./suppliers/supplier-card/supplier-card.component";
import {EmployeeCardComponent} from "./employees/employee-card/employee-card.component";
import {ListLayoutComponent} from './components/list-layout/list-layout.component';
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from "@angular-material-components/datetime-picker";
import {NgxMatMomentModule} from "@angular-material-components/moment-adapter";
import {AuthenticationComponent} from './authentication/authentication.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatMenuModule} from "@angular/material/menu";
import {VerificationComponent} from './verification/verification.component';
import {BusinessAdministrationComponent} from './business-administration/business-administration.component';
import {SickLeaveProfileComponent} from "./employees/sick-leave-profile/sick-leave-profile.component";
import {MatChipsModule} from "@angular/material/chips";
import {OutboundInvoicesComponent} from './invoicing/outbound/invoices/outbound-invoices.component';
import {OutboundInvoiceComponent} from './invoicing/outbound/invoice/outbound-invoice.component';
import {MatRadioModule} from "@angular/material/radio";
import {InboundInvoicesComponent} from "./invoicing/inbound/invoices/inbound-invoices.component";
import {InboundInvoiceComponent} from "./invoicing/inbound/invoice/inbound-invoice.component";
import {HttpClientModule} from "@angular/common/http";
import {MatBadgeModule} from "@angular/material/badge";
import {MatTabsModule} from "@angular/material/tabs";
import {ConfirmDialogComponent} from './invoicing/outbound/confirm-dialog/confirm-dialog.component';
import {TranslateStatusPipe} from './invoicing/translate-status.pipe';
import {ExportInvoiceToXmlComponent} from './invoicing/export-invoice-to-xml/export-invoice-to-xml.component';
import {ExportInvoiceToPdfComponent} from './invoicing/export-invoice-to-pdf/export-invoice-to-pdf.component';
import {UniqueInvoiceNumberDirective} from './invoicing/unique-invoice-number.directive';
import {PaymentTotalValidatorDirective} from './invoicing/payment-total-validator.directive';
import {TimesheetComponent} from './timesheet/timesheet.component';
import {WorkingDayDialog} from './timesheet/working-times-dialog/working-times-dialog.component';
import {WeatherDialogComponent} from './timesheet/weather-dialog/weather-dialog.component';
import {JournalComponent} from './projects-management/journal/journal.component';
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {FormatCurrencyPipe} from './format-currency.pipe';
import {AdminComponent} from './admin/admin.component';
import {InboundMessagesComponent} from './admin/inbound-messages/inbound-messages.component';
import {InboundMessageErrorsComponent} from './admin/inbound-message-errors/inbound-message-errors.component';
import {OutboundMessagesComponent} from './admin/outbound-messages/outbound-messages.component';
import {LinesValidatorDirective} from "./invoicing/lines-validator.directive";
import {getPaginatorIntl} from "./paginator.intl";
import {SearchTableComponent} from './invoicing/search-invoice/search-table.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {LegalNotesComponent} from './legal-notes/legal-notes.component';
import {ContactRequestsComponent} from './admin/contact-requests/contact-requests.component';
import {QuotesComponent} from './invoicing/quotes/quotes.component';
import {QuoteComponent} from './invoicing/quotes/quote/quote.component';
import {AttachmentsComponent} from './invoicing/attachments/attachments.component';
import {InvoicePaymentsComponent} from './invoicing/payments/invoice-payments.component';
import {
    GovernmentContributionsComponent
} from './invoicing/government-contributions/government-contributions.component';
import {PriceModifiersComponent} from './invoicing/price-modifiers/price-modifiers.component';
import {LinesComponent} from './invoicing/lines/lines.component';
import {UniqueQuoteNumberDirective} from "./invoicing/unique-quote-number.directive";
import {CheckoutComponent} from './checkout/checkout.component';
import {DemoComponent} from './demo/demo.component';
import {PriceListsComponent} from './price-lists/price-lists/price-lists.component';
import {PriceListComponent} from './price-lists/price-list/price-list.component';
import {PricedItemSelectorComponent} from './price-lists/priced-item-selector/priced-item-selector.component';
import {FormComponent} from "./components/form.component";
import {InformDialogComponent} from "./inform-dialog/inform-dialog.component";
import {GoogleAnalyticsService} from "./google-analytics.service";
import {SpinnerDialogComponent} from "./spinner-dialog/spinner-dialog.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MedicalRecordDialog} from './employees/medical-profile/medical-record-dialog/medical-record-dialog.component';
import {EducationRecordDialog} from './employees/education/education-record-dialog/education-record-dialog.component';
import {LeaveRecordDialog} from './employees/leave-profile/leave-record-dialog/leave-record-dialog.component';
import {
    SickLeaveRecordDialog
} from './employees/sick-leave-profile/sick-leave-record-dialog/sick-leave-record-dialog.component';
import {PayslipRecordDialog} from './employees/payroll/payslip-record-dialog/payslip-record-dialog.component';
import {ActivityDialogComponent} from './projects-management/activities/activity-dialog/activity-dialog.component';
import {DocumentDialog} from './components/documents/document-dialog/document-dialog.component';
import { DataImportComponent } from './data-import/data-import.component';
import {FirestoreWrapper} from "./persistence/firestoreWrapper";
import {VatTypeSelectorDialog} from './invoicing/lines/vat-type-selector-dialog/vat-type-selector-dialog.component';
import {
    ProjectRequestsComponent as AdminProjectRequestsComponent
} from './admin/project-requests/project-requests.component';
import {ProjectRequestsComponent} from './project-requests/project-requests.component';
import {
    ProjectRequestComponent as AdminProjectRequestComponent
} from './admin/project-requests/project-request/project-request.component';
import {ProjectRequestComponent} from "./project-requests/project-request/project-request.component";
import {Message} from "./chats/message";
import {ProjectRequest} from "./model/project.request";
import {BusinessShowcasesComponent} from "./showcases/business-showcases.component";
import {BusinessShowcaseCardComponent} from './showcases/showcase-card/business-showcase-card.component';
import {BusinessShowcaseComponent} from './showcases/business-showcase/business-showcase.component';
import {
    BusinessContactRequestComponent
} from './admin/business-contact-requests/business-contact-request/business-contact-request.component';
import {ShowcaseComponent} from "./business-administration/showcase/showcase.component";
import {FakeShowcasesComponent} from "./admin/showcases/fake-showcases.component";
import {FakeShowcaseComponent} from "./admin/showcases/fakeShowcase/fake-showcase/fake-showcase.component";
import {RegistrationComponent} from "./authentication/registration/registration.component";
import {
    OnRequestRegistrationComponent
} from "./authentication/on-request-registration/on-request-registration.component";
import {InfoTooltipComponent} from "./info-tooltip/info-tooltip.component";
import { ClientsImportDialog } from './data-import/clients-import-dialog/clients-import-dialog.component';
import { SuppliersImportDialog } from './data-import/suppliers-import-dialog/suppliers-import-dialog.component';
import { EmployeesImportDialog } from './data-import/employees-import-dialog/employees-import-dialog.component';
import {FrontendError} from "./model/frontend-error.request";
import {FrontendErrorRepository} from "./project-requests/frontend-error.repository";
import {Bouncer} from "./bouncer.service";

//moment.locale('it')
//registerLocaleData(localeIT, 'it-IT', localeItExtra);


@Injectable()
class GlobalErrorHandler implements ErrorHandler {


    constructor(private errorRepository: FrontendErrorRepository, private bouncer: Bouncer, private location: Location) {
    }

    handleError(error) {

        if(error.rejection) {
            error = error.rejection
        }

        console.error(error);
        this.errorRepository.save(
            {
                description: 'error in global handler',
                data: `${this.location.path()} -- ${error} -- ${JSON.stringify(error)}`,
                user: this.bouncer.getUser()?.email
            } as FrontendError)
    }
}


@NgModule({
    declarations: [
        FormComponent,
        CheckoutComponent,
        DemoComponent,
        AppComponent,
        AccountingComponent,
        DocumentInputComponent,
        ClientsComponent,
        ClientComponent,
        ProjectsComponent,
        ProjectComponent,
        DocumentsComponent,
        EmployeesComponent,
        EmployeeComponent,
        MedicalProfileComponent,
        EducationComponent,
        LeaveProfileComponent,
        SickLeaveProfileComponent,
        ActivitiesComponent,
        NextMedicalAppointmentDialogComponent,
        SuppliersComponent,
        SupplierComponent,
        SuppliesComponent,
        BalanceComponent,
        SupplyComponent,
        PaymentComponent,
        PayrollComponent,
        ClientCardComponent,
        SupplierCardComponent,
        EmployeeCardComponent,
        ListLayoutComponent,
        AuthenticationComponent,
        VerificationComponent,
        BusinessAdministrationComponent,
        InboundInvoicesComponent,
        OutboundInvoicesComponent,
        InboundInvoiceComponent,
        OutboundInvoiceComponent,
        ConfirmDialogComponent,
        InformDialogComponent,
        TranslateStatusPipe,
        ExportInvoiceToXmlComponent,
        ExportInvoiceToPdfComponent,
        UniqueInvoiceNumberDirective,
        UniqueQuoteNumberDirective,
        PaymentTotalValidatorDirective,
        LinesValidatorDirective,
        TimesheetComponent,
        WorkingDayDialog,
        WeatherDialogComponent,
        JournalComponent,
        FormatCurrencyPipe,
        AdminComponent,
        InboundMessagesComponent,
        InboundMessageErrorsComponent,
        OutboundMessagesComponent,
        SearchTableComponent,
        WelcomeComponent,
        PrivacyPolicyComponent,
        LegalNotesComponent,
        ContactRequestsComponent,
        QuotesComponent,
        QuoteComponent,
        AttachmentsComponent,
        InvoicePaymentsComponent,
        GovernmentContributionsComponent,
        PriceModifiersComponent,
        LinesComponent,
        PriceListsComponent,
        PriceListComponent,
        PricedItemSelectorComponent,
        RegistrationComponent,
        OnRequestRegistrationComponent,
        SpinnerDialogComponent,
        MedicalRecordDialog,
        EducationRecordDialog,
        LeaveRecordDialog,
        SickLeaveRecordDialog,
        PayslipRecordDialog,
        ActivityDialogComponent,
        DocumentDialog,
        VatTypeSelectorDialog,
        ProjectRequestsComponent,
        AdminProjectRequestComponent,
        AdminProjectRequestsComponent,
        ProjectRequestComponent,
        BusinessShowcasesComponent,
        BusinessShowcaseCardComponent,
        BusinessShowcaseComponent,
        BusinessContactRequestComponent,
        ShowcaseComponent,
        FakeShowcasesComponent,
        FakeShowcaseComponent,
        InfoTooltipComponent,
        DataImportComponent,
        ClientsImportDialog,
        SuppliersImportDialog,
        EmployeesImportDialog
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        provideAuth(() => getAuth()),
        provideStorage(() => getStorage()),
        BrowserAnimationsModule,

        MatSidenavModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatCardModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatMomentDateModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatTableModule,
        MatDialogModule,
        MatSelectModule,
        MatExpansionModule,
        MatTooltipModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        MatToolbarModule,
        MatMenuModule,
        MatChipsModule,
        MatRadioModule,
        MatFormFieldModule,
        HttpClientModule,
        MatBadgeModule,
        MatTabsModule,
        MatPaginatorModule,
        MatSortModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        NgOptimizedImage
    ],
    providers: [
        {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {dateFormat: 'shortDate'}},
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'EUR'
        },
        //  {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
      //  {provide: LOCALE_ID, useValue: "it-IT"},
        {provide: MatPaginatorIntl, useValue: getPaginatorIntl()},
        TranslateStatusPipe,
        FormatCurrencyPipe,
        GoogleAnalyticsService,
        FirestoreWrapper
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    // to trigger persistence converter registration
    message = new Message()
    projRequest = new ProjectRequest()
    erro = new FrontendError()
}


