import {Component, OnInit} from '@angular/core';
import {DocumentStorageService} from "../../document-storage.service";
import {Bouncer} from "../../bouncer.service";
import {Business} from "../../model/business";
import {OutboundSdiMessage, OutboundSdiMessagesRepository} from "./outbound-sdi-messages.repository";

@Component({
  selector: 'outbound-messages',
  templateUrl: './outbound-messages.component.html',
  styleUrls: ['./outbound-messages.component.scss']
})
export class OutboundMessagesComponent implements OnInit {

    outboundMessages: any;
    displayedColumns: string[] = [
        "flowId",
        "business",
        "requestTime",
        "availability",
        "fileName",
        "actions",
    ];

    constructor(private outboundSdiMessagesRepository: OutboundSdiMessagesRepository, private storageService: DocumentStorageService,
                private bouncer: Bouncer) {
    }

    ngOnInit(): void {
        this.getAllMessages();
    }

    private getAllMessages() {
        this.outboundMessages = this.outboundSdiMessagesRepository.getAll()
    }

    downloadXml(message: OutboundSdiMessage) {
            this.storageService.xmlToFile(message.sentXml, 'application/xml', message.fileName)
    }

    goToInvoice(message: OutboundSdiMessage) {
        this.bouncer.storeInspectedBusiness({id: message.businessId} as Business)
        location.pathname = `/invoicing/outbound/${message.invoiceId}`
    }

    showResponse(message: OutboundSdiMessage) {
        let blob = new Blob([message.response], {type: 'text/xml'});
        let url = URL.createObjectURL(blob);
        window.open(url);
        URL.revokeObjectURL(url);
    }

    archive(message: OutboundSdiMessage) {
        this.outboundSdiMessagesRepository.setArchived(message)
        this.getAllMessages()
    }
}
