<h1 mat-dialog-title i18n="@@activity">Attivit&agrave;</h1>
<div mat-dialog-content>
    <form #form="ngForm" name="addForm" (ngSubmit)="form.valid && onSubmit()">

        <mat-form-field class="flexible-size">
            <mat-label i18n="@@activity">Attivit&agrave;</mat-label>
            <input name="name" #name="ngModel" matInput
                   required [(ngModel)]="record.name"/>
            <mat-error *ngIf="name.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
        </mat-form-field>

        <mat-form-field class="period flexible-size">
            <mat-label i18n="@@period">Periodo</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input name="from" matStartDate i18n-placeholder="@@start" placeholder="Inizio" [(ngModel)]="record.from">
                <input name="to" matEndDate i18n-placeholder="@@end" placeholder="Fine" [(ngModel)]="record.to">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field>
            <mat-label i18n="@@description">Descrizione</mat-label>
            <textarea name="description" matInput [(ngModel)]="record.description"></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label i18n="@@assign_to">Assegna a</mat-label>
            <input #assignees name="assignees" [formControl]="autocompleteControl" [matAutocomplete]="auto"
                   [matChipInputFor]="chipList" (matChipInputTokenEnd)="onEnterAssigneeInput($event)">

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="employeeName" (optionSelected)="onAssigneeSelected($event)">
                <mat-option *ngFor="let employee of filteredOptions | async" [value]="employee">
                    {{ employee.fullName }}
                </mat-option>
            </mat-autocomplete>

            <mat-chip-grid #chipList>
                <mat-chip-row *ngFor="let assignee of record.assignees" (removed)="onAssigneeRemoved(assignee)">
                    {{ assignee.fullName }}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-row>
            </mat-chip-grid>
        </mat-form-field>

        <mat-form-field>
            <mat-label i18n="@@notes">Note</mat-label>
            <textarea name="notes" matInput [(ngModel)]="record.notes"></textarea>
        </mat-form-field>

        <mat-dialog-actions align="end">
            <button (click)="cancel($event)" mat-button i18n="@@cancel">Annulla</button>
            <button *ngIf="!editMode" name="addButton" type="submit" mat-button color="primary" i18n="@@add">Aggiungi
            </button>

            <button *ngIf="editMode" type="submit" color="primary" mat-button i18n="@@edit">Modifica</button>
        </mat-dialog-actions>
    </form>
</div>
