import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ConfirmDialogComponent} from "./invoicing/outbound/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
    providedIn: 'root'
})
export class CanLeaveFormGuard implements CanDeactivate<unknown> {


    constructor(private dialog: MatDialog) {
    }

    canDeactivate(component: any,
                  currentRoute: ActivatedRouteSnapshot,
                  currentState: RouterStateSnapshot,
                  nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (!component.canDeactivate()) {
            return this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title: $localize `Modifiche non salvate`,
                    question: $localize `Sono presenti modifiche non salvate. Vuoi annullare le modifiche?`
                }
            }).afterClosed()
        }

        return true;
    }

}
