import {Activity} from "./activity";
import {Deliverable} from "./supply";
import {Document} from "./document";
import {Entity} from "./entity";

export class Project extends Entity {
    name: string;
    address: string
    clientId: string;
    completed: boolean = false;
    paid: boolean = false;
    registryDocuments: Document[] = [];
    administrativeDocuments: Document[] = [];
    activities: Activity[] = [];
    supplies: Deliverable[] = [];
    quote: Document | null
    order: Document | null
    businessId: string;

    override getDocuments() {
        return [
            this.quote!,
            this.order!,
            this.registryDocuments,
            this.administrativeDocuments,
            this.supplies.map(value => value.getDocuments()).flat()
        ].flat().filter(value => !!value)
    }

}
