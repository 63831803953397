<table mat-table [dataSource]="getPriceModifiersWithNoContributions()" class="input-table" #priceModifiersTable>

    <ng-container matColumnDef="description">
        <td mat-cell *matCellDef="let element" style="text-align: right">{{element.isDiscount() ? 'Sconto' : 'Maggiorazione' }}</td>
    </ng-container>

    <ng-container matColumnDef="percentage">
        <td mat-cell *matCellDef="let element; let i = index;" style="width: 4%">
            <mat-form-field class="small">
                <input type="number" name="percentage-{{i}}" #percentage="ngModel"
                       placeholder="Percentuale" i18n-placeholder="@@percentage"
                       matInput [required]="!element.amount"
                       [disabled]="disabled || !!element.amount"
                       [(ngModel)]="element.percentage"/>
                <label matSuffix>%</label>
                <mat-error *ngIf="percentage.hasError('required')" i18n="@@required_percentage">Inserire percentuale o importo</mat-error>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <td mat-cell *matCellDef="let element; let i = index;" style="width: 4%">
            <mat-form-field class="small">
                <input type="number" name="amount-{{i}}" #amount="ngModel" placeholder="Importo" i18n-placeholder="@@amount" matInput [required]="!element.percentage"
                       [disabled]="disabled || element.percentage" [(ngModel)]="element.amount"/>
                <label matSuffix>€</label>
                <mat-error *ngIf="amount.hasError('required')" i18n="@@required_percentage">Inserire percentuale o importo</mat-error>
            </mat-form-field>
        </td>
    </ng-container>


    <ng-container matColumnDef="value">
        <td mat-cell *matCellDef="let element;" style="width: 6%">{{element.getValue(fiscalDocument.getLinesTotal()) | formatCurrency }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <td mat-cell *matCellDef="let element">
            <button type="button" mat-icon-button *ngIf="!disabled" (click)="remove($event, element)">
                <mat-icon class="mat-24">delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: priceModifiersColumns;" class="priceModifier"></tr>
</table>
