import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-legal-notes',
  templateUrl: './legal-notes.component.html',
  styleUrls: ['./legal-notes.component.scss']
})
export class LegalNotesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
