<article class="page">
    <header>
        <h1>Fatturazione</h1>

        <div class="flex-row">
            <div style="display: inline" matTooltip="Scarica Fattura" matTooltipPosition="above">
                <button type="button" mat-fab color="accent" (click)="downloadPdf()" [disabled]="selection.isEmpty()">
                    <mat-icon>download</mat-icon>
                </button>
            </div>

            <button mat-fab color="primary" name="addEntityButton" matTooltip="Nuova Fattura"
                    i18n-matTooltip="@@new_invoice" matTooltipPosition="above"
                    [routerLink]="['new']">
                <mat-icon>add</mat-icon>
            </button>

            <button mat-fab color="primary" name="importXmlButton" matTooltip="Importa Fattura da XML"
                    i18n-matTooltip="import_from_xml"
                    matTooltipPosition="above"
                    (click)="selectXml($event, xmlUpload)">
                <mat-icon>cloud_upload</mat-icon>
            </button>

            <input name="fileUpload" type="file" style="display: none" (change)="importXml($event)" #xmlUpload>
        </div>
    </header>

    <div class="content">

        <mat-card class="infoCard invoices">
            <div *ngIf="data | async as datasource">

                <mat-card-header>
                    <mat-icon mat-card-avatar>receipt</mat-icon>

                    <div class="headerWithCommand">
                        <mat-card-title>
                            <span i18n="@@inbound_invoices">Fatture in entrata</span>
                        </mat-card-title>
                        <search-table [dataSource]="$any(datasource)"></search-table>
                    </div>
                </mat-card-header>

                <mat-card-content>

                    <table mat-table matSort [dataSource]="datasource" matSortActive="date" matSortDirection="desc">

                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                              [checked]="selection.hasValue() && isAllSelected()"
                                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox (click)="$event.stopPropagation()"
                                              (change)="$event ? selection.toggle(row) : null"
                                              [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="number">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef>Numero</th>
                            <td mat-cell *matCellDef="let invoice">{{ invoice.number }} <span class="label" i18n="@@new"
                                                                                              *ngIf="invoice.isReceived()">Nuova</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="sender">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@supplier">Fornitore</th>
                            <td mat-cell *matCellDef="let invoice">{{ invoice.sender.name }}</td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@issue_date">Data emissione
                            </th>
                            <td mat-cell *matCellDef="let invoice">{{ invoice.date | date }}</td>
                        </ng-container>

                        <ng-container matColumnDef="dueDate">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@due_date">Data scadenza</th>
                            <td mat-cell *matCellDef="let invoice"> {{ invoice.dueDate || '' | date }}</td>
                        </ng-container>

                        <ng-container matColumnDef="paid">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@payment_state">Stato
                                pagamento
                            </th>
                            <td mat-cell *matCellDef="let invoice"> {{ invoice.isPaid() ? 'Pagata' : 'Non pagata' }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="taxable">
                            <th mat-header-cell class="currencyColumn" mat-sort-header *matHeaderCellDef
                                i18n="@@taxable">Imponibile
                            </th>
                            <td mat-cell class="currencyColumn"
                                *matCellDef="let invoice"> {{ invoice.taxable | formatCurrency }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="tax">
                            <th mat-header-cell class="currencyColumn" mat-sort-header *matHeaderCellDef i18n="@@tax">
                                Imposta
                            </th>
                            <td mat-cell class="currencyColumn"
                                *matCellDef="let invoice"> {{ invoice.tax | formatCurrency }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <th mat-header-cell class="currencyColumn" mat-sort-header *matHeaderCellDef
                                i18n="@@amount">Importo
                            </th>
                            <td mat-cell class="currencyColumn"
                                *matCellDef="let invoice"> {{ invoice.total | formatCurrency }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let invoice" style="width: 6%">
                                <div class="iconsContainer">

                                    <button type="button" mat-icon-button *ngIf="invoice.isDraft()"
                                            (click)="deleteInvoice($event, invoice)">
                                        <mat-icon class="mat-24">delete</mat-icon>
                                    </button>
                                    <mat-icon *ngIf="invoice.hasAttachments()" i18n-matTooltip="@@contains_attachments"
                                              matTooltip="La fattura contiene allegati" matTooltipPosition="above">
                                        attach_file
                                    </mat-icon>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let invoice; columns: displayedColumns;"
                            [class.highlighted]="invoice.isReceived()"
                            (click)="invoiceSelected(invoice)"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" style="text-align: center" [colSpan]="displayedColumns.length"
                                i18n="@@no_invoice">Nessuna
                                fattura
                            </td>
                        </tr>

                    </table>
                </mat-card-content>
            </div>

            <mat-card-footer>
                <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
            </mat-card-footer>

        </mat-card>

    </div>
</article>
