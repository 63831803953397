import {Component, Input, OnInit} from '@angular/core';
import {Employee} from "../../model/employee";
import * as moment from "moment";

@Component({
    selector: 'employee-card',
    templateUrl: './employee-card.component.html',
    styleUrls: ['./employee-card.component.scss']
})
export class EmployeeCardComponent implements OnInit {


    @Input() employee: Employee;
    currentYear: number;

    constructor() {
    }

    ngOnInit(): void {
        this.currentYear = moment().year()
    }

}
