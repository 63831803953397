import {Component, Injectable} from '@angular/core';
import {ListLayoutComponent} from "../components/list-layout/list-layout.component";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Repository} from "../persistence/repository";
import {Business, Showcase} from "../model/business";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";
import {ConverterRegistry} from "../persistence/converter-registry.service";
import {getCurrentBusiness} from "../utils";
import {collectionData, CollectionReference, query} from "@angular/fire/firestore";
import {FakeShowcaseRepository} from "../admin/showcases/fake-showcases.component";
import {combineLatestWith, flatMap, map, merge, mergeMap, Observable, of, tap} from "rxjs";
import {Timesheet} from "../model/timesheet";
import {Employee} from "../model/employee";
import {Supplier} from "../model/supply";

@Injectable({
    providedIn: 'root'
})
export class ShowcaseRepository extends Repository<Showcase> {
    business: Business

    constructor(firestore: FirestoreWrapper) {
        super(firestore, ConverterRegistry.find('Showcase'))
    }

    override getCollectionReference() {
        this.business = getCurrentBusiness();
        return this.firestoreWrapper.collection(`businesses/${this.business.id}/showcase`).withConverter(this.converter);
    }

    getAllBusinesses() {
        return collectionData( query(this.firestoreWrapper.collectionGroup('showcase').withConverter(this.converter)) )
    }


    override findById(id: string): Observable<Showcase | undefined> {
        // @ts-ignore
        return collectionData( query(this.firestoreWrapper.collectionGroup('showcase').withConverter(this.converter)))
            .pipe(map(value => value.filter(value1 => {
                return value1.id == id;
            })[0]))
    }
}

@Injectable({
    providedIn: 'root'
})
export class CompositeShowcaseRepository extends Repository<Showcase> {


    constructor(private showcaseRepository: ShowcaseRepository,private fakeShowcaseRepository: FakeShowcaseRepository) {
        // @ts-ignore
        super(null, null);
    }

    getCollectionReference(): CollectionReference<Showcase> {
        return this.firestoreWrapper.collection(`wrong`).withConverter(this.converter);
    }

    override getAll(): Observable<Showcase[]> {
        return this.showcaseRepository.getAllBusinesses().pipe(combineLatestWith(this.fakeShowcaseRepository.getAll()),
            map(args => [...args[0], ...args[1]]),
            tap(value => console.log("...", value)))
    }


    override findById(id: string) {
        let observable: Observable<Showcase | undefined> = this.showcaseRepository.findById(id).pipe(mergeMap((value, index) => {
            if(value == undefined) return this.fakeShowcaseRepository.findById(id)

            return of(value)
        }))

        return observable
    }
}

@Component({
    selector: 'app-showcase',
    templateUrl: './business-showcases.component.html',
    styleUrls: ['./business-showcases.component.scss']
})
export class BusinessShowcasesComponent extends ListLayoutComponent {

    label: { singular: string; plural: string };

    constructor(public showcaseRepository: CompositeShowcaseRepository, router: Router, snackBar: MatSnackBar) {
        // @ts-ignore
        super(showcaseRepository, router, snackBar);
        this.label = {singular: 'Impresa edile', plural: 'Imprese edili'}
    }

}
