import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {DOCUMENT} from "@angular/common";
import {map} from "rxjs";

declare var gtag: any;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    private loaded = false
    private renderer2: Renderer2

    constructor(private rendererFactory2: RendererFactory2, @Inject(DOCUMENT) private _document: Document, private router: Router) {
        if (!environment.googleAnalytics.enabled || this.loaded) return

        this.renderer2 = this.rendererFactory2.createRenderer(null, null);

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((e) => {
            //    gtag('js', new Date());
            //    gtag('config', environment.googleAnalytics.id);
        });
    }

    init() {
        if (!environment.googleAnalytics.enabled || this.loaded) return

        let analyticsId = environment.googleAnalytics.id;

        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
        script.async = true;
        script.type = 'text/javascript';
        script.onload = () => {
            const secondScript = document.createElement('script');
            secondScript.type = 'text/javascript';
            secondScript.src = '/assets/analytics.js'

            secondScript.onload = () => {
                this.setupPagePathTagging(analyticsId);
            };

            this.renderer2.appendChild(this._document.body, secondScript);
        }

        this.renderer2.appendChild(this._document.body, script);
    }

    sendContactRequestClicked() {
        gtag('event', 'contact_request_click')
    }

    private setupPagePathTagging(analyticsId: string) {
        gtag('js', new Date())
        gtag('config', analyticsId)
        this.loaded = true

        this.router.events.pipe(filter(event => event instanceof NavigationEnd), map(value => value as NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                    gtag('config', analyticsId, {'page_path': event.urlAfterRedirects});
                }
            )
    }
}
