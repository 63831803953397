<mat-card *ngIf="supplier" class="infoCard summaryCard">
    <mat-card-header>
        <mat-icon mat-card-avatar>local_shipping</mat-icon>

        <mat-card-title>{{supplier.name}}</mat-card-title>
        <mat-card-subtitle>{{supplier.taxNumber}}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <table>
            <tr>
                <td>
                    <mat-icon>pallet</mat-icon>
                </td>
                <td><span i18n="@@number_of_invoices">Numero di forniture</span></td>
                <td>{{ supplier.numberOfSupplies() }}</td>
            </tr>

            <tr>
                <td>
                    <mat-icon>arrow_backward</mat-icon>
                </td>
                <td><span i18n="@@received_invoices">Fatture ricevute</span></td>
                <td>{{ supplier.numberOfIssuedInvoices() }}</td>
            </tr>

            <tr>
                <td>
                    <mat-icon>payments</mat-icon>
                </td>
                <td><span i18n="@@paid_invoices">Fatture pagate</span></td>
                <td>{{ supplier.numberOfPaidInvoices() }}</td>
            </tr>

            <tr *ngIf="supplier.numberOfUnpaidInvoices() as numberOfPaidInvoices" class="alert">
                <td>
                    <mat-icon>error</mat-icon>
                </td>
                <td><span i18n="@@overdue_invoices">Fatture scadute</span></td>
                <td>{{ numberOfPaidInvoices }}</td>
            </tr>
        </table>
    </mat-card-content>

    <mat-card-actions>
        <button mat-button [routerLink]="[supplier.id]"  i18n="@@see">VEDI</button>
    </mat-card-actions>
</mat-card>
