import {Injectable} from '@angular/core';
import {Repository} from "../persistence/repository";
import {getCurrentBusiness} from "../utils";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";
import {ConverterRegistry} from "../persistence/converter-registry.service";
import {Chat, Message} from "./message";
import {Business} from "../model/business";
import {Converter} from "../persistence/converter";
import {Quote} from "../model/invoice";
import {first, tap} from "rxjs";
import {collectionData, query, where} from "@angular/fire/firestore";

@Injectable({
    providedIn: 'root'
})
export class ChatRepository extends Repository<Chat> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, ConverterRegistry.find('Chat'))
    }

    override getCollectionReference(businessId: string | null = null) {
        let business: Business = getCurrentBusiness();
        return this.firestoreWrapper.collection(`businesses/${businessId || business.id}/chats`).withConverter(this.converter);
    }

    saveMessage(chat: Chat, message: Message) {
        let reference = this.firestoreWrapper.doc(this.getCollectionReference(chat.context.businessId), chat.id);
        return this.firestoreWrapper.addToArray(reference, 'messages', message, new Converter(Message))
    }

    saveChatADMIN(chat: Chat) {
        let reference = this.firestoreWrapper.doc(this.getCollectionReference(chat.context.businessId), chat.id);
        return this.firestoreWrapper.setDoc(reference, chat)
    }

    findByProjectRequestIdCOLLECTIONGROUP(id: string) {
        return collectionData(query(
            this.firestoreWrapper.collectionGroup('chats').withConverter(this.converter), where('context.projectRequestId', '==', id)
        ))
    }

    findByProjectRequestId(id: string) {
        return this.firestoreWrapper.query(this.getCollectionReference(), 'context.projectRequestId', id)
    }

    saveQuoteByProjectRequestId(quote: Quote, id: string) {
        return this.findByProjectRequestId(id).pipe(first()).subscribe((chats: Chat[]) => {
            let chat = chats[0]
            chat.quoteId = quote.id

            let business: Business = getCurrentBusiness();
            let message = chat.sendMessageFromBusiness(business, 'Un nuovo preventivo è stato inviato o aggiornato');
            return this.saveMessage(chat, message).then(value => this.save(chat))

        })
    }
}

