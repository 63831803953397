import {Component, OnInit} from '@angular/core';
import {Moment} from "moment";
import {PriceListRepository} from "../priceList.repository";
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Searchable} from "../../model/fiscalDocument";
import {Entity} from "../../model/entity";

@Component({
    selector: 'price-lists',
    templateUrl: './price-lists.component.html',
    styleUrls: ['./price-lists.component.scss']
})
export class PriceListsComponent implements OnInit {
    lists: Observable<PriceList[]>;

    displayedColumns: string[] = ['id', 'name', 'date', 'actions'];

    constructor(private priceListRepository: PriceListRepository, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.lists = this.priceListRepository.getAll()
    }

    onSelected(priceList) {
        this.router.navigate([priceList.id], {relativeTo: this.route});
    }

    delete(event: MouseEvent, priceList) {
        event.preventDefault()
        event.stopPropagation()

        let confirmation = confirm("Do you want to delete the entity?");

        if(confirmation) {
            this.priceListRepository.delete(priceList)
        }
    }
}

export class PriceList extends Entity {
    name: string
    items: PricedItem[] = []
    date: Moment
    length: number = 0
    index: string;
}

export class PricedItem extends Entity implements Searchable {
    code: string
    description: string
    unitOfMeasure: string
    cost: number

    asText(transformers: any): string {
        return this.description
    }
}
