import {Component, OnInit} from '@angular/core';
import {ContactRequest, ContactRequestRepository} from "./contact-request.repository";
import {NgForm} from "@angular/forms";
import {NotificationService} from "../notification.service";
import {environment} from "../../environments/environment";
import {GoogleAnalyticsService} from "../google-analytics.service";

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
    contactRequest = new ContactRequest()
    reviewSelected: number = 1;

    promotion = environment.promotion

    constructor(private repository: ContactRequestRepository, private notificationService: NotificationService, private analyticsService: GoogleAnalyticsService) {
    }

    ngOnInit(): void {
    }


    onReviewSelectorClicked(review: number) {
        this.reviewSelected = review;
    }

    onSubmit(form: NgForm) {
        if (form.invalid) return

        this.repository.save(this.contactRequest)
        this.notificationService.notify("Richiesta inviata")
        this.contactRequest = new ContactRequest()
        form.resetForm()
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({
            behavior: 'smooth'
        });
    }

    sendContactRequestClicked() {
        this.analyticsService.sendContactRequestClicked()
    }

    formChanged() {
        console.log("form changed")
    }
}
