import {Injectable} from "@angular/core";
import {collectionGroup, Firestore, getDocs, query, where,} from "@angular/fire/firestore";
import {getCurrentBusiness} from "../utils";
import {ProjectConverter} from "../projects-management/project-repository.service";
import {Project} from "../model/project";
import {DocumentConverter} from "../document.repository";

@Injectable({
    providedIn: 'root'
})
export class BusinessAccountingRepository {

    constructor(private firestore: Firestore) {
    }

    async getAllProjects() {
        let documentConverter = new DocumentConverter();
        let projectConverter = new ProjectConverter(documentConverter);
        const querySnapshot = await getDocs(
            query(
                collectionGroup(this.firestore, 'projects').withConverter(projectConverter),
                where('businessId', '==', getCurrentBusiness().id)
            )
        );

        let projects: Project[] = []
        querySnapshot.forEach((doc) => {
            projects.push(doc.data())
        });

        return projects
    }
}
