import {Entity} from "./entity";
import {Moment} from "moment/moment";
import {persist, PersistenceConfig} from "../persistence/decorators";

export enum Status {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED'
}

@persist(new PersistenceConfig({name: 'ProjectRequest', properties: ['title', 'date', 'requester', 'status', 'description']}))
export class ProjectRequest extends Entity {
    title: string
    requester: string
    status: Status = Status.OPEN
    date: Moment
    description: string;

    isClosed() {
        return this.status == Status.CLOSED
    }
}
