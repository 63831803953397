import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {Leave, SickLeave} from "../../model/leave";
import {MatTable} from "@angular/material/table";
import {CrudCardComponent} from "../../components/crud-card/crud-card.component";
import {DocumentStorageBatch} from "../../document-storage.service";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import * as moment from "moment/moment";
import {clone, generateId} from "../../utils";
import {SickLeaveRecordDialog} from "./sick-leave-record-dialog/sick-leave-record-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'sick-leave-profile',
    templateUrl: './sick-leave-profile.component.html',
    styleUrls: ['./sick-leave-profile.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SickLeaveProfileComponent
        }
    ]
})
export class SickLeaveProfileComponent extends CrudCardComponent<SickLeave> implements AfterViewInit {

    @ViewChild(MatTable) leaveTable: MatTable<SickLeave>;
    @Input() batch: DocumentStorageBatch;

    displayedColumns: string[] = ['from', 'to', 'actions'];
    selectedYear: BehaviorSubject<number> = new BehaviorSubject(moment().year());
    filteredList: BehaviorSubject<SickLeave[]> = new BehaviorSubject<SickLeave[]>([]);

    constructor(dialog: MatDialog) {
        super(dialog)

        this.setDialogComponent(SickLeaveRecordDialog)
    }

    ngAfterViewInit() {
        super.table = this.leaveTable
    }

    public newEntity() {
        let sickLeave = new SickLeave();
        sickLeave.id = generateId()
        return sickLeave
    }

    get leaves(): BehaviorSubject<SickLeave[]> {
        return this.filteredList;
    }

    override remove(event: Event, entity: SickLeave) {
        super.remove(event, entity);
        entity.getDocuments().forEach(value =>  this.batch.addToDeleted(value) )
    }

    override onListWritten() {
        this.selectedYear.subscribe(year => this.filteredList.next( this.list?.filter(leave => leave.from.year() == year) || [] ))
    }

    moveTimeLeft() {
        this.selectedYear.next(this.selectedYear.value-1)
    }

    moveTimeRight() {
        this.selectedYear.next(this.selectedYear.value+1)
    }

    override hideTable(): boolean {
        return this.filteredList.value.length == 0
    }

    override refreshTable() {
        this.filteredList.next( this.list?.filter(leave => leave.from.year() == this.selectedYear.value) || [] )
    }

    openNewDialog() {
        super.openNewRecordDialog({
            record: this.newEntity(),
            batch: this.batch
        })
    }

    openEditDialog(event: MouseEvent, record: Leave) {
        super.openEditRecordDialog(event, {
            record: clone(record),
            batch: this.batch
        })

    }
}
