import {Payment} from "./payment";
import {Invoice} from "./invoice";
import {Document} from "./document";
import {FiscalDocument} from "./fiscalDocument";
import {Entity} from "./entity";

export class Balance extends Entity {
    payments: Payment[] = []
    quotedAmount: number | null = null
    amount: number | null = null

    addPaymentFromInvoice(invoice: FiscalDocument) {
        let payment = new Payment();
        this.syncPaymentWithInvoice(payment, invoice);

        this.payments.push(payment)

        return payment
    }

    addPaymentsFromInvoices(invoices: FiscalDocument[]) {
        invoices.forEach(invoice => this.addPaymentFromInvoice(invoice))
    }

    private syncPaymentWithInvoice(payment: Payment, invoice: FiscalDocument) {
        payment.syncPaymentWithInvoice(invoice)
    }

    updatePaymentFromInvoice(invoice: Invoice) {
        let payment = this.findInvoicePayment(invoice)!
        this.syncPaymentWithInvoice(payment, invoice)
    }

    findInvoicePayment(invoice: Invoice) {
        return this.payments.find(value => value.invoiceId == invoice.id);
    }

    getTotal(): number {
        return this.payments.reduce((total, payment) => total + payment.amount, 0)
    }

    getResidualToPay(): number {
        if (!this.amount) return 0

        return this.amount - this.getTotal()
    }

    numberOfInvoices() {
        return this.payments.length
    }

    numberOfPaidInvoices() {
        return this.payments.filter(value => value.isPaid()).length
    }

    numberOfUnpaidInvoices() {
        return this.payments.filter(value => value.isOverdue()).length
    }

    override getDocuments(): Document[] {
        return this.payments.map(value => value.getDocuments()).flat()
    }
}
