import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ProcessingError} from "../data-import.component";

@Component({
  selector: 'app-employees-import-dialog',
  templateUrl: './employees-import-dialog.component.html',
  styleUrls: ['./employees-import-dialog.component.scss']
})
export class EmployeesImportDialog {
    errors: ProcessingError[];
    entities: any[];
    displayedColumns: string[] = ["name","taxNumber","function","rate"];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.errors = data.errors
        this.entities = data.entities.map(value => value.entity)
    }

    isBadImport(entity) {
        return !!this.errors.find(value => value.entity == entity)
    }

    isErrorPresent() {
        return this.errors.length > 0
    }
}
