<form id="inboundInvoicePage" name="businessForm" [fiscalDocument]="invoice" validLines #form="ngForm" (ngSubmit)="saveInvoice(invoice, form)"
      (reset)="discardChanges(invoice)">
    <article class="page" id="invoiceView">
        <header>
            <h1 i18n="@@invoice">Fattura</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above" [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <export-invoice-to-pdf *ngIf="invoice.filePath" [fiscalDocument]="invoice" [form]="form"></export-invoice-to-pdf>
                <export-invoice-to-xml *ngIf="invoice.filePath" [fiscalDocument]="invoice"></export-invoice-to-xml>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>
        <div class="content">

            <mat-card class="infoCard link">
                <mat-card-header>
                    <mat-icon mat-card-avatar>link</mat-icon>
                    <mat-card-title i18n="@@link_invoice">
                        Associazione Fattura
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <div *ngIf="!invoice.isLinked()" class="warning">
                        <mat-icon>warning</mat-icon>
                        <p i18n="@@invoice_not_linked">La fattura non è associata ad alcuna fornitura.</p>
                    </div>
                    <div class="linkContent">
                            <div class="flex-row" style="align-items: baseline">
                                <mat-form-field *ngIf="projects && erpEnabled">
                                    <mat-label i18n="@@project">Progetto</mat-label>

                                    <mat-select name="project" [(ngModel)]="invoice.project"
                                                [disabled]="invoice.isLinked() || !!invoice.supplier"
                                                [compareWith]="compareEntities"
                                                (selectionChange)="getSuppliesByProject(invoice.project)">
                                        <mat-option *ngFor="let project of projects" [value]="project">
                                            {{ project.name }}
                                        </mat-option>
                                        <mat-option [value]="null" i18n="@@no_proeject">Nessun progetto</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <p i18n="@@or">oppure</p>

                                <mat-form-field *ngIf="suppliers && erpEnabled">
                                    <mat-label i18n="@@supplier">Fornitore</mat-label>

                                    <mat-select name="supplier" [(ngModel)]="invoice.supplier"
                                                [disabled]="invoice.isLinked() || !!invoice.project"
                                                [compareWith]="compareEntities"
                                                (selectionChange)="getSuppliesBySupplier(invoice.supplier)">
                                        <mat-option *ngFor="let supplier of suppliers" [value]="supplier">
                                            {{ supplier.name }}
                                        </mat-option>
                                        <mat-option [value]="null" i18n="@@no_supplier">Nessun fornitore</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <mat-form-field>
                                <mat-label i18n="@@supply">Fornitura</mat-label>

                                <mat-select name="supply" #supply="ngModel" [(ngModel)]="invoice.supply" [disabled]="invoice.isLinked()"
                                            [compareWith]="compareEntities" (selectionChange)="onSupplySelected(invoice)">
                                    <div *ngIf="invoice.project || invoice.supplier">
                                        <mat-option *ngFor="let supplyItem of this.supplies"
                                                    [value]="supplyItem">
                                            {{ supplyItem.description }}
                                        </mat-option>
                                    </div>

                                    <mat-option [value]="null" i18n="@@no_supply">Nessuna fornitura</mat-option>
                                </mat-select>
                            </mat-form-field>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="infoCard invoice">
                <mat-card-header>
                    <mat-icon mat-card-avatar>receipt</mat-icon>

                    <mat-card-title class="includeInlineInput">
                        <span i18n="@@number">Numero</span>

                        <mat-form-field class="title">
                            <input name="number" #number="ngModel"
                                   required matInput [disabled]="!invoice.isDraft()"
                                   [(ngModel)]="invoice.number">
                            <mat-error *ngIf="number.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>


                        <span class="tag warning" *ngIf="!invoice.isPaid()" i18n="@@not_paid">Non Pagata</span>
                        <span class="tag" *ngIf="invoice.isPaid()" i18n="@@paid">Pagata</span>
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content id="invoiceBody">

                    <section class="details">

                        <mat-form-field>
                            <mat-select name="type" #type="ngModel" required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.type">
                                <mat-option *ngFor="let invoiceType of fiscalDocumentTypes" [value]="invoiceType.value">
                                    {{ invoiceType.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="type.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label i18n="@@issue_date">Data emissione</mat-label>
                            <input name="date" matInput [matDatepicker]="pickerdate" #date="ngModel" required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.date">
                            <mat-datepicker-toggle matSuffix [for]="pickerdate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerdate></mat-datepicker>
                            <mat-error *ngIf="date.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label i18n="@@due_date">Data scadenza</mat-label>
                            <input name="dueDate" matInput [matDatepicker]="pickerDueDate" [min]="invoice.date"
                                   #dueDate="ngModel" required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.dueDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerDueDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDueDate></mat-datepicker>
                            <mat-error *ngIf="dueDate.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            <mat-error *ngIf="dueDate.hasError('matDatepickerMin')" i18n="@@due_date_invalid">Data scadenza precendete a data di emissione</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label i18n="@@purchase_order">Ordine D'acquisto</mat-label>
                            <input name="purchaseOrder" #purchaseOrder="ngModel" matInput [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.purchaseOrder">
                            <mat-error *ngIf="purchaseOrder.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field  (mouseover)="tooltip.show()">
                            <mat-label i18n="@@paid_date">Data pagamento</mat-label>
                            <input name="paidDate" matInput [matDatepicker]="pickerPaidDate" [(ngModel)]="invoice.paidDate" (focus)="tooltip.show()"
                                   i18n-matTooltip="@@paid_date_info"
                                   matTooltip="Una volta specificata la data di pagamento, non sarà più possibile cancellare la fattura"
                                   matTooltipPosition="before" #tooltip="matTooltip" matTooltipClass="paidDateHint">
                            <mat-datepicker-toggle matSuffix [for]="pickerPaidDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerPaidDate></mat-datepicker>
                        </mat-form-field>

                        <document-input type="receipt" label="Prova di pagamento" name="receipt" [batch]="documentStorageBatch" [(ngModel)]="invoice.receipt"></document-input>

                    </section>

                    <section class="from">
                        <header>
                            <h1 i18n="@@from">Da</h1>
                            <mat-form-field *ngIf="invoice.isDraft()">
                                <mat-select name="from" required [(ngModel)]="invoice.sender" [compareWith]="compareEntities">
                                    <mat-option *ngFor="let option of suppliers" [value]="option">{{ option.name }}</mat-option>
                                    <mat-option *ngIf="senderOptions.length == 0" [value]="newGenericSender()" i18n="@@select_supplier">Seleziona Fornitore</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </header>

                        <div>

                            <mat-form-field>
                                <mat-label i18n="@@name">Nome</mat-label>
                                <input name="senderName" #fullName="ngModel" required matInput [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.sender.name">
                                <mat-error *ngIf="fullName.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>


                            <mat-form-field>
                                <mat-label i18n="@@address">Indirizzo</mat-label>
                                <input name="senderStreet" #address="ngModel" matInput required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.sender.address.street">
                                <mat-error *ngIf="address.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label i18n="@@city">Citt&agrave;</mat-label>
                                <input name="senderCity" #city="ngModel" matInput required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.sender.address.city">
                                <mat-error *ngIf="city.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label i18n="@@postcode">CAP</mat-label>
                                <input name="senderPostcode" #postcode="ngModel" matInput required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.sender.address.postcode">
                                <mat-error *ngIf="postcode.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label i18n="@@district">Provincia</mat-label>
                                <mat-select name="senderDistrict" #district="ngModel" required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.sender.address.district">
                                    <mat-option *ngFor="let district of districts" [value]="district.abbr">
                                        {{ district.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="district.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                            <mat-form-field>
                                <mat-label i18n="@@vat_number_or_tax_number">Partita IVA / Codice fiscale</mat-label>
                                <input name="senderTaxNumber" #taxNumber="ngModel" matInput required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.sender.taxNumber">
                                <mat-error *ngIf="taxNumber.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>
                        </div>


                    </section>

                    <section class="to">
                        <header><h1 i18n="@@to">A</h1></header>

                        <div class="entityDetails">
                            <div class="label" i18n="@@name">Nome</div>
                            <div class="value">
                                <p>{{ invoice.receiver.name }}</p>
                            </div>
                            <div class="label" i18n="@@address">Indirizzo</div>
                            <div class="value">
                                <p>{{ invoice.receiver.address.street }} {{ invoice.receiver.address.city }}
                                    {{ invoice.receiver.address.postcode }} {{ invoice.receiver.address.district }}</p>
                            </div>
                            <div class="label" i18n="@@vat_number_or_tax_number">Partita IVA / Codice fiscale</div>
                            <div class="value">
                                <p>{{ invoice.receiver.taxNumber }}</p>
                            </div>
                        </div>
                    </section>

                    <section class="description">
                        <header><h1 i18n="@@description">Causale</h1></header>

                        <mat-form-field>
                            <textarea name="description" #description="ngModel" cdkTextareaAutosize [disabled]="!invoice.isDraft()" matInput
                                      [(ngModel)]="invoice.description"></textarea>
                        </mat-form-field>
                    </section>

                    <section class="lines">

                        <div class="actions" *ngIf="invoice.isDraft()">
                            <button mat-flat-button color="primary" [matMenuTriggerFor]="addMenu" type="button"  i18n="@@add">Aggiungi</button>
                        </div>

                        <mat-menu #addMenu="matMenu">
                            <button mat-menu-item (click)="addNewLine()"  i18n="@@line">Linea</button>
                            <button mat-menu-item (click)="addNewPricedItem()"  i18n="@@line_list">Linea da listino</button>
                            <button mat-menu-item (click)="addNewDiscount()"  i18n="@@discount">Sconto</button>
                            <button mat-menu-item (click)="addNewSurcharge()"  i18n="@@surcharge">Maggiorazione</button>
                            <button mat-menu-item (click)="addNewGovernmentContribution()"  i18n="@@government_contribution">Contributo Governativo</button>
                        </mat-menu>

                        <lines name="lines" [(ngModel)]="invoice.lines" [priceModifiers]="invoice.priceModifiers" [form]="form" [disabled]="!invoice.isDraft()"></lines>

                        <price-modifiers name="priceModifiers" [(ngModel)]="invoice.priceModifiers" [fiscalDocument]="invoice" [disabled]="!invoice.isDraft()"></price-modifiers>
                        <table mat-table class="input-table">

                            <tr mat-footer-row *matFooterRowDef="['taxableDesc', 'taxableValue', 'empty']"></tr>
                            <tr mat-footer-row *matFooterRowDef="['taxDesc', 'taxValue', 'empty']"></tr>
                            <tr mat-footer-row *matFooterRowDef="['footerDesc', 'footerValue', 'empty']"
                                class="highlighted"></tr>


                            <ng-container matColumnDef="empty">
                                <td mat-footer-cell *matFooterCellDef>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="taxDesc">
                                <td mat-footer-cell *matFooterCellDef style="text-align: right"  i18n="@@vat">IVA</td>
                            </ng-container>
                            <ng-container matColumnDef="taxValue">
                                <td mat-footer-cell *matFooterCellDef style="width: 7%">
                                    {{ invoice.tax | formatCurrency }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="taxableDesc">
                                <td mat-footer-cell *matFooterCellDef style="text-align: right"  i18n="@@taxable_amount">
                                    Imponibile
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="taxableValue">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ invoice.taxable | formatCurrency }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="footerDesc">
                                <td mat-footer-cell *matFooterCellDef style="text-align: right"  i18n="@@total">
                                    Totale
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="footerValue">
                                <td mat-footer-cell *matFooterCellDef>
                                    {{ invoice.total | formatCurrency }}
                                </td>
                            </ng-container>
                        </table>
                        <government-contributions name="governmentContributions" [(ngModel)]="invoice.priceModifiers"
                                                  [linesTotal]="invoice.getLinesTotal()"
                                                  [disabled]="!invoice.isDraft()"></government-contributions>
                    </section>

                    <invoice-payments name="invoicePayments" [(ngModel)]="invoice.payments" [form]="form" [disabled]="!invoice.isDraft()"
                                      [invoiceTotal]="invoice.getTotalWithContributions()"></invoice-payments>

                    <attachments [descriptionRequired]="false" name="attachments" [(ngModel)]="invoice.attachments" [fiscalDocumentFilePath]="invoice.filePath"></attachments>

                </mat-card-content>

            </mat-card>

        </div>
    </article>
</form>
