<form name="businessForm" #form="ngForm" (ngSubmit)="saveInvoice(invoice, form)"
      [paymentsTotalMatchInvoiceTotal]="invoice" validLines [fiscalDocument]="invoice" (reset)="discardChanges(invoice)">
    <article class="page" id="invoiceView">
        <header>
            <h1 i18n="@@invoice">Fattura</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above"
                        [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button type="button" mat-mini-fab color="accent" (click)="send(invoice, form)"
                        *ngIf="!invoice.isSent() && !invoice.isSending() && !invoice.isPaid()"
                        matTooltip="Invia" matTooltipPosition="above">
                    <mat-icon>send</mat-icon>
                </button>

                <button type="button" mat-mini-fab color="primary" (click)="downloadProForma(invoice, form)"
                        matTooltip="Scarica Pro Forma" matTooltipPosition="above">
                    <mat-icon>download</mat-icon>
                </button>

                <export-invoice-to-pdf *ngIf="invoice.filePath" [fiscalDocument]="invoice" [form]="form"></export-invoice-to-pdf>

                <export-invoice-to-xml *ngIf="invoice.filePath" [fiscalDocument]="invoice"></export-invoice-to-xml>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>
        <div class="content">

            <mat-card class="infoCard invoice">
                <mat-card-header>
                    <mat-icon mat-card-avatar>receipt</mat-icon>

                    <mat-card-title class="includeInlineInput">
                        <span>Numero</span>

                        <mat-form-field>
                            <input name="number" #number="ngModel"
                                   required matInput [disabled]="!invoice.isDraft()"
                                   uniqueInvoiceNumber
                                   pattern="^\d+$|^([A-z]*\d+[\-A-z\d]*)+$"
                                   [fiscalDocument]="invoice"
                                   [ngModelOptions]="{updateOn: 'blur'}"
                                   [(ngModel)]="invoice.number">
                            <mat-error *ngIf="number.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            <mat-error *ngIf="number.hasError('nonUnique')" i18n="@@non_unique_invoice_number">Numero fattura già utilizzato</mat-error>
                            <mat-error *ngIf="number.hasError('pattern')" i18n="@@at_least_one_number">Deve contenere almeno un numero</mat-error>
                        </mat-form-field>


                        <span class="tag warning" *ngIf="invoice.isDraft()" i18n="@@not_sent">Non Inviata</span>
                        <span class="tag" *ngIf="invoice.isSent() && !invoice.isError()" i18n="@@sent">Inviata</span>
                        <span class="tag" *ngIf="invoice.isSending()" i18n="@@sending">Invio In Corso</span>
                        <span class="tag warning" *ngIf="invoice.isError()" i18n="@@error">Errore</span>
                        <span class="tag warning" *ngIf="!invoice.isPaid() && invoice.isSent()" i18n="@@not_paid">Non Pagata</span>
                        <span class="tag" *ngIf="invoice.isPaid()" i18n="@@paid">Pagata</span>
                    </mat-card-title>
                </mat-card-header>

                <mat-card-content id="invoiceBody">
                    <section class="details">

                        <mat-form-field>
                            <mat-select name="type" #type="ngModel" required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.type">
                                <mat-option *ngFor="let invoiceType of fiscalDocumentTypes" [value]="invoiceType.value">
                                    {{ invoiceType.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="type.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Data emissione</mat-label>
                            <input name="date" matInput [matDatepicker]="pickerdate" #date="ngModel" required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.date">
                            <mat-datepicker-toggle matSuffix [for]="pickerdate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerdate></mat-datepicker>
                            <mat-error *ngIf="date.hasError('required')" i18n="@@required_field" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Data scadenza</mat-label>
                            <input name="dueDate" matInput [matDatepicker]="pickerDueDate" [min]="invoice.date"
                                   #dueDate="ngModel" required [disabled]="!invoice.isDraft()" [(ngModel)]="invoice.dueDate">
                            <mat-datepicker-toggle matSuffix [for]="pickerDueDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDueDate></mat-datepicker>
                            <mat-error *ngIf="dueDate.hasError('required')" i18n="@@required_field" i18n="@@required_field">Campo obbligatorio</mat-error>
                            <mat-error *ngIf="dueDate.hasError('matDatepickerMin')" i18n="@@invalid_due_date">Data scadenza precendete a data di emissione</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Ordine D'acquisto</mat-label>
                            <input name="purchaseOrder" #purchaseOrder="ngModel"
                                   matInput [disabled]="!invoice.isDraft()"
                                   [(ngModel)]="invoice.purchaseOrder">
                            <mat-error *ngIf="purchaseOrder.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Data pagamento</mat-label>
                            <input name="paidDate" matInput [matDatepicker]="pickerPaidDate" [(ngModel)]="invoice.paidDate"
                                   i18n-matTooltip="@@paid_date_info"
                                   matTooltip="Una volta specificata la data di pagamento, non sarà più possibile cancellare la fattura"
                                   matTooltipPosition="before" #tooltip="matTooltip" matTooltipClass="paidDateHint">
                            <mat-datepicker-toggle matSuffix [for]="pickerPaidDate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerPaidDate></mat-datepicker>
                        </mat-form-field>

                        <document-input type="receipt" label="Prova di pagamento" i18n-label="@@payment_proof"
                                        name="receipt" [batch]="documentStorageBatch"
                                        [(ngModel)]="invoice.receipt"></document-input>


                    </section>

                    <section class="from">
                        <header>
                            <h1>Da</h1>
                        </header>

                        <div class="entityDetails">
                            <div class="label">Nome</div>
                            <div class="value">
                                <p>{{ invoice.sender.name }}</p>
                            </div>
                            <div class="label">Indirizzo</div>
                            <div class="value">
                                <p>{{ invoice.sender.address.street }} {{ invoice.sender.address.city }}
                                    {{ invoice.sender.address.postcode }} {{ invoice.sender.address.district }}</p>
                            </div>
                            <div class="label">Partita IVA / Codice fiscale</div>
                            <div class="value">
                                <p>{{ invoice.sender.taxNumber }}</p>
                            </div>
                        </div>

                    </section>

                    <section class="to">
                        <header><h1>A</h1></header>

                        <mat-form-field>
                            <mat-label>Cliente</mat-label>

                            <mat-select name="to" #to="ngModel" required [(ngModel)]="invoice.receiver"
                                        [compareWith]="compareEntities" [disabled]="!invoice.isDraft()"
                                        (ngModelChange)="loadProjects($event); cleanProject(invoice)">
                                <mat-option *ngFor="let client of clients" [value]="client">
                                    {{ client.name }}
                                </mat-option>
                                <mat-option *ngIf="clients.length == 0" [value]="null">Nessun cliente</mat-option>
                            </mat-select>
                            <mat-error *ngIf="to.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <div class="entityDetails" *ngIf="invoice.receiver">
                            <div class="entityProperty">
                                <div class="label">Indirizzo</div>
                                <div class="value">
                                    <p>{{ invoice.receiver.address.street }} {{ invoice.receiver.address.city }}
                                        {{ invoice.receiver.address.postcode }}
                                        {{ invoice.receiver.address.district }}</p>
                                </div>
                            </div>

                            <div class="entityProperty">
                                <div class="label">Partita IVA / Codice fiscale</div>
                                <div class="value">
                                    <p>{{ invoice.receiver.taxNumber }}</p>
                                </div>
                            </div>

                        </div>

                        <mat-form-field *ngIf="projectsObservable | async as projects">
                            <mat-label>Progetto</mat-label>

                            <mat-select name="project" #project="ngModel" [(ngModel)]="invoice.project"
                                        required
                                        [disabled]="!invoice.isDraft()"
                                        [compareWith]="compareEntities">
                                <mat-option *ngFor="let project of projects" [value]="project">
                                    {{ project.name }}
                                </mat-option>
                                <mat-option *ngIf="projects.length == 0" [value]="null">Nessun progetto</mat-option>

                            </mat-select>
                            <mat-error *ngIf="project.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>
                    </section>

                    <section class="description">
                        <header><h1 i18n="@@description">Causale</h1></header>

                        <mat-form-field>
                            <textarea cdkTextareaAutosize name="description" type="text" [disabled]="!invoice.isDraft()" matInput [(ngModel)]="invoice.description"
                            maxlength="200"></textarea>
                        </mat-form-field>
                    </section>

                    <section class="lines">

                        <div class="actions" *ngIf="invoice.isDraft()">
                            <button mat-flat-button color="primary" [matMenuTriggerFor]="addMenu" type="button" i18n="@@add">Aggiungi</button>
                        </div>

                        <mat-menu #addMenu="matMenu">
                            <button mat-menu-item (click)="addNewLine()" i18n="@@line">Linea</button>
                            <button mat-menu-item (click)="addNewPricedItem()" i18n="@@line_list">Linea da listino</button>
                            <button mat-menu-item (click)="addNewDiscount()" i18n="@@discount">Sconto</button>
                            <button mat-menu-item (click)="addNewSurcharge()" i18n="@@surcharge">Maggiorazione</button>
                            <button mat-menu-item (click)="addNewGovernmentContribution()" i18n="@@government_contribution">Contributo Governativo</button>
                        </mat-menu>

                        <lines name="lines" [(ngModel)]="invoice.lines" [priceModifiers]="invoice.priceModifiers" [form]="form" [disabled]="!invoice.isDraft()"></lines>

                        <price-modifiers name="priceModifiers" [(ngModel)]="invoice.priceModifiers" [fiscalDocument]="invoice" [disabled]="!invoice.isDraft()"></price-modifiers>
                        <table mat-table class="input-table">

                            <tr mat-footer-row *matFooterRowDef="['taxableDesc', 'taxableValue', 'empty']"></tr>
                            <tr mat-footer-row *matFooterRowDef="['taxDesc', 'taxValue', 'empty']"></tr>
                            <tr mat-footer-row *matFooterRowDef="['footerDesc', 'footerValue', 'empty']"
                                class="highlighted"></tr>


                            <ng-container matColumnDef="empty">
                                <td mat-footer-cell *matFooterCellDef>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="taxDesc">
                                <td mat-footer-cell *matFooterCellDef style="text-align: right" i18n="@@vat">IVA</td>
                            </ng-container>
                            <ng-container matColumnDef="taxValue">
                                <td mat-footer-cell *matFooterCellDef style="width: 7%">{{ invoice.tax | formatCurrency }}</td>
                            </ng-container>

                            <ng-container matColumnDef="taxableDesc">
                                <td mat-footer-cell *matFooterCellDef style="text-align: right" i18n="@@taxable">Imponibile</td>
                            </ng-container>
                            <ng-container matColumnDef="taxableValue">
                                <td mat-footer-cell *matFooterCellDef>{{ invoice.taxable | formatCurrency }}</td>
                            </ng-container>
                            <ng-container matColumnDef="footerDesc">
                                <td mat-footer-cell *matFooterCellDef style="text-align: right" i18n="@@total">Totale</td>
                            </ng-container>
                            <ng-container matColumnDef="footerValue">
                                <td mat-footer-cell *matFooterCellDef>{{ invoice.total | formatCurrency }}</td>
                            </ng-container>
                        </table>
                        <government-contributions name="governmentContributions" [(ngModel)]="invoice.priceModifiers"
                                                  [linesTotal]="invoice.getLinesTotal()"
                                                  [disabled]="!invoice.isDraft()"></government-contributions>
                    </section>

                    <invoice-payments name="invoicePayments" [(ngModel)]="invoice.payments" [form]="form" [disabled]="!invoice.isDraft()"
                                      [invoiceTotal]="invoice.getTotalWithContributions()"></invoice-payments>

                    <attachments name="attachments" [(ngModel)]="invoice.attachments" [fiscalDocumentFilePath]="invoice.filePath" [disabled]="!invoice.isDraft()"></attachments>
                </mat-card-content>

            </mat-card>

        </div>
    </article>
</form>
