import {Project} from "./project";
import {Document} from "./document";
import {Address} from "./address";
import {OrganizationType} from "./business";
import {Entity} from "./entity";
import {persist, PersistenceConfig} from "../persistence/decorators";

@persist(new PersistenceConfig({
        name: 'Client',
        properties: {
            name: {},
            address: {converter: 'Address'},
            taxNumber: {},
            identificationDocument: {converter: 'Document'},
            phoneNumber: {},
            email: {},
            organizationType: {},
            sdi: {}
        }
    })
)
export class Client extends Entity {
    name: string
    address: Address = new Address()
    taxNumber: string
    identificationDocument: Document | null = null
    projects: Project[] = []
    phoneNumber: string | null = null;
    email: string | null = null;
    organizationType: OrganizationType = OrganizationType.PrivatePerson
    sdi: string | null = null

    isPublicAdministration() {
        return this.organizationType == OrganizationType.PublicAdministration
    }


    override getDocuments(): Document[] {
        return this.identificationDocument ? [this.identificationDocument] : [];
    }

}
