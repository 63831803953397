<h1 mat-dialog-title>{{weatherDay.date.format('DD MMMM yyyy')}}</h1>
<div mat-dialog-content>
    <form #form="ngForm">
        <mat-form-field class="hours">
            <mat-label  i18n="@@hours_of_rain">Ore di pioggia</mat-label>
            <input type="number" name="weather-hours-{{weatherDay.day}}" #hours="ngModel" required matInput [(ngModel)]="weatherDay.hoursOfRain"
                   min="0" max="24"/>
            <mat-error *ngIf="hours.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
            <mat-error *ngIf="hours.hasError('max')"  i18n="@@hours_more_than_a_day">Non pu&ograve; essere pi&ugrave; di 24 ore</mat-error>
            <mat-error *ngIf="hours.hasError('min')"  i18n="@@negative_hours">Non pu&ograve; essere meno di 0 ore</mat-error>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-button [disabled]="form.invalid" [mat-dialog-close]="true" cdkFocusInitial i18n="@@edit">Chiudi</button>
</div>

