import {Injectable} from '@angular/core';
import {
    collectionData,
    DocumentData,
    FirestoreDataConverter,
    query,
    QueryDocumentSnapshot,
    SnapshotOptions,
    where
} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import {getCurrentBusiness} from "../utils";
import {Reminder} from "../model/reminder";
import {NotificationType} from "../model/notification";
import {first} from "rxjs";
import {firestore} from "firebase-admin";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class ReminderRepository extends Repository<Reminder> {

    constructor(public firestore: FirestoreWrapper) {
        super(firestore, new ReminderConverter())
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection( `businesses/${business.id}/reminders`).withConverter(this.converter);
    }

    findNonExpired() {
        return collectionData(query(
            this.getCollectionReference(),
            where('expiryDate', '>', new Date()),
        )).pipe(first());
    }
}

class ReminderConverter implements FirestoreDataConverter<Reminder> {
    constructor() {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Reminder {
        const data = snapshot.data(options)!
        let reminder = new Reminder();
        reminder.id = data['id']
        reminder.notificationType = NotificationType[data['notificationType'] as keyof typeof NotificationType]
        reminder.notificationPayload = data['notificationPayload']
        reminder.expiryDate = new Date(data['expiryDate'].seconds*1000)
        reminder.notification30daysId = data['notification30daysId']

        return reminder
    }

    toFirestore(reminder: Reminder): DocumentData {
        let newVar = {
            id: reminder.id,
            notificationPayload: reminder.notificationPayload,
            notificationType: NotificationType[reminder.notificationType],
            expiryDate: reminder.expiryDate,
            notification30daysId: reminder.notification30daysId
        }
        return newVar
    }
}
