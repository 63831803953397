<form name="businessForm" #form="ngForm" (ngSubmit)="save(business)" (reset)="discardChanges(business)">
    <article class="page">
        <header>
            <h1 i18n="@@business_details">Dettagli Aziendali</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above"
                        [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>
        <div class="content">

                <mat-card class="infoCard business">
                    <mat-card-header>
                        <mat-icon mat-card-avatar>business</mat-icon>
                        <mat-card-title>{{ business.name }}</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <mat-form-field>
                            <mat-label i18n="@@name">Nome</mat-label>
                            <input name="name" #fullName="ngModel" required matInput [(ngModel)]="business.name">
                            <mat-error *ngIf="fullName.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field class="flexible-size">
                            <mat-label i18n="@@vat_number">Partita IVA</mat-label>
                            <input name="taxNumber" #taxNumber="ngModel" matInput required [(ngModel)]="business.taxNumber">
                            <mat-error *ngIf="taxNumber.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field class="flexible-size">
                            <mat-label i18n="@@phone_number">Numero di telefono</mat-label>
                            <input name="phoneNumber" #phoneNumber="ngModel" matInput required [(ngModel)]="business.phoneNumber">
                            <mat-error *ngIf="phoneNumber.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>


                        <mat-form-field class="flexible-size">
                            <mat-label>SDI</mat-label>
                            <input name="sdi" #sdi="ngModel" matInput required [(ngModel)]="business.sdi">
                        </mat-form-field>

                        <div class="addressFormPart">
                            <mat-form-field class="fixed-size nation">
                                <mat-label i18n="@@country">Nazione</mat-label>
                                <mat-select name="nation" #nation="ngModel" [required]="true" [(ngModel)]="business.address.nation">
                                    <mat-option *ngFor="let nation of countries" [value]="nation.code">
                                        {{ nation.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="nation.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                            <mat-form-field class="fixed-size" *ngIf="business.address.nation == 'IT'">
                                <mat-label i18n="@@district">Provincia</mat-label>
                                <mat-select name="district" #district="ngModel" [required]="true" [(ngModel)]="business.address.district">
                                    <mat-option *ngFor="let district of districts"
                                                [value]="district.abbr">
                                        {{ district.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="district.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                            <mat-form-field class="fixed-size" *ngIf="business.address.nation != 'IT'">
                                <mat-label i18n="@@district">Provincia</mat-label>
                                <input name="district" #district="ngModel" matInput [required]="true" [(ngModel)]="business.address.district"/>
                                <mat-error *ngIf="district.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>


                            <div class="flex-row" style="width: 100%">
                                <mat-form-field class="flexible-size">
                                    <mat-label i18n="@@city">Citt&agrave;</mat-label>
                                    <input name="city" #city="ngModel" matInput required
                                           [(ngModel)]="business.address.city">
                                    <mat-error *ngIf="city.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                                </mat-form-field>

                            <mat-form-field class="fixed-size postcode">
                                <mat-label i18n="@@postcode">CAP</mat-label>
                                <input name="postcode" #postcode="ngModel" matInput [required]="true" [(ngModel)]="business.address.postcode">
                                <mat-error *ngIf="postcode.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>

                            </div>

                            <mat-form-field class="flexible-size">
                                <mat-label i18n="@@address">Indirizzo</mat-label>
                                <input name="street" #addressLine="ngModel" matInput required [(ngModel)]="business.address.street">
                                <mat-error *ngIf="addressLine.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            </mat-form-field>
                        </div>



                        <mat-form-field>
                            <mat-label i18n="@@tax_regime">Regime Fiscale</mat-label>
                            <mat-select name="taxRegime" #taxRegime="ngModel" required [(ngModel)]="business.taxRegime">
                                <mat-option *ngFor="let taxRegime of taxRegimes" [value]="taxRegime.code">
                                    {{ taxRegime.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="taxRegime.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>


                        <mat-form-field class="flexible-size">
                            <mat-label i18n="@@bank">Istituto finanziario</mat-label>
                            <input name="bank" #bank="ngModel" matInput [(ngModel)]="business.bank">
                        </mat-form-field>

                        <mat-form-field class="flexible-size">
                            <mat-label i18n="@@iban">IBAN</mat-label>
                            <input name="iban" #iban="ngModel" matInput required [(ngModel)]="business.iban">
                            <mat-error *ngIf="iban.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                    </mat-card-content>

                </mat-card>

                <documents name="documents" [batch]="documentStorageBatch" [(ngModel)]="business.documents" i18n="@@documents">Documenti</documents>

        </div>
    </article>
</form>
