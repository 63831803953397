import {Injectable} from '@angular/core';
import {Converter} from "./converter";
import {Message} from "../chats/message";

@Injectable({
    providedIn: 'root',
})
export class ConverterRegistry {
    static converters = ConverterRegistry.init()

    constructor() {
    }

    static register(key: any, converter: Converter<any>) {
        ConverterRegistry.converters.set(key, converter)
    }

    static find(key: any): Converter<any> {
        let converter = ConverterRegistry.converters.get(key);

        if(!converter) {
            throw new Error(`Converter for ${key} not found`)
        }
        return converter
    }

    static init() {
        let map = new Map<any, Converter<any>>;
        return map
    }
}

