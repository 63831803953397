import {Component, OnInit} from '@angular/core';
import {forkJoin, map, Observable, of} from "rxjs";
import {Project} from "../../model/project";
import {ActivatedRoute} from "@angular/router";
import {DocumentStorageService} from "../../document-storage.service";
import {Employee} from "../../model/employee";
import {Activity} from "../../model/activity";
import {NavigatorService} from "../../navigator.service";
import {NotificationService} from "../../notification.service";
import {ClientRepository} from "../../clients-management/client.repository";
import {FormComponentV2} from "../../components/formV2.component";
import {Client} from "../../model/client";
import {Transaction} from "@angular/fire/firestore";
import {Balance} from "../../model/balance";
import {InboundInvoicesRepository} from "../../invoicing/inbound/inbound-invoices.repository";
import {QuotesRepository} from "../../invoicing/quotes/quotes.repository";
import {BalanceService} from "../../business-administration/balance.service";

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss']
})
export class ProjectComponent extends FormComponentV2<Project, Client> implements OnInit {

    project: Project
    assignedEmployees: Employee[] = []
    balanceDetailsObservable: Observable<BalanceDetails>

    revenueBalanceObservable: Observable<Balance>
    expensesBalanceObservable: Observable<Balance>

    private quotesBalanceObservable: Observable<Balance>;

    constructor(route: ActivatedRoute, private clientRepository: ClientRepository, navigatorService: NavigatorService, notificationService: NotificationService, documentStorage: DocumentStorageService,
                private balanceService: BalanceService, private inboundInvoicesRepository: InboundInvoicesRepository, private quotesRepository: QuotesRepository) {
        super(documentStorage, navigatorService, notificationService, clientRepository);
        this.project = route.snapshot.data.project
    }

    ngOnInit(): void {
        if(!this.project.id) {
            this.revenueBalanceObservable = of(new Balance())
            this.expensesBalanceObservable = of(new Balance())
            this.quotesBalanceObservable = of(new Balance())
        } else {

            this.revenueBalanceObservable = this.balanceService.getRevenueBalanceByProject(this.project)
            this.expensesBalanceObservable = this.balanceService.getExpensesBalanceByProject(this.project);
            this.quotesBalanceObservable = this.balanceService.getQuoteBalanceByProject(this.project);
        }

        this.balanceDetailsObservable = forkJoin([this.expensesBalanceObservable, this.revenueBalanceObservable, this.quotesBalanceObservable]).pipe(
            map(([inbound, outbound, quotes]) => {
                let balanceDetails = new BalanceDetails();
                balanceDetails.inbound = inbound
                balanceDetails.outbound = outbound
                balanceDetails.quotes = quotes

                balanceDetails.amounts.quoted = quotes.getTotal()
                balanceDetails.amounts.paid = inbound.getTotal()
                balanceDetails.amounts.received = outbound.getTotal()

                return balanceDetails
            }))
    }

    private loadAssignedEmployees(activities: Activity[]) {
        let employeeSets = activities.map(activity => activity.assignees)
        this.assignedEmployees = this.union(...employeeSets)
    }

    private union(...iterables) {
        const uniqueElements: Employee[] = []

        for (const iterable of iterables) {
            for (const item of iterable) {
                if (!uniqueElements.map(value => value.id).includes(item.id)) uniqueElements.push(item);
            }
        }

        return uniqueElements.sort((a, b) => a.fullName.localeCompare(b.fullName));
    }

    async doCreate(transaction: Transaction, project: Project) {
        this.clientRepository.saveProject(transaction, project)
    }

    async doUpdate(transaction: Transaction, project: Project) {
        await this.clientRepository.updateProject(transaction, project)

    }


    delete(project: Project) {
        //     this.repository.delete(project)
    }

    onActivityChanged(project: Project) {
        console.log("activity added", this.assignedEmployees)
        this.loadAssignedEmployees(project.activities)
    }

}

class BalanceDetails {
    inbound: Balance
    outbound: Balance
    quotes: Balance;
    amounts: {quoted: number, paid: number, received: number} = {quoted: 0, paid: 0, received: 0}
}
