<article class="page">
    <header>
        <h1 i18n="@@requests">Richieste</h1>
    </header>

    <div class="content">

        <mat-card class="infoCard requests">
            <div *ngIf="data | async as datasource">
                <mat-card-header>
                    <mat-icon mat-card-avatar>chat_bubble</mat-icon>

                    <div class="headerWithCommand">
                        <mat-card-title>
                            <span i18n="@@requests">Richieste</span>
                        </mat-card-title>

                    </div>
                </mat-card-header>

                <mat-card-content>

                    <table mat-table matSort [dataSource]="datasource" matSortActive="date" matSortDirection="desc">

                        <ng-container matColumnDef="date">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@issue_date">Data</th>
                            <td mat-cell *matCellDef="let request"> {{ request.date | date }}</td>
                        </ng-container>

                        <ng-container matColumnDef="title">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@title">Titolo</th>
                            <td mat-cell *matCellDef="let request"> {{ request.title }}</td>
                        </ng-container>

                        <ng-container matColumnDef="requester">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@requester">Richiedente</th>
                            <td mat-cell *matCellDef="let request"> {{ request.requester }}</td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@requester">Stato</th>
                            <td mat-cell
                                *matCellDef="let request"> {{ request.status == Status.OPEN ? 'IN CORSO' : 'CHIUSA' }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef i18n="@@description">Descrizione</th>
                            <td mat-cell *matCellDef="let request"> {{ request.description.substring(0, 70) }}...</td>
                        </ng-container>


                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let request" style="width: 6%">
                                <div class="iconsContainer">
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let request; columns: displayedColumns;"
                            (click)="projectRequestSelected(request)"></tr>

                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" style="text-align: center" [colSpan]="displayedColumns.length"
                                i18n="@@no_requests">Nessuna richiesta
                            </td>
                        </tr>
                    </table>

                </mat-card-content>
            </div>

            <mat-card-footer>
                <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
            </mat-card-footer>

        </mat-card>

    </div>
</article>
