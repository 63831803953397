import {Component, OnInit} from '@angular/core';
import {InboundSdiMessage, InboundSdiMessagesRepository} from "./inbound-sdi-messages.repository";
import {DocumentStorageService} from "../../document-storage.service";
import {Bouncer} from "../../bouncer.service";
import {Business} from "../../model/business";

@Component({
  selector: 'inbound-messages',
  templateUrl: './inbound-messages.component.html',
  styleUrls: ['./inbound-messages.component.scss']
})
export class InboundMessagesComponent implements OnInit {

    inboundMessages: any;
    displayedColumns: string[] = ['flowId', 'business', 'receivedTime', 'format', 'fileName', 'actions'];

    constructor(private inboundSdiMessagesRepository: InboundSdiMessagesRepository, private storageService: DocumentStorageService,
                private bouncer: Bouncer) {
    }

    ngOnInit(): void {
        this.inboundMessages = this.inboundSdiMessagesRepository.getAll()
    }

    downloadXml(message: InboundSdiMessage) {
        this.storageService.retrieveBlob(message.filePath).then(invoiceXml => {
            this.storageService.xmlToFile(invoiceXml, 'application/xml', message.fileName || "no_file_name.xml")
        }).catch(reason => alert("Impossibile convertire in XML al momento, riprova più tardi"))
    }

    goToInvoice(message: InboundSdiMessage) {
        this.bouncer.storeInspectedBusiness({id: message.businessId} as Business)
        location.pathname = `/invoicing/inbound/${message.invoiceId}`
    }

    archive(message) {

    }
}
