import {Component} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ProjectRequest} from "../../model/project.request";
import {ProjectRequestRepository} from "../../project-requests/project-requests.repository";


@Component({
  templateUrl: './project-requests.component.html',
  styleUrls: ['./project-requests.component.scss']
})
export class ProjectRequestsComponent {
    list: Observable<ProjectRequest[]>;

    displayedColumns: string[] = ['id', 'title', 'requester', 'date', 'description', 'actions'];

    constructor(private projectRequestRepository: ProjectRequestRepository, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.list = this.projectRequestRepository.getAll()
    }

    onSelected(projectRequest) {
        this.router.navigate([projectRequest.id], {relativeTo: this.route});
    }

    delete(event: MouseEvent, ProjectRequest) {
        event.preventDefault()
        event.stopPropagation()

        let confirmation = confirm("Do you want to delete the entity?");

        if(confirmation) {
            this.projectRequestRepository.delete(ProjectRequest)
        }
    }
}
