<mat-card class="infoCard">
    <mat-card-header>
        <mat-icon mat-card-avatar>sick</mat-icon>

        <div class="headerWithCommand">
            <mat-card-title i18n="@@sick_leave">Malattia</mat-card-title>
            <span class="yearSelector" *ngIf="selectedYear | async as selected">
                <mat-icon (click)="moveTimeLeft()">arrow_left</mat-icon>{{ selected }}<mat-icon (click)="moveTimeRight()">arrow_right</mat-icon>
            </span>
        </div>
    </mat-card-header>

    <mat-card-content>
        <table mat-table [dataSource]="leaves" [hidden]="hideTable()">

            <ng-container matColumnDef="from">
                <th mat-header-cell *matHeaderCellDef i18n="@@start_date">Data inizio</th>
                <td mat-cell *matCellDef="let leave"> {{ leave.from | date }}</td>
            </ng-container>

            <ng-container matColumnDef="to">
                <th mat-header-cell *matHeaderCellDef i18n="@@end_date">Data fine</th>
                <td mat-cell *matCellDef="let leave"> {{ leave.to | date }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="actionColumnHeader">
                </th>
                <td mat-cell *matCellDef="let leave">
                    <button mat-icon-button (click)="openEditDialog($event, leave)">
                        <mat-icon class="mat-24">edit</mat-icon>
                    </button>

                    <button mat-icon-button (click)="remove($event, leave)">
                        <mat-icon class="mat-24">delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="hideTable()">
            <p i18n="@@no_sick_leave">Nessuna malattia per l&apos;anno selezionato</p>
        </div>

    </mat-card-content>

    <mat-card-actions>
        <button mat-button name="openFormButton" color="primary" type="button" (click)="openNewDialog()" i18n="@@add">Aggiungi</button>
    </mat-card-actions>

</mat-card>
