import {Moment} from "moment";
import {Employee} from "./employee";

import {Entity} from "./entity";

export class Activity extends Entity {
    name: string
    from: Moment | null = null
    to: Moment | null = null
    description: string | null = null
    progress: number | null = null
    assignees: Employee[] = []
    notes: string | null = null

    hasEmployeeAssigned(employee: Employee) {
        return this.assignees.map(value => value.id).includes(employee.id)
    }
}
