import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BusinessRepository} from "../../../authentication/business.repository";
import {compareEntities, districts} from "../../../utils";
import {fiscalDocumentTypes, Invoice} from "../../../model/invoice";
import {forkJoin, Observable, take, tap} from "rxjs";
import {Project} from "../../../model/project";
import {Bouncer} from "../../../bouncer.service";
import {Supplier, Supply} from "../../../model/supply";
import {SupplierRepository} from "../../../suppliers/supplier.repository";
import {InboundInvoicesRepository} from "../inbound-invoices.repository";
import {DocumentStorageService} from "../../../document-storage.service";
import {GenericSender, InvoiceSender} from "../../../model/invoiceSender";
import {SupplyRepository} from "../../../projects-management/supply/supply-repository.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../outbound/confirm-dialog/confirm-dialog.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {PriceModifiersComponent} from "../../price-modifiers/price-modifiers.component";
import {GovernmentContributionsComponent} from "../../government-contributions/government-contributions.component";
import {LinesComponent} from "../../lines/lines.component";
import {NavigatorService} from "../../../navigator.service";
import {NotificationService} from "../../../notification.service";
import {NgForm} from "@angular/forms";
import {Transaction} from "@angular/fire/firestore";
import {FormComponentV2} from "../../../components/formV2.component";

@Component({
    selector: 'app-invoice',
    templateUrl: './inbound-invoice.component.html',
    styleUrls: ['./inbound-invoice.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', 'padding-top': '0', 'padding-bottom': '0'})),
            state('expanded', style({height: '*', 'padding-top': '*', 'padding-bottom': '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class InboundInvoiceComponent extends FormComponentV2<Invoice> implements OnInit {

    @ViewChild(PriceModifiersComponent) priceModifiersComponent: PriceModifiersComponent
    @ViewChild(GovernmentContributionsComponent) governmentContributionsComponent: GovernmentContributionsComponent
    @ViewChild(LinesComponent) linesComponent: LinesComponent

    invoice: Invoice
    suppliers: Supplier[] = []
    erpEnabled: boolean;
    senderOptions: InvoiceSender[] = [];
    districts: any = districts;
    supplies: Supply[] = [];
    projects: Project[];
    fiscalDocumentTypes = fiscalDocumentTypes;

    constructor(private route: ActivatedRoute, private businessRepository: BusinessRepository, private bouncer: Bouncer,
                navigatorService: NavigatorService,
                notificationService: NotificationService,
                storageService: DocumentStorageService,
                invoiceRepository: InboundInvoicesRepository,
                private supplierRepository: SupplierRepository,
                private suppliesRepository: SupplyRepository,
                private dialog: MatDialog) {
        super(storageService, navigatorService, notificationService, invoiceRepository);
    }

    ngOnInit(): void {
        this.erpEnabled = this.bouncer.getConfig().erp
        this.invoice = this.route.snapshot.data.invoice as Invoice;

        this.initializeOptionsState(this.invoice);

    }

    private initializeOptionsState(invoice: Invoice) {

        forkJoin([
                this.supplierRepository.getAll().pipe(take(1)),
                this.businessRepository.getAllProjects().pipe(take(1))
            ]
        ).subscribe((results: any[]) => {
            this.suppliers = results[0]
            this.projects = results[1]

            if (invoice.project && invoice.supply) {
                invoice.project = this.projects.find(value => value.id == invoice.project!.id) || null
                this.getSuppliesByProject(invoice.project, supplies => {
                    invoice.supply = supplies.find(value => value.id == invoice.supply!.id) || null
                })
            }

            if (invoice.supplier) {
                invoice.supplier = this.suppliers.find(value => value.id == invoice.supplier!.id) || null
            }

            if (invoice.supply) {
                this.getSuppliesBySupplier(invoice.supplier, supplies => {
                    invoice.supply = supplies.find(value => value.id == invoice.supply!.id) || null
                })
            }

            invoice.lines.forEach(line => line.expanded = line.hasReferences())

        })


    }

    saveInvoice(invoice: Invoice, form: NgForm) {
        form.control.updateValueAndValidity()

        if (invoice.isLinked()) {
            invoice.markAsConfirmed()
        }
        super.save(invoice)
    }

    async doCreate(transaction: Transaction, invoice: Invoice) {
        this.repository.transactionalSave(transaction, invoice)
    }

    async doUpdate(transaction: Transaction, invoice: Invoice): Promise<void> {
        let invoiceToSave = invoice.clone(new Invoice());
        ++invoiceToSave.version
        await this.repository.transactionalUpdate(transaction, invoice.id, invoiceToSave)
    }

    compareEntities(a, b) {
        return compareEntities(a, b)
    }

    getSuppliesByProject(project: Project | null, next: any = value => {
    }) {
        if (!project) return

        return this.suppliesRepository.findSuppliesByProject(project.id)
            .pipe(tap(next)).subscribe(value => this.supplies = value || [])
    }

    getSuppliesBySupplier(supplier: Supplier | null, next: any = value => {}) {
        if (!supplier) return

        return this.suppliesRepository.findSuppliesBySupplier(supplier.id)
            .pipe(tap(next)).subscribe(value => this.supplies = value || [])
    }

    public newGenericSender() {
        return new GenericSender()
    }


    confirmLink(): Observable<any> {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Conferma associazione',
                question: 'Una volta associata la fattura non potrà più essere modificata. Confermi l\'associazione?'
            }
        }).afterClosed()
    }

    onSupplySelected(invoice: Invoice) {
        this.confirmLink().subscribe(value => {
            if (!value) {
                invoice.project = null
                invoice.supply = null
                return
            }

            invoice.accountant = null
            invoice.employee = null
            invoice.education = null
            invoice.medicalRecord = null
        })

    }

    onAccountantSelected(invoice: Invoice) {
        this.confirmLink().subscribe(value => {
            if (!value) {
                invoice.accountant = null
                return
            }

            invoice.project = null
            invoice.supply = null
            invoice.employee = null
            invoice.education = null
            invoice.medicalRecord = null
        })
    }


    addNewDiscount() {
        this.priceModifiersComponent.addNewDiscount()
    }

    addNewSurcharge() {
        this.priceModifiersComponent.addNewSurcharge()
    }

    addNewGovernmentContribution() {
        this.governmentContributionsComponent.addNew()
    }

    addNewLine() {
        this.linesComponent.addNewLine()
    }

    addNewPricedItem() {
        this.linesComponent.addNewPricedItem()
    }

}
