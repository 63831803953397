import {Component, ElementRef, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Activity} from "../../../model/activity";
import {Employee} from "../../../model/employee";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatChipInputEvent} from "@angular/material/chips";
import {UntypedFormControl} from "@angular/forms";
import {debounceTime, map, Observable, startWith, Subject} from "rxjs";

@Component({
  selector: 'app-activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.scss']
})
export class ActivityDialogComponent {
    record: Activity
    editMode: boolean;

    autocompleteControl: UntypedFormControl = new UntypedFormControl()
    filteredOptions: Observable<Employee[]>;
    private fullEmployeesObjects: Employee[];
    private filteredOptionsRecord: Subject<Employee[]>;
    assigneesInput: ElementRef<HTMLInputElement>;


    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ActivityDialogComponent>) {
        this.record = data.record
        this.editMode = data.editMode
        this.assigneesInput = data.assigneesInput
        this.fullEmployeesObjects = data.fullEmployeesObjects
    }

    ngOnInit(): void {
        this.filteredOptionsRecord = new Subject<Employee[]>()
        this.filteredOptions = this.filteredOptionsRecord.asObservable()
        this.filteredOptionsRecord.next(this.fullEmployeesObjects)

        this.autocompleteControl.valueChanges.pipe(
            startWith(''),
            debounceTime(500),
            map(value => typeof value === 'string' ? value : value?.fullName),
            map(name => name ? this._filter(name) : this.getEmployeesForCurrentActivity()),
            map(value => {
                if (value.length != 0) return value

                let dummyEmployee = new Employee()
                dummyEmployee.fullName = "Nessun dipendente trovato"
                return [dummyEmployee]
            })
        ).subscribe(employees => {
            this.filteredOptionsRecord.next(employees)
        })
    }

    onAssigneeRemoved(assignee: Employee) {
        const index = this.record.assignees.indexOf(assignee);

        if (index >= 0) {
            this.record.assignees.splice(index, 1);
        }

        this.filteredOptionsRecord.next( this.getEmployeesForCurrentActivity() )
    }

    onAssigneeSelected(event: MatAutocompleteSelectedEvent) {
        if (event.option.value.id && !this.record.hasEmployeeAssigned(event.option.value)) {
            this.record.assignees.push(event.option.value);
        }

        this.autocompleteControl.setValue(null);
        this.assigneesInput.nativeElement.value = '';
    }

    onEnterAssigneeInput(event: MatChipInputEvent) {
        event.chipInput!.clear();
        this.autocompleteControl.setValue(null);
        this.assigneesInput.nativeElement.value = '';

    }

    private getEmployeesForCurrentActivity() {
        return this.fullEmployeesObjects.filter(option => !this.record.hasEmployeeAssigned(option))
    }

    private _filter(name: string): Employee[] {
        const filterValue = name.toLowerCase();

        return this.getEmployeesForCurrentActivity().filter(option => option.fullName.toLowerCase().includes(filterValue))
    }

    employeeName(employee: Employee): string {
        return employee && employee.fullName ? employee.fullName : '';
    }

    onSubmit() {
        this.dialogRef.close(this.record)
    }

    cancel(event: MouseEvent) {
        event.stopPropagation()
        event.preventDefault()
        this.dialogRef.close(null)
    }
}
