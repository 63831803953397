import {Embedded, Entity} from "../model/entity";
import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot} from "@angular/fire/firestore";
import * as moment from "moment";
import {Timestamp} from '@firebase/firestore';
import {PersistenceConfig} from "./decorators";
import {ConverterRegistry} from "./converter-registry.service";


export class Converter<T extends Entity | Embedded> implements FirestoreDataConverter<T> {
    constructor(private type: new () => T) {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot): T {
        let data = snapshot.data() as any;
        return this.fromData(data);
    }

    fromData(data) {

        console.log("converting to firestre", data)

        if(data === null || data === undefined) {
            return null
        }

        if (data instanceof Array) {
            return data.map(value => this.fromData(value))
        }

        let instance = new this.type();
        if (instance instanceof Entity) {
            instance.id = data['id']
            instance.version = data['version'] || 0
        }


        let persistenceConfig = this.type['_persistence'] as PersistenceConfig;
        if (persistenceConfig.timestamped) {
            instance['createdTime'] = data['createdTime'] ? moment(data['createdTime'].toDate()) : null
            instance['updatedTime'] = data['updatedTime'] ? moment(data['updatedTime'].toDate()) : null
        }

        persistenceConfig.getPropertyNames().forEach(propertyName => {
            let value = data[propertyName];

            let converterKey = persistenceConfig.getConverterKey(propertyName);
            if (converterKey) {
                let converter = ConverterRegistry.find(converterKey)
                instance[propertyName] = converter.fromData(value)
            } else if (value instanceof Timestamp) {
                instance[propertyName] = moment(value.toDate())
            } else
                instance[propertyName] = value
        })
        return instance
    }

    toFirestore(t: T): DocumentData {
        let toStore = {}
        let persistenceConfig = this.type['_persistence'] as PersistenceConfig;

        if (new this.type() instanceof Entity) {
            toStore['id'] = t['id']
            toStore['version'] = t['version'] === undefined ? null : t['version']

        }
        if (persistenceConfig.timestamped) {
            toStore['createdTime'] = t['createdTime'] ? t['createdTime'].toDate() : new Date()
            toStore['updatedTime'] = new Date()
        }

        persistenceConfig.getPropertyNames().forEach(propertyName => {
            let value = t[propertyName];
            if(value === null) {
                toStore[propertyName] = null
                return
            }

            let converterKey = persistenceConfig.getConverterKey(propertyName);

            console.log("key", propertyName, value)

            if (value instanceof Array) {
                toStore[propertyName] = value.map(value => {
                    let converter = ConverterRegistry.find(persistenceConfig.getConverterKey(propertyName))
                    return converter.toFirestore(value)
                })
            } else if (converterKey) {
                let converter = ConverterRegistry.find(converterKey)
                toStore[propertyName] = converter.toFirestore(value)
            } else if (value === undefined) {
                console.warn("key", propertyName, 'is undefined')
                throw new Error(`undefined value for key ${propertyName.toString()} in ${t.constructor.name}`)
            } else if (value === null) {
                toStore[propertyName] = null
            } else if (value instanceof Number || value.constructor === Number) {
                console.log("key", propertyName, 'instanceof number')
                toStore[propertyName] = value
            } else if (value instanceof String || value.constructor === String) {
                console.log("key", propertyName, 'instanceof string')
                toStore[propertyName] = value
            } else if (value instanceof Boolean || value.constructor === Boolean) {
                console.log("key", propertyName, 'instanceof boolean')
                toStore[propertyName] = value
            } else if (moment.isMoment(value)) {
                console.log("key", propertyName, 'instanceof date')
                toStore[propertyName] = value.toDate()
            } else if (value.constructor === Object) {
                console.warn("key", propertyName, 'instanceof object')
                toStore[propertyName] = value
            }
        })
        return toStore
    }
}
