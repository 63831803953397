import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {NotificationRepository} from "./notifications/notification.repository";
import {Notification} from "./model/notification";
import {NavigatorService} from "./navigator.service";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private snackBar: MatSnackBar, private notificationRepository: NotificationRepository, private navigatorService: NavigatorService) {
    }

    notify(message: string) {
        this.snackBar.open(message, undefined, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 5 * 1000,
            panelClass: 'notificationSnack'
        });
    }

    notifyError(message: string) {
        this.snackBar.open(message, 'Chiudi', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['notificationSnack', 'error'],
        });
    }

    notifyMissingDataError() {
        let snackBarRef = this.snackBar.open($localize `:@@complete_business_details:Per poter utilizzare l'applicazione, devi completare i dati della tua azienda`, 'Completa', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['notificationSnack', 'error'],
        });

        snackBarRef.onAction().subscribe(value => {
            this.navigatorService.goToBusinessPage()
        })
    }

    notifyProjectRequests() {
        let snackBarRef = this.snackBar.open($localize `:@@requests_received:Hai richieste da potenziali clienti!`, 'Vedi', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['notificationSnack', 'error'],
        });

        snackBarRef.onAction().subscribe(value => {
            this.navigatorService.goToProjectRequests()
        })
    }

    notifyProjectRequestsButMissingData() {
        let snackBarRef = this.snackBar.open($localize `:@@requests_received_error:Hai richieste da potenziali clienti, ma per poter utilizzare l'applicazione, devi completare i dati della tua azienda`, 'Completa', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['notificationSnack', 'error'],
        });

        snackBarRef.onAction().subscribe(value => {
            this.navigatorService.goToBusinessPage()
        })
    }

    notifyFormError() {
        let message = $localize`:@@cannot_save:Impossibile salvare, il form contiene errori`;
        this.notifyError(message)
    }


    getAllNotAcknowledged() {
        return this.notificationRepository.getAllNotAcknowledged()
    }

    markAcknowledged(notification: Notification) {
        notification.acknowledged = true
        this.notificationRepository.save(notification)
    }
}
