import {Component, OnInit} from '@angular/core';
import {DocumentStorageService} from "../../document-storage.service";
import {InboundSdiMessageErrorRepository, MessageError} from "./inbound-sdi-message-errors.repository";

@Component({
    selector: 'inbound-message-errors',
    templateUrl: './inbound-message-errors.component.html',
    styleUrls: ['./inbound-message-errors.component.scss']
})
export class InboundMessageErrorsComponent implements OnInit {


    messageErrors: any;
    displayedColumns: string[] = ['id', 'flowId', 'messageId', 'receivedTime', 'errorCode', 'errorMessage', 'actions'];

    constructor(private inboundSdiMessagesRepository: InboundSdiMessageErrorRepository, private storageService: DocumentStorageService) {
    }

    ngOnInit(): void {
        this.messageErrors = this.inboundSdiMessagesRepository.getAll()
    }

    downloadXml(message: MessageError) {
        this.storageService.retrieveBlob(message.filePath).then(invoiceXml => {
            this.storageService.xmlToFile(invoiceXml, 'application/xml', `${message.flowId}.xml`)
        }).catch(reason => alert("Impossibile convertire in XML al momento, riprova più tardi"))
    }

}
