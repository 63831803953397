import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {map, Observable} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute, Router} from "@angular/router";
import {Firestore} from "@angular/fire/firestore";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../outbound/confirm-dialog/confirm-dialog.component";
import {QuotesRepository} from "./quotes.repository";
import {Quote} from "../../model/invoice";
import {FiscalDocument} from "../../model/fiscalDocument";

@Component({
    selector: 'app-quotes',
    templateUrl: './quotes.component.html',
    styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChildren(MatSort) sortQuery: QueryList<MatSort>;

    data: Observable<MatTableDataSource<FiscalDocument>>
    displayedColumns: string[] = ['number', 'client', 'date', 'taxable', 'tax', 'total', 'actions'];

    constructor(private router: Router, private route: ActivatedRoute,
                private quotesRepository: QuotesRepository,
                private firestore: Firestore,
                private dialog: MatDialog
    ) {

    }

    ngOnInit(): void {


    }

    ngAfterViewInit() {
        this.data = this.quotesRepository.getAll().pipe(map(data => {
            data.sort((a, b) => a.date.isBefore(b.date) ? 1 : -1)

            let dataSource = new MatTableDataSource<FiscalDocument>(data);
            dataSource.paginator = this.paginator;
            dataSource.sortingDataAccessor = (quote, property) => {
                switch (property) {
                    case 'client': {
                        return quote.receiver.name
                    }
                    default: {
                        return quote[property];
                    }
                }
            }
            this.sortQuery.changes.subscribe(value => {
                if (value) dataSource.sort = value.get(0)
            })
            return dataSource
        }))
    }

    quoteSelected(quote) {
        this.router.navigate([quote.id], {relativeTo: this.route});

    }

    deleteQuote(event: MouseEvent, quote: Quote) {
        event.preventDefault()
        event.stopPropagation()

        this.confirmDelete().subscribe(confirmed => {
            if (confirmed) {
                this.quotesRepository.delete(quote)
            }
        })
    }

    confirmDelete(): Observable<any> {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Conferma cancellazione',
                question: 'Sei sicuro che vuoi cancellare il preventivo?'
            }
        }).afterClosed();
    }
}
