<table mat-table [dataSource]="getGovernmentContributions()" class="input-table" #governmentContributionsTable>

    <ng-container matColumnDef="description">
        <td mat-cell *matCellDef="let element;  let i = index;" style="text-align: right">
            <span  i18n="@@government_contribution">Contributo governativo</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="percentage">
        <td mat-cell *matCellDef="let element; let i = index;" style="width: 30%">
            <mat-form-field class="contributionDescription" style="width: 100%; max-width: none">
                <input name="contributionDescription-{{i}}" #contributionDescription="ngModel"
                       placeholder="Descrizione" i18n-placeholder="@@description" matInput required maxlength="59" [disabled]="disabled"
                       [(ngModel)]="element.description"/>
                <mat-error *ngIf="contributionDescription.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <td mat-cell *matCellDef="let element; let i = index;" style="width: 8%">
            <mat-form-field class="small">
                <input type="number" name="contributionAmount-{{i}}" #amount="ngModel"
                       matInput required placeholder="Importo" i18n-placeholder="@@amount"
                       [disabled]="disabled"
                       [(ngModel)]="element.amount"/>
                <label matSuffix></label>
                <mat-error *ngIf="amount.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="value">
        <td mat-cell *matCellDef="let element;" style="width: 6%; text-align: right">
            {{element.getValue(linesTotal)  | formatCurrency }}
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <td mat-cell *matCellDef="let element">
            <button type="button" mat-icon-button *ngIf="disabled"
                    (click)="remove($event, element)">
                <mat-icon class="mat-24">delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: governmentContributionsColumns;"
        class="governmentContribution"></tr>

</table>
