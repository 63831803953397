import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {compareEntities} from "../../../utils";
import {FiscalDocumentType, fiscalDocumentTypes, Invoice} from "../../../model/invoice";
import {OutboundInvoicesRepository} from "../outbound-invoices.repository";
import {Observable} from "rxjs";
import {ClientRepository} from "../../../clients-management/client.repository";
import {Client} from "../../../model/client";
import {NgForm} from "@angular/forms";
import {Project} from "../../../model/project";
import {Bouncer} from "../../../bouncer.service";
import {Transaction} from "@angular/fire/firestore";
import {DocumentStorageService} from "../../../document-storage.service";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {GovernmentContributionsComponent} from "../../government-contributions/government-contributions.component";
import {PriceModifiersComponent} from "../../price-modifiers/price-modifiers.component";
import {LinesComponent} from "../../lines/lines.component";
import {NavigatorService} from "../../../navigator.service";
import {NotificationService} from "../../../notification.service";
import {FiscalDocumentService} from "../../fiscal-document.service";
import {FormComponentV2} from "../../../components/formV2.component";

// @ts-ignore
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
    selector: 'app-invoice',
    templateUrl: './outbound-invoice.component.html',
    styleUrls: ['./outbound-invoice.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', 'padding-top': '0', 'padding-bottom': '0'})),
            state('expanded', style({height: '*', 'padding-top': '*', 'padding-bottom': '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class OutboundInvoiceComponent extends FormComponentV2<Invoice> implements OnInit {

    @ViewChild(GovernmentContributionsComponent) governmentContributionsComponent: GovernmentContributionsComponent
    @ViewChild(PriceModifiersComponent) priceModifiersComponent: PriceModifiersComponent
    @ViewChild(LinesComponent) linesComponent: LinesComponent

    invoice: Invoice
    clients: Client[] = []

    projectsObservable: Observable<Project[]>;
    erpEnabled: boolean;
    fiscalDocumentTypes = fiscalDocumentTypes

    constructor(private route: ActivatedRoute, private bouncer: Bouncer,
                navigatorService: NavigatorService,
                notificationService: NotificationService,
                storageService: DocumentStorageService,
                private fiscalDocumentService: FiscalDocumentService,
                private invoiceRepository: OutboundInvoicesRepository, private clientRepository: ClientRepository) {
        super(storageService, navigatorService, notificationService, invoiceRepository);
    }


    ngOnInit(): void {
        this.erpEnabled = this.bouncer.getConfig().erp

        this.invoice = this.route.snapshot.data.invoice as Invoice;

        this.clientRepository.getAll().subscribe(clients => {
            return this.clients = clients;
        })

        this.loadProjects(this.invoice.receiver as Client)
    }

    saveInvoice(invoice: Invoice, form: NgForm) {
        form.control.updateValueAndValidity()

        if (!invoice.isPaid() && invoice.isPaidDatePresent()) {
            invoice.markAsPaid()
        }
        super.save(invoice)
    }

    async doCreate(transaction: Transaction, invoice: Invoice) {
        this.repository.transactionalSave(transaction, invoice)
    }

    async doUpdate(transaction: Transaction, invoice: Invoice): Promise<void> {
        let invoiceToSave = invoice.clone(new Invoice());
        ++invoiceToSave.version
        await this.repository.transactionalUpdate(transaction, invoice.id, invoiceToSave)
    }

    send(invoice: Invoice, form: NgForm) {
        form.control.updateValueAndValidity()
        if (!form.valid) {
            form.control.markAllAsTouched();
            alert("La fattura contiene errori, impossibile inviare")
            return
        }

        if (!form.pristine) {
            alert("La fattura contiene modifiche, salva prima di inviare")
            return
        }
        console.log("sending invoice", invoice.id)

        invoice.markAsSending()

        this.invoiceRepository.updateToSent(invoice)

    }

    compareEntities(a, b) {
        return compareEntities(a, b)
    }

    loadProjects(client: Client) {
        if (client && this.erpEnabled) this.projectsObservable = this.clientRepository.getProjects(client.id);
    }

    cleanProject(invoice: Invoice) {
        invoice.project = null
    }

    public downloadProForma(invoice: Invoice, form: NgForm) {
        if (!form.valid) {
            form.control.markAllAsTouched();
            return
        }

       this.fiscalDocumentService.downloadOutboundInvoiceProForma(invoice)
    }

    addNewDiscount() {
        this.priceModifiersComponent.addNewDiscount()
    }

    addNewSurcharge() {
        this.priceModifiersComponent.addNewSurcharge()
    }

    addNewGovernmentContribution() {
        this.governmentContributionsComponent.addNew()
    }

    addNewLine() {
        this.linesComponent.addNewLine()
    }

    addNewPricedItem() {
        this.linesComponent.addNewPricedItem()
    }
}
