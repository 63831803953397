<h1 mat-dialog-title i18n="@@leave">Ferie</h1>
<div mat-dialog-content>
    <form #form="ngForm" name="addForm" (ngSubmit)="form.valid && onSubmit()">

        <mat-form-field>
            <mat-label  i18n="@@period">Periodo</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input required #fromDate="ngModel" name="from" matStartDate placeholder="Inizio" [(ngModel)]="record.from">
                <input required #toDate="ngModel" name="to" matEndDate placeholder="Fine" [(ngModel)]="record.to">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="fromDate.hasError('required')"  i18n="@@start_date_required">Campo Inizio obbligatorio</mat-error>
            <mat-error *ngIf="toDate.hasError('required')"  i18n="@@end_date_required">Campo Fine obbligatorio</mat-error>
        </mat-form-field>

        <mat-dialog-actions align="end">
            <button (click)="cancel($event)" mat-button i18n="@@cancel">Annulla</button>
            <button *ngIf="!editMode" name="addButton" type="submit" mat-button color="primary" i18n="@@add">Aggiungi
            </button>

            <button *ngIf="editMode" type="submit" color="primary" mat-button i18n="@@edit">Modifica</button>
        </mat-dialog-actions>
    </form>
</div>
