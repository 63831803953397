import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {map, Observable, of, take} from "rxjs";
import {Quote} from "../model/invoice";
import {FiscalDocument} from "../model/fiscalDocument";
import {QuotesRepository} from "./quotes/quotes.repository";

@Directive({
    selector: '[uniqueQuoteNumber]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: forwardRef(() => UniqueQuoteNumberDirective),
        multi: true
    }]
})
export class UniqueQuoteNumberDirective implements AsyncValidator {

    @Input('fiscalDocument') fiscalDocument: FiscalDocument

    constructor(private repository: QuotesRepository) {
    }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        const number = control.value;

        if (!number) return of(null);

        return this.repository.findByInvoiceNumber(number)
            .pipe(take(1), map((quotes: Quote[]) => {
                    let sameNumber = quotes.find(value => value.id != this.fiscalDocument.id);
                    return sameNumber ? {nonUnique: true} : null;
                }
            ))

    }

}
