import {Component, OnInit} from '@angular/core';
import {ContactRequest, ContactRequestRepository} from "../../../welcome/contact-request.repository";
import {map, Observable} from "rxjs";
import {BusinessContactRequest, BusinessContactRequestRepository} from "../../../showcases/business-showcase/business-showcase.component";

@Component({
  selector: 'app-business-contact-request',
  templateUrl: './business-contact-request.component.html',
  styleUrls: ['./business-contact-request.component.scss']
})
export class BusinessContactRequestComponent implements OnInit{

    displayedColumns: string[] = [
        "id",
        "date",
        "name",
        "email",
        "phoneNumber",
        'description',
    ];

    constructor(private repository: BusinessContactRequestRepository) {
    }

    requests: Observable<BusinessContactRequest[]>;

    ngOnInit(): void {
        this.requests = this.repository.getAll().pipe(map(value => value.sort((a, b) => a.createdTime.isBefore(b.createdTime) ? 1 : -1)));
    }

}
