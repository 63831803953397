import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {EmployeeRepository} from "../employee.repository";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ListLayoutComponent} from "../../components/list-layout/list-layout.component";

@Component({
    selector: 'app-employees',
    templateUrl: './employees.component.html',
    styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent extends ListLayoutComponent {
    label: { singular: string; plural: string };

    constructor(public employeeRepository: EmployeeRepository, router: Router, snackBar: MatSnackBar) {
        // @ts-ignore
        super(employeeRepository, router, snackBar);
        let singular = $localize`:@@employee:dipendente`
        let plural = $localize`:@@employees:dipendenti`
        this.label = {
            singular, plural
        }
    }

}
