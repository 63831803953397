<h1 mat-dialog-title>Importa Clienti</h1>

<div mat-dialog-content>

    <mat-list-item *ngFor="let error of errors">
        <div matListItemLine class="importError">Il cliente {{ error.entity.name }} contiene un errore: {{ error.message }}</div>
    </mat-list-item>

    <table mat-table [dataSource]="entities">

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let entity"> {{ entity.name }}</td>
        </ng-container>

        <ng-container matColumnDef="taxNumber">
            <th mat-header-cell *matHeaderCellDef>Codice Fiscale / Partita IVA</th>
            <td mat-cell *matCellDef="let entity"> {{ entity.taxNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="district">
            <th mat-header-cell *matHeaderCellDef>Provincia</th>
            <td mat-cell *matCellDef="let entity"> {{ entity.address.district }}</td>
        </ng-container>

        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef>Citt&agrave;</th>
            <td mat-cell *matCellDef="let entity"> {{ entity.address.city }}</td>
        </ng-container>

        <ng-container matColumnDef="postcode">
            <th mat-header-cell *matHeaderCellDef>CAP</th>
            <td mat-cell *matCellDef="let entity"> {{ entity.address.postcode }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Indirizzo</th>
            <td mat-cell *matCellDef="let entity"> {{ entity.address.street }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.bad]="isBadImport(row)"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length"
                style="text-align: center">
                Nessun cliente
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false" i18n="@@close">Annulla</button>
    <button mat-button [mat-dialog-close]="true" i18n="@@close" [disabled]="isErrorPresent()">Importa</button>
</div>
