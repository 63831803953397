import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {TimesheetService} from "../../timesheet/timesheet.service";
import * as moment from "moment";
import {map, Observable} from "rxjs";
import {Project} from "../../model/project";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {getMonthName} from "../../utils";

@Component({
    selector: 'journal',
    templateUrl: './journal.component.html',
    styleUrls: ['./journal.component.scss']
})
export class JournalComponent implements AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Input() project: Project

    year: number = moment().year()
    month: number = moment().month()
    monthName: string;

    paginatorDisplayed: boolean = false

    constructor(private timesheetService: TimesheetService) {
        this.updateMonthName();
    }

    activitiesObservable: Observable<MatTableDataSource<any>>;
    displayedColumns: any = ['date', 'assignee', 'hours', 'description'];

    ngAfterViewInit(): void {
        this.loadJournal();
    }

    private loadJournal() {

        this.activitiesObservable = this.timesheetService.findTimesheet(this.year, this.month).pipe(map(timesheet => {
                if (!timesheet) return []

                return timesheet.findRowsByProject(this.project).flatMap(row => {
                    let subject = row.subject;
                    return row.findWorkedDaysByProject(this.project).map(workedDay => ({
                            // @ts-ignores
                            day: workedDay.day,
                            subject: subject,
                            // @ts-ignores
                            hours: workedDay.times.hours,
                            // @ts-ignores
                            description: workedDay.times.description
                        })
                    );
                }).sort((a, b) => a.day.day < b.day.day ? -1 : 1)
            }),
            map(data => {
                let dataSource = new MatTableDataSource<any>(data);
                dataSource.paginator = this.paginator;
                this.paginatorDisplayed = data.length > 0

                return dataSource
            }));
    }

    private updateMonthName() {
        this.monthName = getMonthName(this.month)
    }

    moveTimeLeft() {
        --this.month

        if (this.month < 0) {
            this.month = 11
            --this.year
        }

        this.loadJournal()
        this.updateMonthName()
    }

    moveTimeRight() {
        ++this.month

        if (this.month > 11) {
            this.month = 0
            ++this.year
        }

        this.loadJournal()
        this.updateMonthName()
    }

}
