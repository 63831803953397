<article class="page">
    <header>
        <h1 i18n="@@timesheet">Importazione Dati</h1>
    </header>

    <div class="content">

        <mat-card class="infoCard">
            <mat-card-content>
                <p>Scarica i formati excel da compilare</p>
                <p><a href="/assets/import_templates/clienti.csv" download>Clienti</a> &nbsp;
                    <a href="/assets/import_templates/fornitori.csv" download>Fornitori</a> &nbsp;
                    <a href="/assets/import_templates/dipendenti.csv" download>Dipendenti</a></p>
            </mat-card-content>
        </mat-card>

        <mat-card class="infoCard import">
            <mat-card-content>
                <div>
                    Importa
                    <button mat-raised-button color="primary" name="importClientsButton" (click)="$event.preventDefault(); clientsImport.click()">
                        <span>Clienti</span>
                    </button>
                    <input name="fileUpload" type="file" style="display: none" (change)="validateClientsImport($event)" #clientsImport>

                    &nbsp;

                    <button mat-raised-button color="primary" name="importSuppliersButton" (click)="$event.preventDefault(); suppliersImport.click()">
                        <span>Fornitori</span>
                    </button>
                    <input name="fileUpload" type="file" style="display: none" (change)="validateSuppliersImport($event)" #suppliersImport>

                    &nbsp;

                    <button mat-raised-button color="primary" name="importEmployeesButton" (click)="$event.preventDefault(); employeesImport.click()">
                        <span>Dipendenti</span>
                    </button>
                    <input name="fileUpload" type="file" style="display: none" (change)="validateEmployeesImport($event)" #employeesImport>

                </div>
            </mat-card-content>
        </mat-card>

    </div>
</article>
