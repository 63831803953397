<form name="businessForm" #form="ngForm" (ngSubmit)="saveQuote(quote, form)"
      [paymentsTotalMatchInvoiceTotal]="quote" validLines [fiscalDocument]="quote" (reset)="discardChanges(quote)">
    <article class="page" id="quoteView">
        <header>
            <h1 i18n="@@quote">Preventivo</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above" [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button type="button" mat-mini-fab color="accent" (click)="send(quote, form)" *ngIf="quote.isForProjectRequest()"
                        matTooltip="Invia" matTooltipPosition="above">
                    <mat-icon>send</mat-icon>
                </button>

                <button type="button" mat-mini-fab color="primary" (click)="copy(quote)" *ngIf="quote.persisted()"
                        matTooltip="Copia preventivo" matTooltipPosition="above">
                    <mat-icon>file_copy</mat-icon>
                </button>

                <button type="button" mat-mini-fab color="accent" (click)="createInvoice(quote)" *ngIf="quote.persisted()" matTooltip="Crea fattura da preventivo"
                        matTooltipPosition="above">
                    <mat-icon>receipt</mat-icon>
                </button>

                <button type="button" mat-mini-fab color="primary" (click)="downloadProForma(quote, form)" matTooltip="Scarica Pro Forma" matTooltipPosition="above">
                    <mat-icon>download</mat-icon>
                </button>

                <export-invoice-to-pdf *ngIf="quote.filePath" [fiscalDocument]="quote" [form]="form"></export-invoice-to-pdf>

                <export-invoice-to-xml *ngIf="quote.filePath" [fiscalDocument]="quote"></export-invoice-to-xml>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>
        <div class="content">

            <mat-card class="infoCard quote">
                <mat-card-header>
                    <mat-icon mat-card-avatar>receipt</mat-icon>

                    <mat-card-title class="includeInlineInput">
                        <span i18n="@@number">Numero</span>

                        <mat-form-field class="title">
                            <input name="number" #number="ngModel" required matInput [disabled]="!quote.isDraft()"
                                   uniqueQuoteNumber pattern="^\d+$|^([A-z]*\d+[\-A-z\d]*)+$" [fiscalDocument]="quote"
                                   [ngModelOptions]="{updateOn: 'blur'}" [(ngModel)]="quote.number">

                            <mat-error *ngIf="number.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                            <mat-error *ngIf="number.hasError('nonUnique')" i18n="@@non_unique_number">Numero preventivo già utilizzato</mat-error>
                            <mat-error *ngIf="number.hasError('pattern')" i18n="@@invalid_quote_number">Numero preventivo deve contenere lettere, trattini e almeno un numero</mat-error>
                        </mat-form-field>

                    </mat-card-title>
                </mat-card-header>

                <mat-card-content id="quoteBody">
                    <section class="details">

                        <mat-form-field>
                            <mat-label i18n="@@issue_date">Data emissione</mat-label>
                            <input name="date" matInput [matDatepicker]="pickerdate" #date="ngModel" required [disabled]="!quote.isDraft()"
                                   [(ngModel)]="quote.date">
                            <mat-datepicker-toggle matSuffix [for]="pickerdate"></mat-datepicker-toggle>
                            <mat-datepicker #pickerdate></mat-datepicker>
                            <mat-error *ngIf="date.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                    </section>

                    <section class="from">
                        <header>
                            <h1 i18n="@@from">Da</h1>
                        </header>

                        <div class="entityDetails">
                            <div class="label" i18n="@@name">Nome</div>
                            <div class="value">
                                <p>{{ quote.sender.name }}</p>
                            </div>
                            <div class="label" i18n="@@address">Indirizzo</div>
                            <div class="value">
                                <p>{{ quote.sender.address.street }} {{ quote.sender.address.city }}
                                    {{ quote.sender.address.postcode }} {{ quote.sender.address.district }}</p>
                            </div>
                            <div class="label" i18n="@@vat_number_or_tax_code">Partita IVA / Codice fiscale</div>
                            <div class="value">
                                <p>{{ quote.sender.taxNumber }}</p>
                            </div>
                        </div>

                    </section>

                    <section class="to">
                        <header><h1 i18n="@@to">A</h1></header>

                        <mat-form-field *ngIf="!quote.isForProjectRequest()">
                            <mat-label i18n="@@client">Cliente</mat-label>

                            <mat-select name="to" #to="ngModel" required [(ngModel)]="quote.receiver" [compareWith]="compareEntities" [disabled]="!quote.isDraft()"
                                        (ngModelChange)="loadProjects($event); cleanProject(quote)">
                                <mat-option *ngFor="let client of clients" [value]="client">
                                    {{ client.name }}
                                </mat-option>
                                <mat-option *ngIf="clients.length == 0" [value]="null" i18n="@@no_client">Nessun cliente</mat-option>
                            </mat-select>
                            <mat-error *ngIf="to.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <p *ngIf="quote.isForProjectRequest()">{{quote.receiver.name}}</p>

                        <div class="entityDetails" *ngIf="!quote.isForProjectRequest() && quote.receiver">
                            <div class="entityProperty">
                                <div class="label" i18n="@@address">Indirizzo</div>
                                <div class="value">
                                    <p>{{ quote.receiver.address.street }} {{ quote.receiver.address.city }}
                                        {{ quote.receiver.address.postcode }}
                                        {{ quote.receiver.address.district }}</p>
                                </div>
                            </div>

                            <div class="entityProperty">
                                <div class="label" i18n="@@vat_number_or_tax_code">Partita IVA / Codice fiscale</div>
                                <div class="value">
                                    <p>{{ quote.receiver.taxNumber }}</p>
                                </div>
                            </div>

                        </div>

                        <mat-form-field *ngIf="projectsObservable | async as projects">
                            <mat-label i18n="@@project">Progetto</mat-label>

                            <mat-select name="project" #project="ngModel" [(ngModel)]="quote.project"
                                        required [disabled]="!quote.isDraft()" [compareWith]="compareEntities">
                                <mat-option *ngFor="let project of projects" [value]="project">
                                    {{ project.name }}
                                </mat-option>
                                <mat-option *ngIf="projects.length == 0" [value]="null" i18n="@@no_project">Nessun progetto</mat-option>

                            </mat-select>
                            <mat-error *ngIf="project.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
                        </mat-form-field>
                    </section>

                    <section class="description">
                        <header><h1 i18n="@@description">Causale</h1></header>

                        <mat-form-field>
                            <textarea cdkTextareaAutosize name="description" type="text" [disabled]="!quote.isDraft()"
                                      matInput [(ngModel)]="quote.description"></textarea>
                        </mat-form-field>
                    </section>

                    <section class="lines">

                        <div class="actions" *ngIf="quote.isDraft()">
                            <button mat-flat-button color="primary" [matMenuTriggerFor]="addMenu" type="button" i18n="@@add">Aggiungi</button>
                        </div>

                        <mat-menu #addMenu="matMenu">
                            <button mat-menu-item (click)="addNewLine()" i18n="@@line">Linea</button>
                            <button mat-menu-item (click)="addNewPricedItem()" i18n="@@line_list">Linea da listino</button>
                            <button mat-menu-item (click)="addNewDiscount()" i18n="@@discount">Sconto</button>
                            <button mat-menu-item (click)="addNewSurcharge()" i18n="@@surcharge">Maggiorazione</button>
                            <button mat-menu-item (click)="addNewGovernmentContribution()" i18n="@@government_contribution">Contributo Governativo</button>
                        </mat-menu>


                        <lines name="lines" [(ngModel)]="quote.lines" [priceModifiers]="quote.priceModifiers" [form]="form" [disabled]="!quote.isDraft()"></lines>

                        <price-modifiers name="priceModifiers" [(ngModel)]="quote.priceModifiers" [fiscalDocument]="quote" [disabled]="!quote.isDraft()"></price-modifiers>
                        <table mat-table class="input-table">

                            <tr mat-footer-row *matFooterRowDef="['taxableDesc', 'taxableValue', 'empty']"></tr>
                            <tr mat-footer-row *matFooterRowDef="['taxDesc', 'taxValue', 'empty']"></tr>
                            <tr mat-footer-row *matFooterRowDef="['footerDesc', 'footerValue', 'empty']"
                                class="highlighted"></tr>


                            <ng-container matColumnDef="empty">
                                <td mat-footer-cell *matFooterCellDef>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="taxDesc">
                                <td mat-footer-cell *matFooterCellDef style="text-align: right" i18n="@@vat">
                                    IVA
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="taxValue">
                                <td mat-footer-cell *matFooterCellDef style="width: 7%">
                                    {{ quote.tax | formatCurrency }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="taxableDesc">
                                <td mat-footer-cell *matFooterCellDef style="text-align: right" i18n="@@taxable">Imponibile</td>
                            </ng-container>
                            <ng-container matColumnDef="taxableValue">
                                <td mat-footer-cell *matFooterCellDef>{{ quote.taxable | formatCurrency }}</td>
                            </ng-container>
                            <ng-container matColumnDef="footerDesc">
                                <td mat-footer-cell *matFooterCellDef style="text-align: right" i18n="@@total">Totale</td>
                            </ng-container>
                            <ng-container matColumnDef="footerValue">
                                <td mat-footer-cell *matFooterCellDef>{{ quote.total | formatCurrency }}</td>
                            </ng-container>
                        </table>
                        <government-contributions name="governmentContributions" [(ngModel)]="quote.priceModifiers"
                                                  [linesTotal]="quote.getLinesTotal()"
                                                  [disabled]="!quote.isDraft()"></government-contributions>
                    </section>

                    <invoice-payments name="quotePayments" [(ngModel)]="quote.payments" [form]="form" [disabled]="!quote.isDraft()"
                                      [invoiceTotal]="quote.getTotalWithContributions()"></invoice-payments>

                    <attachments name="attachments" [(ngModel)]="quote.attachments" [fiscalDocumentFilePath]="quote.filePath"></attachments>
                </mat-card-content>

            </mat-card>

        </div>
    </article>
</form>
