import {Directive, forwardRef, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {FiscalDocument} from "../model/fiscalDocument";

@Directive({
    selector: '[paymentsTotalMatchInvoiceTotal]',
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PaymentTotalValidatorDirective),
        multi: true
    }]
})
export class PaymentTotalValidatorDirective implements Validator {

    @Input('paymentsTotalMatchInvoiceTotal') fiscalDocument: FiscalDocument

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (!this.fiscalDocument || this.fiscalDocument.payments.length == 0) return null

        let paymentTotal = this.fiscalDocument.payments.reduce((previousValue, payment) => {
            return previousValue + payment.amount
        }, 0);

        if (paymentTotal != this.fiscalDocument.getTotalWithContributions()) {
            return {paymentsAmountNotMatchingTotal: true}
        }

        return null;
    }

}
