import {AfterViewInit, Component, ViewChild} from '@angular/core';
import * as moment from "moment";
import {EmployeeRepository} from "../employees/employee.repository";
import {MatDialog} from "@angular/material/dialog";
import {WorkingDayDialog} from "./working-times-dialog/working-times-dialog.component";
import {Timesheet, TimesheetRow, WeatherDay, WorkingDay} from "../model/timesheet";
import {TimesheetRepository} from "./timesheet.repository";
import {TimesheetService} from "./timesheet.service";
import {BehaviorSubject, mergeMap, Observable, of, tap} from "rxjs";
import {WeatherDialogComponent} from "./weather-dialog/weather-dialog.component";
import {Supplier} from "../model/supply";
import {SupplierRepository} from "../suppliers/supplier.repository";
import {MatSelectChange} from "@angular/material/select";
import {MatTable} from "@angular/material/table";
import {Employee} from "../model/employee";
import {Payslip} from "../model/payroll";
import {doc, runTransaction} from "@angular/fire/firestore";
import {NotificationService} from "../notification.service";
import {BusinessRepository} from "../authentication/business.repository";
import {Project} from "../model/project";
import {NgForm} from "@angular/forms";
import {FrontendErrorRepository} from "../project-requests/frontend-error.repository";
import {FrontendError} from "../model/frontend-error.request";
import {Bouncer} from "../bouncer.service";


@Component({
    selector: 'app-timesheet',
    templateUrl: './timesheet.component.html',
    styleUrls: ['./timesheet.component.scss'],
})
export class TimesheetComponent implements AfterViewInit {

    @ViewChild(MatTable) table: MatTable<TimesheetRow>;
    @ViewChild(NgForm) form: NgForm;


    year: number = moment().year()
    month: number = moment().month()
    data: TimesheetRow[] = [];

    timesheetObservable: Observable<Timesheet>

    loading: boolean = false
    suppliersObservable: BehaviorSubject<Supplier[]>;
    private suppliers: Supplier[];
    projects: Project[];
    maxWidth: string = '1000px';

    constructor(private employeeRepository: EmployeeRepository,
                private timesheetRepository: TimesheetRepository,
                private timesheetService: TimesheetService,
                private supplierRepository: SupplierRepository,
                private notificationService: NotificationService,
                private businessRepository: BusinessRepository,
                private errorRepository: FrontendErrorRepository,
                private bouncer: Bouncer,
                private dialog: MatDialog) {

        this.suppliersObservable = new BehaviorSubject<Supplier[]>([]);
        businessRepository.getAllProjects().subscribe(projects => {
            this.projects = projects;
        })
        this.findOrNewTimesheet();
    }

    private findOrNewTimesheet() {
        this.timesheetObservable = this.timesheetService.findTimesheet(this.year, this.month).pipe(mergeMap(timesheet => {
                if (timesheet) return of(timesheet)

                return this.timesheetService.newTimesheet(this.year, this.month)
            }),
            tap(timesheet => {
                this.supplierRepository.getAll().subscribe(suppliers => {
                    this.suppliers = suppliers
                    this.updateAvailableSuppliers(timesheet)
                });
            }));


    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            // @ts-ignore
            let width = document.getElementById('tablecontainer').offsetWidth * 0.95;
            this.maxWidth = `${width}px`
        }, 500)

    }

    get secondHeaderColumns() {
        return ['employee', ...(this.daysColumns.map(value => value.name)), 'total', 'actions'];
    }

    get weatherColumns() {
        let daysInMonth = this.getDaysInMonth();
        return [...Array(daysInMonth).keys()].map(value => (value + 1)).map(value => {
            return {name: `weather-${value}`, day: value}
        })
    }

    get firstHeaderColumns() {
        return ['empty', ...(this.weatherColumns.map(value => value.name)), 'empty', 'empty'];
    }

    get daysColumns() {
        let daysInMonth = this.getDaysInMonth();
        return [...Array(daysInMonth).keys()].map(value => (value + 1)).map(value => {
            return {name: value.toString(), day: value}
        });
    }

    private getDaysInMonth(): number {
        return moment(`${this.year}-${this.month + 1}`, 'YYYY-MM').daysInMonth();
    }

    remove(event: MouseEvent, row, timesheet: Timesheet) {
        event.stopPropagation()
        event.preventDefault()

        let index = timesheet.rows.findIndex(value => value == row)
        timesheet.rows.splice(index, 1)

        this.table.renderRows()
        this.updateAvailableSuppliers(timesheet);

    }

    getMonthName(timesheet: Timesheet) {
        return timesheet.getMonthName()
    }

    async save(timesheet: Timesheet) {
        this.loading = true
        timesheet.id = `${this.year}-${this.month}`

        await runTransaction(this.timesheetRepository.firestoreWrapper.firestore, async (transaction) => {
            console.log("Saving Timesheet")

            transaction.set(doc(this.timesheetRepository.getCollectionReference(), timesheet.id), timesheet)
            console.log("timesheet saved")

            timesheet.rows.forEach(row => {
                if (row.isEmployee()) {
                    let employee = row.subject as Employee;
                    if (!employee.hourlyRate || row.total == 0) return

                    let payslip = employee.findPayslip(timesheet.year, timesheet.month + 1);
                    if (!payslip) {
                        payslip = new Payslip();
                        payslip.number = `${timesheet.year}-${timesheet.month+1}`
                        payslip.year = timesheet.year
                        payslip.month = timesheet.month + 1
                        employee.payroll.add(payslip)
                    }

                    if(payslip.isPaid()) return

                    payslip.amount = row.total * employee.hourlyRate
                    ++employee.version
                    console.log("Saving Timesheet - update employee")
                    transaction.set(doc(this.employeeRepository.getCollectionReference(), employee.id), employee)
                }
            })

            this.loading = false
            this.form.control.markAsPristine()
            this.notificationService.notify('Registro ore salvato')
        }).catch(reason => {
            this.notificationService.notifyError("Si è verificato un errore, impossibile salvare")
            throw reason
        })


    }

    discardChanges() {

    }

    moveTimeLeft() {
        --this.month

        if (this.month < 0) {
            this.month = 11
            --this.year
        }

        this.findOrNewTimesheet()
    }

    moveTimeRight() {
        ++this.month

        if (this.month > 11) {
            this.month = 0
            ++this.year
        }

        this.findOrNewTimesheet()

    }

    getLink(row: TimesheetRow) {
        if (row.isEmployee()) return '/employees'
        if (row.isSupplier()) return '/suppliers'

        return ''
    }

    openWorkingDay(workingDay: WorkingDay) {
        this.form.control.markAsDirty()
        return this.dialog.open(WorkingDayDialog, {
            data: {
                workingDay,
                projects: this.projects
            },
            minWidth: '500px',
            disableClose: true
        }).afterClosed()
    }

    openWeatherDay(weatherDay: WeatherDay) {
        this.form.control.markAsDirty()

        return this.dialog.open(WeatherDialogComponent, {
            data: {
                weatherDay: weatherDay,
            },
            minWidth: '500px',
            disableClose: true
        }).afterClosed()
    }


    addSupplier(event: MatSelectChange, timesheet: Timesheet) {
        let supplier = event.value
        event.source.value = null

        let timesheetRow = this.timesheetService.makeNewTimesheetRowForTimesheet(timesheet);
        timesheetRow.subject = supplier
        timesheet.rows.push(timesheetRow)
        this.table.renderRows()
        this.updateAvailableSuppliers(timesheet);

    }

    private updateAvailableSuppliers(timesheet: Timesheet) {
        let suppliers = this.suppliers
            .filter(supplier => !timesheet.findRow(supplier, supplier.type()))
        this.suppliersObservable.next(suppliers)
    }

    downloadAsPDF(timesheet: Timesheet, project: Project) {
        console.log("download as pdf", timesheet)
        this.timesheetService.downloadAsPDF(timesheet, project)
    }

    canDeactivate() {
        if(!this.form) return true

        return this.form?.pristine
    }
}
