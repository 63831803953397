import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Observable} from "rxjs";
import {Client} from "../../../model/client";
import {Project} from "../../../model/project";
import {DocumentStorageService} from "../../../document-storage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Bouncer} from "../../../bouncer.service";
import {ClientRepository} from "../../../clients-management/client.repository";
import {compareEntities} from "../../../utils";
import {NgForm} from "@angular/forms";
import {QuotesRepository} from "../quotes.repository";
import {Quote} from "../../../model/invoice";
import {NotificationService} from "../../../notification.service";
import {GovernmentContributionsComponent} from "../../government-contributions/government-contributions.component";
import {PriceModifiersComponent} from "../../price-modifiers/price-modifiers.component";
import {LinesComponent} from "../../lines/lines.component";
import {NavigatorService} from "../../../navigator.service";
import {FiscalDocumentService} from "../../fiscal-document.service";
import {FormComponentV2} from "../../../components/formV2.component";
import {Transaction} from "@angular/fire/firestore";
import {ProjectRequest} from "../../../model/project.request";
import {ChatRepository} from "../../../chats/chat.repository";

@Component({
    selector: 'app-quote',
    templateUrl: './quote.component.html',
    styleUrls: ['./quote.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class QuoteComponent extends FormComponentV2<Quote> implements OnInit {

    @ViewChild(GovernmentContributionsComponent) governmentContributionsComponent: GovernmentContributionsComponent
    @ViewChild(PriceModifiersComponent) priceModifiersComponent: PriceModifiersComponent
    @ViewChild(LinesComponent) linesComponent: LinesComponent

    quote: Quote;
    clients: Client[] = []

    projectsObservable: Observable<Project[]>;
    erpEnabled: boolean;

    constructor(private route: ActivatedRoute, private bouncer: Bouncer,
                navigatorService: NavigatorService, documentStorage: DocumentStorageService,
                private fiscalDocumentService: FiscalDocumentService,
                private router: Router,
                notificationService: NotificationService,
                quotesRepository: QuotesRepository,
                private chatRepository: ChatRepository,
                private clientRepository: ClientRepository) {
        super(documentStorage, navigatorService, notificationService, quotesRepository);
    }


    ngOnInit(): void {
        this.erpEnabled = this.bouncer.getConfig().erp

        this.quote = this.route.snapshot.data.quote as Quote;
        let projectRequest = this.route.snapshot.data.projectRequest as ProjectRequest;

        if(projectRequest) {
            this.quote.receiver = new Client()
            this.quote.receiver.name = projectRequest.requester
            this.quote.receiver.taxNumber = `PR--${projectRequest.id}`
        }

        this.quote.lines.forEach(line => line.expanded = line.hasReferences())
        this.loadProjects(this.quote.receiver as Client)

        this.clientRepository.getAll().subscribe(clients => {
            return this.clients = clients;
        })

    }

    saveQuote(quote: Quote, form: NgForm) {
        form.control.updateValueAndValidity()
        super.save(quote)
    }

    async doCreate(transaction: Transaction, quote: Quote) {
        this.repository.transactionalSave(transaction, quote)
    }

    async doUpdate(transaction: Transaction, quote: Quote): Promise<void> {
        let quoteToSave = quote.clone(new Quote());
        ++quoteToSave.version
        await this.repository.transactionalUpdate(transaction, quoteToSave.id, quoteToSave)
    }

    compareEntities(a, b) {
        return compareEntities(a, b)
    }

    loadProjects(client: Client) {
        if (client && this.erpEnabled) this.projectsObservable = this.clientRepository.getProjects(client.id);
    }

    cleanProject(quote: Quote) {
        quote.project = null
    }

    downloadProForma(quote: Quote, form: NgForm) {
        if (!form.valid) {
            form.control.markAllAsTouched();
            return
        }

       this.fiscalDocumentService.downloadQuoteProForma(quote)
    }

    copy(quote: Quote) {
        // for some reason we need to reaload the page because otherwise the component is not refreshed with the new quote
        this.router.navigateByUrl(`/invoicing/quotes/new?toCopy=${quote.id}`).then(value => location.reload())
    }

    createInvoice(quote: Quote) {
        this.router.navigateByUrl(`/invoicing/outbound/new?toCopy=${quote.id}`);
    }

    addNewDiscount() {
        this.priceModifiersComponent.addNewDiscount()
    }

    addNewSurcharge() {
        this.priceModifiersComponent.addNewSurcharge()
    }

    addNewGovernmentContribution() {
        this.governmentContributionsComponent.addNew()
    }

    addNewLine() {
        this.linesComponent.addNewLine()
    }

    addNewPricedItem() {
        this.linesComponent.addNewPricedItem()
    }

    send(quote: Quote, form: NgForm) {
        form.control.updateValueAndValidity()
        if (!form.valid) {
            form.control.markAllAsTouched();
            alert("Il preventivo contiene errori, impossibile inviare")
            return
        }

        if (!form.pristine) {
            alert("Il preventivo contiene modifiche, salva prima di inviare")
            return
        }
        console.log("sending quote", quote.id)

        ++quote.version
        this.repository.save(quote).then(() => this.chatRepository.saveQuoteByProjectRequestId(quote, quote.getProjectRequestId()))
            .then(value => {
                this.notificationService.notify('Preventivo inviato')
                this.navigatorService.goToProjectRequest(quote.getProjectRequestId())
            })

    }
}
