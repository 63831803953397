import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-suppliers-import-dialog',
  templateUrl: './suppliers-import-dialog.component.html',
  styleUrls: ['./suppliers-import-dialog.component.scss']
})
export class SuppliersImportDialog {
    errors: any[];
    entities: any[];
    displayedColumns: string[] = ["name", "sdi", "taxNumber","district","city","postcode","address" ];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.errors = data.errors
        this.entities = data.entities.map(e => e.entity)
    }

    isBadImport(entity) {
        return !!this.errors.find(value => value.entity == entity)
    }

    isErrorPresent() {
        return this.errors.length > 0
    }
}
