import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {MatTable} from "@angular/material/table";
import {Payment} from "../../../model/payment";
import {CrudCardComponent} from "../../crud-card/crud-card.component";
import {Balance} from "../../../model/balance";
import {Accountant} from "../../../model/accountant";
import {Business} from "../../../model/business";
import {Supplier, Supply} from "../../../model/supply";

@Component({
    selector: 'payments',
    templateUrl: './balance.component.html',
    styleUrls: ['./balance.component.scss']
})
export class BalanceComponent extends CrudCardComponent<Payment> implements AfterViewInit {

    private _balance: Balance;
    @ViewChild(MatTable) paymentsTable: MatTable<Payment>;

    @Input() owner: Accountant | Business | Supplier | Supply
    @Input() mode: 'inbound' | 'outbound' | 'quotes' = 'inbound'

    displayedColumns: string[] = ['invoiceNumber', 'description', 'dueDate', 'paidDate', 'amount', 'actions'];

    constructor() {
        super()
    }

    getBaseUrl() {
        if (this.isInboundMode()) {
            return '/invoicing/inbound'
        } else if (this.isOutboundMode()) {
            return '/invoicing/outbound'
        } else {
            return '/invoicing/quotes'
        }
    }

    isOutboundMode() {
        return this.mode == 'outbound';
    }

    isInboundMode() {
        return this.mode == 'inbound';
    }

    isQuoteMode() {
        return this.mode == 'quotes';
    }

    ngAfterViewInit() {
        super.table = this.paymentsTable
    }

    public newEntity() {
        return new Payment()
    }

    get balance(): Balance {
        return this._balance;
    }

    @Input()
    set balance(value: Balance) {
        this._balance = value;
        super.list = this._balance.payments
    }

}
