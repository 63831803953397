import {Component, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Repository} from "../../persistence/repository";
import {Business, Showcase} from "../../model/business";
import {getCurrentBusiness} from "../../utils";
import {FirestoreWrapper} from "../../persistence/firestoreWrapper";
import {ConverterRegistry} from "../../persistence/converter-registry.service";

@Component({
  selector: 'app-showcase',
  templateUrl: './fake-showcases.component.html',
  styleUrls: ['./fake-showcases.component.scss']
})
export class FakeShowcasesComponent {
    list: Observable<Showcase[]>;

    displayedColumns: string[] = ['id', 'businessName', 'description', 'createdDate', 'actions'];

    constructor(private showcaseRepository: FakeShowcaseRepository, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.list = this.showcaseRepository.getAll()
    }

    onSelected(showcase) {
        this.router.navigate([showcase.id], {relativeTo: this.route});
    }

    delete(event: MouseEvent, Showcase) {
        event.preventDefault()
        event.stopPropagation()

        let confirmation = confirm("Do you want to delete the entity?");

        if(confirmation) {
            this.showcaseRepository.delete(Showcase)
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class FakeShowcaseRepository extends Repository<Showcase> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, ConverterRegistry.find('Showcase'))
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection(`externalshowcase`).withConverter(this.converter);
    }

}
