import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {Supplier} from "../../../model/supply";
import {map, Observable, startWith} from "rxjs";
import {DocumentStorageBatch} from "../../../document-storage.service";
import {verifyAutocomplete} from "../../../utils";
import {MedicalRecord} from "../../../model/medicalRecord";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'medical-record-dialog',
    templateUrl: './medical-record-dialog.component.html',
    styleUrls: ['./medical-record-dialog.component.scss']
})
export class MedicalRecordDialog implements OnInit {

    record: MedicalRecord
    batch: DocumentStorageBatch
    suppliers: Supplier[]

    supplierAutocompleteControl: UntypedFormControl = new UntypedFormControl(null, verifyAutocomplete())
    filteredSupplierOptions: Observable<Supplier[]>;
    editMode: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<MedicalRecordDialog>) {
        this.batch = data.batch
        this.record = data.record
        this.suppliers = data.suppliers

        this.editMode = data.editMode
    }

    ngOnInit(): void {

        this.filteredSupplierOptions = this.supplierAutocompleteControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value?.name),
            map(name => (name ? this._filterSuppliers(name) : this.suppliers.slice())),
        );

        this.supplierAutocompleteControl.setValue(this.record.supplier)

    }


    getSupplierName(supplier: Supplier): string {
        return supplier && supplier.name ? supplier.name : '';
    }

    private _filterSuppliers(name: string): Supplier[] {
        const filterValue = name.toLowerCase();

        return this.suppliers.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onSupplierSelected(event) {
        this.record.supplier = event.option.value
    }

    onSubmit() {
        this.dialogRef.close(this.record)
    }

    cancel(event: MouseEvent) {
        event.stopPropagation()
        event.preventDefault()
        this.dialogRef.close(null)
    }
}
