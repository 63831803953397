import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Leave} from "../../../model/leave";

@Component({
  selector: 'app-leave-record-dialog',
  templateUrl: './leave-record-dialog.component.html',
  styleUrls: ['./leave-record-dialog.component.scss']
})
export class LeaveRecordDialog {
    record: Leave
    editMode: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<LeaveRecordDialog>) {
        this.record = data.record
        this.editMode = data.editMode
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.dialogRef.close(this.record)
    }

    cancel(event: MouseEvent) {
        event.stopPropagation()
        event.preventDefault()
        this.dialogRef.close(null)
    }
}
