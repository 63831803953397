import {Component} from '@angular/core';

@Component({
    templateUrl: './spinner-dialog.component.html',
    styleUrls: ['./spinner-dialog.component.scss']
})
export class SpinnerDialogComponent {


    constructor() {
    }

}
