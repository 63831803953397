<form *ngIf="priceListObservable | async as priceList" name="businessForm"
      #form="ngForm" (ngSubmit)="save(priceList, form)"
      (reset)="discardChanges(priceList)">
    <article class="page" id="priceListView">
        <header>
            <h1>Listino prezzi</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above"
                        [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>
        <div class="content">


            <mat-form-field>
                <mat-label>Name</mat-label>
                <input name="name" #name="ngModel" required matInput [(ngModel)]="priceList.name">
                <mat-error *ngIf="name.hasError('required')">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Index</mat-label>
                <input name="name" #name="ngModel" required matInput [(ngModel)]="priceList.index">
            </mat-form-field>

                <div class="actions">
                    <button mat-flat-button color="primary" (click)="addNewItem(priceList)" type="button">
                        Aggiungi
                    </button>
                </div>

                <table mat-table [dataSource]="priceList.items" #itemsTable class="input-table">
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef>Code</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <mat-form-field class="small">
                                <input name="code-{{i}}" #code="ngModel" matInput [(ngModel)]="element.code" required/>
                                <mat-error *ngIf="code.hasError('required')">Campo obbligatorio
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Descrizione</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <mat-form-field>
                                        <textarea name="description-{{i}}" #description="ngModel"
                                                  matInput required cdkTextareaAutosize
                                                  [(ngModel)]="element.description"></textarea>
                                <mat-error *ngIf="description.hasError('required')">Campo obbligatorio
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="unitOfMeasure">
                        <th mat-header-cell *matHeaderCellDef>Unit&agrave; di misura</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <mat-form-field class="small">
                                <input name="unit-{{i}}"
                                       #unit="ngModel" required
                                       matInput [(ngModel)]="element.unitOfMeasure"/>
                                <mat-error *ngIf="unit.hasError('required')">Campo obbligatorio
                                </mat-error>
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="cost">
                        <th mat-header-cell *matHeaderCellDef>Prezzo Unitario</th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <mat-form-field class="small">
                                <input type="number" name="cost-{{i}}" #cost="ngModel"
                                       matInput required [(ngModel)]="element.cost"/>
                                <label matSuffix>€</label>
                                <mat-error *ngIf="cost.hasError('required')">Campo obbligatorio</mat-error>
                            </mat-form-field>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let item; let i = index">
                            <div style="display: flex; flex-flow: row-reverse">
                                <button type="button" mat-icon-button [matMenuTriggerFor]="lineMenu">
                                    <mat-icon class="mat-24">more_vert</mat-icon>
                                </button>
                                <mat-menu #lineMenu="matMenu">
                                    <button mat-menu-item (click)="removeItem($event, priceList, item)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Elimina</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row class="lineRow" *matRowDef="let row; columns: displayedColumns"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="displayedColumns.length"
                            style="text-align: center">
                            <p>Nessun prezzo presente</p>
                        </td>
                    </tr>

                </table>

        </div>
    </article>
</form>
