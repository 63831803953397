<table mat-table [dataSource]="requests">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef style="width: 4%">id</th>
        <td mat-cell *matCellDef="let request"> {{request.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Business</th>
        <td mat-cell *matCellDef="let request"> {{request.name}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let request"> {{request.email}} </td>
    </ng-container>

    <ng-container matColumnDef="package">
        <th mat-header-cell *matHeaderCellDef>Package</th>
        <td mat-cell *matCellDef="let request"> {{request.package}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let request"> {{request.description}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Request time</th>
        <td mat-cell *matCellDef="let request"> {{request.date  | date:'dd/MM/YYYY HH:mm'}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length"
            style="text-align: center">
            Nessuna richiesta di contatto
        </td>
    </tr>
</table>
