import {Injectable} from '@angular/core';
import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
    updateDoc
} from "@angular/fire/firestore";
import {Repository} from "./persistence/repository";
import {getCurrentBusiness} from "./utils";
import {Document, DocumentStatus} from "./model/document";
import * as moment from "moment";
import {FirestoreWrapper} from "./persistence/firestoreWrapper";


@Injectable({
    providedIn: "root"
})
export class DocumentConverter implements FirestoreDataConverter<Document> {

    constructor() {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Document {
        let data = snapshot.data(options)!;
        return this.fromData(data)!
    }

    toFirestore(document: Document): DocumentData {
        if (!document) throw new Error("Attempt to store null document")

        return this.toData(document)!
    }

    fromData(data: DocumentData) {
        if (!data) return null
        let document = new Document()
        document.id = data.id
        document.name = data.name
        document.fileName = data.fileName
        document.fullPath = data.fullPath
        document.downloadUrl = data.downloadUrl
        document.status = DocumentStatus[data['status'] as keyof typeof DocumentStatus]
        document.createdTime = data['createdTime'] ? moment(data['createdTime'].toDate()) : null
        document.updatedTime = data['updatedTime'] ? moment(data['updatedTime'].toDate()) : null

        return document
    }

    toData(document: Document | null) {
        if (!document) return null

        return {
            id: document.id,
            name: document.name,
            fileName: document.fileName,
            fullPath: document.fullPath,
            downloadUrl: document.downloadUrl,
            status: DocumentStatus[document.status] || null,
            createdTime: document.createdTime?.toDate() || new Date(),
            updatedTime: new Date()
        };
    }
}

@Injectable({
    providedIn: 'root'
})
export class DocumentRepository extends Repository<Document> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, new DocumentConverter())
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection(`businesses/${business.id}/documents`).withConverter(this.converter);
    }

    updateDeleted(document: Document) {
        // @ts-ignore
        return updateDoc(this.getReference(document), {
            status: DocumentStatus[document.status],
            updatedTime: new Date()
        })
    }

}

