<mat-card class="infoCard information">
    <mat-card-header>
        <mat-card-title>Visite Mediche</mat-card-title>

        <mat-icon mat-card-avatar>medication</mat-icon>
    </mat-card-header>

    <mat-card-content>

        <table mat-table [dataSource]="list" [hidden]="hideTable()">

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef i18n="@@description">Descrizione</th>
                <td mat-cell *matCellDef="let record">{{ record.description }}</td>
            </ng-container>

            <ng-container matColumnDef="expectedDate">
                <th mat-header-cell *matHeaderCellDef i18n="@@expected_date">Data prevista</th>
                <td mat-cell *matCellDef="let record"> {{ record.expectedDate | date }}</td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef i18n="@@date">Data</th>
                <td mat-cell *matCellDef="let record"> {{ record.date | date }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let record">
                    <button mat-icon-button (click)="openEditDialog($event, record)">
                        <mat-icon class="mat-24">edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="remove($event, record)">
                        <mat-icon class="mat-24">delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="hideTable()">
            <p i18n="@@no_medical_appointment">Nessuna visita medica</p>
        </div>

    </mat-card-content>

    <mat-card-actions>
        <button type="button" name="openFormButton" mat-button color="primary" (click)="openNewDialog()" i18n="@@add">Aggiungi</button>
    </mat-card-actions>

</mat-card>
