import {Component, Input, OnInit} from '@angular/core';
import {XmlExporterService} from "../xml-exporter.service";
import {FiscalDocument} from "../../model/fiscalDocument";

@Component({
    selector: 'export-invoice-to-xml',
    templateUrl: './export-invoice-to-xml.component.html',
    styleUrls: ['./export-invoice-to-xml.component.scss']
})
export class ExportInvoiceToXmlComponent implements OnInit {

    @Input() fiscalDocument: FiscalDocument

    constructor(private xmlExporter: XmlExporterService) {
    }

    ngOnInit(): void {
    }

    exportToXml(event: MouseEvent) {
        event.preventDefault()
        this.xmlExporter.downloadAsXml(this.fiscalDocument)
    }

}
