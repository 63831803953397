import {AfterViewInit, Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {Education} from "../../model/education";
import {MatTable} from "@angular/material/table";
import {CrudCardComponent} from "../../components/crud-card/crud-card.component";
import {DocumentStorageBatch} from "../../document-storage.service";
import {Supplier} from "../../model/supply";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {EducationRecordDialog} from "./education-record-dialog/education-record-dialog.component";
import {clone, generateId} from "../../utils";

@Component({
    selector: 'education-profile',
    templateUrl: './education.component.html',
    styleUrls: ['./education.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EducationComponent),
            multi: true,
        },
    ],
})
export class EducationComponent extends CrudCardComponent<Education> implements OnInit, AfterViewInit {

    @ViewChild(MatTable) educationTable: MatTable<Education>;
    @Input() batch: DocumentStorageBatch
    @Input() suppliers: Supplier[];


    displayedColumns: string[] = ['description', 'expectedDate', 'date', 'actions'];


    constructor(dialog: MatDialog) {
        super(dialog)

        this.setDialogComponent(EducationRecordDialog)
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        super.table = this.educationTable
    }

    public newEntity() {
        let education = new Education();
        education.id = generateId()
        return education
    }

    openDialog() {
        super.openNewRecordDialog({
            record: this.newEntity(),
            suppliers: this.suppliers,
            batch: this.batch
        })
    }

    onEditDialog(event: MouseEvent, record: Education) {
        super.openEditRecordDialog(event,{
            record: clone(record),
            suppliers: this.suppliers,
            batch: this.batch
        })
    }
}
