import {Moment} from "moment";
import {Document} from "./document";
import {Entity} from "./entity";

export class Leave extends Entity {
    from: Moment
    to: Moment

    containsDate(date: Moment): boolean {
        return date.isBetween(this.from, this.to, 'day', '[]')
    }
}

export class HourlyLeave extends Leave {
    date: Moment
}

export class SickLeave extends Leave {
    certificate: Document | null


    override getDocuments(): Document[] {
        return this.certificate ? [this.certificate] : []
    }
}
