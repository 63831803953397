import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {FiscalDocument} from "../../model/fiscalDocument";
import {MatTable} from "@angular/material/table";
import {Line, PriceModifier} from "../../model/invoice";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'price-modifiers',
    templateUrl: './price-modifiers.component.html',
    styleUrls: ['./price-modifiers.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PriceModifiersComponent),
            multi: true,
        },
    ],
})
export class PriceModifiersComponent implements OnInit, ControlValueAccessor {
    priceModifiersColumns: string[] = ['description', 'percentage', 'amount', 'value', 'actions'];

    onChange = (value) => {};
    onTouched = () => {};
    touched = false;
    @Input() disabled = false;
    @Input() lines: Line[]
    @Input() fiscalDocument: FiscalDocument

    priceModifiers: PriceModifier[] = [];
    @ViewChild('priceModifiersTable') priceModifiersTable: MatTable<PriceModifier>


    constructor() {
    }

    ngOnInit(): void {
    }

    addNewDiscount() {
        let priceModifier = new PriceModifier();
        priceModifier.type = 'SC'

        this.priceModifiers.push(priceModifier)
        this.priceModifiersTable.renderRows()
    }

    addNewSurcharge() {
        let priceModifier = new PriceModifier();
        priceModifier.type = 'MG'

        this.priceModifiers.push(priceModifier)
        this.priceModifiersTable.renderRows()
    }

    remove(event: MouseEvent, element) {
        event.preventDefault()
        event.stopPropagation()

        let index = this.priceModifiers.findIndex(value => element == value);
        this.priceModifiers.splice(index, 1)
        this.priceModifiersTable.renderRows()

    }

    getPriceModifiersWithNoContributions(): PriceModifier[] {
        return this.priceModifiers.filter(value => !value.governmentContribution)
    }

    writeValue(priceModifiers: PriceModifier[]) {
        this.priceModifiers = priceModifiers
        this.priceModifiersTable.renderRows()
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

}
