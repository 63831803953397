<h1 mat-dialog-title i18n="@@education">Formazione</h1>
<div mat-dialog-content>
    <form name="addForm" #form="ngForm" (ngSubmit)="form.valid && onSubmit()">

        <mat-form-field>
            <mat-label i18n="@@description">Descrizione</mat-label>
            <input name="recordDescription" matInput #description="ngModel" required [(ngModel)]="record.description">
            <mat-error *ngIf="description.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label i18n="@@supplier">Fornitore</mat-label>
            <input name="supplier" type="text" matInput [formControl]="supplierAutocompleteControl" required
                   [matAutocomplete]="supplierAutocomplete">
            <mat-autocomplete #supplierAutocomplete="matAutocomplete" [displayWith]="getSupplierName"
                              (optionSelected)="onSupplierSelected($event)">
                <mat-option *ngFor="let supplier of filteredSupplierOptions | async" [value]="supplier">
                    {{ supplier.name }}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="supplierAutocompleteControl.hasError('required')" i18n="@@required">Campo obbligatorio
            </mat-error>
            <mat-error *ngIf="supplierAutocompleteControl.hasError('nonExistent')" i18n="@@supplier_not_found">Il fornitore non esiste. Seleziona un fornitore esistente</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label i18n="@@expected_date">Data prevista</mat-label>
            <input name="expectedDate" matInput [matDatepicker]="expectedDatePicker" #date="ngModel"
                   [(ngModel)]="record.expectedDate">
            <mat-datepicker-toggle matSuffix [for]="expectedDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expectedDatePicker></mat-datepicker>
            <mat-hint i18n="@@expected_date_hint">Ti ricorderemo dell&apos;appuntamento 30 giorni prima</mat-hint>
        </mat-form-field>


        <mat-form-field>
            <mat-label i18n="@@actual_date">Data effettiva</mat-label>
            <input name="date" matInput [matDatepicker]="datePicker" #date="ngModel" [(ngModel)]="record.date">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <document-input [batch]="batch" type="certification" label="Attestato" i18n-label="@@certification" name="certification"
                        [(ngModel)]="record.certificate"></document-input>

        <mat-dialog-actions align="end">
            <button (click)="cancel($event)" mat-button i18n="@@cancel">Annulla</button>
            <button *ngIf="!editMode" name="addButton" type="submit" mat-button color="primary" i18n="@@add">Aggiungi
            </button>

            <button *ngIf="editMode" type="submit" color="primary" mat-button i18n="@@edit">Modifica</button>
        </mat-dialog-actions>
    </form>
</div>
