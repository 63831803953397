<article class="page">
    <section class="discover">
        <div class="intro">
            <h1 i18n="@@edilie_slogan">La vera gestione edile semplificata</h1>
            <p i18n="@@edilie_intro">Edilie, &egrave; il sistema gestionale online che ti permette di gestire i tuoi progetti, la
                fatturazione
                elettronica e molto altro, in modo semplice e veloce.</p>
            <button (click)="scroll(features)" mat-flat-button color="primary" i18n="@@discover">Scopri di pi&ugrave;</button>
        </div>
        <div class="picture">
            <div class="chip">
                <mat-icon matChipAvatar>business</mat-icon>
                <span i18n="@@business">Azienda</span>
            </div>
            <div class="chip">
                <mat-icon matChipAvatar>receipt</mat-icon>
                <span i18n="@@invoicing">Fatturazione</span>
            </div>
            <div class="chip">
                <mat-icon matChipAvatar>punch_clock</mat-icon>
                <span i18n="@@employees">Dipendenti</span>
            </div>
            <div class="chip">
                <mat-icon matChipAvatar>people</mat-icon>
                <span i18n="@@clients">Clienti</span>
            </div>
            <div class="chip">
                <mat-icon matChipAvatar>ssid_chart</mat-icon>
                <span i18n="@@accounting">Contabilit&agrave;</span>
            </div>
            <div class="chip">
                <mat-icon matChipAvatar>local_shipping</mat-icon>
                <span i18n="@@suppliers">Fornitori</span>
            </div>
        </div>
    </section>
    <section class="showcase">
        <h1 i18n="@@check_demo">Guarda la demo</h1>

        <div class="container">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/5jMrLKo0LBA" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
        </div>

    </section>
    <section class="features" #features>
        <h1 i18n="@@all_you_need">
            Tutto quello che ti serve gestire,<br>
            all'interno di un'unica applicazione online.
        </h1>

        <div class="container">
            <mat-card>
                <mat-card-title>
                    <mat-icon>receipt_long</mat-icon>
                    <h1 i18n="@@digital_invoicing">Fatturazione Elettronica</h1>
                </mat-card-title>
                <mat-card-content>
                    <ul i18n="@@features_invoicing">
                        <li>Invia e ricevi fatture dallo SDI</li>
                        <li>Gestione fatture</li>
                        <li>Gestione Preventivi</li>
                        <li>Tracciamento pagamenti</li>
                    </ul>
                </mat-card-content>
            </mat-card>

            <mat-card>
                <mat-card-title>
                    <mat-icon>people</mat-icon>
                    <h1 i18n="@@clients_and_prjects">Clienti e Progetti</h1>
                </mat-card-title>
                <mat-card-content>
                    <ul i18n="@@features_projects">
                        <li>Gestione clienti</li>
                        <li>Gestione progetti</li>
                        <li>Pianificazionie attivit&agrave;</li>
                        <li>Stato lavori</li>
                        <li>Raccolta documenti</li>
                    </ul>
                </mat-card-content>
            </mat-card>

            <mat-card>
                <mat-card-title>
                    <mat-icon>list</mat-icon>
                    <h1 i18n="@@employees_management">Attivit&agrave; Dipendenti</h1>
                </mat-card-title>
                <mat-card-content>
                    <ul i18n="@@features_employees">
                        <li>Gestione dipendenti</li>
                        <li>Tracciamento malattie/ferie</li>
                        <li>Gestione ore e salario</li>
                        <li>Tracciamento dei pagamenti</li>
                    </ul>
                </mat-card-content>
            </mat-card>

            <mat-card>
                <mat-card-title>
                    <mat-icon>engineering</mat-icon>
                    <h1 i18n="@@supplier_relationship">Rapporti Fornitori</h1>
                </mat-card-title>
                <mat-card-content>
                    <ul i18n="@@features_suppliers">
                        <li>Gestione fornitori</li>
                        <li>Associazione fatture</li>
                        <li>Tracciamento pagamenti</li>
                    </ul>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="action">
            <button (click)="scroll(contact)" mat-flat-button color="primary" i18n="@@contact_us">Contattaci</button>
        </div>

    </section>
    <section class="reviews">

        <h1 i18n="@@they_say_about_us">Cosa dicono i nostri clienti:</h1>

        <div class="slider">

            <mat-card class="review" *ngIf="reviewSelected == 1">
                <div mat-card-avatar
                     style="background-image: url('/assets/imgs/review1.jpg'); background-size: cover;"></div>

                <div class="right">
                    <mat-card-header>
                        <mat-card-title>Sara</mat-card-title>
                    </mat-card-header>
                    <mat-card-content i18n="@@review_1">
                        Complimenti! In Edilie ho trovato tutto quello che mi serviva.
                        Ed è davvero facile gestire tutto tramite la vostra applicazione.
                    </mat-card-content>
                </div>
            </mat-card>

            <mat-card class="review" *ngIf="reviewSelected == 2">
                <div mat-card-avatar
                     style="background-image: url('/assets/imgs/review2.jpeg'); background-size: cover;"></div>

                <div class="right">
                    <mat-card-header>
                        <mat-card-title>Luigi</mat-card-title>
                    </mat-card-header>
                    <mat-card-content i18n="@@review_2">
                        Fino a ieri la mia scrivania era piena di fogli e fatture e mi capitava di perdere molto tempo a
                        ritrovare le cose che mi servivano.
                        Ora con Edilie ho tutto sotto controllo e maggior tempo libero per la mia famiglia.
                    </mat-card-content>
                </div>
            </mat-card>

            <mat-card class="review" *ngIf="reviewSelected == 3">
                <div mat-card-avatar
                     style="background-image: url('/assets/imgs/review3.jpg'); background-size: cover;"></div>

                <div class="right">
                    <mat-card-header>
                        <mat-card-title>Pietro</mat-card-title>
                    </mat-card-header>
                    <mat-card-content i18n="@@review_3">
                        Se solo avessi scoperto Edilie prima, sarebbe stato tutto più facile.
                        Non pensavo fosse davvero così semplice. Grazie
                    </mat-card-content>
                </div>
            </mat-card>
        </div>

        <div class="selector">
            <button mat-icon-button color="accent" [class.selected]="reviewSelected == 1"
                    (click)="onReviewSelectorClicked(1)">
                <mat-icon>fiber_manual_record</mat-icon>
            </button>
            <button mat-icon-button color="accent" [class.selected]="reviewSelected == 2"
                    (click)="onReviewSelectorClicked(2)">
                <mat-icon>fiber_manual_record</mat-icon>
            </button>
            <button mat-icon-button color="accent" [class.selected]="reviewSelected == 3"
                    (click)="onReviewSelectorClicked(3)">
                <mat-icon>fiber_manual_record</mat-icon>
            </button>
        </div>


    </section>

    <section class="packages" #packages>
        <h1 i18n="@@choose_package">
            Scegli l'abbonamento che fa al caso tuo.
        </h1>

        <div class="trial">
            <mat-card>
                <mat-card-content>
                    <p i18n="@@trial">Tutti i pacchetti includono <b>1 mese di prova gratuita!</b></p>
                    <p><small i18n="@@can_be_cancelled">L'abbonamento pu&ograve; essere cancellato in ogni momento.</small></p>
                    <p><small i18n="@@yearly_payment">Pagamento su base annuale</small></p>
                </mat-card-content>
            </mat-card>
        </div>

        <div class="container">
            <mat-card>
                <mat-card-title i18n="@@basic_offer">Base</mat-card-title>
                <mat-card-content>
                    <div class="price">
                        <span class="trialPrice">{{ promotion.period }} <span i18n="@@free_trial">mese gratis</span></span>
                        <span i18n="@@then">poi</span>
                        <span class="discounted-price">{{ promotion.base.price | formatCurrency }}</span>
                        <!-- <div><span class="discounted-price">{{ promotion.base.discountedPrice | formatCurrency }} <span class="sup">-{{ promotion.base.discount }}%</span></span></div> -->
                        <span class="period" i18n="@@per_month">Al mese</span>
                    </div>
                    <div class="description">
                        <p i18n="@@basic_description">Per professionisti o piccole aziende senza tante pretese.</p>
                    </div>
                    <div class="action">
                        <a mat-flat-button color="primary" routerLink="/checkout/basic" i18n="@@select">Seleziona</a>
                    </div>
                </mat-card-content>
                <mat-card-footer>
                    <ul i18n="@@basic_offer_content">
                        <li>fino a 10 dipendenti</li>
                        <li>fino a 20 clienti</li>
                        <li>fino a 50 fornitori</li>
                        <li>fino a 50 fatture al mese</li>
                        <li>fino a 2Gb di spazio</li>
                    </ul>
                </mat-card-footer>
            </mat-card>

            <mat-card class="best">

                <mat-card-subtitle i18n="@@best_choice">Miglior valore</mat-card-subtitle>
                <mat-card-title i18n="@@standard_offer">Standard</mat-card-title>
                <mat-card-content>
                    <div class="price">
                        <span class="trialPrice">{{ promotion.period }} <span i18n="@@free_trial">mese gratis</span></span>
                        <span i18n="@@then">poi</span>
                        <span class="discounted-price">{{ promotion.standard.price | formatCurrency }}</span>
                        <!-- <div><span class="discounted-price">{{ promotion.standard.discountedPrice | formatCurrency }} <span class="sup">-{{ promotion.standard.discount }}%</span></span></div> -->
                        <span class="period" i18n="@@per_month">Al mese</span>
                    </div>
                    <div class="description">
                        <p i18n="@@standard_description">Per aziende di medie dimensioni.</p>
                    </div>
                    <div class="action">
                        <a mat-flat-button color="primary" routerLink="/checkout/standard" i18n="@@select">Seleziona</a>
                    </div>
                </mat-card-content>
                <mat-card-footer>
                    <ul i18n="@@standard_offer_content">
                        <li>fino a 20 dipendenti</li>
                        <li>fino a 50 clienti</li>
                        <li>fino a 100 fornitori</li>
                        <li>fino a 100 fatture al mese</li>
                        <li>fino a 5Gb di spazio</li>
                    </ul>
                </mat-card-footer>
            </mat-card>

            <mat-card>
                <mat-card-title i18n="@@premium_offer">Premium</mat-card-title>
                <mat-card-content>
                    <div class="price">
                        <span class="trialPrice">{{ promotion.period }} <span i18n="@@free_trial">mese gratis</span></span>
                        <span i18n="@@then">poi</span>
                        <span class="discounted-price">{{ promotion.premium.price | formatCurrency }}</span>
                        <!-- <div><span class="discounted-price">{{ promotion.premium.discountedPrice | formatCurrency }} <span class="sup">-{{ promotion.premium.discount }}%</span></span></div> -->
                        <span class="period" i18n="@@per_month">Al mese</span>
                    </div>
                    <div class="description">
                        <p i18n="@@premium_description">Per aziende pi&ugrave; esigenti.</p>
                    </div>
                    <div class="action">
                        <a mat-flat-button color="primary" routerLink="/checkout/premium" routerLinkActive="active" i18n="@@select">Seleziona</a>
                    </div>
                </mat-card-content>
                <mat-card-footer>
                    <ul i18n="@@premium_offer_content">
                        <li>fino a 50 dipendenti</li>
                        <li>fino a 100 clienti</li>
                        <li>fino a 200 fornitori</li>
                        <li>fino a 200 fatture al mese</li>
                        <li>fino a 10Gb di spazio</li>
                    </ul>
                </mat-card-footer>
            </mat-card>

        </div>

    </section>

    <section class="contact" #contact>
        <div class="container">
            <h1 i18n="@@call_to_action">Semplifica la tua gestione. Scegli Edilie.</h1>

            <div class="container">
                <p i18n="@@contact_for_more">Contattaci compilando il form sotto se vuoi saperne di più.</p>
                <p i18n="@@we_will_reply">Saremo lieti di mostrarti come funziona Edilie e rispondere alle tue domande.</p>

                <form #f="ngForm" (ngSubmit)="onSubmit(f)" (change)="formChanged()">
                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@business">Azienda</mat-label>
                        <input name="business" #b="ngModel" matInput required
                               [(ngModel)]="contactRequest.name">
                        <mat-error *ngIf="b.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@email">Email</mat-label>
                        <input name="email" matInput #e='ngModel' pattern="^[\w+\.\-]+@[\w\.\-]+$"
                               [(ngModel)]="contactRequest.email" required/>
                        <mat-error *ngIf="e.hasError('pattern')" i18n="@@invalid_email">Email non valida</mat-error>
                        <mat-error *ngIf="e.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@contact_reason">Motivo della richiesta contatto</mat-label>
                        <textarea name="description" #description="ngModel" matInput required cdkTextareaAutosize
                                  [(ngModel)]="contactRequest.description"></textarea>
                        <mat-error *ngIf="description.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
                    </mat-form-field>

                    <button mat-flat-button color="primary" (click)="sendContactRequestClicked()" i18n="@@contact_us">Contattaci</button>

                </form>
            </div>

        </div>

    </section>
</article>

