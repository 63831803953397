import {Component, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {map, Observable} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ProjectRequestRepository} from "./project-requests.repository";
import {ProjectRequest, Status} from "../model/project.request";

@Component({
  selector: 'app-project-requests',
  templateUrl: './project-requests.component.html',
  styleUrls: ['./project-requests.component.scss']
})
export class ProjectRequestsComponent {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChildren(MatSort) sortQuery: QueryList<MatSort>;

    data: Observable<MatTableDataSource<ProjectRequest>>
    displayedColumns: string[] = ['title', 'requester', 'status', 'date', 'description', 'actions'];

    constructor(private router: Router, private route: ActivatedRoute,
                private projectRequestsRepository: ProjectRequestRepository,
                private dialog: MatDialog
    ) {

    }

    ngOnInit(): void {


    }

    ngAfterViewInit() {
        this.data = this.projectRequestsRepository.getAll().pipe(map(data => {
            data.sort((a, b) => a.date.isBefore(b.date) ? 1 : -1)

            let dataSource = new MatTableDataSource<ProjectRequest>(data);
            dataSource.paginator = this.paginator;
            return dataSource
        }))
    }

    projectRequestSelected(projectRequest) {
        this.router.navigate([projectRequest.id], {relativeTo: this.route});

    }

    protected readonly Status = Status;
}
