import {
    arrayUnion,
    collection,
    collectionData,
    collectionGroup,
    CollectionReference,
    deleteDoc,
    doc,
    DocumentData,
    DocumentReference,
    Firestore,
    getDoc,
    query,
    runTransaction,
    setDoc,
    Transaction,
    updateDoc,
    where,
    writeBatch,
} from "@angular/fire/firestore";
import {Injectable} from "@angular/core";
import {Chat, Message} from "../chats/message";
import {Converter} from "./converter";
import {Entity} from "../model/entity";

@Injectable()
export class FirestoreWrapper {
    constructor(public firestore: Firestore) {
    }

    setDoc(reference: any, data: any): Promise<void> {
        return setDoc(reference, data);
    }

    async runTransaction(callback: (transaction: Transaction) => Promise<unknown>) {
        await runTransaction(this.firestore, callback)
    }

    public doc<T>(collectionReference: CollectionReference<T>, id: string) {
        return doc(collectionReference, id);
    }

    docId(collectionReference: CollectionReference) {
        return doc(collectionReference).id;
    }

    getDoc<T>(documentReference: DocumentReference<T>) {
        return getDoc(documentReference);
    }

    collectionData<T>(collectionReference: CollectionReference<T>) {
        return collectionData(collectionReference);
    }

    deleteDoc<T>(reference: DocumentReference<T, DocumentData>) {
        return deleteDoc(reference)
    }

    collection(path: string, ...paths: string[]) {
        return collection(this.firestore, path, ...paths)
    }

    collectionGroup(path: string) {
        return collectionGroup(this.firestore, path)
    }

    writeBatch() {
        return writeBatch(this.firestore)
    }

    async addToArray(reference: DocumentReference<Entity, DocumentData>, arrayName: string,  element: any, converter: Converter<any>) {
        let params = {}
        params[arrayName] = arrayUnion(converter.toFirestore(element))
        return await updateDoc(reference, params);
    }

    query<T>(collectionReference: CollectionReference<T>, property: string, value: any) {
        return collectionData(query(
            collectionReference,
            where(property, '==', value)
        ));
    }
}
