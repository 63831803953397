<article class="page">

    <div class="content">
        <mat-card class="infoCard registration" *ngIf="true">
            <mat-card-header>
                <mat-icon mat-card-avatar>app_registration</mat-icon>
                <mat-card-title i18n="@@registration">REGISTRAZIONE</mat-card-title>
            </mat-card-header>
            <form (submit)="signUp($event, signupModel)"
                  *ngIf="!emailSent">

                <mat-card-content>
                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@email">Email</mat-label>
                        <input matInput #username2='ngModel' pattern="^[\w+\.\-]+@[\w\.\-]+$"
                               name="username2" [(ngModel)]="signupModel.username"/>
                        <mat-error *ngIf="username2.hasError('pattern')" i18n="@@invalid_email">Email non valida</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@password">Password</mat-label>
                        <input matInput #password2 name="password2" type="password" [(ngModel)]="signupModel.password"/>
                        <button type="button" mat-icon-button matSuffix (click)="togglePassword(password2)">
                            <mat-icon *ngIf="password2.type=='password'">visibility</mat-icon>
                            <mat-icon *ngIf="password2.type=='text'">visibility_off</mat-icon>
                        </button>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@name">Nome</mat-label>
                        <input matInput #name name="name" [(ngModel)]="signupModel.name"/>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label i18n="@@business_name">Societ&aacute;</mat-label>
                        <input matInput #businessName name="businessName" [(ngModel)]="signupModel.businessName"/>
                    </mat-form-field>

                </mat-card-content>

                <mat-card-actions >
                    <button mat-button name="RegistrationSubmitButton" type="submit" i18n="@@signup">REGISTRATI</button>
                </mat-card-actions>
            </form>

            <mat-card-content *ngIf="emailSent">
                <p i18n="@@email_sent">Email inviata. Controlla la tua mailbox per completare la registrazione</p>
            </mat-card-content>
        </mat-card>
    </div>
</article>
