import {InvoiceSender} from "./invoiceSender";
import {Client} from "./client";
import {Business} from "./business";
import {Project} from "./project";
import {Payment} from "./payment";
import {round} from "../utils";
import {Attachment, FiscalDocumentType, Line, PriceModifier, SenderType} from "./invoice";
import {Document} from "./document";
import {Accountant} from "./accountant";
import {Supplier} from "./supply";
import {Moment} from "moment";
import {Entity} from "./entity";

export interface Searchable {
    asText(transformers: any): string
}

export abstract class FiscalDocument extends Entity implements Searchable {
    number: string;
    abstract type: string
    private _sender: InvoiceSender
    receiver: Client | Business
    date: Moment
    currency: string = 'Euro';
    purchaseOrder: string;
    lines: Line[] = [];
    description: string | null = null;
    senderType: SenderType = SenderType.Supplier
    project: Project | null = null;
    payments: Payment[] = [];
    priceModifiers: PriceModifier[] = [];
    attachments: Attachment[] = [];
    receipt: Document | null = null
    sentDate: Moment | null = null;

    filePath: string | null = null

    _tax: number | null = null
    _taxable: number | null = null
    _total: number | null = null

    get tax(): number {
        if (this._tax) return this._tax

        return this.lines.map(value => value.tax).reduce((previousValue, currentValue) => round(currentValue + previousValue), 0)
    }

    get taxable(): number {
        if (this._taxable) return this._taxable

        return this.lines.map(value => value.taxable)
            .reduce((previousValue, currentValue) => {
                return round(currentValue + previousValue)
            }, 0)
    }

    get total(): number {
        if (this._total && !this.isDraft()) {
            return this._total
        }

        let partial = this.getLinesTotal();

        let total = this.priceModifiers
            .filter(value => !value.governmentContribution)
            .map(value => value.getValue(partial) || 0)
            .reduce((previousValue, currentValue) => currentValue + previousValue, partial);

        return round(total)
    }

    getGovernmentContributionTotal() {
        return this.governmentContributions
            .map(value => value.amount!)
            .reduce((previousValue, currentValue) => {
                return currentValue + previousValue
            }, 0)
    }

    getTotalWithContributions() {
        return this.total - this.getGovernmentContributionTotal()
    }

    getLinesTotal() {
        return this.lines.map(line => line.total)
            .reduce((previousValue, currentValue) => {
                return currentValue + previousValue
            }, 0);
    }

    abstract isDraft()

    abstract get status()

    isPaid() {
        return false
    }

    isError() {
        return false
    }

    hasAttachments(): boolean {
        return this.attachments?.length > 0
    }

    addAttachment(attachment: Attachment) {
        this.attachments.push(attachment)
    }

    get governmentContributions(): PriceModifier[] {
        return this.priceModifiers.filter(value => value.governmentContribution)
    }

    getPriceModifiersWithNoContributions(): PriceModifier[] {
        return this.priceModifiers.filter(value => !value.governmentContribution)
    }

    addGovernmentContribution(contribution: PriceModifier) {
        if (!contribution.governmentContribution) throw new Error('Not a contribution')
        this.priceModifiers.push(contribution)
    }

    isSenderASupplier() {
        return this.senderType == SenderType.Supplier
    }

    isSenderABusiness() {
        return this.senderType == SenderType.Business
    }

    isSenderAnAccountant() {
        return this.senderType == SenderType.Accountant
    }

    override getDocuments(): Document[] {
        return [this.receipt!].filter(value => !!value)
    }

    asText(transformers: any) {
        return this.receiver.name.concat(`#${this.number}`)
            .concat(`#${this._sender.name}`)
            .concat(transformers.translateStatusPipe.transform(this))
            .concat(transformers.formatCurrencyPipe.transform(this.total))
            .concat(transformers.formatCurrencyPipe.transform(this.taxable))
            .concat(transformers.formatCurrencyPipe.transform(this.tax))
            .toLowerCase();
    }

    get sender(): InvoiceSender {
        return this._sender;
    }

    set sender(sender: InvoiceSender) {
        this._sender = sender;

        if (sender instanceof Business) {
            this.senderType = SenderType.Business
        } else if (this.sender instanceof Accountant) {
            this.senderType = SenderType.Accountant
        } else if (this.sender instanceof Supplier) {
            this.senderType = SenderType.Supplier
        } else {
            this.senderType = SenderType.Generic
        }
    }

    isForProjectRequest() {
        return false
    }

    getProjectRequestId() {
        return ''
    }

    isCreditNote() {
        return this.type == FiscalDocumentType.CreditNote;
    }
}
