<table mat-table [dataSource]="outboundMessages">

    <ng-container matColumnDef="flowId">
        <th mat-header-cell *matHeaderCellDef style="width: 4%">Flow id</th>
        <td mat-cell *matCellDef="let message"> {{message.flowId}} </td>
    </ng-container>

    <ng-container matColumnDef="business">
        <th mat-header-cell *matHeaderCellDef>Business</th>
        <td mat-cell *matCellDef="let message" [matTooltip]="message.businessId"> {{message.businessName}} </td>
    </ng-container>

    <ng-container matColumnDef="requestTime">
        <th mat-header-cell *matHeaderCellDef>Request time</th>
        <td mat-cell *matCellDef="let message"> {{message.sentRequestTime  | date:'dd/MM/YYYY HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="availability">
        <th mat-header-cell *matHeaderCellDef>Availability date</th>
        <td mat-cell *matCellDef="let message"> {{message.invoiceAvailabilityDate | date}} </td>
    </ng-container>

    <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>File name</th>
        <td mat-cell *matCellDef="let message"
            [matTooltip]="message.filePath"> {{message.fileName}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let message" style="max-width: 13%; text-align: right">

            <button mat-icon-button *ngIf="message.receptionError" [matTooltip]="message.receptionError">
                <mat-icon style="color: red">error</mat-icon>
            </button>

            <button mat-icon-button *ngIf="message.hasError()" (click)="showResponse(message)" matTooltip="invoice errors present">
                <mat-icon style="color: red">error</mat-icon>
            </button>

            <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon class="mat-24">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="downloadXml(message)">
                    <mat-icon>download</mat-icon>
                    <span>Download XML</span>
                </button>

                <button mat-menu-item [disabled]="!message.response" (click)="showResponse(message)">
                    <mat-icon>sms</mat-icon>
                    <span>Show SDI response</span>
                </button>

                <button mat-menu-item (click)="goToInvoice(message)">
                    <mat-icon>article</mat-icon>
                    <span>Go to invoice</span>
                </button>

                <button mat-menu-item (click)="archive(message)">
                    <mat-icon>archive</mat-icon>
                    <span>Archive</span>
                </button>

            </mat-menu>


        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length"
            style="text-align: center">
            Nessun messaggio da SDI presente
        </td>
    </tr>
</table>
