<article class="page" *ngIf="showcase">
    <header>
    </header>
    <div class="content">

        <div class="businessContainer">
            <mat-card class="infoCard business">
                <mat-card-header>
                    <mat-icon mat-card-avatar>business</mat-icon>
                    <mat-card-title>{{ showcase.businessName }}</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <div style="white-space: pre-wrap">{{ showcase.description }}</div>
                </mat-card-content>

            </mat-card>


            <form #f="ngForm" (ngSubmit)="onSubmit(f)">

                <mat-card class="infoCard contactRequest">
                    <mat-card-content>

                        <mat-form-field class="flexible-size">
                            <mat-label i18n="@@name">Nome</mat-label>
                            <input name="name" #b="ngModel" matInput required [(ngModel)]="contactRequest.name">
                            <mat-error *ngIf="b.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field class="flexible-size">
                            <mat-label i18n="@@email">Email</mat-label>
                            <input name="email" matInput #e='ngModel' pattern="^[\w+\.\-]+@[\w\.\-]+$" [(ngModel)]="contactRequest.email" required/>
                            <mat-error *ngIf="e.hasError('pattern')" i18n="@@invalid_email">Email non valida</mat-error>
                            <mat-error *ngIf="e.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
                        </mat-form-field>

                        <mat-form-field class="flexible-size">
                            <mat-label i18n="@@phone_number">Telefono</mat-label>
                            <input name="phoneNumber" #phoneNumber="ngModel" matInput pattern="\+{0,1}[0-9]+" minlength="4"
                                   [(ngModel)]="contactRequest.phoneNumber">
                            <mat-error *ngIf="phoneNumber.hasError('pattern')" i18n="@@invalid_phone_number">Numero telefono non valido</mat-error>
                            <mat-error *ngIf="phoneNumber.hasError('minlength')" i18n="@@phone_number_short">Deve essere almeno 4 cifre</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label i18n="@@message">Messaggio</mat-label>
                            <textarea name="description" #description="ngModel" matInput required cdkTextareaAutosize [(ngModel)]="contactRequest.description"></textarea>
                            <mat-error *ngIf="description.hasError('required')" i18n="@@required">Campo obbligatorio</mat-error>
                        </mat-form-field>

                    </mat-card-content>

                    <mat-card-actions>
                        <button style="text-align: end" mat-flat-button color="primary" i18n="@@contact_us">Contattaci</button>
                    </mat-card-actions>
                </mat-card>
            </form>

        </div>

        <div *ngIf="showcase.logo" class="logoContainer">
            <img class="showcaseLogo" [src]="showcase.logo.downloadUrl ">
        </div>

    </div>
</article>
