import {NotificationType} from "./notification";
import {firestore} from "firebase-admin";
import {Entity} from "./entity";

export class Reminder extends Entity {
    notificationPayload: any
    expiryDate: Date
    notificationType: NotificationType

    notification30daysId: string | null = null
}
