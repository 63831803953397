import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Deliverable, Supplier, Supply} from "../../model/supply";
import {UntypedFormControl} from "@angular/forms";
import {first, map, Observable, startWith} from "rxjs";
import {DocumentStorageService} from "../../document-storage.service";
import {NotificationService} from "../../notification.service";
import {verifyAutocomplete} from "../../utils";
import {SupplyRepository} from "./supply-repository.service";
import {Project} from "../../model/project";
import {NavigatorService} from "../../navigator.service";
import {SupplyPageData} from "../../components/resolvers";
import {FormComponentV2} from "../../components/formV2.component";
import {Transaction} from "@angular/fire/firestore";
import {InboundInvoicesRepository} from "../../invoicing/inbound/inbound-invoices.repository";
import {Balance} from "../../model/balance";

@Component({
    selector: 'supply',
    templateUrl: './supply.component.html',
    styleUrls: ['./supply.component.scss']
})
export class SupplyComponent extends FormComponentV2<Supply> {

    supplierAutocompleteControl: UntypedFormControl = new UntypedFormControl(null, verifyAutocomplete())
    projectAutocompleteControl: UntypedFormControl = new UntypedFormControl(null, verifyAutocomplete())

    private autocompleteSupplierOptions: Supplier[];
    filteredSupplierOptions: Observable<Supplier[]>;

    private autocompleteProjectOptions: Project[];
    filteredProjectOptions: Observable<Project[]>;

    supply: Supply;

    constructor(private route: ActivatedRoute,
                private inboundInvoicesRepository: InboundInvoicesRepository,
                private supplyRepository: SupplyRepository, navigatorService: NavigatorService,
                documentStorage: DocumentStorageService, notificationService: NotificationService) {

        super(documentStorage, navigatorService, notificationService, supplyRepository);
        let supplyPageData = route.snapshot.data.supplyPageData as SupplyPageData;
        this.supply = supplyPageData.supply

        this.autocompleteSupplierOptions = supplyPageData.suppliers

        this.filteredSupplierOptions = this.supplierAutocompleteControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value?.name),
            map(name => (name ? this._filterSuppliers(name) : this.autocompleteSupplierOptions.slice())),
        );

        this.autocompleteProjectOptions = supplyPageData.projects
        this.filteredProjectOptions = this.projectAutocompleteControl.valueChanges.pipe(
            startWith(''),
            map(value => typeof value === 'string' ? value : value?.name),
            map(name => (name ? this._filterProjects(name) : this.autocompleteProjectOptions.slice())),
        );


        this.supplierAutocompleteControl.setValue(this.supply.supplier)
        if (this.supply.projectId) {
            this.projectAutocompleteControl.setValue(this.autocompleteProjectOptions.find(value => value.id == this.supply.projectId))
        }

        if(this.supply.persisted()) {
            this.inboundInvoicesRepository.findBySupply(this.supply).pipe(first(), map(invoices => {
                let balance = new Balance();
                balance.addPaymentsFromInvoices(invoices)
                balance.quotedAmount = this.supply.quotedAmount
                return balance
            })).subscribe(value => this.supply.balance = value)
        }

    }

    private _filterSuppliers(name: string): Supplier[] {
        const filterValue = name.toLowerCase();

        return this.autocompleteSupplierOptions.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    private _filterProjects(name: string): Project[] {
        const filterValue = name.toLowerCase();

        return this.autocompleteProjectOptions.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    getSupplierName(supplier: Supplier): string {
        return supplier && supplier.name ? supplier.name : '';
    }

    getProjectName(project: Project): string {
        return project && project.name ? project.name : '';
    }


    async doCreate(transaction: Transaction, supply: Supply) {
        supply.supplier = this.supplierAutocompleteControl.value
        supply.projectId = this.projectAutocompleteControl.value ? this.projectAutocompleteControl.value.id : null

        this.repository.transactionalSave(transaction, supply)
    }

    async doUpdate(transaction: Transaction, supply: Deliverable): Promise<void> {

        await this.supplyRepository.transactionalUpdate(transaction, supply.id, {
            id: supply.id,
            _type: supply._type,
            description: supply.description,
            order: supply.order,
            quote: supply.quote,
            supplier: supply.supplier,
            projectId: supply.projectId,
            clientId: supply.clientId,
            deliveryDate: supply.deliveryDate,
            estimatedDeliveryDate: supply.estimatedDeliveryDate,
            quotedAmount: supply.quotedAmount,
            version: supply.version +1
        } as Deliverable)

    }

}
