import {Injectable} from '@angular/core';
import {
    collection,
    collectionData,
    doc,
    DocumentData,
    FirestoreDataConverter,
    query,
    QueryDocumentSnapshot,
    SnapshotOptions,
    writeBatch
} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import {DocumentConverter} from "../document.repository";
import {PricedItem, PriceList} from "./price-lists/price-lists.component";
import * as moment from "moment";
import {PricedItemConverter} from "./pricedItem.repository";
import {forkJoin, map, Observable, take} from "rxjs";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class PriceListRepository extends Repository<PriceList> {

    documentConverter: DocumentConverter

    constructor(firestore: FirestoreWrapper) {
        super(firestore, new PriceListConverter())

        this.documentConverter = new DocumentConverter();
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection( `priceLists`).withConverter(this.converter);
    }


    override findById(id: string): Observable<PriceList | undefined> {
        return  forkJoin([
                super.findById(id).pipe(take(1)),
                collectionData(query(collection(doc(this.getCollectionReference(), id).withConverter(this.converter), 'items').withConverter(new PricedItemConverter()))).pipe(take(1))
            ]
        ).pipe(
            map(([list, items]) => {
            let priceList = list as PriceList;
            priceList.items = items as PricedItem[]
            return priceList
        }))

    }


    override save(entity: PriceList, noNotification: boolean = false): Promise<PriceList> {
        const batch = this.firestoreWrapper.writeBatch();
        if (!entity.id) entity.id = this.getId()

        let priceListRef = doc(this.getCollectionReference(), entity.id);
        batch.set(priceListRef, entity)

        let pricedItemConverter = new PricedItemConverter();
        entity.items.forEach(value => {
            value.id = value.code
            batch.set(doc(priceListRef, 'items', value.code).withConverter(pricedItemConverter), value);
        })

        return batch.commit().then(value => entity)
    }
}


class PriceListConverter implements FirestoreDataConverter<PriceList> {
    constructor() {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): PriceList {
        const data = snapshot.data(options)!
        let priceList = new PriceList();
        priceList.id = data['id']
        priceList.name = data['name']
        priceList.length = data['length']
        priceList.index = data['index']
        priceList.date = moment(data['date'].toDate())

        return priceList
    }

    toFirestore(priceList: PriceList): DocumentData {
        let newVar = {
            id: priceList.id,
            name: priceList.name,
            length: priceList.length,
            index: priceList.index,
            date: new Date(),
        };
        console.log("price", newVar)
        return newVar
    }
}
