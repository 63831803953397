import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {Document} from "../../model/document";
import {MatFormFieldControl} from "@angular/material/form-field";
import {Subject} from "rxjs";
import {MatInput} from "@angular/material/input";
import {getCurrentBusiness} from "../../utils";
import {DocumentStorageBatch} from "../../document-storage.service";

@Component({
    selector: 'document-input',
    templateUrl: "document-input.component.html",
    styleUrls: ["document-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DocumentInputComponent),
            multi: true
        },
        {provide: MatFormFieldControl, useExisting: DocumentInputComponent}]
})
export class DocumentInputComponent implements OnInit, ControlValueAccessor, MatFormFieldControl<Document> {
    @ViewChild('fileUpload')
    inputFile: ElementRef

    @Output("selected") documentSelectedEmitter = new EventEmitter<DocumentEvent>();
    @Output("removed") documentRemovedEmitter = new EventEmitter<DocumentEvent>();

    @Input("type") type: string
    @Input("label") label: string
    @Input("batch") deletedFiles: DocumentStorageBatch

    @Input() displayName: boolean = false;
    onChange: any = () => {
    }
    onTouch: any = () => {
    }

    document: Document | null;

    constructor() {
    }

    static nextId = 0;
    @HostBinding() id = `document-input-${DocumentInputComponent.nextId++}`;

    stateChanges = new Subject<void>();
    private _placeholder: string = 'Nessun documento';
    ngControl: NgControl | null;
    focused: boolean;
    empty: boolean;
    shouldLabelFloat: boolean;
    @Input() required: boolean;
    @Input() disabled: boolean;
    errorState: boolean;

    setDescribedByIds(ids: string[]): void {
        //throw new Error("Method not implemented.");
    }

    onContainerClick(event: MouseEvent): void {
    }

    get placeholder(): string {
        return this._placeholder;
    }

    @Input()
    set placeholder(value: string) {
        this._placeholder = value;
        this.stateChanges.next()
    }

    ngOnInit(): void {
    }

    onDocumentSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.document = Document.makeDocument(this.type || this.document?.name || '', file, getCurrentBusiness());
            this.documentSelectedEmitter.emit({document: this.document})
            this.onChange(this.document)
        }
    }


    remove(event: MouseEvent) {
        event.stopPropagation()
        event.preventDefault()
        this.document?.markDeleted()
        this.deletedFiles.addToDeleted(this.document!)
        this.documentRemovedEmitter.emit({document: this.document!})
        this.clear()
    }

    @Input("value")
    set value(value: Document | null) {
        if (value !== undefined && this.document !== value) {
            this.document = value;
            this.onChange(value)
            this.onTouch(value)
            this.stateChanges.next()
        }
    }

    get value(): Document | null {
        return this.document;
    }

    selectDocument(event: MouseEvent | undefined = undefined) {

        if(event) {
            event.preventDefault()
            event.stopPropagation()
        }

        this.inputFile.nativeElement.click()
    }

    isDownloadable(): boolean {
        return !!this.document && this.document.isDownloadable()
    }

    getDownloadUrl() {
        return this.document?.downloadUrl
    }

    clear() {
        this.document = null
        this.onChange(null)
    }

    writeValue(document: Document): void {
        this.document = document
    }

    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn
    }
}

export class DocumentEvent {
    constructor(public document: Document) {
    }
}


