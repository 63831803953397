import {Injectable} from '@angular/core';
import {Client} from "../model/client";
import {collectionData, query, Transaction, where} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import {firstValueFrom, map, Observable} from "rxjs";
import {Project} from "../model/project";
import {getCurrentBusiness} from "../utils";
import {ProjectRepositoryV2} from "../projects-management/project.repository";
import {ProjectConverter} from "../projects-management/project-repository.service";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";
import {ConverterRegistry} from "../persistence/converter-registry.service";


@Injectable({
    providedIn: 'root'
})
export class ClientRepository extends Repository<Client> {


    constructor(firestore: FirestoreWrapper, private projectConverter: ProjectConverter) {
        super(firestore, ConverterRegistry.find('Client'))
    }

    ngOnInit(): void {
    }

    override getCollectionReference() {
        let business = getCurrentBusiness();
        return this.firestoreWrapper.collection(`businesses/${business.id}/clients`).withConverter(this.converter);
    }

    getProjects(clientId: string): Observable<Project[]> {
        return this.makeProjectRepository(clientId).getAllByClient(clientId)
    }


    private makeProjectRepository(clientId: string) {
        return new ProjectRepositoryV2(this.firestoreWrapper, this.projectConverter)
    }

    override getAll(): Observable<Client[]> {
        return super.getAll().pipe(map(value => value.sort((a, b) => a.name.localeCompare(b.name))));
    }

    async updateClient(transaction: Transaction, client: Client) {
        await this.transactionalUpdate(transaction, client.id, {
            name: client.name,
            taxNumber: client.taxNumber,
            phoneNumber: client.phoneNumber,
            address: {
                nation: client.address.nation,
                street: client.address.street,
                city: client.address.city,
                district: client.address.district,
                postcode: client.address.postcode,
            },
            sdi: client.sdi,
            organizationType: client.organizationType,
            identificationDocument: client.identificationDocument,
            email: client.email,
            version: client.version + 1
        } as Client);
    }

    findProjectById(clientId: string, id: string) {
        return this.makeProjectRepository(clientId).findProjectById(clientId, id)
    }

    saveProject(transaction: Transaction, project: Project) {
        this.makeProjectRepository(project.clientId).transactionalSave(transaction, project)
    }

    updateProject(transaction: Transaction, project: Project) {
        return this.makeProjectRepository(project.clientId).transactionalUpdate(transaction, project.id, {
            ...project,
            version: project.version + 1
        });
    }


    //  getClientSummary(clientId: string) {
    //let collection = collectionGroup(this.firestore, 'projects').withConverter(this.projectRepository.converter);
    //let projectQuery = query(
    //    collection,
    //    where('businessId', '==', getCurrentBusiness().id),
    //    where('clientId', '==', clientId)
    //)
//
    //getAggregateFromServer(projectQuery, {
    //    numberOfProjects: count(),
    //    numberOfInvoices: sum('balance')
    //}).then(value => console.log("--", value.data()))
//
    //return collectionData(projectQuery)
    //}

    findByTaxNumber(taxNumber: string) {
        return firstValueFrom(collectionData(
            query(this.getCollectionReference(), where('taxNumber', '==', taxNumber))
        ))
    }
}
