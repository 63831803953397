<mat-card class="infoCard activities">
    <mat-card-header>
        <mat-card-title i18n="@@activities">Pianficazione Lavori</mat-card-title>

        <mat-icon mat-card-avatar>task</mat-icon>
    </mat-card-header>

    <mat-card-content>
        <table mat-table [dataSource]="activities" [hidden]="hideTable()">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef i18n="@@activity name">Attivit&agrave;</th>
                <td mat-cell *matCellDef="let activity"> {{ activity.name }}</td>
            </ng-container>

            <ng-container matColumnDef="from">
                <th mat-header-cell *matHeaderCellDef i18n="@@start_date">Data inizio</th>
                <td mat-cell *matCellDef="let activity"> {{ activity.from | date }}</td>
            </ng-container>

            <ng-container matColumnDef="to">
                <th mat-header-cell *matHeaderCellDef i18n="@@end_date">Data fine</th>
                <td mat-cell *matCellDef="let activity"> {{ activity.to | date }}</td>
            </ng-container>

            <ng-container matColumnDef="assignees">
                <th mat-header-cell *matHeaderCellDef i18n="@@employees">Dipendenti</th>
                <td mat-cell *matCellDef="let activity">
                    <mat-chip-listbox>
                        <mat-chip-option *ngFor="let assignee of activity.assignees">{{ assignee.fullName }}</mat-chip-option>
                    </mat-chip-listbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="actionColumnHeader">
                </th>
                <td mat-cell *matCellDef="let activity">
                    <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openEditDialog($event, activity)">
                            <mat-icon class="mat-24">edit</mat-icon>
                            <span i18n="@@edit">Modifica</span>
                        </button>

                        <button mat-menu-item  (click)="remove($event, activity)">
                            <mat-icon class="mat-24">delete</mat-icon>
                            <span i18n="@@delete">Rimuovi</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <div *ngIf="hideTable()">
            <p i18n="@@no_activities">Nessuna attivit&agrave;</p>
        </div>
    </mat-card-content>

    <mat-card-actions>
        <button name="openFormButton" mat-button color="primary" type="button" (click)="openNewDialog()">Aggiungi</button>
    </mat-card-actions>

</mat-card>
