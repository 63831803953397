import {Injectable} from '@angular/core';
import {ReminderRepository} from "./reminder.repository";
import {Notification} from "../model/notification";
import {NotificationRepository} from "./notification.repository";
import {doc, runTransaction, Transaction} from "@angular/fire/firestore";
import {firstValueFrom, map} from 'rxjs';
import {Reminder} from "../model/reminder";

@Injectable({
    providedIn: 'root'
})
export class ReminderService {

    constructor(private reminderRepository: ReminderRepository, private notificationRepository: NotificationRepository) {
    }

    createNotificationFromReminders() {

        return firstValueFrom(this.reminderRepository.findNonExpired().pipe(map(allReminders => {

            let reminders = allReminders.filter(reminder => !reminder.notification30daysId);

            return reminders.map(async reminder => {
                    return runTransaction(this.reminderRepository.firestore.firestore, async transaction => {
                        let notificationId = `30D:${reminder.id}`;
                        let notificationSnap = await transaction.get(doc(this.notificationRepository.getCollectionReference(), notificationId));
                        let notification = notificationSnap.exists() ? notificationSnap.data() : new Notification();
                        notification.id = notificationId
                        notification.payload = reminder.notificationPayload
                        notification.type = reminder.notificationType

                        reminder.notification30daysId = notification.id

                        this.saveNotification(transaction, notification);
                        this.updateReminder(transaction, reminder);
                    })
                })
            })
        ))
    }

    private updateReminder(transaction: Transaction, reminder: Reminder) {
        let collectionReference = this.reminderRepository.getCollectionReference().withConverter(null);
        let documentReference = doc(collectionReference, reminder.id);

        transaction.update(documentReference, {
            notification30daysId: reminder.notification30daysId,
        })
    }

    private saveNotification(transaction: Transaction, notification: Notification) {
        let collectionReference = this.notificationRepository.getCollectionReference()
        let documentReference = doc(collectionReference, notification.id)

        transaction.set(documentReference, {
            id: notification.id,
            payload: notification.payload,
            type: notification.type,
            acknowledged: notification.acknowledged
        }, {merge: true})
    }
}
