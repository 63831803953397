import {Injectable} from "@angular/core";
import {Repository} from "../persistence/repository";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";
import {ConverterRegistry} from "../persistence/converter-registry.service";
import {FrontendError} from "../model/frontend-error.request";

@Injectable({
    providedIn: 'root'
})
export class FrontendErrorRepository extends Repository<FrontendError> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, ConverterRegistry.find('FrontendError'))
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection(`frontendErrors`).withConverter(this.converter);
    }

}
