<mat-card class="infoCard journal">
    <mat-card-header>
        <div class="title">
            <mat-card-title i18n="@@journal">Giornale Lavori</mat-card-title>

            <div class="tableHeader">
                <mat-icon (click)="moveTimeLeft()">arrow_left</mat-icon>
                <p>{{ year }} {{ monthName }}</p>
                <mat-icon (click)="moveTimeRight()">arrow_right</mat-icon>
            </div>
        </div>
        <mat-icon mat-card-avatar>task</mat-icon>
    </mat-card-header>

    <mat-card-content>
        <div *ngIf="activitiesObservable | async as activitiesDatasource">

            <table mat-table [dataSource]="activitiesDatasource">

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef i18n="@@date">Data</th>
                    <td mat-cell *matCellDef="let activity"> {{ activity.day.date | date }}</td>
                </ng-container>

                <ng-container matColumnDef="assignee">
                    <th mat-header-cell *matHeaderCellDef i18n="@@employee_or_supplier">Dipendente/Fornitore</th>
                    <td mat-cell *matCellDef="let activity">
                        {{ activity.subject.name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="hours">
                    <th mat-header-cell *matHeaderCellDef i18n="@@hours">Ore</th>
                    <td mat-cell *matCellDef="let activity"> {{ activity.hours }}</td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef i18n="@@activity">Attività</th>
                    <td mat-cell *matCellDef="let activity"> {{ activity.description }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns.length"
                        style="text-align: center" i18n="@@no_activities">
                        Nessuna attivit&agrave; presente
                    </td>
                </tr>

            </table>
        </div>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons [style.display]="paginatorDisplayed? '' : 'none'"></mat-paginator>
    </mat-card-content>

</mat-card>
