<article class="page">
    <header><h1 i18n="@@legal_notes">Note Legali</h1></header>
    <p>Edilie.com | Pragmatic Developers Ltd.<br>
        Derby, UK.<br>
        VAT 399682027.</p>

    <h2 i18n="@@legal_notes_h2_1">Comunicazione relativa al software disponibile in questo sito web</h2>
    <p i18n="@@legal_notes_p_1">Qualsivoglia software reso disponibile da questo server (il "Software") è opera coperta da copyright di Pragmatic
        Developers Ltd. e/o dei relativi fornitori. L'uso del Software è disciplinato dalle condizioni dell'eventuale
        Contratto di Licenza con l'utente finale accluso al software (il "Contratto di Licenza"). L'utente finale non è
        autorizzato a installare il Software che è accompagnato da un Contratto di Licenza o che lo include qualora non
        acconsenta in precedenza ai termini del Contratto di Licenza.</p>

    <p i18n="@@legal_notes_p2">Il Software è reso disponibile esclusivamente per l'uso da parte dell'utente finale in conformità con i termini e
        le condizioni del Contratto di Licenza. Qualsivoglia riproduzione o ridistribuzione del Software che non sia
        conforme con il Contratto di Licenza è espressamente vietata dalla legge in vigore e può comportare gravi
        sanzioni civili e penali. I trasgressori saranno perseguiti nella misura massima consentita dalla legge in
        vigore.</p>

    <p i18n="@@legal_notes_p3">Senza per questo limitare la suddetta disposizione, la copia o la riproduzione del software in qualsiasi altro
        server o posizione per un'ulteriore riproduzione o ridistribuzione è espressamente vietata.</p>

    <p i18n="@@legal_notes_p4">Il software è eventualmente coperto da garanzia solo in conformità con i termini e le condizioni del contratto di
        licenza. Fatta eccezione per quanto garantito nel contratto di licenza, con la presente Pragamatic Developers
        Ltd. non riconosce alcuna garanzia o condizione relativamente al software, incluse tutte le garanzie e
        condizioni implicite di commerciabilità, idoneità per un fine particolare, titolarità e non violazione dei
        diritti altrui.</p>

    <h2 i18n="@@legal_notes_h2_2">Comunicazione relativa alla documentazione disponibilein questo sito web</h2>
    <p i18n="@@legal_notes_p5">L'utente è autorizzato a utilizzare la Documentazione disponibile in questo server (il "Server"), a
        condizione che (1) la comunicazione di copyright di cui infra sia presente in tutte le copie e che sia
        accompagnata
        dalla presente autorizzazione, (2) l'uso di tale Documentazione sia inteso solo a scopo informativo e non
        commerciale o per uso personale e che la suddetta Documentazione non sia copiata o distribuita su alcun computer
        di
        rete o trasmessa tramite alcun canale di comunicazione e infine (3) non vengano apportate modifiche ad alcuna
        parte
        della Documentazione. L'uso per qualsiasi altro scopo è espressamente vietato dalla legge in vigore e può
        comportare
        gravi sanzioni civili e penali. I trasgressori saranno perseguiti nella misura massima consentita dalla legge in
        vigore.</p>

    <p i18n="@@legal_notes_p6">La Documentazione sopra indicata non include la struttura o il layout del sito Web di Edilie.com o di qualsiasi
        altro sito sviluppato, concesso in licenza, controllato o di proprietà di Pragmatic Developers Ltd. Gli elementi
        costitutivi dei siti Web di Pragmatic Developers Ltd. sono tutelati da accordi commerciali, da leggi sui marchi
        registrati e sulla concorrenza sleale e da altre leggi e non possono essere copiati o imitati integralmente o
        parzialmente. Nessun logo, elemento grafico, suono o immagine tratta da un sito Web di Pragmatic Developers Ltd.
        può
        essere copiata o ritrasmessa senza l'espressa autorizzazione dell'azienda Pragmatic Developers Ltd.</p>

    <p i18n="@@legal_notes_p7">La Pragmatic Developers e/o i relativi fornitori non rilasciano alcuna dichiarazione relativamente
        all'adeguatezza delle informazioni contenute nella documentazione e nella relativa grafica pubblicati nel
        presente server per qualsiasi scopo. La documentazione e la relativa grafica vengono fornite "così come sono"
        senza garanzia di alcun tipo. Con la presente la Pragmatic Developers e/o i relativi fornitori non riconoscono
        alcuna garanzia o condizione relativamente alle presenti informazioni, incluse tutte le garanzie e condizioni
        implicite di commerciabilità, idoneità per un fine particolare, titolarità e non violazione dei diritti altrui.
        Salvo i casi di dolo e colpa grave, la Pragmatic Developers e/o i relativi fornitori non saranno responsabili
        per danni speciali, indiretti o consequenziali o per altri danni di qualsiasi tipo risultanti da perdita del
        diritto d'uso, perdita di informazioni o mancato guadagno, siano essi risultanti dall'adempimento del contratto,
        da negligenza o da altre azioni lesive, derivanti da o in qualsiasi modo connessi all'utilizzo o alle
        prestazioni delle informazioni disponibili sul presente server.</p>

    <p i18n="@@legal_notes_p8">La documentazione e la relativa grafica pubblicate su questo server possono includere inaccuratezze tecniche o
        errori tipografici. Le suddette informazioni sono soggette a modifiche periodiche. La Pragmatic Developers Ltd.
        e/o i relativi fornitori possono apportare miglioramenti o modifiche ai prodotti e/o ai programmi descritti nel
        presente contratto in qualunque momento.</p>

    <h2 i18n="@@legal_notes_h2_3">Comunicazioni relative al software, alla documentazione e ai servizi disponibili in questo sito web</h2>
    Salvo i casi di dolo o colpa grave, la Pragmatic Developers e/o i relativi fornitori non saranno responsabili per
    danni speciali, indiretti o consequenziali o per altri danni di qualsiasi tipo risultanti da perdita del diritto
    d'uso, perdita di informazioni o mancato guadagno, siano essi risultanti dall'adempimento del contratto, da
    negligenza o da altre azioni lesive, derivanti da o in qualsiasi modo connessi all'utilizzo o alle prestazioni del
    software e della documentazione o all'errata o mancata fornitura dei servizi o delle informazioni disponibili sul
    presente server.

    <br>

    <br>

    <h2 i18n="@@legal_notes_h2_4">Collegamenti ad altri siti</h2>
    <p i18n="@@legal_notes_p9">Alcuni collegamenti consentono di uscire dal sito Edilie.com. I siti ai quali rimandano tali collegamenti non
        sono sotto il controllo di Pragmatic Developers Ltd., pertanto tale azienda non è responsabile del relativo
        contenuto o di eventuali altri collegamenti in essi contenuti ovvero di eventuali modifiche o aggiornamenti ai
        suddetti siti. La Pragmatic Developers Ltd. non è responsabile di eventuali pubblicazioni su web o di qualsiasi
        altra forma di trasmissione ricevuta da altri siti collegati. La Pragmatic Developers Ltd. fornisce questi
        collegamenti solo per motivi di praticità e l'inserimento dei suddetti collegamenti non implica l'approvazione
        dei siti corrispondenti da parte della Pragmatic Developers Ltd.</p>

    <p i18n="@@legal_notes_p10">COMUNICAZIONE DI COPYRIGHT. Copyright © Edilie.com - Pragmatic Developers Ltd. - Derby, UK, - Tutti i
        diritti riservati.</p>

    <p i18n="@@legal_notes_p11">Tutti i diritti non espressamente concessi dal presente Contratto sono riservati</p>
</article>
