import {Moment} from "moment";
import {Document} from "./document";
import {Entity} from "./entity";


export class MedicalRecord extends Entity {

    description: string
    supplier: { name: string, id: string, taxNumber: string }
    expectedDate: Moment | null = null
    date: Moment | null = null
    certificate: Document | null = null

    override getDocuments(): Document[] {
        return [this.certificate!].flat().filter(value => !!value)
    }

}

export class RecordsProfile<T extends Entity> extends Entity {
    records: T[] = []

    findIndex(filter: (value) => boolean): number {
        return this.records.findIndex(filter)
    }

    remove(index: number): void {
        this.records.splice(index, 1)
    }

    add(record: T): void {
        this.records.push(record)
    }

    override getDocuments() {
        return this.records.map(value => value.getDocuments()).flat().filter(value => !!value)
    }

}

export class MedicalProfile extends RecordsProfile<MedicalRecord> {

}
