import {Component, OnInit} from '@angular/core';
import {Client} from "../../model/client";
import {ActivatedRoute, Router} from "@angular/router";
import {ClientRepository} from "../client.repository";
import {Project} from "../../model/project";
import {DocumentStorageService} from "../../document-storage.service";
import {Bouncer} from "../../bouncer.service";
import {OrganizationType} from "../../model/business";
import {NavigatorService} from "../../navigator.service";
import {NotificationService} from "../../notification.service";
import {Transaction} from "@angular/fire/firestore";
import {FormComponentV2} from "../../components/formV2.component";
import {countries, districts} from "../../utils";

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.scss']
})
export class ClientComponent extends FormComponentV2<Client> implements OnInit {

    privatePersonType = OrganizationType.PrivatePerson
    legalEntityType = OrganizationType.LegalEntity
    publicAdministrationType = OrganizationType.PublicAdministration

    client: Client
    displayedColumns: string[] = ['name', 'address', 'completed'];
    isErpEnabled: boolean;

    constructor(private router: Router, private route: ActivatedRoute, private clientRepository: ClientRepository, private bouncer: Bouncer,
                navigatorService: NavigatorService,
                notificationService: NotificationService,
                documentStorage: DocumentStorageService,
    ) {
        super(documentStorage, navigatorService, notificationService, clientRepository)
    }

    ngOnInit(): void {
        this.isErpEnabled = this.bouncer.getConfig().erp

        this.client = this.route.snapshot.data.client
    }

    async doCreate(transaction: Transaction, client: Client) {
        this.clientRepository.transactionalSave(transaction, client)
    }

    async doUpdate(transaction: Transaction, client: Client) {
        await this.clientRepository.updateClient(transaction, client)
    }

    delete(client: Client) {
        this.clientRepository.delete(client)
    }

    getStatus(project: Project) {
        return project.completed ? 'completato' : 'in corso'
    }

    projectSelected(project) {
        this.router.navigate(['projects', project.id], {relativeTo: this.route});
    }

    hideTable(projects: Project[]): boolean {
        return projects.length == 0
    }

    protected readonly districts = districts;
    protected readonly countries = countries;
}
