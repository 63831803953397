import {Entity} from "./entity";
import {persist, PersistenceConfig} from "../persistence/decorators";


@persist(new PersistenceConfig({name: 'FrontendError', properties: ['data', 'description', 'user'], timestamped: true}))
export class FrontendError extends Entity {
    description: string;
    data: string
    user: string
}

