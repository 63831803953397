import {MatTable} from "@angular/material/table";
import {clone, generateId} from "../../utils";
import {Component, EventEmitter, Output} from "@angular/core";
import {ControlValueAccessor} from "@angular/forms";
import {FormComponent} from "../form.component";
import {MatDialog} from "@angular/material/dialog";
import {Entity} from "../../model/entity";

@Component({
    template: '',
})

// IMPORTANT this.onChange is used when opening the edit panel to force the form to a non-pristine state
export abstract class CrudCardComponent<E extends Entity> extends FormComponent implements ControlValueAccessor {

    private dialogComponent: any;

    onChange = (list: E[]) => {};
    onTouched = () => {};
    touched = false;
    disabled = false;

    subject: E
    editMode: boolean = false

    list: E[] = []
    table: MatTable<E>
    @Output() onDeleted: EventEmitter<E> = new EventEmitter<E>()

    constructor(public dialog: MatDialog | null = null) {
        super();
        this.subject = this.newEntity();
    }

    setDialogComponent(dialogComponent: any) {
        this.dialogComponent = dialogComponent
    }

    refreshTable() {
        this.table.renderRows();
    }

    submit(event) {
        if (this.editMode) this.closeEdit()
        else this.add(event)
    }

    add(event: any) {
        this.beforeAdd()
        this.subject.id = generateId()
        this.subject.transient = true
        this.list.push(this.subject)
        this.subject = this.newEntity()
        this.refreshTable()
        this.onChange(this.list)

        this.onEntitySaved(this.subject)
    }

    public abstract newEntity()

    discardChanges() {
        this.subject = this.newEntity()
    }

    closeEdit() {
        this.beforeCloseEdit()
        this.editMode = false
        let target = this.list.find(value => value.id === this.subject.id);
        Object.assign(target!, this.subject)
        this.onChange(this.list)
        this.onEntityEdited(this.subject)
        this.subject = this.newEntity()
    }

    remove(event: Event, entity: E) {
        event.preventDefault()
        let index = this.list.findIndex(value => value === entity);
        let removed = this.list.splice(index, 1)[0];
        this.refreshTable()
        this.onChange(this.list)
        this.onDeleted.emit(removed)
    }

    edit(event: Event, entity: E) {
        this.beforeEdit(event, entity)
        event.preventDefault()

        this.subject = clone(entity)
        this.editMode = true
        this.onChange(this.list)

        this.afterExpanded(event, entity)

    }

    writeValue(list: E[]) {
        this.list = list
        this.onListWritten()
        this.refreshTable()
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    beforeCloseEdit() {
    }

    beforeEdit(event: Event, entity: E) {
        // TODO use modal instead of panel?
        this.markAsTouched()
    }

    afterExpanded(event: Event, entity: E) {
    }

    beforeAdd() {
    }

    onEntitySaved(entity: E) {

    }

    onEntityEdited(entity: E) {

    }

    hideTable(): boolean {
        return this.list?.length == 0
    }

    onListWritten() {

    }

    openNewRecordDialog(data: any) {
        if(!this.dialog || !this.dialogComponent) throw new Error("no dialog injected")

        data.editMode = false

        this.dialog.open(this.dialogComponent, {
            data: data,
            maxWidth: '500px',
        }).afterClosed().subscribe(result => {
            if(!result) return

            this.list.push(result)
            this.onChange(this.list)
            this.refreshTable()
        })

    }

    openEditRecordDialog(event: MouseEvent, data: any) {
        event.preventDefault()
        event.stopPropagation()

        if(!this.dialog || !this.dialogComponent) throw new Error("no dialog injected")

        data.editMode = true
        this.dialog.open(this.dialogComponent, {
            data: data,
            maxWidth: '500px',
        }).afterClosed().subscribe(result => {
            if(!result) return

            let target = this.list.find(value => value.id === result.id);
            Object.assign(target!, result)
            this.onChange(this.list)
            this.refreshTable()
        })
    }

}
