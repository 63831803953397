import {Injectable} from '@angular/core';
import {
    collectionData,
    collectionSnapshots,
    DocumentData,
    FirestoreDataConverter,
    orderBy,
    query,
    QueryDocumentSnapshot,
    SnapshotOptions
} from "@angular/fire/firestore";
import {Repository} from "../persistence/repository";
import {DocumentConverter} from "../document.repository";
import {PricedItem, PriceList} from "./price-lists/price-lists.component";
import {map, Observable, take, tap} from "rxjs";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class PricedItemRepository extends Repository<PricedItem> {

    documentConverter: DocumentConverter
    priceList: PriceList

    constructor(firestore: FirestoreWrapper, priceList: PriceList) {
        super(firestore, new PricedItemConverter())

        this.documentConverter = new DocumentConverter();
        this.priceList = priceList
        this.initObservables()
    }


    override getCollectionReference() {
        return this.firestoreWrapper.collection( 'priceLists', this.priceList?.id || 'noop', 'items').withConverter(this.converter);
    }

    override getAll() {
        return collectionData(query(this.getCollectionReference()).withConverter(this.converter))
    }

    page(pageSize: number = 10, ...constraints: any[]): Observable<PricedItem[]> {

        let orderBy1 = orderBy('code');

        let q = query(this.getCollectionReference(), orderBy1, ...constraints)
        return collectionSnapshots(q).pipe(take(1), map(value => {
            return value.map(snap => snap.data())
        }), tap(value => console.log("here")))

    }

    count() {
        return this.priceList.length
    }

}

export class PricedItemConverter implements FirestoreDataConverter<PricedItem> {
    constructor() {
    }

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): PricedItem {
        const data = snapshot.data(options)!
        let item = new PricedItem();
        item.code = data['code']
        item.description = data['description']
        item.unitOfMeasure = data['unitOfMeasure']
        item.cost = data['cost']

        return item
    }

    toFirestore(item: PricedItem): DocumentData {
        let newVar = {
            code: item.code,
            description: item.description,
            unitOfMeasure: item.unitOfMeasure,
            cost: item.cost,
        };
        console.log("priced item", newVar)
        return newVar
    }
}
