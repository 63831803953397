import {Component, Inject} from '@angular/core';
import {SickLeave} from "../../../model/leave";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DocumentStorageBatch} from "../../../document-storage.service";

@Component({
  selector: 'app-sick-leave-record-dialog',
  templateUrl: './sick-leave-record-dialog.component.html',
  styleUrls: ['./sick-leave-record-dialog.component.scss']
})
export class SickLeaveRecordDialog {
    record: SickLeave
    batch: DocumentStorageBatch
    editMode: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SickLeaveRecordDialog>) {
        this.record = data.record
        this.batch = data.batch
        this.editMode = data.editMode
    }

    ngOnInit(): void {
    }

    onSubmit() {
        this.dialogRef.close(this.record)
    }

    cancel(event: MouseEvent) {
        event.stopPropagation()
        event.preventDefault()
        this.dialogRef.close(null)
    }
}
