import {Injectable} from "@angular/core";
import {Repository} from "../../persistence/repository";
import {
    collectionData,
    DocumentData,
    FirestoreDataConverter,
    orderBy,
    query,
    QueryDocumentSnapshot,
    SnapshotOptions
} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import * as moment from "moment";
import {Moment} from "moment";
import {Entity} from "../../model/entity";
import {FirestoreWrapper} from "../../persistence/firestoreWrapper";

@Injectable({
    providedIn: 'root'
})
export class InboundSdiMessageErrorRepository extends Repository<MessageError> {


    constructor(firestore: FirestoreWrapper) {
        super(firestore, new MessageErrorConverter())
    }

    ngOnInit(): void {
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection("errorInboundInvoices").withConverter(this.converter);
    }


    override getAll(): Observable<MessageError[]> {
        return collectionData(query(
            this.getCollectionReference(),
            orderBy("receivedTime", "desc"),
        ));
    }
}

class MessageErrorConverter implements FirestoreDataConverter<MessageError> {

    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): MessageError {
        const data = snapshot.data(options)!
        let sdiInbound = new MessageError();
        sdiInbound.id = snapshot.id
        sdiInbound.flowId = data['flowId']
        sdiInbound.errorMessage = data['errorMessage']
        sdiInbound.errorCode = data['errorCode']
        sdiInbound.filePath = data['filePath']
        sdiInbound.messageId = data['messageId']
        sdiInbound.receivedTime = moment(data['receivedTime'].toDate())
        return sdiInbound
    }

    toFirestore(business: MessageError): DocumentData {
        throw new Error("not possible")
    }
}


export class MessageError extends Entity {
    errorMessage: string
    errorCode: string | null
    flowId: number
    messageId: string
    receivedTime: Moment
    filePath: string | null
}
