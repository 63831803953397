import {Injectable} from '@angular/core';
import {Project} from "../model/project";
import {map, take} from "rxjs";
import {Balance} from "../model/balance";
import {OutboundInvoicesRepository} from "../invoicing/outbound/outbound-invoices.repository";
import {InboundInvoicesRepository} from "../invoicing/inbound/inbound-invoices.repository";
import {QuotesRepository} from "../invoicing/quotes/quotes.repository";

@Injectable({
    providedIn: 'root'
})
export class BalanceService {

    constructor(private outboundInvoicesRepository: OutboundInvoicesRepository,
                private inboundInvoicesRepository: InboundInvoicesRepository, private quotesRepository: QuotesRepository) {
    }

    getRevenueBalanceByProject(project: Project) {
        return this.outboundInvoicesRepository.findByProject(project).pipe(take(1), map(invoices => {
            let balance = new Balance();
            balance.addPaymentsFromInvoices(invoices)

            return balance
        }));
    }

    getExpensesBalanceByProject(project: Project) {
        return this.inboundInvoicesRepository.findByProject(project).pipe(take(1), map(invoices => {
            let balance = new Balance();
            balance.addPaymentsFromInvoices(invoices)

            return balance
        }));
    }

    getQuoteBalanceByProject(project: Project) {
        return this.quotesRepository.findByProject(project).pipe(take(1), map(quotes => {
            let balance = new Balance();
            balance.addPaymentsFromInvoices(quotes)

            return balance
        }));
    }

    getRevenueBalanceByYear(year: number) {
        return this.outboundInvoicesRepository.findAllByYear(year).pipe(take(1), map(invoices => {
            let balance = new Balance();
            balance.addPaymentsFromInvoices(invoices)
            return balance
        }))
    }

    getExpensesBalanceByYear(year: number) {
        return this.inboundInvoicesRepository.findAllByYear(year).pipe(take(1), map(invoices => {
            let balance = new Balance();
            balance.addPaymentsFromInvoices(invoices)
            return balance
        }))
    }
}
