import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {OutboundInvoicesRepository} from "../outbound-invoices.repository";
import {map, Observable} from "rxjs";
import {Invoice} from "../../../model/invoice";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Firestore} from "@angular/fire/firestore";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {FiscalDocument} from "../../../model/fiscalDocument";
import {SelectionModel} from "@angular/cdk/collections";
import {FiscalDocumentService} from "../../fiscal-document.service";

@Component({
    templateUrl: './outbound-invoices.component.html',
    styleUrls: ['./outbound-invoices.component.scss']
})
export class OutboundInvoicesComponent implements OnInit, AfterViewInit {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChildren(MatSort) sortQuery: QueryList<MatSort>;


    dataSource: MatTableDataSource<FiscalDocument>
    selection = new SelectionModel<Invoice>(true, []);
    data: Observable<MatTableDataSource<FiscalDocument>>
    displayedColumns: string[] = ['select', 'number', 'status', 'client', 'date', 'dueDate', 'sentDate', 'paid', 'taxable', 'tax', 'total', 'actions'];

    constructor(private router: Router, private route: ActivatedRoute,
                private invoiceRepository: OutboundInvoicesRepository,
                private fiscalDocumentService: FiscalDocumentService,
                private dialog: MatDialog
    ) {

    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.data = this.invoiceRepository.getAll().pipe(map(data => {
            data.sort((a, b) => a.date.isBefore(b.date) ? 1 : -1)

            this.dataSource = new MatTableDataSource<FiscalDocument>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = (invoice, property) => {
                switch (property) {
                    case 'client': {
                        return invoice.receiver.name
                    }
                    case 'paid': {
                        return invoice.isPaid() ? 'Pagata' : 'Non pagata'
                    }
                    default: {
                        return invoice[property];
                    }
                }
            }
            this.sortQuery.changes.subscribe(value => {
                if (value) this.dataSource.sort = value.get(0)
            })
            return this.dataSource
        }))
    }

    invoiceSelected(invoice) {
        this.router.navigate([invoice.id], {relativeTo: this.route});

    }

    deleteInvoice(event: MouseEvent, invoice: Invoice) {
        event.preventDefault()
        event.stopPropagation()

        this.confirmDelete().subscribe(confirmed => {
            if (confirmed) {
                this.invoiceRepository.delete(invoice)
            }
        })
    }

    confirmDelete(): Observable<any> {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: $localize `:@@confirm_delete:Conferma cancellazione`,
                question: $localize `:@@confirm_delete_question:Sei sicuro che vuoi cancellare la fattura?`
            }
        }).afterClosed();
    }

    getPaymentState(invoice) {
        return invoice.isPaid() ? $localize `:@@paid:Pagata` : $localize `:@@not_paid:Non Pagata`
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected == numRows;
    }

    toggleAllRows() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row as Invoice));
    }

    downloadProForma() {
        this.selection.selected.filter(value => !!value.filePath)
            .forEach(invoice => this.fiscalDocumentService.downloadOutboundInvoiceProForma(invoice))
    }

}
