<form #form="ngForm" (ngSubmit)="form.valid && save(timesheet)"
      *ngIf="timesheetObservable | async as timesheet; else timesheetMissing"
      (reset)="discardChanges()">

    <article class="page">
        <header>
            <h1 i18n="@@timesheet">Registro ore</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save"
                        matTooltipPosition="above" [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button [matMenuTriggerFor]="projectsMenu"
                        type="button" mat-mini-fab color="primary" matTooltip="Scarica report per cantiere"
                        i18n-matTooltip="@@download_by_project" matTooltipPosition="above">
                    <mat-icon>summarize</mat-icon>
                </button>

                <mat-menu #projectsMenu="matMenu">
                    <button mat-menu-item *ngFor="let project of projects"
                            (click)="downloadAsPDF(timesheet, project)">{{ project.name }}
                    </button>
                </mat-menu>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel"
                        matTooltipPosition="above">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>

        <div class="content">

            <mat-card class="infoCard timesheet" id=tablecontainer>
                <mat-card-header>
                    <mat-icon mat-card-avatar>punch_clock</mat-icon>
                    <div class="headerWithCommand">
                        <mat-card-title>
                        <span><mat-icon (click)="moveTimeLeft()">arrow_left</mat-icon>
                            {{ year }} {{ getMonthName(timesheet) }}
                            <mat-icon (click)="moveTimeRight()">arrow_right</mat-icon></span>
                        </mat-card-title>

                        <div class="flex-column addSupplier">
                            <mat-form-field class="paymentType" *ngIf="suppliersObservable | async as suppliers">
                                <mat-label i18n="@@add_supplier">Aggiungi Fornitore</mat-label>

                                <mat-select (selectionChange)="addSupplier($event, timesheet)">
                                    <mat-option *ngFor="let supplier of suppliers" [value]="supplier">
                                        {{ supplier.name }}
                                    </mat-option>
                                    <mat-option *ngIf="suppliers.length == 0" [value]="null" i18n="@@no_supplier_left">
                                        Nessun fornitore o nessun fornitore rimasto
                                    </mat-option>

                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-header>

                <mat-card-content [style.max-width]="maxWidth" style="overflow: auto">


                    <table mat-table [dataSource]="timesheet.rows">
                        <ng-container matColumnDef="employee">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <a [routerLink]="[getLink(row), row.subject.id]">{{ row.subject.name }}</a>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="{{column.name}}" *ngFor="let column of daysColumns">
                            <th mat-header-cell *matHeaderCellDef class="hourCell"
                                [class.bad]="timesheet.weatherRainyOnDay(column.day)">{{ column.name }}
                            </th>
                            <td mat-cell *matCellDef="let row" class="hourCell"
                                [class.bad]="timesheet.weatherRainyOnDay(column.day)">
                                    <span *ngIf="!row.isAbsentOnDay(column.day)"
                                          [class.bold]="row.getTotalForDay(column.day) > 0"
                                          (click)="openWorkingDay(row.getDay(column.day))">
                                        {{ row.getTotalForDay(column.day) }}
                                    </span>
                                <mat-icon *ngIf="row.isSickOnDay(column.day)">local_hospital</mat-icon>
                                <mat-icon *ngIf="row.isOnLeaveOnDay(column.day)">close</mat-icon>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef i18n="@@total">Totale</th>
                            <td mat-cell *matCellDef="let row" class="hourCell">
                                {{ row.total }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button type="button" mat-icon-button *ngIf="!element.isEmployee()"
                                        (click)="remove($event, element, timesheet)">
                                    <mat-icon class="mat-24">delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="empty">
                            <th mat-header-cell *matHeaderCellDef>
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="{{column.name}}" *ngFor="let column of weatherColumns">
                            <th mat-header-cell *matHeaderCellDef class="weatherCell"
                                [class.bad]="timesheet.weatherRainyOnDay(column.day)">
                                <span (click)="openWeatherDay(timesheet.weatherForDay(column.day))"
                                      *ngIf="timesheet.weatherSunnyOnDay(column.day)" class="material-symbols-outlined">sunny</span>
                                <mat-icon (click)="openWeatherDay(timesheet.weatherForDay(column.day))"
                                          *ngIf="timesheet.weatherRainyOnDay(column.day)">thunderstorm
                                </mat-icon>
                            </th>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="firstHeaderColumns"></tr>
                        <tr mat-header-row *matHeaderRowDef="secondHeaderColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: secondHeaderColumns"
                            [class.supplier]="row.isSupplier()"></tr>
                    </table>
                </mat-card-content>

            </mat-card>

        </div>
    </article>
</form>

<ng-template #timesheetMissing>
    <p i18n="@@no_timesheet">Registro ore inesistente</p>
</ng-template>
