import {Component} from '@angular/core';
import {Observable, of} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {NgForm} from "@angular/forms";
import {ProjectRequest, Status} from "../../../model/project.request";
import {ProjectRequestRepository} from "../../../project-requests/project-requests.repository";
import {Chat, Message} from "../../../chats/message";
import {ChatRepository} from "../../../chats/chat.repository";
import {generateId} from "../../../utils";
import {MatSelectionList} from "@angular/material/list";

@Component({
  selector: 'app-project-request',
  templateUrl: './project-request.component.html',
  styleUrls: ['./project-request.component.scss']
})
export class ProjectRequestComponent {

    loading: boolean = false;
    projectRequestObservable: Observable<ProjectRequest | undefined>;
    chatsObservable: Observable<Chat[]>;
    readonly Status = Status;
    selectedChat: Chat;

    constructor(private chatRepository: ChatRepository, private route: ActivatedRoute, private projectRequestRepository: ProjectRequestRepository) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            let id = params.get('id');
            if (!id || id === 'new') {
                this.projectRequestObservable = of(new ProjectRequest())
            } else {
                this.projectRequestObservable = this.projectRequestRepository.findById(id)

                this.chatsObservable = this.chatRepository.findByProjectRequestIdCOLLECTIONGROUP(id)
            }
        });
    }

    save(projectRequest: ProjectRequest, form: NgForm) {
        if (!form.valid) return

        this.loading = true;
        console.log("storing project request")
        this.projectRequestRepository.save(projectRequest).then(value => this.loading = false);
    }

    sendMessage(chat: Chat, messageInput: HTMLTextAreaElement, request: ProjectRequest) {

        let message = new Message()

        message.id = generateId()
        message.content = messageInput.value
        message.senderId = 'AA000'
        message.senderName = request.requester

        chat.messages.push(message)

        console.log("storing message")

        this.chatRepository.saveMessage(chat, message).then(() => messageInput.value = '')
    }

    onSelectedChat(chats: MatSelectionList) {
        this.selectedChat = chats.selectedOptions.selected[0].value
    }

    markAsRead(chats: MatSelectionList, message: Message) {
        message.readByReceiver = true
        this.chatRepository.saveChatADMIN(chats.selectedOptions.selected[0].value)
    }

    isNotRead(message: Message) {
        return message.isNotRead('AA000')
    }
}
