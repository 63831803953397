<form *ngIf="projectRequestObservable | async as projectRequest"
      #form="ngForm" (ngSubmit)="save(projectRequest, form)">
    <article class="page" id="projectRequestView">
        <header>
            <h1>Project request</h1>

            <div class="flex-row">
                <button mat-mini-fab color="primary" matTooltip="Salva" i18n-matTooltip="@@save" matTooltipPosition="above"
                        [class.spinner]="loading">
                    <mat-icon *ngIf="!loading">save</mat-icon>
                </button>

                <button type="reset" mat-mini-fab color="primary" matTooltip="Annulla" i18n-matTooltip="@@cancel" matTooltipPosition="above">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </header>
        <div class="content">


            <mat-form-field>
                <mat-label>Title</mat-label>
                <input name="name" #name="ngModel" required matInput [(ngModel)]="projectRequest.title">
                <mat-error *ngIf="name.hasError('required')">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field class="fixed-size">
                <mat-label>Requester</mat-label>
                <input name="requester" #requester="ngModel" required matInput [(ngModel)]="projectRequest.requester">
                <mat-error *ngIf="requester.hasError('required')">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field class="fixed-size">
                <mat-label i18n="@@date">Data</mat-label>
                <input name="date" matInput [matDatepicker]="picker" [(ngModel)]="projectRequest.date" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="name.hasError('required')">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-select name="status" #status="ngModel" required [(ngModel)]="projectRequest.status">
                    <mat-option *ngFor="let status of [Status.OPEN, Status.CLOSED]" [value]="status">
                        {{ status }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="status.hasError('required')" i18n="@@required_field">Campo obbligatorio</mat-error>
            </mat-form-field>


            <mat-form-field>
                <mat-label>Description</mat-label>
                <textarea name="description" #description="ngModel" required matInput [(ngModel)]="projectRequest.description"></textarea>
                <mat-error *ngIf="description.hasError('required')">Campo obbligatorio</mat-error>
            </mat-form-field>


        </div>
    </article>

    <article>
        <header>
            <h1>Chats</h1>
        </header>

        <div class="content">

            <mat-selection-list #chats (selectionChange)="onSelectedChat(chats)" multiple="false" >
                <mat-list-option *ngFor="let chat of chatsObservable | async" [value]="chat" [selected]="selectedChat?.id == chat.id">
                    {{chat.messages[0].senderName}} ({{chat.getNumberOfNotReadByReceiverMessages('AA000')}})
                </mat-list-option>
            </mat-selection-list>

            <mat-card class="chat" *ngIf="selectedChat">
                <mat-card-header>
                    <mat-card-title>Chat</mat-card-title>
                </mat-card-header>

                <mat-list>
                    <mat-list-item class="message" [class.notRead]="isNotRead(message)" *ngFor="let message of selectedChat.messages">
                        <div matListItemTitle>
                            {{ message.senderName }}

                            <mat-icon *ngIf="isNotRead(message)" (click)="markAsRead(chats, message); $event.stopPropagation(); $event.preventDefault()">check</mat-icon>
                        </div>
                        <div matListItemLine>
                            {{ message.content }}
                        </div>
                    </mat-list-item>

                    <mat-list-item class="message noMessage" *ngIf="selectedChat.messages.length == 0">
                        <div matListItemTitle>Nessun messaggio</div>
                    </mat-list-item>

                </mat-list>

                <mat-card-footer>
                    <mat-card-actions>

                        <mat-form-field appearance="fill">
                            <textarea #messageInput name="notes" type="text" matInput placeholder="Invia messaggio..."></textarea>
                        </mat-form-field>
                        <button mat-raised-button type="button" color="primary" i18n="@@send" (click)="sendMessage(selectedChat, messageInput, projectRequest)" [disabled]="! messageInput.value">Invia</button>

                    </mat-card-actions>
                </mat-card-footer>

            </mat-card>
        </div>
    </article>


</form>
