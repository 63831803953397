<mat-card *ngIf="client" class="infoCard summaryCard">
    <mat-card-header>
        <mat-icon mat-card-avatar>person</mat-icon>

        <mat-card-title>{{ client.name }}</mat-card-title>
        <mat-card-subtitle>{{ client.taxNumber }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

        <table>
            <tr>
                <td>
                    <mat-icon>location_city</mat-icon>
                </td>
                <td><span i18n="@@number_of_projects">Progetti</span></td>
                <td>{{ client.projects.length }}</td>
            </tr>

            <tr>
                <td>
                    <mat-icon>arrow_forward</mat-icon>
                </td>
                <td><span i18n="@@issued_invoices">Fatture emesse</span></td>
                <td>{{ numberOfIssuedInvoices }}</td>
            </tr>

            <tr>
                <td>
                    <mat-icon>payments</mat-icon>
                </td>
                <td><span i18n="@@issued_invoices">Fatture pagate</span></td>
                <td>{{ numberOfPaidInvoices }}</td>
            </tr>

            <tr *ngIf="numberOfOverdueInvoices" class="alert">
                <td>
                    <mat-icon>error</mat-icon>
                </td>
                <td><span i18n="@@overdue_invoices">Fatture scadute</span></td>
                <td>{{ numberOfOverdueInvoices }}</td>
            </tr>
        </table>


    </mat-card-content>

    <mat-card-actions>
        <button mat-button [routerLink]="[client.id]" i18n="@@see">VEDI</button>
    </mat-card-actions>
</mat-card>
