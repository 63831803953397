<table mat-table [dataSource]="inboundMessages">

    <ng-container matColumnDef="flowId">
        <th mat-header-cell *matHeaderCellDef style="width: 4%">Flow id</th>
        <td mat-cell *matCellDef="let message"> {{message.id}} </td>
    </ng-container>

    <ng-container matColumnDef="business">
        <th mat-header-cell *matHeaderCellDef>Business</th>
        <td mat-cell *matCellDef="let message" [matTooltip]="message.businessId"> {{message.businessName}} </td>
    </ng-container>

    <ng-container matColumnDef="receivedTime">
        <th mat-header-cell *matHeaderCellDef>Received Time</th>
        <td mat-cell *matCellDef="let message"> {{message.receivedTime   | date:'dd/MM/YYYY HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="format">
        <th mat-header-cell *matHeaderCellDef  style="width: 4%">Format</th>
        <td mat-cell *matCellDef="let message"> {{message.xades? 'Xades' : 'Cades'}} </td>
    </ng-container>

    <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>File name</th>
        <td mat-cell *matCellDef="let message" style="cursor: pointer"> {{message.filePath}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let message" style="width: 10%; text-align: right">

            <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon class="mat-24">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item [disabled]="!message.filePath" (click)="downloadXml(message)">
                    <mat-icon>download</mat-icon>
                    <span>Download XML</span>
                </button>

                <button mat-menu-item [disabled]="!message.hasInvoiceDetails()" (click)="goToInvoice(message)">
                    <mat-icon>article</mat-icon>
                    <span>Go to invoice</span>
                </button>

                <button mat-menu-item (click)="archive(message)">
                    <mat-icon>archive</mat-icon>
                    <span>Archive</span>
                </button>

            </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length"
            style="text-align: center">
            Nessun messaggio da SDI presente
        </td>
    </tr>
</table>
